<template>
  <div class="h-full">
    <BaseDataTable
      ref="table"
      url="/restify/projects"
      entity="projects"
      :url-params="{ related: 'tasks,client' }"
      :columns="columns"
      :actions="actions"
      :extra-actions="rowActions"
      :row-height="40"
      :create-text="$t('Create project')"
      :edit-text="$t('Update project')"
      :add-text="$t('Add project')"
      :transform-data="transformData"
      :is-full-width-row="isFullWidthRow"
      :full-width-cell-renderer="ClientRow"
      @add="$router.push('/projects/create')"
    >
      <template #attributes.name="{ row }">
        <router-link
          :to="`/projects/${row.id}/details`"
          class="cursor-pointer no-underline text-base-content"
        >
          {{ row.attributes.name }}
        </router-link>
      </template>
    </BaseDataTable>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { ColDef, IsFullWidthRowParams } from '@ag-grid-community/core'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import { groupBy } from 'lodash-es'
import {
  ArchiveBoxIcon,
  ArchiveBoxXMarkIcon,
  DocumentPlusIcon,
  PaperAirplaneIcon, TrashIcon,
  XCircleIcon,
} from "@heroicons/vue/24/outline"
import { can } from '@/plugins/permissionPlugin'
import ClientRow from '@/modules/projects/components/ClientRow.vue'
import { RowAction } from "@/components/table/tableTypes"
import i18n from "@/i18n"
import { $confirm } from "@/components/common/modal/modalPlugin"
import { Status } from "@/modules/employees/utils/employeeUtils"
import { success } from "@/components/common/NotificationPlugin"
import Data = API.Data
import { useProjectStore } from "@/modules/projects/store/projectStore"
import { FilterTypes } from "@/components/table/filters/filterTypes"

const props = defineProps({})

const { t } = useI18n()
const columns = ref<ColDef[]>([
  {
    headerName: t('Name'),
    field: 'attributes.name',
    minWidth: 250,
    filter: FilterTypes.ProjectStatus,
  },
])

const actions = computed(() => {
  if (can('manageProjects')) {
    return 'view,add,edit'
  }
  return ''
})

const projectsStore = useProjectStore()

const rowActions = computed<RowAction[]>(() => {
  return [
    {
      label: i18n.t('Archive'),
      icon: ArchiveBoxIcon,
      action: async (project: Data<any>, params: any) => {
        const confirmed = await $confirm({
          title: t('Archive'),
          description: t(`After archiving, the project will no longer be visible in the project list. To restore it, use the archived filter`),
          buttonText: t('Yes, archive'),
          icon: XCircleIcon,
        })
        if (!confirmed) {
          return
        }
        await projectsStore.archiveProject(project)
        await refreshTable()
        await success(t('Project archived'))
      },
      show: (row: Data<any>) => {
        if (row.attributes?.archived) {
          return false
        }
        return can('manageProjects')
      },
    },
    {
      label: i18n.t('Restore'),
      icon: ArchiveBoxXMarkIcon,
      action: async (project: Data<any>) => {
        const confirmed = await $confirm({
          title: t('Restore'),
          description: t(`After restoring, the project will be restored and can be accessed again.`),
          buttonText: t('Yes, restore'),
          icon: XCircleIcon,
        })
        if (!confirmed) {
          return
        }
        await projectsStore.restoreProject(project)
        await refreshTable()
        await success(t('Project restored successfully'))
      },
      show: (row: Data<any>) => {
        return row.attributes?.archived && can('manageProjects')
      },
    },
  ]
})

const router = useRouter()
const route = useRoute()

function transformData(data: any[]) {
  const clientGroups = groupBy(data, 'attributes.client_id')
  const rows = []
  for (const clientId in clientGroups) {
    const projects = clientGroups[clientId]
    const firstEntry = projects[0]
    rows.push({
      id: crypto.randomUUID(),
      is_full_width: true,
      client_id: clientId,
      client: firstEntry?.relationships?.client,
      projects,
    })
    rows.push(...projects)
  }
  return rows
}

const table = ref()
async function refreshTable() {
  await table.value?.refresh()
}

function isFullWidthRow(row: IsFullWidthRowParams) {
  return row.rowNode?.data?.is_full_width
}
</script>
