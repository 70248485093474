<template>
  <router-link
    v-if="params.value"
    :to="routeLink"
    class="no-underline text-base-content capitalize flex items-center space-x-2"
  >
    <component :is="evaluationIcon" class="min-w-[16px] w-4 h-4 text-primary"/>
    <span>
      {{ evaluation.name }}
    </span>
  </router-link>
  <div v-else>
    - -
  </div>
</template>
<script lang="ts" setup>
import { computed } from "vue";
import { storeToRefs } from "pinia";
import { useSettingsStore } from "@/modules/settings/store/settingsStore";
import { Cog8ToothIcon, BanknotesIcon, CheckBadgeIcon } from "@heroicons/vue/24/outline";
import { useAuthStore } from "@/modules/auth/store/authStore";
import { useAuth } from "@/modules/auth/composables/useAuth";
import { useRoute } from "vue-router";

const props = defineProps({
  params: {
    type: Object,
    default: () => ({}),
  }
})

const settingsStore = useSettingsStore()
const { evaluationTypes } = storeToRefs(settingsStore)

const evaluation = computed(() => {
  return evaluationTypes.value.find(p => p.id?.toString() === props.params.value?.toString())?.attributes || {}
})

const { can } = useAuth()
const route = useRoute()

const routeLink = computed(() => {
  if (props.params.link) {
    return props.params.link
  }
  if (!can('manageEvaluations')) {
    return route.fullPath
  }
  return `/settings/evaluations?search=${evaluation.value.name}`
})

const evaluationIcon = computed(() => {
  const typeMap: any = {
    salary: BanknotesIcon,
    technical: Cog8ToothIcon,
    default: CheckBadgeIcon,
  }
  const evaluationName = evaluation.value?.name as string 
  return typeMap[evaluationName] || typeMap.default
})
</script>
