<template>
  <svg
    class="clock"
    :class="{
      running: props.running,
    }"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
    aria-hidden="true"
  >
    <circle cx="12" cy="12" r="10"/>
    <polyline points="12 6 12 12" class="minutes-arrow"/>
    <polyline points="12 12 16 14" class="hours-arrow"/>
  </svg>
</template>

<script setup lang="ts">
const props = defineProps<{
  running: boolean
}>()
</script>

<style>
.clock.running .minutes-arrow {
  animation: rotate-360 3s infinite linear;
  transform-origin: 50% 50%;
}

.clock.running .hours-arrow {
  animation: rotate-360 30s infinite linear;
  transform-origin: 50% 50%;
}

@keyframes rotate-360 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
