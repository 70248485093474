import i18n from "@/i18n"

export function minutesToHours(value: number): string {
  const hours = Math.floor(value / 60)
  const hoursStr = hours.toString().padStart(2, '0')
  const minutes = value % 60
  const minutesStr = minutes.toString().padStart(2, '0')
  return `${hoursStr}:${minutesStr}`
}

export function parseHoursToMinutes(value: string): number {
  const parts = value.split(':')
  if (parts.length === 2) {
    let minutes = +parts[1]
    let hours = +parts[0]
    if (isNaN(hours)) {
      hours = 0
    }
    if (isNaN(minutes)) {
      minutes = 0
    }
    return hours * 60 + minutes
  }
  if (parts.length === 1) {
    const hours = parseFloat(parts[0])
    if (isNaN(hours)) {
      return 0
    }
    return hours * 60
  }
  return 0
}

export function minutesToHoursFixed(minutes: number) {
  if (isNaN(minutes)) {
    return
  }
  const formatter = new Intl.NumberFormat(i18n.locale, {
    style: 'decimal',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })
  return formatter.format(minutes / 60)
}

export function hoursValidation(node: any): boolean {
  try {
    const value = node.value
    if (!value) {
      return false
    }
    const parts = value.split(':')
    if (parts.length > 2) {
      return false
    }
    if (parts.length === 2) {
      const minutes = +parts[1]
      const hours = +parts[0]
      if (isNaN(hours) || isNaN(minutes)) {
        return false
      }
      return minutes >= 0 && minutes < 60 && hours >= 0
    }
    if (parts.length === 1) {
      const val = parseFloat(value)
      return val >= 0 && !isNaN(val)
    }
    return false
  } catch (err) {
    return false
  }
}
