<script lang="ts">
import { defineComponent } from 'vue'
import { useAuthStore } from "@/modules/auth/store/authStore"
import { useTemplateStore } from "@/modules/templates/store/templateStore"
import { isRootDomain } from "@/modules/common/config"

export default defineComponent({
  async beforeRouteEnter(to, from, next) {
    const { token, loginToken, shared_template } = to.query
    const authToken = token || loginToken
    const storageToken = localStorage.getItem('token')
    const authStore = useAuthStore()
    const rootDomain = isRootDomain()
    if (shared_template) {
      const templateStore = useTemplateStore()
      templateStore.sharedTemplateToken = shared_template as string
    }
    if (storageToken && rootDomain) {
      authStore.redirectToSubdomain(authStore.tenantData, storageToken as string)
      return
    }
    if (authToken) {
      await authStore.loginWithToken(authToken as string)
      return next()
    }
    if (localStorage.getItem('token')) {
      return next('/dashboard')
    }
    // Handle auth logic here, redirect to login or home if logged in
    next('/login')
  },
})
</script>
