<template>
  <SettingsCrudPage
    :title="$t('Expense Categories')"
    :modal-component="ExpenseCategoryModal"
    :extra-columns="extraColumns"
    :add-text="$t('Add expense category')"
    :edit-text="$t('Edit expense category')"
    :has-description="true"
    entity="expense-categories"
    entity-path="expenses"
  />
</template>

<script setup lang="ts">
import { computed } from "vue"
import { useI18n } from "vue-i18n"
import SettingsCrudPage from "@/modules/settings/components/SettingsCrudPage.vue"
import ExpenseCategoryModal from "@/modules/settings/components/ExpenseCategoryModal.vue"

const { t } = useI18n()
const extraColumns = computed(() => {
  return [
    {
      headerName: t('Color'),
      field: 'attributes.color',
    },
  ]
})
</script>

<route lang="yaml">
name: Expense Categories
meta:
  permission: manageExpenses
</route>
