<template>
  <div class="min-h-full py-16 px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
    <div class="mx-auto max-w-max">
      <main class="sm:flex">
        <p class="text-4xl font-bold tracking-tight text-primary sm:text-5xl">404</p>
        <div class="sm:ml-6">
          <div class="sm:border-l sm:border-gray-200 sm:pl-6">
            <h1 class="text-4xl font-bold tracking-tight sm:text-5xl">{{$t('Page not found')}}</h1>
            <p class="mt-1 text-base text-base-300">{{$t('Please check the URL in the address bar and try again.')}}</p>
          </div>
          <div class="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
            <RouterLink to="/" class="btn btn-outline btn-primary">{{$t('Go back home')}}</RouterLink>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>
<script setup lang="ts">
</script>
<route lang="yaml">
name: Not Found
meta:
  layout: emptyLayout
  skipOrganizationCheck: true
</route>
