<template>
  <EmployeeDetailLayout :employee-id="route.params.id as string" />
</template>

<script lang="ts" setup>
import { useRoute } from 'vue-router'
import EmployeeDetailLayout from '@/modules/employees/components/EmployeeDetailLayout.vue'
const route = useRoute()
</script>

<route lang="yaml">
redirect:
  name: Employee Details
</route>
