<template>
  <router-link
    v-if="id"
    :to="`/clients/${id}/details`"
    class="flex items-center px-3 py-1.5 bg-primary-light rounded-sm no-underline"
  >
    <span class="text-sm text-primary">
      {{ companyName }}
    </span>
  </router-link>
</template>

<script setup lang="ts">
import { computed } from "vue"
import { useClientStore } from "@/modules/clients/store/clientStore"

const props = defineProps({
  id: {
    type: String,
    default: '',
  },
  data: {
    type: Object,
  },
})

const clientStore = useClientStore()
const client = computed(() => {
  if (props.data) {
    return props.data
  }
  return clientStore.getClientById(props.id)
})

const companyName = computed(() => {
  return client.value?.attributes?.company_name
})
</script>
