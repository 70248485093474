<template>
  <div>
    <PageTitle>
      <template v-if="isEdit">
        {{ client?.company_name }}
      </template>
      <template v-else>
        {{ t('Add Client') }}
      </template>
    </PageTitle>
    <BaseFormCard
      :submit-text="isEdit ? t('Update') : t('Create')"
      @submit="createOrUpdateClient"
      @cancel="onCancel"
    >
      <FormCardSection :title="t('General Information')">
        <div class="col-span-6 md:col-span-2 flex h-full justify-center items-center">
          <AvatarUpload
            v-model="model.logo_path"
            :name="model.company_name"
            :label="$t('Change logo')"
          />
        </div>
        <div class="grid grid-cols-6 gap-x-4 col-span-6 md:col-span-4">
          <FormKit
            v-model="model.company_name"
            name="company_name"
            type="text"
            :label="t('Company name')"
            :placeholder="t('Company name')"
            validation="required"
            outer-class="col-span-6 md:col-span-3"
          />

          <FormKit
            v-model="model.website"
            name="website"
            type="text"
            :label="t('Website')"
            :placeholder="t('Website')"
            rules="url"
            outer-class="col-span-6 md:col-span-3"
            @change="onWebsiteChange"
          />

          <FormKit
            v-model="model.company_identifier"
            name="company_identifier"
            type="text"
            :label="t('Company identifier')"
            :placeholder="t('Company identifier')"
            outer-class="col-span-6 md:col-span-2"
          />

          <FormKit
            v-model="model.company_vat"
            name="company_vat"
            type="text"
            :label="t('Company VAT')"
            :placeholder="t('Company VAT')"
            outer-class="col-span-6 md:col-span-2"
          />

          <FormKit
            v-model="model.prefered_currency"
            name="preferred_currency"
            type="currencySelect"
            :label="t('Currency')"
            :placeholder="t('EUR')"
            outer-class="col-span-6 md:col-span-2"
          />
        </div>

        <FormKit
          v-model="model.description"
          name="description"
          type="textarea"
          :label="t('Description')"
          :placeholder="t('Description')"
          validation="length:0,255"
          outer-class="col-span-6 md:col-span-6"
        />

        <FormKit
          v-model="model.company_address.street"
          name="company_address_street"
          type="text"
          :label="t('Home Street')"
          :placeholder="t('Startdust Way 23')"
          outer-class="col-span-6 md:col-span-3"
        />

        <FormKit
          v-model="model.company_address.zip_code"
          name="company_address_zip"
          type="text"
          :label="t('Zip Code')"
          :placeholder="t('8GJ 7H8')"
          outer-class="col-span-6 md:col-span-1"
        />

        <FormKit
          v-model="model.company_address.city"
          name="company_address_city"
          type="text"
          :label="t('City')"
          :placeholder="t('Cluj-Napoca')"
          outer-class="col-span-6 md:col-span-2"
        />

        <FormKit
          v-model="model.company_address.state"
          name="company_address_state"
          type="text"
          :label="t('State')"
          :placeholder="t('Cluj')"
          outer-class="col-span-6 md:col-span-2"
        />
        <FormKit
          v-model="model.company_address.country"
          name="company_address_country"
          type="countrySelect"
          :label="t('Country')"
          :placeholder="t('Country')"
          outer-class="col-span-6 md:col-span-2"
        />

        <FormKit
          v-model="model.bank_name"
          name="bank_name"
          type="text"
          :label="t('Bank name')"
          :placeholder="t('Bank name')"
          outer-class="col-span-6 md:col-span-3"
        />

        <FormKit
          v-model="model.bank_account"
          name="bank_account"
          type="text"
          :label="t('Bank account')"
          :placeholder="t('Bank account')"
          outer-class="col-span-6 md:col-span-1"
        />

        <FormKit
          v-model="model.bank_swift"
          name="bank_swift"
          type="text"
          :label="t('Bank swift')"
          :placeholder="t('Bank swift')"
          outer-class="col-span-6 md:col-span-2"
        />
      </FormCardSection>
      <FormCardSection :title="t('Contacts')">
        <ClientContacts class="col-span-6" :client-id="model.id"/>
      </FormCardSection>
    </BaseFormCard>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useHead } from '@vueuse/head'
import axios from 'axios'
import { useRoute, useRouter } from 'vue-router'
import Cache from '@/modules/common/Cache'
import PageTitle from '@/components/common/PageTitle.vue'
import FormCardSection from '@/components/form/FormCardSection.vue'
import { useSettingsStore } from '@/modules/settings/store/settingsStore'
import { useClientStore } from '@/modules/clients/store/clientStore'
import ClientContacts from "@/modules/clients/components/ClientContacts.vue";
import AvatarUpload from "@/components/form/AvatarUpload.vue";
import { getLogoFromUrl } from "@/modules/common/utils/stringUtils";
import { cloneDeep } from "lodash-es";

const props = defineProps({
  client: {
    type: Object,
    default: () => ({}),
  },
  loading: Boolean,
})

const { t } = useI18n()
const settingsStore = useSettingsStore()

const address = {
  street: '',
  country: '',
  city: '',
  state: '',
  zip_code: '',
}
const model = ref({
  company_name: '',
  website: '',
  description: '',
  logo_path: '',
  prefered_currency: '',
  company_identifier: '',
  company_vat: '',
  bank_name: '',
  bank_account: '',
  bank_swift: '',
  company_address: {
    ...address,
  },
})
const loading = ref(false)

const isEdit = computed<boolean>(() => {
  return props.client?.id !== undefined
})

const router = useRouter()
const route = useRoute()
const clientStore = useClientStore()

function getRedirectPath() {
  let redirectPath = `/clients/${props.client?.id}/details`
  if (!isEdit.value) {
    redirectPath = '/projects/clients'
  }
  return redirectPath
}

async function saveLogo(clientId: string, logo: File | string | undefined) {
  if (typeof logo === 'string' || !logo) {
    return
  }
  const formData = new FormData()
  formData.append('logo_path', logo)
  return axios.post(`/restify/clients/${clientId}`, formData)
}

async function createOrUpdateClient() {
  try {
    const data: any = {
      ...model.value,
    }
    loading.value = true
    let clientId = data.id
    const logo = model.value.logo_path
    if (isEdit.value) {
      await clientStore.updateClient(data)
    } else {
      const res = await axios.post('/restify/clients', data)
      clientId = res.data.id
    }
    await saveLogo(clientId, logo)
    await router.push(getRedirectPath())
    await clientStore.getClient(data.id)
  } catch (err: any) {
    if (err.handled) {
      return
    }
  } finally {
    loading.value = false
  }
}

function onWebsiteChange() {
  const logo = getLogoFromUrl(model.value.website)
  if (!logo && model.value.logo_path) {
    return
  }
  model.value.logo_path = logo
}

function initModelAddress(addressKey: 'address' | 'company_address') {
  if (!model.value[addressKey]) {
    model.value[addressKey] = {
      ...address,
    }
  } else {
    model.value[addressKey] = {
      ...address,
      ...model.value[addressKey],
    }
  }
}

function initModel(client: any) {
  if (!client) {
    return
  }

  model.value = {
    ...model.value,
    ...client,
  }
  initModelAddress('company_address')

  const keysToDelete = [
    'relationships',
    'meta',
  ]
  keysToDelete.forEach((key: string) => {
    delete model.value[key]
  })
}

watch(() => props.client, (client) => {
  initModel(client)
}, { immediate: true })

async function onCancel() {
  await router.push(getRedirectPath())
}
</script>
