import { defineStore } from 'pinia'
import axios from "axios";
import i18n from "@/i18n";
import Cache from '@/modules/common/Cache'
import { downloadFile } from "@/modules/documents/utils/documentUtils";
import { error } from "@/components/common/NotificationPlugin";
import Data = API.Data;

type Signer = {
  email: string
}
export type DocumentSigningData = {
  signers: Signer[]
}
export const useDocumentsStore = defineStore('documents', {
  state: () => {
    return {
      currentDocument: {} as any,
      currentDocumentLoading: false as boolean,
      currentDocumentError: null as any,
    }
  },
  actions: {
    async getDocument(id: string) {
      if (!id) {
        return
      }
      try {
        this.currentDocumentLoading = true
        const { data } = await Cache.getRequest(`/restify/documents/${id}`, {
          params: {
            temporary_urls: true,
          }
        })
        this.currentDocument = data
        this.currentDocumentError = null
      } catch (err: any) {
        this.currentDocumentError = err
      } finally {
        this.currentDocumentLoading = false
      }
    },

    async updateDocument(data: any) {
      if (!data.id) {
        return
      }
      await axios.put(`/restify/documents/${data.id}`, data)
    },

    async downloadCurrentFile() {
      try {
        const { path, name } = this.currentDocument?.attributes || {}
        downloadFile(path, name)

      } catch (err: any) {
        if (err.handled) {
          return
        }
        error(i18n.t('An error occurred while downloading the document.'))
      }
    },
    async prepareDocumentForSigning(requestData: DocumentSigningData, documentId: string) {
      return await axios.post(`/restify/documents/${documentId}/actions?action=request-signature`, requestData)
    },
    async downloadDocument(document: Data<any>) {
      try {
        const { path, name } = document?.attributes || {}
        downloadFile(path, name)

      } catch (err: any) {
        if (err.handled) {
          return
        }
        error(i18n.t('An error occurred while downloading the document.'))
      }
    }
  },
})
