<template>
  <div
    :class="{
      'bg-white border border-base-200 rounded-sm p-4 md:p-6 w-full mb-1': !isDialog,
    }"
  >
    <slot name="content">
      <div
        :class="{
          'max-w-5xl': !isDialog && !containerClass,
          [containerClass]: containerClass,
        }"
        class="grid grid-cols-6 gap-x-4"
      >
        <h5
          v-if="title && !isDialog || $slots.title"
          :id="titleId"
          :class="titleClass"
          class="col-span-6"
        >
          <slot name="title">
            {{ title }}
          </slot>
        </h5>
        <slot />
      </div>
    </slot>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { snakeCase } from "lodash-es"

const props = defineProps({
  title: {
    type: String,
  },
  isDialog: {
    type: Boolean,
    default: false,
  },
  titleClass: {
    type: String,
  },
  containerClass: {
    type: String,
  },
})

const titleId = computed(() => {
  return snakeCase(props.title)
})
</script>

