<template>
  <div class="w-full flex items-center justify-center">
    <FileUploadButton
      v-if="!hasFilePath"
      :accept="acceptedFileTypes"
      :multiple="false"
      button-size="xs"
      class="print:hidden col-span-6"
      @input="onFileChange"
    >
      {{ $t('Upload file') }}
    </FileUploadButton>
    <BaseButton
      v-else
      outline
      size="xs"
      variant="primary"
      @click="removeFile"
    >
      {{ $t('Remove file') }}
    </BaseButton>
  </div>
</template>

<script>
import axios from 'axios'
import { useCellEditor } from "@/components/table/cells/editors/useCellEditor"
import FileUploadButton from "@/modules/invoices/components/FileUploadButton.vue"
import BaseButton from "@/components/common/buttons/BaseButton.vue"
import { $confirm } from "@/components/common/modal/modalPlugin"

export default {
  components: {
    BaseButton,
    FileUploadButton,
  },
  setup(props) {
    return useCellEditor(props.params)
  },
  data() {
    return {
      acceptedFileTypes: `.pdf,.jpg,.jpeg,.png`,
    }
  },
  computed: {
    hasFilePath() {
      return typeof this.value === 'string'
    },
  },
  methods: {
    onFileChange(event) {
      const file = event.target?.files?.[0]
      if (!file && this.value) {
        this.value = null
        this.onChange(null)
        return
      }
      this.value = file
      this.onChange(file)
    },
    async removeFile() {
      const confirmed = await $confirm({
        title: this.$t('Are you sure you want to remove the file?'),
        buttonText: this.$t('Remove'),
      })
      if (!confirmed) {
        return
      }
      const rowId = this.params?.data?.id
      if (rowId) {
        await axios.delete(`/restify/expenses/${rowId}/field/receipt_path`)
      }
      this.value = null
    },
  },
}
</script>
