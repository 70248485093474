<template>
  <FilterInput
    v-bind="$attrs"
    :data="clients"
    value-key="value"
    label-key="label"
  />
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import FilterInput from '@/components/table/filters/base/FilterInput.vue'
import { useClientStore } from '@/modules/clients/store/clientStore'

const { t } = useI18n()

const clientStore = useClientStore()
const clients = computed(() => {
  return clientStore.allClients.map((client) => {
    const { company_name } = client.attributes
    return {
      value: client.id,
      label: company_name,
    }
  })
})
</script>
