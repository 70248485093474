import { Mention } from "@tiptap/extension-mention"
import { VueNodeViewRenderer, mergeAttributes } from "@tiptap/vue-3"
import VariableNodeTemplate from "@/components/formkit/html/VariableNodeTemplate.vue"

export default Mention.extend({
  name: 'placeholderVariable',

  parseHTML() {
    return [
      {
        tag: 'span',
        getAttrs: (element: any) => {
          return element.hasAttribute('data-id') && element.hasAttribute('data-label')
        },
      },
    ]
  },
  renderHTML({ HTMLAttributes }) {
    return ['span', mergeAttributes(HTMLAttributes), HTMLAttributes['data-id']]
  },
  
  addNodeView() {
    return VueNodeViewRenderer(VariableNodeTemplate)
  },
})
