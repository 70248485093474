<template>
  <div class="flex flex-col">
    <div
      v-if="!hasData && !currentReportLoading"
      class="bg-gray-100 border-l-4 border-gray-400 text-gray-700 p-4 mt-2"
    >
      <div class="flex items-start">
        <InformationCircleIcon class="w-6 h-6 mr-3" />
        <div>
          <p class="font-bold">
            {{ $t("No profitability data") }}
          </p>
          <p class="text-sm mt-1">
            {{
              $t('Profitability data relies on time entries, employee internal cost and client invoices. In order to see profitability data, you need to have at least one time entry, one employee with internal cost and one client invoice.')
            }}
          </p>
        </div>
      </div>
    </div>
    <ReportData :report-type="ReportTypes.Clients" />
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { storeToRefs } from "pinia"
import { InformationCircleIcon } from "@heroicons/vue/24/outline"
import { ReportTypes, useReportsStore } from '@/modules/reports/store/reportsStore'
import ReportData from '@/modules/reports/components/ReportData.vue'
const reportStore = useReportsStore()
reportStore.resetIdFilters()

const { currentReport, currentReportLoading } = storeToRefs(reportStore)

const hasData = computed(() => {
  return currentReport.value.data.length > 0
})
</script>

<route lang="yaml">
name: Clients report
</route>
