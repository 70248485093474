<template>
  <BaseSelect
      v-bind="allAttrs"
      :options="levels"
      :data-loading="levelsLoading"
      value-key="id"
      label-key="attributes.name"
      filterable
      :model-value="value"
      @update:model-value="handleChange"
  />
</template>

<script setup lang="ts">
import { useFormKitInput } from "@/components/formkit/useFormkitInput";
import { useSettingsStore } from "@/modules/settings/store/settingsStore";
import { storeToRefs } from "pinia";
import { onMounted } from "vue";
const props = defineProps({
  context: Object,
})

const settingsStore = useSettingsStore()
const { levels, levelsLoading } = storeToRefs(settingsStore)

const { value, allAttrs, handleChange } = useFormKitInput(props)

onMounted(async () => {
  await settingsStore.getLevels()
})
</script>
