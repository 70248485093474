import axios from 'axios'

export async function downloadFile(url: string, fileName = 'checks.pdf', method = 'GET') {
  let action = 'get'
  if (method === 'POST') {
    action = 'post'
  }
  const data = await axios[action](url, {
    responseType: 'blob',
  })
  downloadFileLocally(data, fileName)
}

export function downloadFileLocally(data: any, fileName: string) {
  const url = window.URL.createObjectURL(new window.Blob([data]))
  const link = document.createElement('a')
  link.href = url

  link.setAttribute('download', fileName)
  document.body.appendChild(link)
  link.click()
}

export function downloadFileFromLink(url: string, fileName: string) {
  const link = document.createElement('a')
  link.href = url

  link.setAttribute('download', fileName)
  document.body.appendChild(link)
  link.click()
}

export async function blobToBase64(blob: Blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => {
      resolve(reader.result)
    }
    reader.readAsDataURL(blob)
    reader.onerror = reject
    reader.onabort = reject
  })
}
