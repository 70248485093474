import { createApp } from 'vue'
import piniaStore from './store/piniaPlugin'
import App from './App.vue'
import router from './router/router'
import globalPlugins from '@/plugins/globalPlugins'
import '@formkit/themes/genesis'
import '@formkit/pro/genesis'
import '@/assets/css/index.scss'

import './modules/common/apiConfig'
import authMiddleware from "@/modules/auth/middleware/authMiddleware"
import flarePlugin from "@/plugins/flarePlugin"

authMiddleware(router)

createApp(App)
  .use(piniaStore)
  .use(router)
  .use(flarePlugin)
  .use(globalPlugins)
  .mount('#app')
