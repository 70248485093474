<template>
  <EvaluationForm />
</template>

<script setup lang="ts">
import { useHead } from "@vueuse/head"
import { useI18n } from "vue-i18n"
import EvaluationForm from "@/modules/evaluations/components/EvaluationForm.vue"
const { t } = useI18n()

useHead({
  title: t('Create Evaluation'),
})
</script>

<route lang="yaml">
name: Create Evaluation
</route>
