<template>
  <div class="relative overflow-hidden inline-block cursor-pointer">
    <BaseButton :variant="variant" :size="buttonSize" type="button">
      <slot/>
    </BaseButton>
    <input
      v-bind="$attrs"
      type="file"
      name="myfile"
      class="absolute left-0 top-0 right-0 bottom-0 opacity-0 text-5xl shadow-none"
      @input.prevent.stop="emit('input', $event)"
    >
  </div>
</template>

<script lang="ts" setup>
import BaseButton from "@/components/common/buttons/BaseButton.vue"

const props = defineProps({
  variant: {
    type: String,
    default: 'secondary',
  },
  buttonSize: {
    type: String,
    default: 'sm',
  },
})

const emit = defineEmits(['input'])
</script>

<style>
</style>
