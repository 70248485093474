<template>
  <div class="h-full flex flex-col account-page">
    <BaseFormCard
      ref="form"
      :submit-text="t('Save')"
      :disabled="loading"
      :show-actions="user.has_password"
      :reset-on-cancel="true"
      class="flex-1"
      @submit="onSubmit"
    >
      <FormCardSection class="h-full">
        <div class="col-span-6">
          <IconBox size="sm" class="mb-3">
            <LockClosedIcon class="w-4 h-4" />
          </IconBox>
          <h4 class="mb-3">
            {{ t('Security') }}
          </h4>
          <h5>{{ t('Change Password') }}</h5>
        </div>

        <template v-if="user.has_password">
          <FormkitPasswordInput
            v-model="model.current_password"
            :name="t('Current Password')"
            :label="t('Current Password')"
            :placeholder="t('Current Password')"
            validation="required|length:6"
            validation-visibility="dirty"
            outer-class="col-span-6 md:col-span-4"
          />

          <FormkitPasswordInput
            v-model="model.password"
            :name="t('New Password')"
            :label="t('New Password')"
            :placeholder="t('New Password')"
            validation="required|length:8|contains_numeric|contains_symbol|contains_uppercase"
            validation-visibility="dirty"
            :validation-messages="{ confirm: t('Passwords do not match') }"
            outer-class="col-span-6 md:col-span-4"
          />
        </template>
        <template v-else>
          <div class="col-span-6 mt-4 prose prose-sm">
            <p>
              {{ t(`It seems you've used social media to create your account. Unfortunately we don't allow changing passwords for accounts created with social media.`) }}
            </p>
            <p>{{ t(``) }}</p>
            <p>
              {{ t(`If you want to set up a password, consider using `) }}
              <router-link to="/forgot-password">
                {{ t('Forgot Password') }}
              </router-link>
            </p>
          </div>
        </template>
      </FormCardSection>
    </BaseFormCard>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { LockClosedIcon } from '@heroicons/vue/24/outline'
import { useI18n } from 'vue-i18n'
import axios from 'axios'
import { useRouter } from 'vue-router'
import { error, success } from '@/components/common/NotificationPlugin'
import FormkitPasswordInput from '@/components/formkit/FormkitPasswordInput.vue'
import { ROOT_API_URL } from '@/modules/common/config'
import { useAuth } from '@/modules/auth/composables/useAuth'

const loading = ref(false)
const model = ref({
  current_password: '',
  password: '',
  password_confirmation: '',
})

const { t } = useI18n()
const { user } = useAuth()

const form = ref()

async function onSubmit() {
  try {
    loading.value = true
    model.value.password_confirmation = model.value.password
    await axios.post(`${ROOT_API_URL}/change-password`, model.value)
    success(t('Password changed successfully'))
    form.value?.resetForm()
  } catch (err: any) {
    if (err.handled) {
      return
    }
    error(t('Could not change the user password'))
  } finally {
    loading.value = false
  }
}

const router = useRouter()
</script>

<route lang="yaml">
name: Security
</route>
