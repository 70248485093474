<template>
  <div ref="el">
    <h1 class="text-center">
      {{ $t('Almost there') }}
    </h1>
    <div class="flex justify-center space-x-2 mt-2">
      <p>{{ $t(`Already have an account?`) }}</p>
      <RouterLink to="/login">{{ $t('Sign In') }}</RouterLink>
    </div>

    <div class="mt-10">
      <FormKit
        #default="{ state: { loading } }"
        :actions="false"
        type="form"
        @submit="onSubmit"
      >

        <FormKit
          v-model="model.username"
          :name="$t('User Name')"
          :label="$t('User Name')"
          :placeholder="$t('Enter your user name')"
          :prefix-icon="UserIcon"
          validation="required"
          validation-visibility="dirty"
        />

        <FormKit
          v-model="model.country"
          :name="$t('Country')"
          :label="$t('Country')"
          :placeholder="$t('Choose your country')"
          :prefix-icon="CountryIcon"
          :select-by-code="true"
          type="countrySelect"
          validation="required"
          validation-visibility="dirty"
        />
        <FormKit
          v-model="model.company_name"
          :name="$t('Company Name')"
          :label="$t('Company Name')"
          :placeholder="$t('Enter your company name')"
          :prefix-icon="BriefCaseIcon"
          validation="required"
          validation-visibility="dirty"
          @update:modelValue="onNameChange"
        />

        <FormKit
          v-model="model.phone"
          :name="$t('Phone')"
          :label="$t('Phone')"
          :placeholder="$t('Enter your phone number')"
          :prefix-icon="PhoneIcon"
          type="tel"
          validation="required|length:8"
          validation-visibility="dirty"
        />

        <FormKit
          v-model="model.subdomain"
          :name="$t('Account URL')"
          :label="$t('Account URL')"
          :help="$t('Account url help text')"
          :placeholder="$t('mycompany')"
          :prefix-icon="LinkIcon"
          validation="required|domain"
          validation-visibility="dirty"
        >
          <template #suffix>
            <span class="input-suffix text-sm">.growee.net</span>
          </template>
        </FormKit>

        <div class="w-full mt-10">
          <div class="mb-4">
            <FormKit
              v-model="agreeWithTerms"
              type="checkbox"
              :name="$t('Agree Terms')"
              validation="accepted"
              validation-visibility="dirty"
            >
              <template #label>
                <div class="flex items-center space-x-1">
                  <span class="pl-2 label">{{ $t('I agree with the') }}</span>
                  <ChevronRightIcon class="w-4 text-primary"/>
                  <a target="_blank" href="https://www.growee.net/terms" class="text-sm">
                    {{ $t('Terms & Conditions') }}
                  </a>
                </div>
              </template>
            </FormKit>
          </div>
          <FormKit
            type="submit"
            :disabled="loading"
            :classes="{ input: 'w-full' }"
          >
            <span>{{ $t('Sign Up') }}</span>
            <ArrowLongRightIcon class="w-4 h-4 ml-2"/>
          </FormKit>
        </div>
      </FormKit>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ArrowLongRightIcon, ChevronRightIcon } from '@heroicons/vue/24/outline'
import { onMounted, reactive, ref } from "vue";
import { error } from "@/components/common/NotificationPlugin";
import { useAuthStore } from "@/modules/auth/store/authStore";
import { useRouter, useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import { RegisterSteps } from "@/modules/auth/types/enums";
import { isRootDomain } from "@/modules/common/config";
import { useStorage } from "@vueuse/core";
import RegisterStep3 = Auth.RegisterStep3;

const UserIcon = `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
</svg>
`
const LinkIcon = `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244" />
</svg>
`
const BriefCaseIcon = `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z" />
</svg>
`

const CountryIcon = `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="m20.893 13.393-1.135-1.135a2.252 2.252 0 0 1-.421-.585l-1.08-2.16a.414.414 0 0 0-.663-.107.827.827 0 0 1-.812.21l-1.273-.363a.89.89 0 0 0-.738 1.595l.587.39c.59.395.674 1.23.172 1.732l-.2.2c-.212.212-.33.498-.33.796v.41c0 .409-.11.809-.32 1.158l-1.315 2.191a2.11 2.11 0 0 1-1.81 1.025 1.055 1.055 0 0 1-1.055-1.055v-1.172c0-.92-.56-1.747-1.414-2.089l-.655-.261a2.25 2.25 0 0 1-1.383-2.46l.007-.042a2.25 2.25 0 0 1 .29-.787l.09-.15a2.25 2.25 0 0 1 2.37-1.048l1.178.236a1.125 1.125 0 0 0 1.302-.795l.208-.73a1.125 1.125 0 0 0-.578-1.315l-.665-.332-.091.091a2.25 2.25 0 0 1-1.591.659h-.18c-.249 0-.487.1-.662.274a.931.931 0 0 1-1.458-1.137l1.411-2.353a2.25 2.25 0 0 0 .286-.76m11.928 9.869A9 9 0 0 0 8.965 3.525m11.928 9.868A9 9 0 1 1 8.965 3.525" />
</svg>
`

const PhoneIcon = `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z" />
</svg>
`

const props = defineProps({
  token: {
    type: String,
    default: '',
  },
})
const onBoardingStep = useStorage<RegisterSteps>('onBoardingStep', RegisterSteps.EmailSetup)
const onBoardingToken = useStorage<string>('onBoardingToken', '')
const agreeWithTerms = ref(false)

const route = useRoute()
const model: RegisterStep3 = reactive({
  username: '',
  company_name: '',
  phone: '',
  subdomain: '',
  country: '',
  onboarding_step: RegisterSteps.CompanySetup,
  onboarding_token: props.token || route.query.onboarding_token as string,
})

const authStore = useAuthStore()
const router = useRouter()
const emit = defineEmits(['success'])
const { t, locale } = useI18n()

if (locale.value === 'ro') {
  model.country = 'RO'
}

function onNameChange(value: string) {
  model.subdomain = value.replace(' ', '').toLowerCase()
}

async function onSubmit() {
  try {
    if (!model.onboarding_token && route.query.onboarding_token) {
      model.onboarding_token = route.query.onboarding_token as string
    }
    model.locale = locale.value as string
    const data: any = await authStore.registerStep3(model)
    data.onboarding_step = RegisterSteps.TeamSetup
    onBoardingStep.value = RegisterSteps.EmailSetup
    onBoardingToken.value = ''
    if (isRootDomain()) {
      let url = data?.attributes?.frontend
      if (url) {
        url = url.replace('.app', '')
        // Step 1: Create a base parameters object
        const params: Record<string, any> = {
          token: data.meta.token,
          locale: locale.value,
        }
        // Step 2: List of optional parameters to check in route.query
        const optionalParams = ['utm_source', 'utm_medium', 'utm_campaign']

        // Add optional parameters if they exist in route.query
        optionalParams.forEach((param) => {
          if (route.query[param]) {
            params[param] = route.query[param]
          }
        })
        // Step 3: Serialize the parameters into a query string
        const queryString = new URLSearchParams(params).toString()
        window.location.href = `${url}?${queryString}`
      }
      return
    }
    await router.push('/welcome-setup')
  } catch (err: any) {
    console.error(err)
    if (err.handled) {
      return
    }
    error(t('An error occurred while trying to login'))
  }
}

const el = ref()
onMounted(() => {
  const firstInput = el.value?.querySelector('input')
  firstInput?.focus()
})
</script>
