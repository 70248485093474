<template>
  <div class="mb-4">
    <ReportHeader />
    <ProjectTasksReport />
  </div>
</template>

<script setup lang="ts">
import ProjectTasksReport from '@/modules/reports/pages/reports/projects/[id]/tasks.vue'
import ReportHeader from '@/modules/reports/components/ReportHeader.vue'
import { IntervalTypes, useReportsStore } from '@/modules/reports/store/reportsStore'

const reportsStore = useReportsStore()
reportsStore.currentReport.intervalType = IntervalTypes.AllTime
reportsStore.changeReportRange()
</script>
