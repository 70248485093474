<template>
  <ProjectSelect
    v-model="value"
    v-focus
    class="w-full"
    @update:modelValue="onChange"
  />
</template>

<script>
import { useCellEditor } from "@/components/table/cells/editors/useCellEditor"
import ProjectSelect from "@/components/formkit/ProjectSelect.vue"

export default {
  components: {
    ProjectSelect,
  },
  setup(props) {
    return useCellEditor(props.params)
  },
}
</script>
