<template>
  <BaseFormCard
    :submitText="$t('Update')"
    :disabled="currentHolidayLoading || isFormDisabled"
    @submit="updateHoliday"
    @cancel="onCancel"
  >
    <FormCardSection>
      <HolidayRequestForm
        ref="form"
        :actions="false"
        :holiday="currentHoliday"
        class="col-span-6"
      />
    </FormCardSection>
  </BaseFormCard>
</template>
<script setup lang="ts">
import HolidayRequestForm from "@/modules/holidays/components/HolidayRequestForm.vue";
import { useHolidayStore } from "@/modules/holidays/store/holidayStore";
import { storeToRefs } from "pinia";
import { computed, ref } from "vue";
import { useRouter } from "vue-router";
import { canEditHoliday } from "@/modules/holidays/utils/holidayUtils";

const holidayStore = useHolidayStore()
const { currentHolidayLoading, currentHoliday } = storeToRefs(holidayStore)

const isFormDisabled = computed<boolean>(() => {
  return !canEditHoliday(currentHoliday.value)
})

const form = ref()

async function updateHoliday() {
  await form.value?.onSubmit()
}

const router = useRouter()
async function onCancel() {
  await router.go(-1)
}
</script>
<route lang="yaml">
name: Holiday Edit
</route>
