<template>
  <div>
    <BaseDataTable
      v-if="viewMode === ViewModes.Group"
      url="/restify/expense-bundles"
      :url-params="urlParams"
      :columns="columns"
      :add-text="$t('Create expense bundle')"
      :row-height="60"
      :extra-actions="rowActions"
      :suppress-cell-focus="true"
      :delete-extra-confirmation="true"
      :master-detail="true"
      :detail-row-auto-height="true"
      :detail-cell-renderer="detailCellRenderer"
      :full-width-cell-renderer="fullWidthCellRenderer"
      :transform-data="transformData"
      :is-full-width-row="(params: any) => params.rowNode?.data?.fullWidth"
      :get-row-height="getExpenseRowHeight"
      dom-layout="autoHeight"
      entity="expenses"
      actions="view,search,add,edit,delete"
      class="h-full"
      @add="onAdd"
    >
      <template #actions-before>
        <ExpenseViewModeButton v-model="viewMode" />
      </template>
      <template #attributes.title="{ row }">
        <router-link
          :to="`/expenses/${row.id}/details`"
          class="no-underline text-base-content capitalize flex items-center space-x-2"
        >
          {{ row.attributes.title }}
        </router-link>
      </template>
      <template #attributes.requested_approver_id="{ row }">
        <EmployeeLink :params="{ value: row?.attributes?.requested_approver_id }"/>
      </template>
    </BaseDataTable>
    <ExpenseTable
      v-else-if="viewMode === ViewModes.List"
      :show-all-columns="true"
      :can-filter="true"
      :extra-url-params="listUrlParams"
      :employee-id="props.employeeId"
      :editable="true"
    >
      <template #actions-before>
        <ExpenseViewModeButton v-model="viewMode" />
      </template>
    </ExpenseTable>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue"
import { ColDef, GridReadyEvent, ICellEditorParams } from "@ag-grid-community/core"
import { useI18n } from "vue-i18n"
import { useRouter } from "vue-router"
import { useStorage } from '@vueuse/core'
import {
  CloudArrowDownIcon, CreditCardIcon,
  DocumentArrowDownIcon,
  ExclamationCircleIcon,
  ShieldCheckIcon,
} from "@heroicons/vue/24/outline"
import { RowAction } from "@/components/table/tableTypes"
import { ColumnTypes } from "@/components/table/cells/tableCellComponents"
import { FilterTypes } from "@/components/table/filters/filterTypes"
import { can } from "@/plugins/permissionPlugin"
import { formatPrice } from "@/plugins/formatPrice"
import Data = API.Data
import { useExpenseStore } from "@/modules/expenses/store/expenseStore"
import i18n from "@/i18n"
import { ViewModes, getExpenseRowHeight } from "@/modules/expenses/utils/expenseUtils"
import ExpenseViewModeButton from "@/modules/expenses/components/ExpenseViewModeButton.vue"
import ExpenseTable from "@/modules/expenses/components/ExpenseTable.vue"
import ExpenseBundleDetailRow from "@/modules/expenses/components/ExpenseBundleDetailRow.vue"
import TotalExpenseBundlesRow from "@/modules/expenses/components/TotalExpenseBundlesRow.vue"
import EmployeeLink from "@/components/table/cells/EmployeeLink.vue";

const props = defineProps({
  employeeId: {
    type: String,
  },
})

const urlParams = ref({
  employee_id: props.employeeId,
  sort: '-created_at',
})

const listUrlParams = ref({
  employee_id: props.employeeId,
  sort: '-date',
})

const detailCellRenderer = ExpenseBundleDetailRow
const fullWidthCellRenderer = TotalExpenseBundlesRow
const viewMode = useStorage('expenseViewMode', ViewModes.Group)

const { t } = useI18n()

const columns = computed<ColDef[]>(() => {
  return [
    {
      headerName: t('Title'),
      field: 'attributes.title',
      type: 'custom',
      minWidth: 280,
      maxWidth: 320,
      cellRenderer: 'agGroupCellRenderer',
    },
    {
      headerName: t('Start Date'),
      field: 'attributes.start_date',
      type: ColumnTypes.Date,
      minWidth: 140,
      maxWidth: 180,
      filter: FilterTypes.StartDate,
    },
    {
      headerName: t('End Date'),
      field: 'attributes.end_date',
      type: ColumnTypes.Date,
      minWidth: 140,
      maxWidth: 180,
    },
    {
      headerName: t('Employee'),
      field: 'attributes.employee_id',
      type: ColumnTypes.EmployeeLink,
      minWidth: 200,
      maxWidth: 400,
      initialHide: !!props.employeeId,
      filter: props.employeeId ? null : FilterTypes.Employee,
    },
    {
      headerName: t('Requested Approver'),
      field: 'attributes.requested_approver_id',
      type: 'custom',
      minWidth: 180,
      maxWidth: 400,
      initialHide: !can('manageExpenses'),
      filter: props.employeeId ? null : FilterTypes.Employee,
    },
    {
      headerName: t('Status'),
      field: 'attributes.status',
      minWidth: 130,
      maxWidth: 160,
      type: ColumnTypes.ExpenseStatus,
      filter: FilterTypes.ExpenseStatus,
    },
    {
      headerName: t('Amount'),
      field: 'attributes.total_amount',
      type: 'rightAligned',
      minWidth: 100,
      maxWidth: 200,
      valueFormatter: (params) => {
        return formatPrice(params.value || 0, {
          currencyDisplay: false,
        })
      },
    },
  ]
})

const expenseStore = useExpenseStore()

async function approveBundle(row: Data<any>, params: ICellEditorParams | GridReadyEvent) {
  await expenseStore.approveExpenseBundle(row)
  params?.api?.redrawRows()
}

async function rejectBundle(row: Data<any>, params: ICellEditorParams | GridReadyEvent) {
  await expenseStore.rejectExpenseBundle(row)
  params?.api?.redrawRows()
}

const rowActions = computed<RowAction[]>(() => [
  {
    label: i18n.t('Approve'),
    icon: ShieldCheckIcon,
    action: async (row: Data<any>, params: ICellEditorParams) => {
      await approveBundle(row, params)
    },
    show: (row: Data<any>) => {
      return expenseStore.canApproveExpenseBundle(row)
    },
  },
  {
    label: i18n.t('Reject'),
    icon: ExclamationCircleIcon,
    action: async (row: Data<any>, params: ICellEditorParams) => {
      await rejectBundle(row, params)
    },
    show: (row: Data<any>) => {
      return expenseStore.canRejectExpenseBundle(row)
    },
  },
  {
    label: i18n.t('Mark as paid'),
    icon: CreditCardIcon,
    action: async (row: any) => {
      await expenseStore.markExpenseBundleAsPaid(row)
    },
    show: (row: any) => {
      return expenseStore.canMarkExpenseBundleAsPaid(row)
    },
  },
  {
    label: i18n.t('Export bundle'),
    icon: CloudArrowDownIcon,
    action: async (row: Data<any>, params: ICellEditorParams) => {
      await expenseStore.downloadExpenseBundle(row)
    },
  },
  {
    label: i18n.t('Generate PDF report'),
    icon: DocumentArrowDownIcon,
    action: async (row: Data<any>) => {
      await expenseStore.downloadExpenseBundleAsPDF(row)
    },
  },
])

function transformData(data: any[], response: any) {
  const fullWidthRow = {
    _localId: crypto.randomUUID(),
    fullWidth: true,
    meta: response?.meta,
  }
  const hasTotals = response?.meta?.by_currency?.length > 0
  if (data?.length === 0 || !hasTotals) {
    return data
  }
  return data.concat([fullWidthRow])
}

const router = useRouter()

async function onAdd() {
  await router.push({
    path: '/expenses/create',
    query: {
      employeeId: props.employeeId,
    },
  })
}
</script>
