<template>
  <div>
    <DocumentsTable ref="documentsTable" :employee-id="currentEmployee.id">
      <template #actions-before>
        <BaseButton
          v-if="$can('manageDocuments')"
          size="sm"
          outline
          variant="primary"
          @click="generateDocuments"
        >
          {{ t('Generate Documents') }}
        </BaseButton>
      </template>
    </DocumentsTable>
    <GenerateDocumentsModal
      v-if="showGenerateModal"
      v-model="showGenerateModal"
      :entity-id="currentEmployee.id"
      :entity="TemplateEntities.Employee"
      :employee="currentEmployee"
      @close="showGenerateModal = false"
      @save="refreshData"
    />
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue"
import { storeToRefs } from "pinia"
import { useI18n } from 'vue-i18n'
import DocumentsTable from "@/modules/documents/components/DocumentsTable.vue"
import { useEmployeeStore } from "@/modules/employees/store/employeeStore"
import GenerateDocumentsModal from "@/modules/employees/components/GenerateDocumentsModal.vue"
import { TemplateEntities } from "@/modules/templates/store/templateStore"

const { t } = useI18n()
const { currentEmployee } = storeToRefs(useEmployeeStore())

const documentsTable = ref()
const showGenerateModal = ref(false)

async function generateDocuments() {
  showGenerateModal.value = true
}

async function refreshData() {
  showGenerateModal.value = false
  // Refresh the table after a short delay to allow files to be generated and saved correctly
  setTimeout(() => {
    documentsTable.value?.refresh()
  }, 1000)
}
</script>

<route lang="yaml">
name: Employee Documents
</route>
