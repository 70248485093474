import { h } from 'vue'
import { get } from 'lodash-es'
import { SetupContext } from '@vue/runtime-core'
import { ColDef } from '@ag-grid-community/core'
import ActionsHeader from '../ActionsHeader.vue'
import TableActionsColumn from '@/components/table/TableActions.vue'
import { TableActions } from '@/components/table/tableUtils'
import { formatPrice } from '@/plugins/formatPrice'
import { tableCellComponents } from "@/components/table/cells/tableCellComponents"
import { isMobile } from "@/util/responsiveUtils"

interface columnTypesParams {
  slots: SetupContext['slots']
  emit: Function
  props: Readonly<any>
  gridContext: any
}
export function getColumnTypes({ slots, emit, props, gridContext }: columnTypesParams) {
  const componentCells: any = {}
  for (const key in tableCellComponents) {
    const component = tableCellComponents[key]
    componentCells[key] = {
      cellRenderer: component,
    }
  }
  return {
    ...componentCells,
    custom: {
      cellRenderer: {
        name: 'CustomCell',
        render(props: any) {
          const field = props.params.colDef.field
          const fieldScopedSlot = slots[field]
          if (!fieldScopedSlot) {
            return h('div', get(props.params.data, field))
          }

          return fieldScopedSlot({
            row: props.params.data,
            index: props.params.rowIndex,
            params: props.params,
          })
        },
      },
    },
    price: {
      cellRenderer: (params: any) => {
        return formatPrice(params.value)
      },
    },
    actions: {
      cellRenderer: {
        name: 'ActionsColumn',
        render(localProps: any) {
          const actionsSlot = slots.actions

          if (actionsSlot) {
            return actionsSlot({
              row: localProps.params.data,
            })
          }

          return h(TableActionsColumn, {
            params: localProps.params,
            actions: props.actions,
            filterRowActions: props.filterRowActions,
            tableProps: props,
            onView: () => emit(TableActions.View, localProps.params.data),
            onEdit: (row: any, rowIndex: number) => {
              gridContext?.onEdit(row, rowIndex)
            },
            onDelete: () => emit(TableActions.Delete, localProps.params.data, localProps.params.rowIndex),
            onAfterDelete: () => emit(TableActions.AfterDelete, localProps.params.data),
          })
        },
      },
    },
  }
}

export const actionsColumn: ColDef = {
  headerName: '',
  field: 'actions',
  type: 'actions',
  sortable: false,
  resizable: false,
  lockVisible: true,
  pinned: 'right',
  width: isMobile() ? 50 : 90,
  cellClass: 'flex items-center actions-header',
  headerClass: '!px-0',
  headerComponent: ActionsHeader,
}

