<template>
  <BaseSelect
    v-bind="allAttrs"
    :options="clientContacts"
    :data-loading="loading"
    :value-key="valueKey"
    label-key="attributes.email"
    multiple
    filterable
    allow-create
    fetch-at-start
    :model-value="value"
    @focus="onFocus"
    @update:model-value="onChange"
  />
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import axios from "axios"
import { useFormKitInput } from "@/components/formkit/useFormkitInput"
import { isUuid } from "@/modules/common/utils/stringUtils"
import get from "lodash/get";

const props = defineProps({
  context: Object,
})
const clientContacts = ref<any[]>([])

const { value, allAttrs, handleChange } = useFormKitInput(props)

const clientId = computed(() => {
  return props.context?.attrs?.['client-id']
})

const valueKey = computed(() => {
  return props.context?.attrs?.['value-key'] || 'id'
})
async function onChange(val: string[]) {
  if (val?.length === 0 || !val) {
    handleChange(val)
    return
  }
  const lastVal = val[val.length - 1]
  const fullValue = clientContacts.value.find(contact => get(contact, valueKey.value) === lastVal)
  if (fullValue?.id) {
    handleChange(val)
    return
  }
  const { data } = await axios.post(`/restify/client-contacts`, {
    name: lastVal,
    email: lastVal,
    client_id: clientId.value,
  })
  clientContacts.value.push(data)
  const tagId = data?.id
  if (!tagId) {
    return
  }
  const tagList = [...val.slice(0, val.length - 1), tagId]
  handleChange(tagList)
}

const loading = ref(false)
async function onFocus() {
  try {
    loading.value = true
    const sameClient = clientContacts.value.every(contact => contact?.attributes.client_id === clientId.value)
    if (clientContacts.value.length > 0 && sameClient) {
      return
    }
    const { data } = await axios.get(`/restify/client-contacts`, {
      params: {
        client_id: clientId.value,
      },
    })
    clientContacts.value = data
  } finally {
    loading.value = false
  }
}
</script>
