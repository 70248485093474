<template>
  <BaseButton
    size="xs"
    variant="secondary"
    :loading="loading"
    @click="copyRecentTimesheet"
  >
    {{ $t('Copy over all rows from most recent timesheet') }}
  </BaseButton>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import axios from "axios"
import { error, success } from "@/components/common/NotificationPlugin"
import i18n from "@/i18n"
import { CalendarDateFormat, formatDate, removeTimezoneFromDate } from "@/modules/common/utils/dateUtils"
import Timesheet = App.Domains.TimeSheets.Models.Timesheet
import Data = API.Data

const props = defineProps({
  date: {
    type: String,
    required: true,
  },
})
const emit = defineEmits(['refresh'])
const loading = ref(false)
async function getMostRecentTimesheetDate() {
  const { data } = await axios.get('/restify/timesheets', {
    params: {
      perPage: 1,
      sort: '-date',
    },
  })
  const date = data[0]?.attributes?.date
  if (!date) {
    error(i18n.t('No timesheet found to copy from'))
    return null
  }
  return formatDate(date, CalendarDateFormat)
}

async function copyRecentTimesheet() {
  try {
    loading.value = true
    const formattedDate = await getMostRecentTimesheetDate()
    if (!formattedDate) {
      return
    }
    const response = await axios.get('/restify/timesheets', {
      params: {
        date: `${formattedDate},${formattedDate}`,
      },
    })
    const requestData = mapTimesheetData(response.data)
    for (let i = 0; i < requestData.length; i++) {
      await createTimesheet(requestData[i])
    }
    success(i18n.t('Timesheet copied successfully'))
    emit('refresh')
  } catch (error) {
    console.error(error)
  } finally {
    loading.value = false
  }
}

async function createTimesheet(requestData: Timesheet) {
  await axios.post(`/restify/timesheets`, requestData)
}

function mapTimesheetData(data: Data<Timesheet>[]) {
  const reversedData = data.reverse()
  return reversedData.map((item: Data<Timesheet>) => {
    const { employee_id, project_id, task_id, description } = item.attributes
    return {
      project_id,
      task_id,
      description,
      date: formatDate(props.date, CalendarDateFormat),
      employee_id,
      worked_minutes: 0,
    }
  })
}
</script>
