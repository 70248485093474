<template>
  <router-link
    v-if="id"
    :to="`/employees/${id}/details`"
    class="flex items-center px-3 py-1.5 bg-primary-light rounded-sm no-underline"
  >
    <span class="text-sm text-primary">
      {{ firstName }} {{ lastNameInitial }}
    </span>
  </router-link>
</template>

<script setup lang="ts">
import { computed } from "vue"
import { useEmployeeStore } from "@/modules/employees/store/employeeStore"

const props = defineProps({
  id: {
    type: String,
    default: '',
  },
})
const employeeStore = useEmployeeStore()
const employee = computed(() => {
  return employeeStore.getEmployeeById(props.id)
})

const firstName = computed(() => {
  return employee.value?.attributes?.first_name
})

const lastName = computed(() => {
  return employee.value?.attributes?.last_name
})

const lastNameInitial = computed(() => {
  return lastName.value?.charAt(0) || ''
})
</script>
