import { defineStore } from 'pinia'
import axios from 'axios'
import Cache from '@/modules/common/Cache'
import Data = API.Data
import { trackProjectCreated } from "@/util/analytics";
import { useBillingStore } from "@/modules/settings/store/billingStore";

export const useProjectStore = defineStore('projects', {
  state: () => {
    return {
      allProjects: [] as Data<any>[],
      allProjectsLoading: false,
      allTasks: [] as Data<any>[],
      allTasksLoading: false,
      currentProject: {} as any,
      currentProjectLoading: false as boolean,
      currentProjectError: null as any,
      employeeProjectsLoading: false,
      archiveLoading: false,
      restoreLoading: false,
      employeeProjects: {} as Record<string, Data<any>[]>,
    }
  },
  actions: {
    async getAllProjects() {
      try {
        this.allProjectsLoading = true
        const { data } = await Cache.getRequest('/restify/projects', {
          params: {
            related: 'tasks,employees[id|first_name|last_name|avatar]',
            perPage: 200,
          },
        })
        this.allProjects = data
      } finally {
        this.allProjectsLoading = false
      }
    },
    async getAllTasks() {
      try {
        this.allTasksLoading = true
        const { data } = await Cache.getRequest('/restify/tasks', {
          params: {
            perPage: 500,
          },
        })
        this.allTasks = data
      } finally {
        this.allTasksLoading = false
      }
    },
    async getEmployeeProjects(employeeId: string) {
      try {
        this.employeeProjectsLoading = true
        let data: any = await axios.get('/restify/projects/employee-projects', {
          params: {
            employee_id: employeeId,
            related: 'tasks',
            perPage: 200,
          },
        })
        if (data?.id) {
          data = [data]
        } else {
          data = data.data
        }
        this.employeeProjects[employeeId] = data
      } finally {
        this.employeeProjectsLoading = false
      }
    },
    async getProject(id: string) {
      if (!id) {
        return
      }
      try {
        this.currentProjectLoading = true
        const { data } = await axios.get(`/restify/projects/${id}`, {
          params: {
            related: 'tasks,client,employees',
          },
        })
        const response = await axios.get(`/restify/tasks`, {
          params: {
            project_id: id,
            archived: true,
          },
        })
        const archivedTasks = response.data || []
        const unarchivedTasks = data?.relationships?.tasks || []
        data.relationships.tasks = [...unarchivedTasks, ...archivedTasks]
        this.currentProject = {
          ...(data?.attributes || {}),
          relationships: data?.relationships,
          meta: data.meta,
        }
        this.currentProjectError = null
      } catch (err: any) {
        this.currentProjectError = err
      } finally {
        this.currentProjectLoading = false
      }
    },
    async archiveProject(project: Data<any>) {
      try {
        this.archiveLoading = true
        await axios.post(`/restify/projects/${project.id}/actions?action=archive`)
      } finally {
        this.archiveLoading = false
      }
    },
    async deleteProject(project: Data<any>) {
      await axios.delete(`/restify/projects/${project.id}`)
    },
    async restoreProject(project: Data<any>) {
      try {
        this.restoreLoading = true
        await axios.post(`/restify/projects/actions?action=unarchive`, {
          project_id: project.id,
        })
      } finally {
        this.restoreLoading = false
      }
    },
    resetCurrentProject() {
      this.currentProject = {}
    },
    async updateProject(requestData: any) {
      if (!requestData.id) {
        return
      }
      const { data } = await axios.put(`/restify/projects/${requestData.id}`, requestData)
      await this.getProject(requestData.id)
      return data
    },
    async createProject(requestData: any) {
      const { data } = await axios.post('/restify/projects', requestData)
      await this.getProject(data.id)
      trackProjectCreated()
      return data
    },
  },
  getters: {
    currentProjectName(state) {
      const { name } = state.currentProject || {}
      if (!name) {
        return ''
      }

      return name
    },
    getProjectById: state => (id: string | null) => {
      return state.allProjects.find((project: any) => project.id === id)
    },
    getProjectClient: state => (id: string | null) => {
      return state.allProjects.find((project: any) => project.id === id)?.attributes?.client_id
    },
    getTaskById: state => (projectId: string | null, taskId: string | null) => {
      const project = state.allProjects.find((project: any) => project.id === projectId)
      if (!project) {
        return state.allTasks.find(t => t.id === taskId)
      }
      return project?.relationships?.tasks.find((task: any) => task.id === taskId)
    },
    getProjectEmployees: (state) => {
      return (projectId: string | null): any[] => {
        const project = state.allProjects.find((project: any) => project.id === projectId)
        return project?.relationships?.employees || []
      }
    },
  },
})
