<template>
  <div class="grid grid-cols-3 gap-x-1 w-full items-center">
    <HolidayLink :holiday="props.data" />
    <div class="col-span-2 md:col-span-1">
      <div>{{ $t('days', props.data.attributes.days_off) }}</div>
      <div class="flex flex-wrap items-center text-sm text-base-content space-x-2">
        <span class="text-gray-400">
          {{ formatDate(props.data.attributes.start_date) }} - {{ formatDate(props.data.attributes.end_date) }}
        </span>
      </div>
    </div>
    <div v-if="props.employeeId" class="hidden truncate md:flex justify-end">
      <EmployeeAvatar
        v-if="props.employeeId"
        :data="employeeStore.getEmployeeById(props.employeeId)"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { PropType } from "vue"
import HolidayLink from "@/modules/holidays/components/HolidayLink.vue"
import EmployeeAvatar from "@/modules/employees/components/EmployeeAvatar.vue"
import { useEmployeeStore } from "@/modules/employees/store/employeeStore"
const props = defineProps({
  data: {
    type: Object as PropType<Data<Holiday>>,
  },
  employeeId: {
    type: String,
  },
})
import Holiday = App.Domains.Holidays.Models.Holiday
import Data = API.Data
import { formatDate } from "@/modules/common/utils/dateUtils"
const employeeStore = useEmployeeStore()
</script>
