<template>
  <div class="flex flex-col w-full space-y-6">
    <div
      v-for="employee in data"
      class="grid grid-cols-3 gap-x-1 w-full items-center"
    >
      <div class="flex space-x-2">
        <CakeIcon class="text-primary w-4 h-4" />
        <span class="text-sm">
          {{ formatDate(employee.attributes.birth_date, 'do MMMM') }}
        </span>
      </div>
      <div class="truncate">
        <EmployeeAvatar
          v-if="employee.id"
          :data="employeeStore.getEmployeeById(employee.id)"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { PropType } from "vue"
import { CakeIcon } from "@heroicons/vue/24/outline"
import { formatDate } from "../../../common/utils/dateUtils"
import EmployeeAvatar from "@/modules/employees/components/EmployeeAvatar.vue"
import { useEmployeeStore } from "@/modules/employees/store/employeeStore"
import Data = API.Data
const props = defineProps({
  data: {
    type: Array as PropType<Data<Employee>[]>,
    default: () => [],
  },
})
import Employee = App.Domains.Employees.Models.Employee
const employeeStore = useEmployeeStore()
</script>
