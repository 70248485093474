<template>
  <PersonalGoals :employee-id="userEmployeeId as string" />
</template>

<script lang="ts" setup>
import PersonalGoals from "@/modules/employees/pages/employees/[id]/goals.vue"
import { useAuth } from "@/modules/auth/composables/useAuth"

const { userEmployeeId } = useAuth()
</script>

<route lang="yaml">
name: Personal Goals
</route>
