<template>
  <FormKit
    type="form"
    :actions="false"
    @submit="onSubmit"
  >
    <ElDialog v-bind="$attrs" :title="$t('Generate Documents')">
      <div v-if="hasData">
        <FormKit
          v-model="model.template_ids"
          type="checkbox"
          :label="$t('Document Templates')"
          :options="availableTemplateOptions"
          :help="$t('Select the templates you want to generate.')"
          validation="required|min:1"
        />
      </div>
      <EmptyState
        v-else
        :title="$t(`You don't have any templates for this entity yet.`)"
        :description="$t('Please create a template first.')"
        :icon="DocumentIcon"
        :action-name="$t('Create Template')"
        action-path="/settings/templates"
      />

      <template #footer>
        <div class="w-full flex justify-center space-x-2">
          <FormKit
            type="button"
            :classes="{ input: 'min-w-[128px] btn-outline' }"
            @click="emit('close')"
          >
            <span>{{ t('Cancel') }}</span>
          </FormKit>
          <FormKit
            type="submit"
            :classes="{ input: 'min-w-[128px]' }"
            :disabled="loading"
          >
            <span>{{ t('Generate') }}</span>
          </FormKit>
        </div>
      </template>
    </ElDialog>
  </FormKit>
</template>

<script lang="ts" setup>
import { computed, onBeforeMount, ref } from 'vue'
import { useI18n } from "vue-i18n"
import axios from "axios"
import { DocumentIcon } from "@heroicons/vue/24/outline"
import { TemplateEntities } from "@/modules/templates/store/templateStore"
import EmptyState from "@/components/data/EmptyState.vue"

const props = defineProps({
  entity: {
    type: String,
    required: true,
  },
  entityId: {
    type: [String, Number],
    required: true,
  },
  employee: {
    type: Object,
  },
})

const emit = defineEmits(['close', 'save'])
const { t } = useI18n()
const loading = ref(true)

const model = ref({
  template_ids: [],
})
const availableTemplates = ref([])

const availableTemplateOptions = computed(() => {
  return availableTemplates.value.map((template: any) => ({
    label: template.attributes?.name,
    value: template.id,
  }))
})

const hasData = computed(() => {
  return loading.value || availableTemplates.value.length
})

async function onSubmit() {
  try {
    const entityMap = {
      [TemplateEntities.Employee]: 'employees',
      [TemplateEntities.Client]: 'clients',
      [TemplateEntities.Holiday]: 'holidays',
      [TemplateEntities.Project]: 'projects',
    }
    const restifyEntity = entityMap[props.entity]
    if (!restifyEntity) {
      return
    }
    loading.value = true
    const res = await axios.post(`/restify/${restifyEntity}/${props.entityId}/actions?action=generate-documents`, model.value)
    emit('save', res)
  } finally {
    loading.value = false
  }
}

async function getTemplatesForEntity() {
  try {
    const employee = props.employee
    const contract_type = employee?.contract_type || employee?.attributes?.contract_type || undefined
    const { data } = await axios.get(`/restify/templates`, {
      params: {
        entities: props.entity,
        // TODO add when backend is filtering correctly
        // contract_type,
      },
    })
    // TODO remove when backend is filtering correctly.
    availableTemplates.value = data.filter((template: any) => {
      const contract_types = template.attributes?.contract_types || []
      if (contract_types?.length === 0 || !contract_type) {
        return true
      }
      return contract_types.includes(contract_type)
    })
  } finally {
    loading.value = false
  }
}

onBeforeMount(() => {
  getTemplatesForEntity()
})
</script>
