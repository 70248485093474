<template>
  <div>
    <SlideTransition :key="currentStep">
      <component
        :is="currentStepComponent"
        :token="onBoardingToken"
        :email="authStore.profile.email"
        @success="nextStep"
      />
    </SlideTransition>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import { useStorage } from '@vueuse/core'
import RegisterEmailSetup from '@/modules/auth/components/RegisterEmailSetup.vue'
import RegisterCompanySetup from '@/modules/auth/components/RegisterCompanySetup.vue'
import RegisterConfirmEmail from '@/modules/auth/components/RegisterConfirmEmail.vue'
import { RegisterSteps } from '@/modules/auth/types/enums'
import User = App.Models.User
import { useAuthStore } from '@/modules/auth/store/authStore'
import { useRoute, useRouter } from 'vue-router'

const route = useRoute()
let { onboarding_step, onboarding_token } = route.query
// Temporary work around as sometimes the onboarding_step is an object
if (route.query['onboarding_step[value]']) {
  onboarding_step = route.query['onboarding_step[value]']
}
const defaultOnboardingToken = (onboarding_token || '')?.toString()
const onBoardingStep = useStorage<RegisterSteps>('onBoardingStep', RegisterSteps.EmailSetup)
const onBoardingToken = useStorage<string>('onBoardingToken', defaultOnboardingToken)

if (onboarding_step) {
  onBoardingStep.value = onboarding_step as RegisterSteps
}

const authStore = useAuthStore()
enum StepDirections {
  Right = 'right',
  Left = 'left',
}

const stepComponents = {
  [RegisterSteps.EmailSetup]: RegisterEmailSetup,
  [RegisterSteps.ConfirmEmail]: RegisterConfirmEmail,
  [RegisterSteps.CompanySetup]: RegisterCompanySetup,
}

const registerSteps = [RegisterSteps.EmailSetup, RegisterSteps.ConfirmEmail, RegisterSteps.CompanySetup]

const currentStep = ref(onBoardingStep.value || RegisterSteps.EmailSetup)

if (currentStep.value === RegisterSteps.TeamSetup) {
  const router = useRouter()
  router.push('/welcome-setup')
}
const currentStepComponent = computed(() => {
  if (currentStep.value === RegisterSteps.TeamSetup) {
    return RegisterEmailSetup
  }
  return stepComponents[currentStep.value] || RegisterEmailSetup
})
const stepDirection = ref(StepDirections.Right)

function nextStep(data: User) {
  if (data?.onboarding_step) {
    onBoardingStep.value = data.onboarding_step as RegisterSteps
  }
  if (data?.onboarding_token) {
    onBoardingToken.value = data.onboarding_token
  }
  const currentIndex = registerSteps.indexOf(currentStep.value)
  if (currentIndex < registerSteps.length - 1) {
    currentStep.value = registerSteps[currentIndex + 1]
    stepDirection.value = StepDirections.Right
  }
}

function previousStep() {
  const currentIndex = registerSteps.indexOf(currentStep.value)
  if (currentIndex > 0) {
    currentStep.value = registerSteps[currentIndex - 1]
    stepDirection.value = StepDirections.Left
  }
}
</script>

<route lang="yaml">
name: Register
meta:
  layout: authLayout
</route>
