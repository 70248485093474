<template>
  <div
    :class="{
      'min-h-[80px]': size === 'md',
      'min-h-[70px]': size === 'sm',
    }"
    class="flex space-x-3 overflow-x-auto tab-links my-2.5">
    <router-link
      v-for="tab in tabs" :key="tab.label"
      :to="tab.path"
      :title="tab.label"
      :data-test="tab.testName || tab.label"
      class="bg-white transition-colors duration-75 cursor-pointer
                 border border-base-200 rounded py-3 px-4 text-base-300 no-underline
                 flex items-center space-x-4 focus-visible:ring-0 focus-visible:outline-none focus-visible:border-primary"
    >
      <IconBox v-if="tab.icon" size="xxs">
        <component :is="tab.icon" class="w-4 min-w-[16px] h-4 icon-box-icon" />
      </IconBox>
      <div :title="tab.label" class="tracking-wide tab-text inline-block">
        {{ tab.label }}
      </div>
    </router-link>
  </div>
</template>

<script setup lang="ts">
import { watch } from "vue"
import { useRoute } from "vue-router"
import IconBox from '@/components/common/IconBox.vue'

const props = defineProps({
  tabs: {
    type: Array,
    default: () => [],
  },
  modelValue: {
    type: String,
    default: '',
  },
  size: {
    type: String,
    default: 'md'
  }
})

const emit = defineEmits(['update:modelValue'])
const route = useRoute()
watch(() => route.path, (newPath) => {
  const tab: any = props.tabs.find((tab: any) => tab?.path === newPath)
  if (tab) {
    emit('update:modelValue', tab?.label)
  }
}, { immediate: true })
</script>

<style lang="scss">
.tab-links .icon-box {
  @apply text-base-content border-base-300;
  &::before {
    @apply bg-background;
  }

  .icon-box-icon {
    @apply text-base-300;
  }
}

.tab-links .router-link-active,
.tab-links .router-link-exact-active {
  @apply text-base-content border-primary;
  -webkit-text-stroke-width: 0.5px;
  -webkit-text-stroke-color: theme('colors.base-content');

  .icon-box {
    @apply font-semibold border-primary;
    &::before {
      @apply bg-primary/10;
    }

    .icon-box-icon {
      @apply text-primary;
    }
  }
}
</style>
