<template>
  <div>

    <div
      v-if="hasData"
      class="rounded-sm border border-gray-200 flex w-full items-center justify-between mt-5 px-4 py-5">

      <HolidayPreview
        v-if="data.type === DataTypes.Holiday"
        :data="data"
        :employee-id="employeeId"
      />
      <EvaluationPreview
        v-else-if="data.type === DataTypes.Evaluation"
        :data="data"
        :employee-id="employeeId"
      />
      <FilePreview
        v-else-if="data.type === DataTypes.Document"
        :data="data"
        :employee-id="employeeId"
      />
      <UpcomingBirthdays
        v-else-if="data.type === DataTypes.UpcomingBirthdays"
        :data="data.attributes.data"
      />

      <UpcomingMembers
        v-else-if="data.type === DataTypes.UpcomingMembers"
        :data="data.attributes.data"
      />
    </div>
    <div v-else></div>
  </div>
</template>
<script setup lang="ts">
import HolidayPreview from "@/modules/dashboard/components/preview/HolidayPreview.vue";
import FilePreview from "@/modules/dashboard/components/preview/FilePreview.vue";
import { computed } from "vue";
import { DataTypes } from "@/modules/dashboard/store/dashboardStore";
import UpcomingBirthdays from "@/modules/dashboard/components/preview/UpcomingBirthdays.vue";
import UpcomingMembers from "@/modules/dashboard/components/preview/UpcomingMembers.vue";
import EvaluationPreview from "@/modules/dashboard/components/preview/EvaluationPreview.vue";

const props = defineProps({
  data: {
    type: Object,
    default: () => ({}),
  },
  activity: {
    type: Object,
    default: () => ({}),
  }
})

const hasData = computed(() => {
  return props.data?.type
})
const employeeId = computed(() => {
  return props.activity?.attributes?.employee_id
})
</script>
