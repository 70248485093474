<template>
  <BaseSelect
    v-bind="allAttrs"
    url="/restify/users"
    value-key="id"
    :label-formatter="formatLabel"
    :model-value="modelValue || context?._value"
    @update:modelValue="handleChange"
  />
</template>

<script setup lang="ts">
import { computed, useAttrs } from "vue"

const props = defineProps({
  context: Object,
  modelValue: {
    type: String, Object, Number, Array,
  },
})

const emit = defineEmits(['update:modelValue'])
const attrs = useAttrs()
const allAttrs = computed(() => {
  return {
    ...attrs,
    ...(props?.context?.attrs || {}),
  }
})
function handleChange(val: any) {
  emit('update:modelValue', val)
  props.context?.node?.input(val)
}

function formatLabel(option: any) {
  const { first_name, last_name } = option?.attributes || {}
  return `${first_name} ${last_name}`
}
</script>
