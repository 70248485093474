<template>
  <div class="h-full flex flex-col account-page">
    <PageTitle>
      {{ t('Account') }}
    </PageTitle>
    <TabLinks v-if="tabs.length > 1" v-model="activeTab" :tabs="tabs" />
    <RouterView />
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { BriefcaseIcon, CalendarIcon, FlagIcon, LockClosedIcon, UserIcon } from "@heroicons/vue/24/outline"
import { useI18n } from "vue-i18n"
import i18n from "@/i18n"

const loading = ref(false)
const { t } = useI18n()

const activeTab = ref('/settings/account/language')
const tabs = computed(() => {
  return [
    {
      label: i18n.t('Profile'),
      path: `/settings/account/profile`,
      icon: UserIcon,
    },
    {
      label: i18n.t('Employee Profile'),
      path: `/settings/account/employee-profile`,
      icon: BriefcaseIcon,
    },
    {
      label: i18n.t('Personal Goals'),
      path: `/settings/account/goals`,
      icon: BriefcaseIcon,
    },
    {
      label: i18n.t('Personal Calendar'),
      path: `/settings/account/calendar`,
      icon: CalendarIcon,
    },
    {
      label: i18n.t('Security'),
      path: `/settings/account/security`,
      icon: LockClosedIcon,
    },
    {
      label: i18n.t('Language'),
      path: `/settings/account/language`,
      icon: FlagIcon,
    },
  ]
})
</script>

<route lang="yaml">
name: Account
redirect:
  name: Profile
</route>

<style>
.account-page .formkit-form {
  @apply h-full;
}
</style>
