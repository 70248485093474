import { defineStore } from 'pinia'
import axios from 'axios'
import ResetPasswordRequestParams = Auth.ResetPasswordRequestParams
import LoginRequestParams = Auth.LoginRequestParams
import User = App.Models.User
import ForgotPasswordRequestParams = Auth.ForgotPasswordRequestParams
import RegisterStep1 = Auth.RegisterStep1
import RegisterStep2 = Auth.RegisterStep2
import RegisterStep3 = Auth.RegisterStep3
import RegisterStep4 = Auth.RegisterStep4
import Data = API.Data
import ConfirmInvitationRequestParams = Auth.ConfirmInvitationRequestParams
import Role = App.Domains.Permissions.Models.Role
import Permission = App.Domains.Permissions.Models.Permission
import { RegisterSteps, Roles } from '@/modules/auth/types/enums'
import router from '@/router/router'
import { ORGANIZATION_NAME, ROOT_API_URL, ROOT_HOST, isRootDomain } from '@/modules/common/config'
import { useEmployeeStore } from '@/modules/employees/store/employeeStore'
import i18n, { setLocale } from '@/i18n'
import { trackOnboardingStep } from "@/util/analytics"
import { useTemplateStore } from "@/modules/templates/store/templateStore"

function getRegisterUrl() {
  return `${ROOT_API_URL}/register`
}
export const useAuthStore = defineStore('auth', {
  persistedState: {
    persist: true,
  },
  state: () => {
    return {
      isLoggedIn: false,
      profile: {} as User,
      userData: {} as Data<User>,
      tenantData: {} as any,
    }
  },
  actions: {
    async login(loginParams: LoginRequestParams) {
      const loginResult: Data<User> = await axios.post('/login', loginParams)
      const { onboarding_step, onboarding_token } = loginResult.attributes
      const locale = localStorage.getItem('locale') || ''
      if (onboarding_step && onboarding_step !== RegisterSteps.TeamSetup) {
        window.location.href = `${ROOT_HOST}/register?onboarding_token=${onboarding_token}&onboarding_step=${onboarding_step}&locale=${locale}`
        return false
      }
      const token = loginResult.meta?.token
      this.setToken(loginResult.meta?.token)
      if (isRootDomain()) {
        const mainTenant = loginResult.meta?.mainTenant
        this.tenantData = mainTenant
        this.redirectToSubdomain(mainTenant, token)
        return false
      }
      if (onboarding_step === RegisterSteps.TeamSetup) {
        await router.push('/welcome-setup')
        return false
      }
      await this.getProfile()
      this.isLoggedIn = true
      return true
    },
    redirectToSubdomain(tenantInfo: any, token: string) {
      const locale = localStorage.getItem('locale') || ''
      const templateStore = useTemplateStore()
      let url = tenantInfo?.attributes?.frontend
      if (url) {
        url = url.replace('.app', '')
        url = `${url}?token=${token}&locale=${locale}`
        if (templateStore.sharedTemplateToken) {
          url = `${url}&shared_template=${templateStore.sharedTemplateToken}`
        }
        window.location.href = url
      }
      return false
    },
    async loginWithToken(token: string, shouldRedirect = true) {
      this.setToken(token)
      this.isLoggedIn = true
      if (!shouldRedirect) {
        return
      }
      const profile = await this.getProfile()
      if (profile === false) {
        return
      }
      await router.push('/dashboard')
    },

    setToken(token: string) {
      localStorage.setItem('token', token)
      axios.defaults.headers.Authorization = `Bearer ${token}`
    },

    async getProfile() {
      const { data } = await axios.get('/restify/profile', {
        params: {
          related: 'roles.permissions,employee',
        },
      })
      this.profile = {
        id: data.id,
        ...(data?.attributes || {}),
        ...(data?.relationships || {}),
      }
      this.userData = data
      const { onboarding_step } = this.profile
      if (onboarding_step === RegisterSteps.TeamSetup) {
        await router.push('/welcome-setup')
        return false
      }
      const userLocale = data.attributes?.locale
      if (i18n.locale.value !== userLocale && userLocale) {
        setLocale(userLocale)
      }
      return data
    },
    async updateProfile(data: any) {
      const newProfile = await axios.post('/restify/profile', data)
      this.profile = newProfile.data?.attributes
    },
    async getTenant() {
      const { data } = await axios.get(`${ROOT_API_URL}/tenant`, {
        params: {
          subdomain: ORGANIZATION_NAME,
        },
      })
      const tenantLocale = data.locale
      if (i18n.locale.value !== tenantLocale && tenantLocale) {
        setLocale(tenantLocale)
      }
      this.tenantData = data
    },
    async updateCompanyLocale(locale: string) {
      this.tenantData.locale = locale
      await axios.post('/restify/tenants/actions?action=set-locale', { locale })
    },
    logout() {
      this.isLoggedIn = false
      localStorage.clear()
      delete axios.defaults.headers.common.Authorization
    },

    async forgotPassword(data: ForgotPasswordRequestParams) {
      return await axios.post('/forgot-password', data)
    },

    async resetPassword(data: ResetPasswordRequestParams) {
      return await axios.post('/reset-password', data)
    },
    async confirmInvitation(data: ConfirmInvitationRequestParams) {
      return await axios.post(`/tenant/join/${data.token}`, data)
    },
    async registerStep1(data: RegisterStep1) {
      const user: any = await axios.post(getRegisterUrl(), data)
      if (user?.meta?.token) {
        this.setToken(user?.meta.token)
      }
      this.profile = user.attributes
      trackOnboardingStep(1, { email: data.email })
      return user.attributes
    },
    async registerStep2(data: RegisterStep2) {
      trackOnboardingStep(2, { email: data.email })
      return await axios.post(getRegisterUrl(), data)
    },
    async resendCode(email: string) {
      await axios.post(`${ROOT_API_URL}/resend-validation-code`, {
        email,
      })
      this.profile.email = email
    },
    async registerStep3(data: RegisterStep3) {
      const user: any = await axios.post(getRegisterUrl(), data)
      if (user?.meta?.token) {
        this.setToken(user?.meta.token)
      }
      this.profile = user.attributes
      const analyticsData = {
        company_name: data.company_name,
        subdomain: data.subdomain,
        country: data.country,
        locale: data.locale,
      }
      trackOnboardingStep(3, analyticsData)
      return user
    },
    async registerStep4(data: RegisterStep4) {
      return await axios.post(getRegisterUrl(), data)
    },
  },
  getters: {
    roles(state): Role[] {
      const roles = state.userData?.relationships?.roles || []
      return roles.map((r: Data<Role>) => {
        return {
          ...r.attributes,
          ...r.relationships,
        }
      })
    },
    roleName() {
      const roles = this.roles as Role[]
      return roles[0]?.name
    },
    isEmployee(): boolean {
      return this.roles.some((role: Role) => role.name === Roles.Employee)
    },
    isManager(): boolean {
      return this.roles.some((role: Role) => role.name === Roles.Manager)
    },
    isHR(): boolean {
      return this.roles.some((role: Role) => role.name === Roles.HR)
    },
    isAdmin(): boolean {
      return this.roles.some((role: Role) => role.name === Roles.Admin)
    },
    allPermissions(): Permission[] {
      const roles = this.roles
      let permissions: Permission[] = []
      roles.forEach((role: Role) => {
        const rolePermissions: any = role.permissions || []
        const mappedRolePermissions = rolePermissions.map((p: any) => p.attributes)
        permissions = permissions.concat(mappedRolePermissions)
      })
      return permissions
    },
    hasPermission() {
      const permissions = this.allPermissions.map(p => p.name)
      return (permission: string) => {
        if (!permission) {
          return true
        }
        return permissions.includes(permission)
      }
    },
    userEmployeeId(): string | null {
      return this.userData?.relationships?.employee?.id || 3
    },
    hasEmployeeId(): boolean {
      return !!this.userEmployeeId
    },
    isOwnEmployee() {
      const employeeStore = useEmployeeStore()
      return this.userEmployeeId === employeeStore.currentEmployee?.id
    },
  },
})
