<template>
  <div class="flex space-x-2">
    <BaseSelect
      v-bind="allAttrs"
      :options="allClients"
      :data-loading="allClientsLoading"
      value-key="id"
      label-key="attributes.company_name"
      filterable
      :model-value="value"
      @update:model-value="handleChange"
    />
    <button
      v-if="allAttrs['show-add']"
      type="button"
      class="btn btn-primary btn-outline btn-sm"
      @click="onNewClientClick"
    >
      <PlusIcon class="w-4 h-4 mr-1"/>
      <span>{{ t('Add client') }}</span>
    </button>
  </div>
</template>

<script setup lang="ts">
import { useFormKitInput } from "@/components/formkit/useFormkitInput";
import { PlusIcon } from '@heroicons/vue/24/outline'
import { storeToRefs } from "pinia";
import { onMounted } from "vue";
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { useClientStore } from "@/modules/clients/store/clientStore";

const props = defineProps({
  context: Object,
})

const { t } = useI18n()
const clientStore = useClientStore()
const { allClients, allClientsLoading } = storeToRefs(clientStore)

const { value, allAttrs, handleChange } = useFormKitInput(props)

onMounted(async () => {
  await clientStore.getAllClients()
})

const router = useRouter()

function onNewClientClick() {
  router.push('/clients/create')
}
</script>
