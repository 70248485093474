import { ReportItem } from "@/modules/reports/store/reportsStore";
import Data = API.Data;

export function formatHours(row: Data<ReportItem>) {
  const { total_worked_minutes } = row?.attributes || {}
  if (total_worked_minutes === undefined) {
    return '0.00'
  }
  const minutes = +(total_worked_minutes || 0)
  return (minutes / 60).toFixed(2)
}
