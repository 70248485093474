<template>
  <component v-if="icon" :is="icon" class="text-primary"/>
</template>
<script setup lang="ts">
import { computed } from "vue";
import { HolidayTypeIcons } from "@/modules/settings/types/settingTypes";
import { useSettingsStore } from "@/modules/settings/store/settingsStore";

const props = defineProps({
  policyId: {
    type: String,
    default: '',
  }
})
const settingsStore = useSettingsStore()

const holidayPolicy = computed(() => {
  return settingsStore.getPolicyById(props.policyId)
})

const icon = computed(() => {
  const type = holidayPolicy.value?.attributes.type as string
  return HolidayTypeIcons[type]
})
</script>
