<template>
  <div class="flex space-x-2 items-center">
    <span :class="statusClass" class="w-2 h-2 rounded-full" />
    <span class="capitalize text-sm">
      <slot>
        {{ statusName }}
      </slot>
    </span>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue"
import { capitalize } from "lodash-es"
import { EmployeeStatus } from "@/modules/employees/utils/employeeUtils"
import i18n from "@/i18n"

const props = defineProps({
  params: {
    type: Object,
    default: () => ({}),
  },
})

interface StatusMapping {
  [key: string]: string
}
const statusMappings: StatusMapping = {
  draft: 'bg-red-500',
  cancelled: 'bg-red-500',
  invited: 'bg-yellow-500',
  active: 'bg-green-500',
  archived: 'bg-gray-500',
  false: 'bg-gray-500',
  true: 'bg-green-500',
}

const statusClass = computed<string>(() => {
  return statusMappings[props.params?.value] || ''
})

const statusName = computed(() => {
  const value = props.params?.value
  const map: Record<any, string> = {
    false: i18n.t('No'),
    true: i18n.t('Yes'),
    [EmployeeStatus.Draft]: i18n.t('Uninvited'),
    default: i18n.t(capitalize(value)),
  }
  return map[value] || map.default
})
</script>
