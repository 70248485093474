<template>
  <WarningTip>
    <span v-if="isHolidayApproved(props.holiday)">
      {{ $t('You can no longer edit this holiday request because it has been approved') }}
    </span>
    <div v-else-if="isHolidayRejected(props.holiday)">
      <p>{{ $t('You can no longer edit this holiday request because it has been rejected') }}</p>
      <p class="mt-1">
        <b>{{ $t('Reject reason: ') }}</b> {{ props.holiday.attributes.status_explanation }}
      </p>
    </div>
    <div v-else-if="isHolidayCancelled(props.holiday)">
      <p>{{ $t('You can no longer edit this holiday request because it has been cancelled') }}</p>
    </div>
  </WarningTip>
</template>
<script lang="ts" setup>
import { PropType } from "vue";
import { isHolidayApproved, isHolidayCancelled, isHolidayRejected } from "@/modules/holidays/utils/holidayUtils";
import WarningTip from "@/components/common/WarningTip.vue";
import Holiday = App.Domains.Holidays.Models.Holiday;
import Data = API.Data;

const props = defineProps({
  holiday: {
    type: Object as PropType<Data<Holiday>>,
    required: true,
  }
})
</script>
