<template>
  <div class="flex flex-col sm:flex-row space-x-2">
    <div class="space-y-2 mb-4 flex flex-1 flex-col">
      <BaseContentCard :title="$t('Company details')">
        <ContentCardRow :title="$t('Company name')">
          {{ currentClient.company_name }}
        </ContentCardRow>
        <ContentCardRow :title="$t('Description')">
          {{ currentClient.description }}
        </ContentCardRow>
        <ContentCardRow :title="$t('Preferred currency')">
          {{ currentClient.prefered_currency }}
        </ContentCardRow>
        <ContentCardRow :title="$t('Company identifier')">
          {{ currentClient.company_identifier }}
        </ContentCardRow>
        <ContentCardRow :title="$t('Company VAT')">
          {{ currentClient.company_vat }}
        </ContentCardRow>
        <ContentCardRow :title="$t('Company address')">
          {{ formattedAddress }}
        </ContentCardRow>
        <ContentCardRow :title="$t('Bank name')">
          {{ currentClient.bank_name }}
        </ContentCardRow>
        <ContentCardRow :title="$t('Bank account')">
          {{ currentClient.bank_account }}
        </ContentCardRow>
        <ContentCardRow :title="$t('Bank swift')">
          {{ currentClient.bank_swift }}
        </ContentCardRow>
      </Basecontentcard>
      <BaseContentCard
        v-if="isSpecialCompany()"
        :title="$t('Stats (This Year)')">
        <ClientStats v-if="$can('manageInvoices')" :client-id="currentClient.id"/>
      </BaseContentCard>
      <BaseContentCard :title="$t('Contacts')">
        <ClientContacts :read-only="true" :client-id="currentClient.id" class="-mt-4"/>
      </BaseContentCard>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'
import BaseContentCard from '@/components/common/BaseContentCard.vue'
import ContentCardRow from '@/components/common/ContentCardRow.vue'
import { useAuthStore } from '@/modules/auth/store/authStore'
import { useClientStore } from '@/modules/clients/store/clientStore'
import Client = App.Domains.Clients.Models.Client
import { formatAddress } from '@/modules/common/utils/addressUtils'
import ClientContacts from "@/modules/clients/components/ClientContacts.vue";
import ClientStats from "@/modules/clients/components/ClientStats.vue";
import { isSpecialCompany } from "@/modules/common/config";

const { t } = useI18n()

const clientStore = useClientStore()
const store = storeToRefs(clientStore)
// @ts-expect-error
const currentClient = computed<Client>(() => {
  return clientStore.currentClient
})

const authStore = useAuthStore()
const { profile } = storeToRefs(authStore)

const formattedAddress = computed(() => {
  return formatAddress(currentClient.value?.company_address)
})
</script>
