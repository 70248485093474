import { defineStore } from 'pinia'
import axios from "axios"
import { addHours, isSameDay } from "date-fns"
import Cache from '@/modules/common/Cache'
import { $deleteConfirm } from "@/components/common/modal/modalPlugin"
import i18n from "@/i18n"
import Timesheet = App.Domains.TimeSheets.Models.Timesheet;
import Data = API.Data;

export const useTimeSheetStore = defineStore('timesheets', {
  state: () => {
    return {
      currentTimeSheet: {} as any,
      currentTimeSheetLoading: false as boolean,
      currentTimeSheetError: null as any,
      hasPastTimer: false as boolean,
      pastTimer: null as Data<Timesheet> | null,
    }
  },
  actions: {
    async getTimeSheet(id: string) {
      if (!id) {
        return
      }
      try {
        this.currentTimeSheetLoading = true
        const { data } = await Cache.getRequest(`/restify/timesheets/${id}`)
        this.currentTimeSheet = data
        this.currentTimeSheetError = null
      } catch (err: any) {
        this.currentTimeSheetError = err
      } finally {
        this.currentTimeSheetLoading = false
      }
    },
    async updateTimeSheet(requestData: any) {
      if (!requestData.id) {
        return
      }
      // Temp workaround to bypass timezone issues
      requestData.date = addHours(requestData.date, 12)
      const { data } = await axios.put(`/restify/timesheets/${requestData.id}`, requestData)
      return data
    },
    async patchTimeSheet(id: string, patchData: any) {
      const { data } = await axios.patch(`/restify/timesheets/${id}`, patchData)
      return data
    },
    async startTimer(time: Data<Timesheet>, otherLogs: Data<Timesheet>[] = []) {
      try {
        const logsExceptCurrent = otherLogs.filter(log => log.id !== time.id)
        time.attributes.timer_started_at = new Date().toISOString()
        const { data } = await axios.post(`/restify/timesheets/${time.id}/actions?action=start-timer`)
        time.attributes.timer_started_at = data.timer_started_at
        for (const log of logsExceptCurrent) {
          await this.stopTimer(log)
        }
        await this.checkForPastTimers(time.attributes?.employee_id)
        return data
      } catch (err) {
        time.attributes.timer_started_at = null
      }
    },
    async stopTimer(time: Data<Timesheet>) {
      const startedAt = time.attributes.timer_started_at
      try {
        time.attributes.timer_started_at = null
        const { data } = await axios.post(`/restify/timesheets/${time.id}/actions?action=stop-timer`)
        await this.checkForPastTimers(time.attributes.employee_id)
        return data
      } catch (err) {
        time.attributes.timer_started_at = startedAt
      }
    },
    async checkForPastTimers(employee_id: string) {
      const { data } = await axios.get('/restify/timesheets', {
        params: {
          '-timer_started_at': 'null',
          employee_id,
        },
      })
      const pastTimers = data.filter((log: Data<Timesheet>) => !isSameDay(new Date(log.attributes.date as string), new Date()))
      this.hasPastTimer = pastTimers?.length > 0
      this.pastTimer = pastTimers[0]
    },
    async createTimeSheet(requestData: any) {
      // Temp workaround to bypass timezone issues
      requestData.date = addHours(requestData.date, 12)
      const { data } = await axios.post(`/restify/timesheets`, requestData)
      return data
    },
    async deleteTimeSheet(id: string) {
      const confirm = await $deleteConfirm({
        title: i18n.t('Permanently delete this time entry?'),
        description: i18n.t('This action cannot be undone'),
      })
      if (!confirm) {
        return
      }
      await axios.delete(`/restify/timesheets/${id}`)
      return true
    },
    async deleteTimesheets(ids: string[]) {
      for (const id of ids) {
        await axios.delete(`/restify/timesheets/${id}`)
      }
    },
    async exportTimesheet() {
      await axios.post(`/restify/timesheets/actions?action=export`, {
        format: 'csv',
        repositories: 'all',
        range: {
          start_date: '2023-09-01',
          end_date: '2023-09-30',
        },
        project_ids: [],
        employee_ids: [],
      })
    },
  },
})
