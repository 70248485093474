<template>
  <div
    class="h-full max-h-full flex justify-center items-center"
  >
    <div
      v-if="currentDocumentLoading"
      class="h-full w-full rounded bg-gray-100 animate-pulse"
    />
    <template v-else>
      <img
        v-if="isImage"
        :src="currentDocument?.attributes?.path"
        :alt="currentDocument?.attributes?.name"
        class="max-h-full"
      >
      <iframe
        v-else-if="isIframePreview"
        :src="currentDocument?.attributes?.path"
        :title="currentDocument?.attributes?.name"
        class="h-full w-full"
      />
      <iframe
        v-else-if="isDocPreview"
        :src="officePreviewUrl"
        class="w-full h-full"
        allowfullscreen
      />
      <div v-else-if="isInvalidType">
        <span>{{ t('Unfortunately this file type is not supported and cannot be downloaded') }}</span>
      </div>
      <div v-else class="w-full flex flex-col items-center justify-center font-medium mb-20">
        <IconBox class="mb-1" @click="documentsStore.downloadCurrentFile">
          <FolderArrowDownIcon class="w-5 h-5" />
        </IconBox>
        <span>{{ t('Unfortunately this file type is not supported for preview.') }}</span>
        <span>{{ t('Click ') }} <button
          class="text-primary hover:underline"
          @click="documentsStore.downloadCurrentFile"
        >{{
          t('here')
        }}</button> {{ t('to download the file') }}</span>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue"
import { useI18n } from "vue-i18n"
import { storeToRefs } from "pinia"
import { useRoute, useRouter } from "vue-router"
import { FolderArrowDownIcon } from '@heroicons/vue/24/outline'
import { useDocumentsStore } from "@/modules/documents/store/documentsStore"
import { docTypes, fileTypes, getFileType, isAcceptedFile, previewTypes } from "@/modules/documents/utils/documentUtils"

const documentsStore = useDocumentsStore()
const { currentDocument, currentDocumentLoading } = storeToRefs(documentsStore)

const { t } = useI18n()
const router = useRouter()
const route = useRoute()

const fileType = computed(() => {
  return getFileType(currentDocument.value?.attributes?.file_name)
})
const isImage = computed(() => {
  return fileType.value === fileTypes.Image
})

const isIframePreview = computed(() => {
  return previewTypes.includes(fileType.value as string)
})

const isDocPreview = computed(() => {
  return docTypes.includes(fileType.value as string)
})

const isInvalidType = computed(() => {
  return !isAcceptedFile({ name: currentDocument.value?.attributes?.file_name } as File)
})

const officePreviewUrl = computed(() => {
  let url = currentDocument.value?.attributes?.path
  url = encodeURIComponent(url)
  return `https://view.officeapps.live.com/op/embed.aspx?src=${url}`
})

async function onClose() {
  const path = route.path

  await router.push({
    path,
  })
}
</script>
