<template>
  <li class="flex items-start justify-between gap-x-6 py-5">
    <div class="min-w-0 w-full">
      <ProgressBar :progress="goal.attributes.progress">
        <template #title>
          <div class="flex items-start gap-x-3">
            <p
              class="text-sm font-semibold leading-6 text-gray-900 cursor-pointer"
              @click="emit('edit', goal)"
            >
              {{ goal.attributes.name }}
            </p>
            <p
              class="rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset"
              :class="[statuses[goal.attributes.progress_status]]"
            >
              {{ statusName }}
            </p>
          </div>
        </template>
      </ProgressBar>
      <div class="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
        <p class="whitespace-nowrap">
          {{ t('Due on') }}
          <time :datetime="goal.attributes.due_date">{{ formatDate(goal.attributes.due_date) }}</time>
          <span
            v-if="daysLeft < daysLeftLevels.Notification"
            class="ml-2"
            :class="{
              'text-red-700': daysLeft < daysLeftLevels.Critical,
              'text-yellow-700': daysLeft < daysLeftLevels.Warning,
            }"
          >
            {{ daysLeftText }}
          </span>
        </p>
        <svg viewBox="0 0 2 2" class="h-0.5 w-0.5 fill-current">
          <circle cx="1" cy="1" r="1"/>
        </svg>
        <p class="truncate">
          {{ t('Priority') }}
          <span class="capitalize font-medium ml-1" :class="priorities[goal.attributes.priority]">
            {{ priorityName }}
          </span>
        </p>
      </div>
      <div
        v-if="goal.attributes.description"
        class="prose whitespace-pre prose-sm prose-gray mt-2" v-html="goal.attributes.description"
      />
    </div>
    <div class="flex flex-none items-center gap-x-4">
      <ElDropdown
        v-if="$can('manageGoals') || isOwnEmployee"
        ref="dropdown"
        :hide-on-click="false"
        trigger="click"
        placement="bottom-end"
      >
        <div class="mt-2 block p-2.5 text-gray-500 hover:text-gray-900">
          <span class="sr-only">{{ $t('Open options') }}</span>
          <EllipsisVerticalIcon class="h-5 w-5" aria-hidden="true"/>
        </div>
        <template #dropdown>
          <ElDropdownMenu class="min-w-[150px]">
            <ElDropdownItem
              @click="emit('edit', goal)"
            >
              <PencilSquareIcon class="w-4 h-4 mr-2 text-primary"/>
              <span>{{ t('Edit') }}</span>
            </ElDropdownItem>
          </ElDropdownMenu>
          <ElDropdownMenu class="min-w-[150px]">
            <ElDropdownItem
              @click="emit('delete', goal)"
            >
              <TrashIcon class="w-4 h-4 mr-2 text-primary"/>
              <span>{{ t('Delete') }}</span>
            </ElDropdownItem>
          </ElDropdownMenu>
        </template>
      </ElDropdown>
    </div>
  </li>
</template>

<script lang="ts" setup>
import { computed, PropType } from "vue"
import { EllipsisVerticalIcon, PencilSquareIcon, TrashIcon } from '@heroicons/vue/24/outline'
import { useRoute } from "vue-router"
import { useI18n } from "vue-i18n"
import differenceInDays from "date-fns/differenceInDays"
import { formatDate } from "../../common/utils/dateUtils"
import { GoalPriority, GoalProgressStatus } from "@/modules/employees/utils/employeeUtils"
import { useAuth } from "@/modules/auth/composables/useAuth"
import ProgressBar from "@/components/data/ProgressBar.vue"
import Data = API.Data;

const props = defineProps({
  goal: {
    type: Object as PropType<Data<any>>,
    default: () => ({}),
  },
})
const emit = defineEmits(['edit', 'delete'])
const { userEmployeeId } = useAuth()
const route = useRoute()

const isOwnEmployee = computed(() => {
  const routeId = route.params.id
  const isPersonalGoalsPage = route.path === '/settings/account/goals'
  if (isPersonalGoalsPage) {
    return true
  }
  return routeId ? routeId === userEmployeeId.value : false
})
const { t } = useI18n()
const statusName = computed(() => {
  const statusMap: Record<string, string> = {
    [GoalProgressStatus.Completed]: t('Completed'),
    [GoalProgressStatus.InProgress]: t('In Progress'),
    [GoalProgressStatus.NotStarted]: t('Not Started'),
  }
  const status = props.goal?.attributes?.progress_status as string
  return statusMap[status]
})

const priorityName = computed(() => {
  const statusMap: Record<string, string> = {
    [GoalPriority.Low]: t('Low'),
    [GoalPriority.Medium]: t('Medium'),
    [GoalPriority.High]: t('High'),
  }
  const status = props.goal?.attributes?.priority as string
  return statusMap[status]
})

const daysLeft = computed(() => {
  const dueDate = props.goal.attributes.due_date
  if (!dueDate) {
    return null
  }
  return differenceInDays(new Date(dueDate), new Date())
})

const daysLeftLevels = {
  Critical: 0,
  Warning: 14,
  Notification: 30,
}

const daysLeftText = computed(() => {
  if (daysLeft.value === null) {
    return null
  }
  if (daysLeft.value < 0) {
    return t('Overdue by {days} days', { days: Math.abs(daysLeft.value) })
  }
  if (daysLeft.value === 0) {
    return t('Due today')
  }
  return t('Due in {days} days', { days: daysLeft.value })
})

const statuses = {
  [GoalProgressStatus.Completed]: 'text-green-700 bg-green-50 ring-green-600/20',
  [GoalProgressStatus.InProgress]: 'text-primary bg-primary/10 ring-primary/10',
  [GoalProgressStatus.NotStarted]: 'text-gray-800 bg-gray-50 ring-gray-600/20',
}

const priorities = {
  [GoalPriority.Low]: 'text-blue-700',
  [GoalPriority.Medium]: 'text-yellow-700',
  [GoalPriority.High]: 'text-red-700',
}
</script>
