<template>
  <BaseFormCard
    :submit-text="t('Update report')"
    class="detailed-report-filters"
    @submit="onSubmit"
    @cancel="onCancel"
  >
    <FormCardSection container-class="flex flex-col space-y-4">
      <div class="col-span-6 flex flex-col">
        <FormKit
          v-model="currentReport.intervalType"
          :options="reportIntervalTypes"
          :label="t('Interval')"
          type="customSelect"
        />
        <div
          v-if="currentReport.intervalType === IntervalTypes.Custom"
          class="flex flex-col md:flex-row col-span-6 md:items-center md:space-x-2 mt-4"
        >
          <div class="w-0 md:w-[142px]"></div>
          <FormKit
            v-model="customRange.from"
            :label="isMobile() ? t('From') : ''"
            :placeholder="t('From')"
            type="customDatePicker"
            outer-class="w-full md:w-[155px]"
          />
          <span class="hidden md:block w-[60px] text-center text-base-300">{{ t('to') }}</span>
          <FormKit
            v-model="customRange.to"
            :label="isMobile() ? t('To') : ''"
            :placeholder="t('To')"
            type="customDatePicker"
            outer-class="w-full md:w-[155px]"
          />
        </div>
      </div>
      <FormKit
        v-model="currentReport.client_id"
        :label="t('Client')"
        :placeholder="t('All clients')"
        :disabled="!$can('manageTimesheets')"
        clearable
        type="clientSelect"
        outer-class="col-span-6"
      />
      <FormKit
        v-model="currentReport.project_id"
        :label="t('Project')"
        :placeholder="t('All projects')"
        :client-id="currentReport.client_id"
        clearable
        type="projectSelect"
        outer-class="col-span-6"
      />
      <FormKit
        v-model="currentReport.task_id"
        :label="t('Task')"
        :placeholder="t('All tasks')"
        :disabled="!currentReport.project_id"
        :options="projectTasks"
        clearable
        type="taskSelect"
        outer-class="col-span-6"
      />
      <FormKit
        v-if="$can('manageTimesheets')"
        v-model="currentReport.employee_id"
        :label="t('Person')"
        :placeholder="t('All team members')"
        :options="currentReport.project_id ? projectEmployees : null"
        clearable
        type="employeeSelect"
        outer-class="col-span-6"
      />
    </FormCardSection>
  </BaseFormCard>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'
import { computed, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { reportIntervalTypes } from '@/modules/reports/enum/reportEnums'
import { IntervalTypes, useReportsStore } from '@/modules/reports/store/reportsStore'
import { useProjectStore } from '@/modules/projects/store/projectStore'
import { API_REPORT_DATE_FORMAT, formatDate } from '@/modules/common/utils/dateUtils'
import { isMobile } from "@/util/responsiveUtils";

const emit = defineEmits(['submit', 'cancel'])
const reportsStore = useReportsStore()
const { currentReport, customRange } = storeToRefs(reportsStore)

const { t } = useI18n()

const projectStore = useProjectStore()
const project = computed(() => {
  return projectStore.allProjects.find(p => p.id === currentReport.value.project_id)
})
const projectTasks = computed(() => {
  if (!currentReport.value.project_id) {
    return projectStore.allTasks || []
  }
  return project.value?.relationships?.tasks || []
})

const projectEmployees = computed(() => {
  return projectStore.getProjectEmployees(currentReport.value.project_id)
})

const route = useRoute()

function initCurrentReportFromQuery() {
  const { start_date, end_date, interval_type, client_id, project_id, task_id, employee_id } = route.query
  if (start_date && end_date && !interval_type) {
    currentReport.value.from = new Date(start_date as string)
    currentReport.value.to = new Date(end_date as string)
    currentReport.value.intervalType = IntervalTypes.Custom
  } else if (interval_type) {
    currentReport.value.intervalType = interval_type as string
    reportsStore.changeReportRange()
  }
  if (client_id) {
    currentReport.value.client_id = client_id as string
  }
  if (employee_id) {
    currentReport.value.employee_id = employee_id as string
  }
  if (project_id) {
    currentReport.value.project_id = project_id as string
  }
  if (task_id) {
    currentReport.value.task_id = task_id as string
  }
}

initCurrentReportFromQuery()
const router = useRouter()

async function onSubmit() {
  reportsStore.changeReportRange()
  const { from, to, intervalType, client_id, project_id, task_id, employee_id } = currentReport.value
  const start_date = formatDate(from, API_REPORT_DATE_FORMAT)
  const end_date = formatDate(to, API_REPORT_DATE_FORMAT)
  await router.replace({
    query: {
      project_id,
      client_id,
      task_id,
      employee_id,
      interval_type: intervalType,
      start_date,
      end_date,
    },
  })
  emit('submit')
}

function onCancel() {
  emit('cancel')
}

onMounted(async () => {
  await projectStore.getAllTasks()
})
</script>

<style lang="scss">
.detailed-report-filters {
  max-height: none;

  .formkit-outer-container > div {
    @apply flex;
    label {
      @apply w-[100px] md:w-[150px] flex items-start pt-1 pb-0;
    }

    .form-control {
      @apply flex-1 max-w-sm;
    }
  }
}
</style>
