<template>
  <div class="flex flex-col">
    <PageTitle :show-back="false" :show-bread-crumbs="false">
      {{ $t($route.name) }}
    </PageTitle>
    <TabLinks v-if="tabs.length > 1" v-model="activeTab" :tabs="tabs" class="mb-0" />
    <EvaluationTable :employee-id="employeeId" class="flex-1" />
  </div>
</template>

<script setup lang="ts">
import EvaluationTable from "@/modules/evaluations/components/EvaluationTable.vue"
import PageTitle from "@/components/common/PageTitle.vue"
import { useFilterTabs } from "@/modules/common/composables/useFilterTabs"

const props = defineProps({
  employeeId: {
    type: String,
  },
})

const { activeTab, tabs } = useFilterTabs('evaluations')
</script>

<route lang="yaml">
name: Evaluations List
redirect:
  name: My Evaluations
</route>
