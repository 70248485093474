<template>
  <div>
    <BaseDataTable
      ref="table"
      url="/restify/clients"
      :url-params="urlParams"
      :columns="columns"
      :add-text="t('Add Client')"
      :row-height="75"
      :extra-actions="rowActions"
      :suppress-cell-focus="true"
      :actions="actions"
      :show-delete-function="showDelete"
      entity="clients"
      class="h-full"
    >
      <template #header-actions-right>
        <button
          v-if="can('manageClients')"
          class="btn btn-sm btn-primary"
          data-test="add_client_button"
          @click="onAdd"
        >
          <PlusIcon class="w-4 h-4 mr-1"/>
          {{ t('Add client') }}
        </button>
      </template>
    </BaseDataTable>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import { ColDef } from '@ag-grid-community/core'
import { PlusIcon } from '@heroicons/vue/24/outline'
import { useI18n } from 'vue-i18n'
import { can } from '@/plugins/permissionPlugin'
import { RowAction } from '@/components/table/tableTypes'
import Client = App.Domains.Clients.Models.Client;
import Data = API.Data;
import { ColumnTypes } from "@/components/table/cells/tableCellComponents";

// TODO: urlParams
const urlParams = computed(() => {
  return {
    related: 'projects[id]',
  }
})
const { t } = useI18n()
const columns = ref<ColDef[]>([
  {
    headerName: t('Name'),
    field: 'attributes.company_name',
    type: ColumnTypes.ClientLink,
    minWidth: 200,
  },
  {
    headerName: t('Company identifier'),
    field: 'attributes.company_identifier',
    minWidth: 200,
  },
  {
    headerName: t('Company VAT'),
    field: 'attributes.company_vat',
    minWidth: 200,
  },
])

const table = ref()
const actions = computed(() => {
  const baseActions = ['view', 'edit']

  return baseActions.join(',')
})

function showDelete(client: Data<Client>) {
  return client.relationships?.projects?.length === 0
}

const rowActions = computed<RowAction[]>(() => {
  return []
})

const router = useRouter()

async function onAdd() {
  await router.push('/clients/create')
}

async function refreshTable() {
  await table.value?.refresh()
}
</script>

<route lang="yaml">
name: Clients List
meta:
  permission: manageClients
</route>
