<template>
  <BaseDataTable
    ref="table"
    :url="noUrl ? '' : '/restify/expenses'"
    :url-params="urlParams"
    :columns="columns"
    :row-height="50"
    :get-empty-row="getEmptyRow"
    :transform-data="transformData"
    :add-new-row-at-top="true"
    :get-row-id="getRowId"
    v-bind="editableTableProps"
    :actions="editable ? 'add,delete' : 'delete'"
    :full-width-cell-renderer="fullWidthCellRenderer"
    :extra-actions="rowActions"
    :is-full-width-row="(params: any) => params.rowNode?.data?.fullWidth"
    :get-row-height="getExpenseRowHeight"
    class="expense-table"
    @grid-init="grid = $event"
    @add="onAdd"
    @cell-value-changed="onCellValueChanged"
  >
    <template #actions-before>
      <slot name="actions-before" />
    </template>
    <template
      v-if="can('manageEmployees')"
      #bulk-actions="{ selectedRows }"
    >
      <li>
        <button
          class="btn-sm no-underline"
          @click="onSelectionToggle"
        >
          <CheckIcon v-if="!showSelection" class="w-4 h-4 text-primary" />
          <EyeSlashIcon v-else class="w-4 h-4 text-primary" />
          <span>
            {{ showSelection ? $t('Hide selection') : $t('Select expenses') }}
          </span>
        </button>
        <BaseTooltip
          :content="selectedRows.length === 0 ? $t('Please select expenses first') : ''"
          :disabled="selectedRows.length > 0"
        >
          <button
            class="btn-sm no-underline"
            :class="{ 'opacity-50 hover:bg-transparent': selectedRows.length === 0 }"
            :disabled="selectedRows.length === 0"
            @click="downloadExpenses(selectedRows)"
          >
            <LoadingCircle v-if="downloadingExpenses" class="w-4 h-4 text-primary" />
            <CloudArrowDownIcon v-else class="w-4 h-4 text-primary" />
            {{ $t('Download') }}
          </button>
        </BaseTooltip>
      </li>
    </template>
    <template #description="{ row }">
      <div class="flex space-x-2 truncate">
        <ExpenseStatus :params="{ data: { id: row.id, attributes: row } }" :show-name="false" />
        <span :title="row.description" class="truncate">
          {{ row.description }}
        </span>
      </div>
    </template>
    <template #expense_category_id="{ row }">
      <ExpenseCategoryTag :id="row.expense_category_id" />
    </template>
    <template #employee_id="{ row }">
      <EmployeeLink :params="{ value: row.employee_id }" :show-link="false" />
    </template>
    <template #receipt_path="{ row }">
      <div class="truncate">
        <div v-if="!row?.receipt_path">
          <FileUploadButton
            :accept="acceptedFileTypes"
            :multiple="false"
            button-size="xs"
            class="print:hidden flex items-center"
            @input="onFileUpload(row, $event)"
          >
            {{ $t('Upload file') }}
          </FileUploadButton>
        </div>
        <PendingFileInfo
          v-if="row?.receipt_path"
          :file="row?.receipt_path"
          :file-name="row?.receipt_filename"
          :can-remove="editable"
          class="!space-x-3 h-[40px]"
          @close="deleteFile(row)"
        />
      </div>
    </template>
  </BaseDataTable>
</template>

<script setup lang="ts">
import { computed, nextTick, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import {
  CellValueChangedEvent,
  GridReadyEvent,
  ICellEditorParams,
  ValueFormatterParams,
  ValueGetterParams, ValueSetterParams,
} from "@ag-grid-community/core"
import {
  CheckIcon,
  CloudArrowDownIcon,
  CreditCardIcon,
  ExclamationCircleIcon,
  EyeSlashIcon,
  ShieldCheckIcon,
} from "@heroicons/vue/24/outline"
import axios from "axios"
import BaseDataTable from "@/components/table/BaseDataTable.vue"
import { useTableEditing } from "@/components/table/useTableEditing"
import { ColumnTypes, tableCellEditors } from "@/components/table/cells/tableCellComponents"
import { formatPrice } from "@/plugins/formatPrice"
import { requiredValueSetter, saveInlineEntry, updateInlineEntry } from "@/components/table/tableUtils"
import { useSettingsStore } from "@/modules/settings/store/settingsStore"
import PendingFileInfo from "@/modules/documents/components/PendingFileInfo.vue"
import FileUploadButton from "@/modules/invoices/components/FileUploadButton.vue"
import Data = API.Data
import ExpenseCategoryTag from "@/components/data/ExpenseCategoryTag.vue"
import { FilterTypes } from "@/components/table/filters/filterTypes"
import { SettingKeys } from "@/modules/auth/types/enums"
import { $confirm, $deleteConfirm } from "@/components/common/modal/modalPlugin"
import { RowAction } from "@/components/table/tableTypes"
import i18n from "@/i18n"
import {
  canApproveExpense,
  canMarkAsPaid,
  isExpensePending,
  useExpenseStore,
} from "@/modules/expenses/store/expenseStore"
import ExpenseStatus from "@/components/table/cells/ExpenseStatus.vue"
import { getExpenseRowHeight } from "@/modules/expenses/utils/expenseUtils"
import TotalExpenseBundlesRow from "@/modules/expenses/components/TotalExpenseBundlesRow.vue"
import EmployeeLink from "@/components/table/cells/EmployeeLink.vue"
import { can } from "@/plugins/permissionPlugin"
import BaseTooltip from "@/components/common/BaseTooltip.vue"
import { downloadFileLocally } from "@/modules/common/utils/downloadFileLocally"
import { success } from "@/components/common/NotificationPlugin"
import LoadingCircle from "@/components/form/LoadingCircle.vue"
import { CalendarDateFormat, formatDate } from "@/modules/common/utils/dateUtils";

const props = defineProps({
  expenses: {
    type: Array,
    default: () => [],
  },
  bundleId: {
    type: String,
  },
  employeeId: {
    type: String,
  },
  projectId: {
    type: String,
  },
  editable: {
    type: Boolean,
  },
  noUrl: {
    type: Boolean,
  },
  extraUrlParams: {
    type: Object,
    default: () => ({}),
  },
  showAllColumns: {
    type: Boolean,
  },
  canFilter: {
    type: Boolean,
  },
})

const { t } = useI18n()
const grid = ref<GridReadyEvent>()
const showSelection = ref(false)
const downloadingExpenses = ref(false)

async function downloadExpenses(selectedRows: any[]) {
  const hideNotification = success({
    message: t('Preparing to download expenses...'),
    loading: true,
  })
  try {
    downloadingExpenses.value = true
    const repositories = selectedRows.map((row: any) => row.id)
    const res = await axios.post(`/restify/expenses/actions?action=download-expenses`, { repositories }, {
      responseType: 'blob',
    })
    const date = formatDate(new Date(), CalendarDateFormat)
    downloadFileLocally(res, `expenses ${date}.zip`)
    grid.value!.api.deselectAll()
  } finally {
    downloadingExpenses.value = false
    hideNotification()
  }
}

async function onSelectionToggle() {
  showSelection.value = !showSelection.value
  await nextTick()
  if (!showSelection.value) {
    grid.value?.api?.deselectAll()
  }
  grid.value!.api?.redrawRows()
}

const { addRow, editableTableProps } = useTableEditing(grid, {
  ...props,
  addNewRowAtTop: true,
})

interface ExpenseRow {
  id?: string
  _localId?: string
  expense_category_id: number
  date: string
  amount: number
  currency: string
  description: string
  receipt_path: string
}

const settingStore = useSettingsStore()
const acceptedFileTypes = `.pdf,.jpg,.jpeg,.png`
const fullWidthCellRenderer = TotalExpenseBundlesRow

function canEditExpense(expense: any) {
  return !expense.paid_at
}

function getRowId(params: any) {
  return params.data?._localId || params.data?.id || params?.data?.entity_id
}

const urlParams = computed(() => {
  return {
    expense_bundle_id: props.bundleId,
    temporary_urls: true,
    employee_id: props.employeeId,
    project_id: props.projectId,
    ...props.extraUrlParams,
  }
})

const expenseStore = useExpenseStore()
const rowActions = computed<RowAction[]>(() => [
  {
    label: i18n.t('Approve'),
    icon: ShieldCheckIcon,
    action: async (row: Data<any>, params: ICellEditorParams) => {
      await expenseStore.approveExpense(row)
      params?.api?.redrawRows()
    },
    show: (row: Data<any>) => {
      return canApproveExpense({ id: row.id, attributes: row })
    },
  },
  {
    label: i18n.t('Reject'),
    icon: ExclamationCircleIcon,
    action: async (row: Data<any>, params: ICellEditorParams) => {
      await expenseStore.rejectExpense(row)
      params?.api?.redrawRows()
    },
    show: (row: Data<any>) => {
      return isExpensePending({ id: row.id, attributes: row })
    },
  },
  {
    label: i18n.t('Mark as paid'),
    icon: CreditCardIcon,
    action: async (row: any, params: ICellEditorParams) => {
      await expenseStore.markExpenseAsPaid(row)
      params?.api?.redrawRows()
    },
    show: (row: any) => {
      return canMarkAsPaid({ id: row.id, attributes: row })
    },
  },
])

const columns = computed(() => {
  return [
    {
      headerName: t('Description'),
      field: 'description',
      cellEditor: 'agLargeTextCellEditor',
      type: 'custom',
      cellEditorParams: {
        rows: 3,
      },
      editable: props.editable,
      minWidth: 150,
      maxWidth: 300,
      checkboxSelection: showSelection.value,
      valueSetter: (params: ValueSetterParams) => requiredValueSetter(params, ''),
      filter: FilterTypes.ExpenseStatus,
    },
    {
      headerName: t('Category'),
      field: 'expense_category_id',
      type: 'custom',
      cellEditor: tableCellEditors.ExpenseCategorySelectCellEditor,
      editable: props.editable,
      minWidth: 160,
      maxWidth: 220,
      valueFormatter: (params: ValueFormatterParams) => {
        return settingStore.getExpenseCategoryById(params.value)?.attributes?.name
      },
      valueGetter: (params: ValueGetterParams) => {
        return params.data.expense_category_id
      },
      valueSetter: requiredValueSetter,
    },
    {
      headerName: t('Date'),
      field: 'date',
      editable: props.editable,
      cellEditor: 'agDateStringCellEditor',
      type: ColumnTypes.Date,
      minWidth: 90,
      maxWidth: 110,
      filter: props.canFilter ? FilterTypes.Date : null,
      valueSetter: requiredValueSetter,
    },
    {
      headerName: t('Amount'),
      field: 'amount',
      editable: props.editable,
      cellEditor: 'agNumberCellEditor',
      type: 'rightAligned',
      cellDataType: 'number',
      cellClass: 'justify-end',
      cellEditorParams: (params: ICellEditorParams) => {
        return {
          min: 0,
        }
      },
      valueFormatter: (params: ValueFormatterParams) => {
        return formatPrice(params.value, {
          currency: params.data.currency,
        })
      },
      valueGetter: (params: ValueGetterParams) => {
        return +params.data.amount
      },
      minWidth: 100,
      maxWidth: 160,
    },
    {
      headerName: t('Currency'),
      field: 'currency',
      editable: props.editable,
      cellEditor: tableCellEditors.CurrencySelectEditor,
      minWidth: 70,
      maxWidth: 100,
      valueSetter: requiredValueSetter,
    },
    {
      headerName: t('Employee'),
      field: 'employee_id',
      type: 'custom',
      cellRendererParams: {
        showLink: false,
      },
      minWidth: 180,
      maxWidth: 250,
      editable: props.editable,
      cellEditor: tableCellEditors.EmployeeCellEditor,
      initialHide: !!props.employeeId || !!props.bundleId,
      filter: props.employeeId ? null : FilterTypes.Employee,
      valueSetter: requiredValueSetter,
    },
    {
      headerName: t('Project'),
      field: 'project_id',
      type: ColumnTypes.ProjectLink,
      editable: props.editable,
      cellEditor: tableCellEditors.ProjectCellEditor,
      minWidth: 100,
      maxWidth: 200,
      initialHide: !!props.projectId || !!props.bundleId,
      valueSetter: requiredValueSetter,
    },
    {
      headerName: t('Receipt'),
      field: 'receipt_path',
      type: 'custom',
      cellClass: 'receipt-cell',
      editable: props.editable,
      cellEditor: tableCellEditors.ReceiptEditor,
      cellDataType: false,
      minWidth: 200,
      maxWidth: 320,
      valueSetter: requiredValueSetter,
    },
  ]
})

function transformData(data: Data<any>[], response: any) {
  const fullWidthRow = {
    _localId: crypto.randomUUID(),
    fullWidth: true,
    meta: response?.meta,
  }
  const hasTotals = response?.meta?.by_currency?.length > 0
  const mappedRows = data.map((entry: any) => {
    return {
      ...(entry.attributes || {}),
      amount: +entry.attributes.amount || 0,
      id: entry.id,
    }
  })
  if (mappedRows?.length === 0 || !hasTotals) {
    return mappedRows
  }
  return mappedRows.concat([fullWidthRow])
}

function onFileUpload(row: any, event: any) {
  const file = event.target?.files?.[0]
  updateFile(row, file)
}

async function deleteFile(row: any) {
  const confirmed = await $confirm({
    title: t('Are you sure you want to remove the file?'),
    buttonText: t('Remove'),
  })
  if (!confirmed) {
    return
  }
  if (row?.id) {
    await axios.delete(`/restify/expenses/${row.id}/field/receipt_path`)
  }
  updateFile(row, null)
}

function updateFile(row: any, file: any) {
  const rowId = row._localId || row.id
  const node = grid.value?.api?.getRowNode(rowId)
  node!.data.receipt_path = file
  node?.setData(node.data)
  const updateEvent = {
    data: node!.data,
    node,
  } as CellValueChangedEvent
  saveOrUpdateEntry(updateEvent)
}

async function onCellValueChanged(params: CellValueChangedEvent) {
  const field = params?.colDef?.field
  await saveOrUpdateEntry(params)
}

function isRowValid(row: any) {
  return row.date && row.amount > 0
}

function getRowData(entry: any) {
  const fieldsToInclude = Object.keys(getEmptyRow())
  const formData = new FormData()
  formData.append('id', entry.id)
  fieldsToInclude.forEach((field) => {
    const value = entry[field]
    const emptyFieldsToSkip = ['_localId', 'project_id', 'expense_bundle_id', 'employee_id', 'expense_category_id']
    if (emptyFieldsToSkip.includes(field) && !value) {
      return
    }
    if (field === 'receipt_path' && typeof value === 'string') {
      return
    }
    formData.append(field, value)
  })
  return formData
}

async function saveOrUpdateEntry(params: CellValueChangedEvent) {
  const row = params.data
  const isValid = isRowValid(row)
  if (!isValid) {
    return params.data
  }
  const oldData = { ...params.data }
  const entry = { ...row }
  let savedData = params.data
  const requestData = getRowData(entry)
  try {
    params.data.loading = true
    if (!entry.id) {
      savedData = await saveInlineEntry({
        url: '/restify/expenses',
        row: requestData,
        params,
      })
    } else {
      savedData = await updateInlineEntry({
        url: `/restify/expenses/${entry.id}?temporary_urls=true`,
        method: 'post',
        row: requestData,
        params,
      })
    }
    params.node.setData(params.data)
  } catch (err) {
    console.error(err)
    params.node.setData(oldData)
  }
  return savedData
}

const settingsStore = useSettingsStore()

function getEmptyRow() {
  return {
    _localId: crypto.randomUUID(),
    expense_category_id: undefined,
    date: new Date().toISOString(),
    amount: 0,
    currency: settingsStore.getSetting(SettingKeys.DefaultCurrency),
    description: '',
    receipt_path: null,
    employee_id: props.employeeId,
    project_id: props.projectId,
    expense_bundle_id: props.bundleId,
  }
}

async function deleteRow(row: any) {
  const confirmed = await $deleteConfirm({
    title: t('Delete expense'),
    description: t('Are you sure you want to delete this expense?'),
    buttonText: t('Delete'),
  })
  if (!confirmed) {
    return
  }
  if (!row.id) {
    grid.value?.api?.applyTransaction({
      remove: [row],
    })
  }
  try {
    await axios.delete(`/restify/expenses/${row.id}`)
    grid.value?.api?.applyTransaction({
      remove: [row],
    })
  } catch (err) {
    console.error(err)
  }
}

function onAdd() {
  addRow(getEmptyRow())
}

const table = ref()

function refresh() {
  table.value?.refresh()
}

defineExpose({
  refresh,
})
</script>

<style lang="scss">
.expense-table .receipt-cell .ag-cell-wrapper,
.expense-table .receipt-cell .ag-cell-value {
  @apply overflow-visible;
}

.expense-table .delete-button {
  @apply h-[30px] w-[30px] max-h-[30px] p-0;
}
</style>
