<template>
  <time>
    {{ formattedPrice }}
  </time>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { formatDate } from "@/modules/common/utils/dateUtils";
import { defaultCurrency, formatPrice } from "@/plugins/formatPrice";

const props = defineProps({
  params: {
    type: Object,
    default: () => ({})
  }
})
const formattedPrice = computed(() => {
  try {
    const currency = props.params.currency || defaultCurrency
    const value = props.params.value as number
    
    return formatPrice(value, {
      currency,
    })
  } catch (err) {
    return ''
  }
})
</script>
