<template>
  <ElDialog
    v-bind="$attrs"
    size="xl"
  >
    <HolidayDetails class="mt-2" />
  </ElDialog>
</template>

<script lang="ts" setup>
import { onMounted, watch } from "vue"
import HolidayDetails from "@/modules/holidays/components/HolidayDetails.vue"
import { useHolidayStore } from "@/modules/holidays/store/holidayStore"
const props = defineProps({
  id: {
    type: String,
  },
})

const holidayStore = useHolidayStore()

watch(() => props.id, async (value: any) => {
  await holidayStore.getHoliday(value as string)
})
onMounted(async () => {
  await holidayStore.getHoliday(props.id as string)
})
</script>
