import { formKitCNPValidation } from './cnpValidation'
import { hoursValidation } from "@/modules/common/utils/parseHours";

export const validationRules = {
  cnp: formKitCNPValidation,
  slackUrl: (node: any) => {
    return node.value.includes('https://hooks.slack.com/services/')
  },
  domain: (node: any) => {
    // check if node only contains alphanumeric and - characters. It should not contain spaces
    return /^[a-zA-Z0-9-]+$/.test(node.value)
  },
  timeLog: hoursValidation,
}
