<template>
  <div class="flex space-x-2 items-center h-full">
    <span class="w-2 h-2 rounded-full" :style="{ backgroundColor: tag.color || defaultColor }" />
    <span class="capitalize text-sm">
      {{ tag.name }}
    </span>
  </div>
</template>

<script setup lang="ts">
import { PropType } from "vue"
import DocumentTag = App.Domains.Documents.Models.DocumentTag
import { TagColors } from "@/modules/settings/types/settingTypes";

const props = defineProps({
  tag: {
    type: Object as PropType<DocumentTag>,
    default: () => ({}),
  },
})

const defaultColor = TagColors.Primary
</script>
