<template>
  <div class="flex flex-col">
    <PageTitle :show-back="false">
      {{ $t($route.name) }}
    </PageTitle>
    <TabLinks v-if="tabs.length > 1" v-model="activeTab" :tabs="tabs" class="mb-0"/>
    <DocumentsTable :employee-id="employeeId" class="flex-1"/>
  </div>
</template>

<script setup lang="ts">
import DocumentsTable from "@/modules/documents/components/DocumentsTable.vue";
import { useFilterTabs } from "@/modules/common/composables/useFilterTabs";
const props = defineProps({
  employeeId: {
    type: String,
  }
})

const { activeTab, tabs } = useFilterTabs('documents')
</script>
<route lang="yaml">
name: Documents
</route>
