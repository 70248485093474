<template>
  <Tooltip
    v-if="tooltip"
    :title="tooltip"
    :shortcut="tooltipShortcut"
  >
    <Button
      v-bind="$attrs"
      :active="active"
      :class="buttonClass"
      :variant="variant"
      :button-size="buttonSize"
    >
      <slot />
    </Button>
  </Tooltip>
  <Button
    v-else
    v-bind="$attrs"
    :active="active"
    :class="buttonClass"
    :variant="variant"
    :button-size="buttonSize"
  >
    <slot />
  </Button>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import Button from "@/components/formkit/html/components/Button.vue"
import { Tooltip } from "@/components/formkit/html/components/tooltip"

defineProps({
  active: Boolean,
  tooltip: String,
  tooltipShortcut: Array,
  buttonSize: {
    type: String,
    default: 'icon',
  },
  variant: {
    type: String,
    default: 'ghost',
  },
})

const buttonClass = computed(() => ['gap-1 min-w-[2rem] px-2 !w-auto'].filter(Boolean).join(' '))
</script>
