<template>
  <div>
    <BaseButton variant="primary" outline size="sm" @click="showDialog = true">
      <CloudArrowUpIcon class="w-4 h-4 mr-2" />
      <span>{{ $t('Add more files') }}</span>
    </BaseButton>
    <BaseFormCard
      v-model="showDialog"
      :disabled="loading"
      :title="$t('Upload files to expense bundle')"
      :submit-text="$t('Upload')"
      :is-dialog="true"
      @submit="onSubmit"
      @close="showDialog = false"
    >
      <FormCardSection
        :is-dialog="true"
      >
        <ExpenseFileDropzone
          v-if="!loading"
          v-model="fromFilesModel.files"
          class="mb-9 mt-2 col-span-6"
        />
        <ExpenseProcessingAnimation v-if="loading" class="col-span-6" />
      </FormCardSection>
    </BaseFormCard>
  </div>
</template>

<script lang="ts" setup>
import { ref, watch } from "vue"
import { useI18n } from "vue-i18n"
import { CloudArrowUpIcon } from "@heroicons/vue/24/outline"
import BaseButton from "@/components/common/buttons/BaseButton.vue"
import ExpenseProcessingAnimation from "@/modules/expenses/components/ExpenseProcessingAnimation.vue"
import { error } from "@/components/common/NotificationPlugin"
import { useExpenseStore } from "@/modules/expenses/store/expenseStore"
import ExpenseFileDropzone from "@/modules/expenses/components/ExpenseFileDropzone.vue"

const props = defineProps({
  bundleId: {
    type: String,
    required: true,
  },
})

const loading = ref<boolean>(false)
const showDialog = ref<boolean>(false)
const fromFilesModel = ref({
  files: [],
})

const expenseStore = useExpenseStore()
const emit = defineEmits(['save', 'close'])

const { t } = useI18n()
async function onSubmit() {
  try {
    loading.value = true
    const data = {
      id: props.bundleId,
      files: fromFilesModel.value.files,
    }
    await expenseStore.addFilesToExpenseBundle(data)
    showDialog.value = false
    fromFilesModel.value.files = []
    emit('save')
  } catch (err: any) {
    if (err?.handled) {
      return
    }
    error(t('Could not add files to expense bundle'))
  } finally {
    loading.value = false
  }
}

watch(() => showDialog.value, (value: boolean) => {
  if (!value) {
    fromFilesModel.value.files = []
  }
})
</script>
