<template>
  <div class="col-span-full flex items-center">
    <label for="file-upload" class="block text-sm font-medium leading-6 text-gray-900">
      <span class="flex flex-col">
        <span class="mb-2">
          <span
            class="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            {{ label }}
          </span>
        </span>
        <span v-if="src">
          <button
            class="btn btn-link -ml-4 -mr-3"
            type="button"
            @click.prevent="clearFile">
            {{ $t('Remove this file') }}
          </button>
          <span>{{ $t('or upload a new one to replace it') }}</span>
        </span>
        <img v-if="src" :src="src" class="h-[100px] w-auto" aria-hidden="true">
      </span>
    </label>
    <input
      ref="input"
      id="file-upload"
      name="file-upload"
      type="file"
      :accept="accept"
      class="w-0 opacity-0"
      @change="onFileChange"
    >
  </div>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue'
import { useI18n } from "vue-i18n"
import axios from "axios"
import { fileToDataURL } from "@/modules/documents/utils/documentUtils"

const props = defineProps({
  modelValue: {
    type: [String],
  },
  accept: {
    type: String,
    default: 'image/*',
  },
  label: {
    type: String,
  },
  automaticUpload: {
    type: Boolean,
    default: true,
  },
  type: {
    type: String,
    default: 'generic',
  },
})

const emit = defineEmits(['update:modelValue'])

const { t } = useI18n()

const src = ref(props.modelValue)
const input = ref()
function clearFile() {
  src.value = ''
  emit('update:modelValue', '')
  input.value.value = null
}

async function onFileChange(event: Event) {
  const target = event.target as HTMLInputElement
  const file = target.files?.[0]
  if (!file) {
    return
  }
  src.value = await fileToDataURL(file)
  if (props.automaticUpload) {
    const data = new FormData()
    data.append('file', file)
    data.append('type', props.type)
    const response = await axios.post(`/restify/settings/actions?action=set-logo`, data)
    emit('update:modelValue', response.data)
  } else {
    emit('update:modelValue', file)
  }
}

watch(() => props.modelValue, (value: string) => {
  if (value !== src.value) {
    src.value = value
  }
})
</script>
