<template>
  <BaseDataTable
    url="/restify/client-contacts"
    :url-params="{ client_id: clientId, perPage: 100 }"
    :columns="columns"
    :hide-pagination="true"
    :hide-search="true"
    :hide-refresh="true"
    :dialog-form="ContactForm"
    :actions="actions"
    dom-layout="autoHeight"
  >
  </BaseDataTable>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from "vue-i18n"
import BaseDataTable from "@/components/table/BaseDataTable.vue"
import ContactForm from "@/modules/clients/components/ContactForm.vue";

const props = defineProps({
  clientId: {
    type: String,
  },
  data: {
    type: Object,
    default: () => ({}),
  },
  readOnly: {
    type: Boolean,
    default: false,
  },
})

const { t } = useI18n()
const columns = computed(() => {
  return [
    {
      headerName: t('Name'),
      field: 'attributes.name',
      minWidth: 160,
    },
    {
      headerName: t('Email'),
      field: 'attributes.email',
      minWidth: 250,
      maxWidth: 350,
    },
    {
      headerName: t('Phone'),
      field: 'attributes.phone',
      minWidth: 120,
    },
    {
      headerName: t('Position'),
      field: 'attributes.position',
      minWidth: 120,
    },
  ]
})

const actions = computed(() => {
  if (props.readOnly) {
    return
  }
  return 'add,edit,delete'
})
</script>

