<template>
  <TimelineEvents
    :data="myActivity"
    :loading="myActivityLoading"
    :icon="ClockIcon"
    :title="$t('My activity')"
    @load-more="dashboardStore.getMyActivity()"
  />
</template>
<script lang="ts" setup>
import { useDashboardStore } from "@/modules/dashboard/store/dashboardStore";
import { onMounted } from "vue";
import { storeToRefs } from "pinia";
import { ClockIcon } from "@heroicons/vue/24/outline";
import TimelineEvents from "@/modules/dashboard/components/TimelineEvents.vue";

const dashboardStore = useDashboardStore()
const { myActivity, myActivityLoading } = storeToRefs(dashboardStore)
onMounted(async () => {
  await dashboardStore.getMyActivity(true)
})
</script>
