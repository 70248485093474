<template>
  <BaseFormDialog
    v-bind="$attrs"
    :title="$t('New Invoice')"
    :submit-text="submitText"
    @submit="onSubmit"
  >
    <FormKit
      v-model="model.client_id"
      :label="t('Client')"
      validation="required"
      type="clientSelect"
      outer-class="col-span-6"
    />

    <FormKit
      v-model="model.invoice_type"
      :label="t('Invoice Type')"
      :options="invoiceTypeOptions"
      validation="required"
      type="radio"
      outer-class="col-span-6 mt-4"
    />
  </BaseFormDialog>
</template>

<script lang="ts" setup>
import { computed, ref } from "vue"
import { useI18n } from "vue-i18n"
import { useRouter } from "vue-router"
import BaseFormDialog from "@/components/form/BaseFormDialog.vue"

const props = defineProps({
  clientId: {
    type: String,
  },
})
const { t } = useI18n()

const InvoiceTypes = {
  FreeForm: "free_form",
  TimeBased: "time_based",
}

const invoiceTypeOptions = computed(() => {
  return [
    {
      value: InvoiceTypes.TimeBased,
      label: t("Create an invoice based on tracked time"),
    },
    {
      value: InvoiceTypes.FreeForm,
      label: t("Create a free form invoice"),
    },
  ]
})

const model = ref({
  client_id: props.clientId || null,
  invoice_type: InvoiceTypes.TimeBased,
})
const submitText = computed(() => {
  if (model.value.invoice_type === InvoiceTypes.FreeForm) {
    return t("Next Step")
  }
  return t('Choose Projects')
})

const router = useRouter()

function onSubmit() {
  const query = {
    client_id: model.value.client_id,
  }
  if (model.value.invoice_type === InvoiceTypes.FreeForm) {
    router.push({
      path: '/invoices/create',
      query,
    })
    return
  }
  router.push({
    path: '/invoices/tracked-time',
    query,
  })
}
</script>
