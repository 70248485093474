<template>
  <div class="flex items-center space-x-2">
    <BaseTooltip effect="light">
      <div class="bg-gray-100 border border-gray-200 rounded px-2 p-[7px]">
        <PencilLineIcon class="w-4 h-4 text-gray-600" />
      </div>
      <template #content>
        <div class="font-semibold p-2 max-w-[320px]">
          {{ $t('The table bellow supports excel like inline editing. Double click or press enter on cells to start editing.') }}
        </div>
      </template>
    </BaseTooltip>
    <BaseTooltip effect="light">
      <div class="bg-gray-100 border border-gray-200 rounded px-2 py-[7px]">
        <KeyboardIcon class="w-4 h-4 text-gray-600" />
      </div>
      <template #content>
        <div class="px-2 pt-2 pb-4 min-w-[320px] max-h-[300px] overflow-y-auto">
          <div class="font-semibold mb-3 text-sm">{{ $t('Useful keyboard shortcuts') }}</div>
          <div class="space-y-1">
            <div
              v-for="shortcut in shortcuts" :key="shortcut.key"
              class="text-sm flex">
              <div class="flex space-x-1 text-sm w-[120px]">
                <kbd class="font-sans border border-gray-100 rounded px-1 py-0.5 text-xs text-gray-700">{{ shortcut.key }}</kbd>
              </div>
              <span class="text-sm text-gray-700">{{ shortcut.description }}</span>
            </div>
          </div>
        </div>
      </template>
    </BaseTooltip>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { KeyboardIcon, PencilLineIcon } from "lucide-vue-next"
import BaseTooltip from "@/components/common/BaseTooltip.vue"

const { t } = useI18n()
const shortcuts = computed(() => {
  return [
    {
      key: 'Enter',
      description: t('Start/stop editing cell'),
    },
    {
      key: 'Double click',
      description: t('Start editing cell'),
    },
    {
      key: 'Tab',
      description: t('Move to next cell'),
    },
    {
      key: 'Shift + Tab',
      description: t('Move to previous cell'),
    },
    {
      key: 'Esc',
      description: t('Cancel editing'),
    },
    {
      key: 'Control + C',
      description: t('Copy cell value'),
    },
    {
      key: 'Control + V',
      description: t('Paste cell value'),
    },
    {
      key: 'Shift + Arrow Key',
      description: t('Extend selection in the direction of the arrow key'),
    },
  ]
})
</script>

<style>
</style>
