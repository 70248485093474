<template>
  <div class="flex flex-col w-full space-y-6">
    <div
      v-for="employee in data"
      :key="employee.id"
      class="grid grid-cols-3 gap-x-1 w-full items-center"
    >
      <div class="truncate">
        <EmployeeAvatar
          v-if="employee.id"
          :data="employeeStore.getEmployeeById(employee.id)"
        />
      </div>
      <div class="text-sm">
        {{ formatDate(employee.attributes.start_date) }}
      </div>
      <div class="flex justify-end">
        <router-link
          :to="`/employees/${employee.id}/details`"
          class="bg-primary-light flex items-center text-primary rounded no-underline border border-primary px-5 py-2"
        >
          <EyeIcon class="w-4 h-4 mr-1" />
          <span class="text-sm font-medium">{{ $t('View Profile') }}</span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { PropType } from "vue"
import { EyeIcon } from "@heroicons/vue/24/outline"
import { formatDate } from "../../../common/utils/dateUtils"
import EmployeeAvatar from "@/modules/employees/components/EmployeeAvatar.vue"
import { useEmployeeStore } from "@/modules/employees/store/employeeStore"
import Data = API.Data
const props = defineProps({
  data: {
    type: Array as PropType<Data<Employee>[]>,
    default: () => [],
  },
})
import Employee = App.Domains.Employees.Models.Employee
const employeeStore = useEmployeeStore()
</script>
