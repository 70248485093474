<template>
  <el-breadcrumb
    v-if="breadcrumbs.length > 1"
    separator="/"
    class="text-sm text-base-300 page-breadcrumbs"
  >
    <el-breadcrumb-item
      v-for="(item, index) in breadcrumbs"
      :key="item.name"
      :to="{ path: item.path }"
    >
      <span
        :class="{
          'font-semibold': index === breadcrumbs.length - 1,
          'font-normal hover:underline': index !== breadcrumbs.length - 1,
        }"
        class="text-base-300">
        {{ $t(item.name as string) || $t('Home') }}
      </span>
    </el-breadcrumb-item>
  </el-breadcrumb>
</template>
<script setup lang="ts">
import { RouteLocationMatched, RouteRecordName, useRoute } from "vue-router";
import { computed } from "vue";
import { uniqBy } from "lodash-es";

const route = useRoute()

type Breadcrumb = {
  name?: RouteRecordName | string | null
  path: string;
}
const breadcrumbs = computed<Breadcrumb[]>(() => {
  let routes = route.matched.filter(r => r.name)
  let routeParams = route.params
  routes = uniqBy(routes, 'path')

  const breadcrumbs: Breadcrumb[] = []
  routes.forEach((r: RouteLocationMatched) => {
    let path = r.path
    // Skip routes where name is not set explcitly
    if (r.name?.toString().includes('-')) {
      return
    }

    // Replace dynamic segments with actual values
    Object.keys(routeParams).forEach((param) => {
      path = path.replace(`:${param}`, routeParams[param])
    })

    const name = sanitizeRouteName(r.name as string)

    breadcrumbs.push({
      name,
      path: path,
    })
  })
  return breadcrumbs
})

function sanitizeRouteName(name: string): string {
  const wordsToExclude = ['Employee', 'Holiday', 'Account', 'Policy', 'Evaluation']

  const nameParts = name?.split(' ') || []
  if (nameParts.length > 1 && wordsToExclude.includes(nameParts[0])) {
    nameParts.shift()
    name = nameParts.join(' ')
  }
  return name?.toString()
}
</script>
<style>
.page-breadcrumbs .el-breadcrumb__inner {
  @apply text-base-300;
}
</style>
