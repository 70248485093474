import { subMonths } from "date-fns"
import startOfMonth from "date-fns/startOfMonth"
import { capitalize } from 'lodash'
import i18n from '@/i18n'
import { formatDate } from '@/modules/common/utils/dateUtils'
import { IntervalTypes } from '@/modules/reports/store/reportsStore'

export const reportIntervalTypes = [
  {
    label: i18n.t('Weekly'),
    value: IntervalTypes.Week,
  },
  {
    label: i18n.t('Monthly'),
    value: IntervalTypes.Month,
  },
  {
    label: i18n.t('Quarterly'),
    value: IntervalTypes.Quarter,
  },
  {
    label: i18n.t('Yearly'),
    value: IntervalTypes.Year,
  },
  {
    label: i18n.t('All Time'),
    value: IntervalTypes.AllTime,
  },
  {
    label: i18n.t('Custom'),
    value: IntervalTypes.Custom,
  },
]

export const invoiceIntervalTypes = () => {
  const now = new Date()
  const monthStart = startOfMonth(now)
  const lastMonth = subMonths(monthStart, 1)
  const thisMonthName = capitalize(formatDate(now, 'MMMM'))
  const lastMonthName = capitalize(formatDate(lastMonth, 'MMMM'))

  return [
    {
      label: `${i18n.t('This Month ')} (${thisMonthName})`,
      value: IntervalTypes.Month,
    },
    {
      label: `${i18n.t('Last Month')} (${lastMonthName})`,
      value: IntervalTypes.LastMonth,
    },
    {
      label: i18n.t('This Quarter'),
      value: IntervalTypes.Quarter,
    },
    {
      label: i18n.t('Last Quarter'),
      value: IntervalTypes.LastQuarter,
    },
    {
      label: i18n.t('This Year'),
      value: IntervalTypes.Year,
    },
    {
      label: i18n.t('Custom'),
      value: IntervalTypes.Custom,
    },
  ]
}

const reportColors = [
  '#15803d',
  '#0d9488',
  '#0284c7',
  '#6d28d9',
  '#9333ea',
  '#f87171',
  '#c2410c',
  '#d97706',
  '#fb7185',
  '#d1d5db',
]

export const primaryColor = '#0d9488'
export const dangerColor = '#c2410c'

export function getTaskColor(index: number) {
  if (index >= reportColors.length) {
    return reportColors[reportColors.length - 1]
  }
  return reportColors[index]
}
