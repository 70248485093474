<template>
  <FileDropzone
    v-bind="$attrs"
    :accept="acceptedFileTypes"
    :multiple="true"
    :multiple-limit="fileLimit"
    :multiple-limit-message="$t('Expense file limit', { count: fileLimit })"
    class="mb-9 mt-2 col-span-6"
  />
</template>

<script lang="ts" setup>
import FileDropzone from "@/modules/documents/components/FileDropzone.vue"
const acceptedFileTypes = `.pdf,.docx,.doc,.txt,.csv,.rtf,.jpg,.jpeg,.png,.svg`

const fileLimit = 10
</script>

<style>
</style>
