<template>
  <BaseContentCard v-if="hasAbout">
    <div v-if="employee.about">
      <IconBox class="!w-8 !h-8 mb-4">
        <ChatBubbleLeftEllipsisIcon class="detail-icon"/>
      </IconBox>
      <p class="text-sm break-words">
        {{ employee.about || '- -' }}
      </p>
    </div>
    <div v-if="employee.quote" class="mt-10">
      <IconBox class="!w-8 !h-8 mb-4">
        <HashtagIcon class="detail-icon"/>
      </IconBox>
      <p class="text-sm break-words">
        <i>" </i>{{ employee.quote || '- -' }}<i>"</i>
      </p>
    </div>
  </BaseContentCard>
</template>
<script lang="ts" setup>
import { computed, PropType } from "vue";
import {
  ChatBubbleLeftEllipsisIcon,
  HashtagIcon,
} from "@heroicons/vue/24/outline";

const props = defineProps({
  employee: {
    type: Object as PropType<any>,
    required: true,
    default: () => ({})
  },
})

const hasAbout = computed(() => {
  return props.employee.about || props.employee.quote
})
</script>
<style scoped>
.detail-icon {
  @apply w-4 h-4 min-w-[16px] text-primary;
}
</style>
