<template>
  <div class="min-h-full flex flex-col">
    <div class="flex items-end w-full justify-between">
      <PageTitle show-back>
        {{ $t('Holiday policies') }}
      </PageTitle>
      <AddButton class="mb-8" @click="onAddPolicy">
        {{ $t('Add policy') }}
      </AddButton>
    </div>
    <BaseContentCard>
      <div class="flex space-x-2 items-end">
        <Rest class="h-6 text-primary" />
        <h5 class="leading-none">
          {{ HolidayTypeLabels[HolidayTypes.TimeOff] }}
        </h5>
      </div>
      <p class="text-base-300 mt-3 text-sm">
        {{ HolidayTypeDescriptions[HolidayTypes.TimeOff] }}
      </p>
      <h5 class="mt-6 mb-4">
        {{ $t('Policies') }}
      </h5>
      <template v-if="holidayPoliciesLoading">
        <PolicyItemsLoading :count="1" />
      </template>
      <div v-else class="flex flex-col space-y-3">
        <PolicyItem
          v-for="policy in groupedPolicies[HolidayTypes.TimeOff]"
          :key="policy.id"
          :policy="policy"
        />
      </div>
    </BaseContentCard>

    <BaseContentCard class="mt-4">
      <div class="flex space-x-2 items-end">
        <Other class="h-6 text-primary" />
        <h5 class="leading-none">
          {{ HolidayTypeLabels[HolidayTypes.Other] }}
        </h5>
      </div>
      <p class="text-base-300 mt-3 text-sm">
        {{ HolidayTypeDescriptions[HolidayTypes.Other] }}
      </p>
      <h5 class="mt-6 mb-4">
        {{ $t('Policies') }}
      </h5>
      <template v-if="holidayPoliciesLoading">
        <PolicyItemsLoading :count="7" />
      </template>
      <div v-else class="flex flex-col space-y-3">
        <PolicyItem
          v-for="policy in groupedPolicies[HolidayTypes.Other]"
          :key="policy.id"
          :policy="policy"
        />
      </div>
    </BaseContentCard>

    <BaseContentCard class="my-4">
      <div class="flex w-full justify-between">
        <div class="flex space-x-2 items-end">
          <National class="h-6 text-primary" />
          <h5 class="leading-none">
            {{ HolidayTypeLabels[HolidayTypes.National] }}
          </h5>
        </div>
        <div>
          <EditButton size="xs" outline @click="goToEdit"/>
        </div>
      </div>
      <p class="text-base-300 mt-3 text-sm">
        {{ HolidayTypeDescriptions[HolidayTypes.National] }}
      </p>
      <ElCollapse class="national-holidays-collapse mt-6">
        <ElCollapseItem :title="$t('Public holidays in Romania')">
          <template #title>
            <ElBadge :value="getLegalHolidaysByYear(selectedYear)?.length" type="primary">
              <h5 class="text-base">
                {{ $t('National holidays') }}
              </h5>
            </ElBadge>
          </template>
          <NationalHolidayCalendars @year-change="selectedYear = $event" />
        </ElCollapseItem>
      </ElCollapse>
    </BaseContentCard>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia"
import { computed, onMounted, ref } from "vue"
import { groupBy } from "lodash-es"
import { useRouter } from "vue-router"
import { useSettingsStore } from "@/modules/settings/store/settingsStore"
import Rest from '@/assets/icons/rest.svg'
import Other from '@/assets/icons/other.svg'
import National from '@/assets/icons/national.svg'
import BaseContentCard from "@/components/common/BaseContentCard.vue"
import { HolidayTypeDescriptions, HolidayTypeLabels, HolidayTypes } from "@/modules/settings/types/settingTypes"
import PolicyItem from "@/modules/settings/components/holidays/PolicyItem.vue"
import PolicyItemsLoading from "@/modules/settings/components/holidays/PolicyItemsLoading.vue"
import NationalHolidayCalendars from "@/modules/settings/components/holidays/NationalHolidayCalendars.vue"
import EditButton from "@/components/common/buttons/EditButton.vue"

const settingsStore = useSettingsStore()
const { holidayPolicies, holidayPoliciesLoading, getLegalHolidaysByYear } = storeToRefs(settingsStore)

const selectedYear = ref(new Date().getFullYear())
const groupedPolicies = computed(() => {
  return groupBy(holidayPolicies.value, 'attributes.type')
})
const router = useRouter()
async function onAddPolicy() {
  await router.push(`/settings/holidays/create`)
}

function goToEdit() {
  router.push(`/settings/holidays/national`)
}
onMounted(async () => {
  await settingsStore.initHolidays()
})
</script>

<style>
.national-holidays-collapse {
  --el-collapse-header-height: 20px;
  --el-collapse-border-color: transparent;
}
</style>

<route lang="yaml">
name: Holiday Policies
meta:
  permission: manageHolidayPolicies
</route>
