<template>
  <div class="flex flex-col">
    <template v-if="!isDetailsPage && !isCreatePage">
      <PageTitle
        :show-bread-crumbs="false" :show-back="false"
      >
        {{ t(route.name as string) }}
      </PageTitle>
      <TabLinks v-model="activeTab" :tabs="tabs" />
    </template>
    <router-view class="flex-1" />
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { InboxIcon, UserIcon } from '@heroicons/vue/24/outline'
import { can } from '@/plugins/permissionPlugin'
import TabLinks from '@/components/tabs/TabLinks.vue'

const { t } = useI18n()

const activeTab = ref('/projects/projects')
const route = useRoute()

const isDetailsPage = computed(() => route.params.id)
const isCreatePage = computed(() => route.path.includes('create'))

const tabs = computed(() => {
  const tabs: any[] = [
    {
      label: t('Projects'),
      testName: 'projects_tab',
      icon: InboxIcon,
      path: '/projects/projects',
      show: can('manageProjects'),
    },
    {
      label: t('Clients'),
      testName: 'clients_tab',
      icon: UserIcon,
      path: '/projects/clients',
      show: can('manageClients'),
    },
  ]
  return tabs.filter(tab => tab.show !== false)
})
</script>

<route lang="yaml">
name: Projects & Clients
redirect: /projects/projects
</route>
