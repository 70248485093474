<template>
  <FilterDate
    v-bind="$attrs"
    :shortcuts="shortcuts"
  />
</template>

<script lang="ts" setup>
import { endOfMonth, startOfMonth, startOfWeek, subYears } from "date-fns"
import { useI18n } from "vue-i18n"
import FilterDate from "@/components/table/filters/base/FilterDate.vue"

const { t } = useI18n()
const shortcuts = [
  {
    text: t('Expired'),
    value: () => {
      const end = new Date()
      const tenYearsAgo = subYears(end, 10)
      return [tenYearsAgo, end]
    },
  },
  {
    text: t('Expiring this week'),
    value: () => {
      const end = new Date()
      const weekStart = startOfWeek(end)
      return [weekStart, end]
    },
  },
  {
    text: t('Expiring this month'),
    value: () => {
      const now = new Date()
      const monthStart = startOfMonth(now)
      const monthEnd = endOfMonth(now)
      return [monthStart, monthEnd]
    },
  },
]
</script>

<style lang="scss">
.expiration-date-filter {
  .el-picker-panel [slot=sidebar], .el-picker-panel__sidebar {
    width: 200px;
  }
}
</style>
