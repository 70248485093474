<template>
  <div class="mb-4">
    <BaseContentCard class="h-full flex flex-col">
      <div class="flex w-full justify-between pr-10 mb-4">
        <FileInfo :data="currentDocument" :loading="currentDocumentLoading"/>
        <div>
          <button
            class="btn btn-square btn-primary h-9 w-9 btn-outline btn-sm bg-primary/10 border-primary/10"
            type="button"
            @click="documentsStore.downloadCurrentFile"
          >
            <FolderArrowDownIcon class="w-4 h-4"/>
          </button>
        </div>
      </div>
      <FilePreviewDetails class="max-h-[calc(100% - 50px)]" />
    </BaseContentCard>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia";
import { FolderArrowDownIcon } from '@heroicons/vue/24/outline'
import FilePreviewDetails from "@/modules/documents/components/FilePreviewDetails.vue";
import FileInfo from "@/modules/documents/components/FileInfo.vue";
import { useDocumentsStore } from "@/modules/documents/store/documentsStore";
const documentsStore = useDocumentsStore()
const { currentDocument, currentDocumentLoading } = storeToRefs(documentsStore)
</script>
<route lang="yaml">
name: Document Details 
</route>
