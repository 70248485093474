<template>
  <time>
    {{ formattedDate }}
  </time>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { formatDate } from "@/modules/common/utils/dateUtils";
import { useI18n } from "vue-i18n";

const props = defineProps({
  params: {
    type: Object,
    default: () => ({})
  }
})
const { t } = useI18n()
const formattedDate = computed(() => {
  if (!props.params.value) {
    return t('No date')
  }
  try {
    const dateForm = props.params.format
    const date = new Date(props.params.value)
    return formatDate(date, dateForm)
  } catch (err) {
    return '- -'
  }
})
</script>
