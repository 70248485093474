import axios from 'axios'

const cache: any = {}
const limit = 50 // max number of objects to store in the cache
const history: any = []
const defaultTTL = 2 * 60 * 1000 // 2 minutes

export default {
  set(key: string, value: any, ttl = defaultTTL) {
    cache[key] = {
      value,
      timestamp: new Date().getTime(),
      ttl,
    }
    history.push(key)
    if (history.length > limit) {
      this.removeOldKeys()
    }
  },
  get(key: string) {
    if (!cache[key]) {
      return null
    }
    let { value, timestamp, ttl } = cache[key]
    ttl = ttl || defaultTTL
    const now = new Date().getTime()
    if (timestamp + ttl < now) {
      // the cache expired
      this.removeKey(key)
      return null
    }
    return value
  },
  removeKey(key: string) {
    const historyIndex = history.indexOf(key)
    if (historyIndex !== -1) {
      history.splice(historyIndex, 1)
    }
    delete cache[key]
  },
  async getRequest(url: string, config: any = {}) {
    const configCopy = {
      ...config,
    }
    delete configCopy.invalidateCache
    const key = JSON.stringify({ url, config: configCopy })

    if (config.invalidateCache) {
      this.removeKey(key)
    }
    if (this.get(key)) {
      return this.get(key)
    }
    const response = await axios.get(url, config)
    this.set(key, response, config.ttl)
    return response
  },
  removeOldKeys() {
    const keysToRemove = history.length - limit
    for (let i = 0; i < keysToRemove; i++) {
      const key = history[0]
      delete cache[key]
      history.shift()
    }
  },
  removeForEntity(entity: string) {
    for (const key in cache) {
      if (key.includes(entity)) {
        this.removeKey(key)
      }
    }
  },
}
