<template>
  <ReportData :report-type="ReportTypes.Tasks" />
</template>

<script setup lang="ts">
import { useRoute } from 'vue-router'
import { storeToRefs } from 'pinia'
import { ReportTypes, useReportsStore } from '@/modules/reports/store/reportsStore'
import ReportData from '@/modules/reports/components/ReportData.vue'

const reportsStore = useReportsStore()
const { currentReport } = storeToRefs(reportsStore)
const route = useRoute()
currentReport.value.employee_id = route.params.id as string
</script>

<route lang="yaml">
name: Team tasks report
</route>
