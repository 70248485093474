<template>
  <BaseFormDialog
    class="import-template-form"
    :loading="loading"
    :submit-text="$t('Import')"
    v-bind="$attrs"
    @submit="onSubmit"
  >
    <FormKit
      v-model="model.share_token"
      :label="t('Share Identifier')"
      :placeholder="t('Share Identifier')"
      :help="t('Enter the share identifier of the template you want to import.')"
      validation="required"
      outer-class="col-span-6"
    />
  </BaseFormDialog>
</template>

<script lang="ts" setup>
import { onMounted, ref } from "vue"
import { useI18n } from "vue-i18n"
import { useRoute, useRouter } from "vue-router"
import { success } from "@/components/common/NotificationPlugin"
import { useTemplateStore } from "@/modules/templates/store/templateStore"
import { useFormInit } from "@/modules/common/composables/useFormInit"
const emit = defineEmits(['save'])
const { t } = useI18n()
const templateStore = useTemplateStore()

const model = ref({
  share_token: '',
})

const loading = ref(false)

async function onSubmit() {
  try {
    const template = await templateStore.getSharedTemplate(model.value.share_token as string)
    if (template) {
      emit('save', model.value.share_token)
    }
  } catch (err: any) {
    if (err.handled) {
      return
    }
  } finally {
    loading.value = false
  }
}
</script>
