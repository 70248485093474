<template>
  <fieldset>
    <RadioGroup
      v-model="selectedOption"
      class="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4"
      @update:model-value="onOptionUpdate"
    >
      <RadioGroupOption v-for="option in options" :key="option.title" v-slot="{ active, checked }" as="template"
                        :value="option" :aria-label="option.title" :aria-description="`${option.description}`">
        <div class="relative flex cursor-pointer rounded-lg border p-4 shadow-sm focus:outline-none"
             :class="[active ? 'border-primary bg-primary/5' : 'border-gray-300 bg-white']">
          <span class="flex flex-1 space-x-3">
            <IconBox>
              <component :is="option.icon" class="w-4 h-4"/>
            </IconBox>
            <span class="flex flex-col">
              <span class="block text-sm font-medium text-gray-900">{{ option.title }}</span>
              <span class="mt-1 flex items-center text-sm text-gray-500">{{ option.description }}</span>
            </span>
          </span>
          <CheckCircleIcon class="w-5 h-5 text-primary" :class="[!checked ? 'invisible' : '']" aria-hidden="true"/>
          <span class="pointer-events-none absolute -inset-px rounded-lg"
                :class="[active ? 'border' : 'border', checked ? 'border-primary' : 'border-transparent']"
                aria-hidden="true"/>
        </div>
      </RadioGroupOption>
    </RadioGroup>
  </fieldset>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { RadioGroup, RadioGroupOption } from '@headlessui/vue'
import { CheckCircleIcon } from '@heroicons/vue/20/solid'
import { useI18n } from "vue-i18n"
import { CloudArrowUpIcon, PlusCircleIcon } from "@heroicons/vue/24/outline"
import { CreateMode } from "@/modules/expenses/store/expenseStore"
const { t } = useI18n()

const props = defineProps({
  modelValue: Object,
})

const options = [
  {
    title: t('Manually'),
    value: CreateMode.Manual,
    icon: PlusCircleIcon,
    description: t('Define the expenses manually'),
  },
  {
    title: t('Upload Invoices'),
    value: CreateMode.Import,
    icon: CloudArrowUpIcon,
    description: t('Upload invoices which will be automatically parsed'),
  },
]

const selectedOption = ref(props.modelValue)

const emit = defineEmits(['update:modelValue'])
function onOptionUpdate(value: string) {
  emit('update:modelValue', value)
}
</script>
