<template>
  <div class="flex flex-col">
    <PageTitle :show-back="false" :show-bread-crumbs="false">
      {{ $t($route.name) }}
    </PageTitle>
    <TabLinks v-if="tabs.length > 1" v-model="activeTab" :tabs="tabs" class="mb-4" />
    <FeedbackTimeline
      :title="$t('Feedback Activity')"
      :feedbackable-id="userEmployeeId"
      :feedbackable-type="FeedbackType.Employee"
      :show-create="false"
    />
  </div>
</template>

<script setup lang="ts">
import Feedback from './index.vue'
import { useAuth } from "@/modules/auth/composables/useAuth"
import { useFilterTabs } from "@/modules/common/composables/useFilterTabs";
import PageTitle from "@/components/common/PageTitle.vue";
import FeedbackTable from "@/modules/feedback/components/FeedbackTable.vue";
import FeedbackTimeline from "@/modules/feedback/components/FeedbackTimeline.vue";
import { FeedbackType } from "@/modules/feedback/store/feedbackStore";
const { userEmployeeId } = useAuth()

const { activeTab, tabs } = useFilterTabs('feedback')
</script>

<route lang="yaml">
name: My Feedback
</route>
