<template>
  <div class="grid grid-cols-3 gap-x-1 w-full items-center">
    <div>
      <EvaluationTypeLink
        :params="{
          value: props.data?.attributes?.type_id,
          link: evaluationLink,
        }"
      />
    </div>
    <div v-if="props.employeeId">
      <EmployeeAvatar
        v-if="props.employeeId"
        :data="employeeStore.getEmployeeById(props.employeeId)"
      />
    </div>

    <div
      v-if="assignees.length > 0"
      :title="$t('Assignees')"
      class="truncate flex justify-end"
    >
      <EmployeeTagsCell
        :params="{ value: assignees }"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { PropType, computed } from "vue"
import EmployeeAvatar from "@/modules/employees/components/EmployeeAvatar.vue"
import { useEmployeeStore } from "@/modules/employees/store/employeeStore"
import EvaluationTypeLink from "@/components/table/cells/EvaluationTypeLink.vue"
import EmployeeTagsCell from "@/components/table/cells/EmployeeTagsCell.vue"
import BaseTooltip from "@/components/common/BaseTooltip.vue"
import Holiday = App.Domains.Holidays.Models.Holiday
import Data = API.Data
const props = defineProps({
  data: {
    type: Object as PropType<Data<Holiday>>,
  },
  employeeId: {
    type: String,
  },
})
const assignees = computed(() => {
  return props.data?.attributes?.assignees || []
})

const evaluationLink = computed(() => {
  return `/evaluations/${props.data?.id}/view?employee_id=${props.employeeId}`
})

const employeeStore = useEmployeeStore()
</script>
