<template>
  <div class="h-full">
    <div
      :class="{
        'print:hidden': !isFirstPage,
        'print:visible': isFirstPage,
      }"
    >
      <PageTitle :show-back="true" :show-bread-crumbs="false">
        <template #content>
          <div class="flex w-full flex-wrap gap-2 items-center">
            <h2>{{ t('Detailed Report:') }}</h2>
            <div class="text-xl md:text-2xl">
              {{ formatDate(currentReport.from) }} - {{ formatDate(currentReport.to) }}
            </div>
            <div class="flex flex-1 justify-end gap-2">
              <button
                type="button"
                class="btn btn-primary btn-outline btn-xs print:hidden"
                :disabled="downloadLoading"
                @click="downloadReport()"
              >
                {{ t('Export PDF') }}
              </button>
              <button
                type="button"
                class="btn btn-primary btn-outline btn-xs print:hidden"
                @click="showFilters = !showFilters"
              >
                {{ t('Show filters') }}
              </button>
            </div>
          </div>
        </template>
      </PageTitle>
      <DetailedReportFilters
        v-show="showFilters"
        class="mt-4"
        @cancel="showFilters = false"
        @submit="onFiltersChange"
      />
      <div class="w-full flex justify-between pt-4 my-4 print:my-2 border-t border-gray-200">
        <div class="flex flex-col w-full h-full justify-center">
          <div v-for="option in filterLabels" :key="option.value" class="flex space-x-2">
            <span class="text-sm text-gray-600 min-w-[120px]">{{ option.label }}</span>
            <span class="font-semibold">{{ option.value }}</span>
          </div>
        </div>
        <div class="flex flex-col w-full items-end h-full justify-center pr-4 pt-4">
          <span class="text-base text-gray-600">{{ t('Total hours') }}</span>
          <span v-if="loading" class="h-7 bg-gray-100 animate-pulse w-16 rounded" />
          <span v-else class="text-xl font-semibold">{{ currentReportTotal }}</span>
        </div>
      </div>
    </div>
    <div class="overflow-auto">
      <table class="min-w-full divide-y divide-gray-300 invoice-line-items">
        <thead>
          <tr>
            <th scope="col" class="table-col text-left">
              {{ t('Project') }}
            </th>
            <th scope="col" class="table-col text-left">
              {{ t('Task') }}
            </th>
            <th scope="col" class="table-col text-left">
              {{ t('Person') }}
            </th>
            <th
              v-if="showCreatedAt"
              scope="col"
              class="table-col text-left w-[120px]"
            >
              {{ t('Created At') }}
            </th>
            <th scope="col" class="table-col text-right w-[80px]">
              {{ t('Hours') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="loading">
            <td :colspan="colspan">
              <div class="flex items-center justify-center w-full min-h-[200px]">
                <LoadingTable />
              </div>
            </td>
          </tr>
          <tr v-if="!loading && tableData.length === 0">
            <td :colspan="colspan">
              <div class="flex items-center justify-center w-full min-h-[200px]">
                {{ t('No data') }}
              </div>
            </td>
          </tr>
          <template v-if="!loading">
            <template
              v-for="(row, index) in tableData"
              :key="row.id"
            >
              <tr
                :data-index="index"
                class="print:break-inside-avoid"
              >
                <template v-if="row.isFullWidth">
                  <td :colspan="colspan - 1" class="table-cell-sm bg-gray-100">
                    {{ formatDate(row.date) }}
                  </td>
                  <td colspan="1" class="table-cell-sm text-right bg-gray-100">
                    {{ minutesToHoursFixed(row.worked_minutes) }}
                  </td>
                </template>
                <template v-else>
                  <td class="table-cell-sm">
                    {{ row?.relationships.project.attributes.name }}
                  </td>
                  <td class="table-cell-sm">
                    {{ row?.relationships.task.attributes.name }}
                  </td>
                  <td class="table-cell-sm">
                    <EmployeeLink
                      :is-for-public-report="isForPublicReport"
                      :show-link="showLinks"
                      :params="{ data: row }"
                    />
                  </td>
                  <td
                    v-if="showCreatedAt"
                    class="table-cell-sm"
                  >
                    {{ formatDate(row.attributes.created_at, DATE_TIME_FORMAT) }}
                  </td>
                  <td class="table-cell-sm w-[70px] text-right">
                    {{ minutesToHoursFixed(row.attributes.worked_minutes) }}
                  </td>
                </template>
              </tr>
              <tr v-if="row?.attributes?.description && !row.isFullWidth">
                <td />
                <td colspan="3" class="table-cell-sm text-gray-500">
                  <div
                    class="text-xs text-gray-500 mt-0.5 whitespace-pre-wrap max-w-[60vw]"
                    v-html="row?.attributes.description"
                  />
                </td>
              </tr>
            </template>
          </template>
          <tr
            :class="{
              'print:hidden': !isLastPage,
              'print:visible': isLastPage,
            }"
          >
            <td colspan="2" />
            <td class="table-cell-total font-semibold text-sm text-right">
              {{ t('Total hours') }}
            </td>
            <td class="table-cell-total font-semibold text-sm text-right">
              {{ currentReportTotal }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="flex justify-center mt-4 print:hidden">
      <ElPagination
        :current-page="tableMeta.current_page"
        background
        layout="prev, pager, next"
        :page-size="tableMeta.per_page"
        :total="tableMeta.total"
        @update:current-page="getData"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { groupBy, sumBy } from 'lodash-es'
import axios from 'axios'
import { storeToRefs } from 'pinia'
import { useRoute } from 'vue-router'
import { parseISO } from "date-fns"
import { DATE_TIME_FORMAT, formatDate } from '@/modules/common/utils/dateUtils'
import { minutesToHoursFixed } from '@/modules/common/utils/parseHours'
import EmployeeLink from '@/components/table/cells/EmployeeLink.vue'
import LoadingTable from '@/components/table/LoadingTable.vue'
import { useReportsStore } from '@/modules/reports/store/reportsStore'
import DetailedReportFilters from '@/modules/reports/components/DetailedReportFilters.vue'
import { useClientStore } from '@/modules/clients/store/clientStore'
import { useProjectStore } from '@/modules/projects/store/projectStore'
import { getEmployeeName, useEmployeeStore } from '@/modules/employees/store/employeeStore'
const props = defineProps({
  showLinks: {
    type: Boolean,
    default: true,
  },
  isForPublicReport: {
    type: Boolean,
    default: false,
  },
})
import DataList = API.DataList
import Timesheet = App.Domains.TimeSheets.Models.Timesheet
import { success } from "@/components/common/NotificationPlugin"
import i18n from "@/i18n"
import { useAuth } from "@/modules/auth/composables/useAuth"
import Employee = App.Domains.Employees.Models.Employee
import { useSettingsStore } from "@/modules/settings/store/settingsStore"

const { t } = useI18n()
const route = useRoute()
const urlParams = computed(() => {
  const query = route.query
  const { start_date, end_date } = route.query
  let date = ''
  if (start_date && end_date) {
    date = `${start_date},${end_date}`
  }
  return {
    sort: 'date',
    related: 'employee[id|first_name|last_name|avatar],project[id|name],task[id|name]',
    date,
    ...(query || {}),
  }
})

const reportStore = useReportsStore()
const settingsStore = useSettingsStore()
const { currentReport } = storeToRefs(reportStore)
const currentReportTotal = ref(0)

const showCreatedAt = computed(() => {
  return settingsStore.showTimesheetDates || route.query.show_timesheet_dates === 'true'
})
const colspan = computed(() => {
  return showCreatedAt.value ? 5 : 4
})

function setDates() {
  const { start_date, end_date } = route.query
  if (start_date) {
    currentReport.value.from = parseISO(start_date as string)
  }
  if (end_date) {
    currentReport.value.to = parseISO(end_date as string)
  }
}

function transformData(response: DataList<Timesheet>) {
  const data = response.data
  currentReportTotal.value = response.meta?.total_worked_hours || 0
  const dateGroups = groupBy(data, 'attributes.date')
  const rows = []
  for (const date in dateGroups) {
    const groupRows = dateGroups[date]
    rows.push({
      isFullWidth: true,
      date,
      worked_minutes: sumBy(groupRows, 'attributes.worked_minutes'),
      id: crypto.randomUUID(),
    })
    rows.push(...groupRows)
  }
  return rows
}

const showFilters = ref(false)
const loading = ref(false)
const tableData = ref<any[]>([])
const tableMeta = ref({
  per_page: 100,
  total: 1,
  current_page: 1,
  last_page: 1,
})

const isLastPage = computed(() => {
  return tableMeta.value.current_page === tableMeta.value.last_page
})

const isFirstPage = computed(() => {
  return tableMeta.value.current_page === 1
})

const clientStore = useClientStore()
const projectStore = useProjectStore()
const employeeStore = useEmployeeStore()

const projectEmployees = computed(() => {
  return projectStore.getProjectEmployees(currentReport.value.project_id)
})

const { isEmployee, userEmployeeId, user } = useAuth()

const employeeLabel = computed(() => {
  const { employee_id } = currentReport.value
  if (isEmployee.value) {
    return getEmployeeName(user.value.employee as any)
  }
  const employeeName = employeeStore.getEmployeeNamesFromIds([employee_id as string], projectEmployees.value)
  return employeeName || t('All team members')
})

const filterLabels = computed(() => {
  const { client_id, project_id, task_id } = currentReport.value
  const client = clientStore.getClientById(client_id)
  const project = projectStore.getProjectById(project_id)
  const task = projectStore.getTaskById(project_id, task_id)
  const clientLabel = client?.attributes?.company_name || t('All clients')
  const projectLabel = project?.attributes?.name || t('All projects')
  const taskLabel = task?.attributes?.name || t('All tasks')

  return [
    {
      label: t('Client'),
      value: clientLabel,
      key: 'client',
    },
    {
      label: t('Project'),
      value: projectLabel,
      key: 'project',
    },
    {
      label: t('Task'),
      value: taskLabel,
      key: 'task',
    },
    {
      label: t('Employee'),
      value: employeeLabel.value,
      key: 'employee',
    },
  ]
})

const downloadLoading = ref(false)

async function downloadReport() {
  try {
    downloadLoading.value = true
    const url = new URL(window.location.href)
    const token = localStorage.getItem('token') as string
    url.pathname = '/share/reports/detailed'
    url.searchParams.append('token', token)
    if (settingsStore.showTimesheetDates) {
      url.searchParams.append('show_timesheet_dates', 'true')
    }
    url.toString()

    await reportStore.downloadReportAsPdf(url.toString(), filterLabels.value)
    success(i18n.t(`We're exporting. You'll receive an email shortly.`))
  } catch (e) {
    console.warn(e)
  } finally {
    downloadLoading.value = false
  }
}
async function getData(page?: number) {
  try {
    loading.value = true
    const response: any = await axios.get('/restify/timesheets', {
      params: {
        perPage: tableMeta.value.per_page,
        page: page || tableMeta.value.current_page,
        ...urlParams.value,
      },
    })
    tableMeta.value = response.meta
    tableData.value = transformData(response)
  } finally {
    loading.value = false
  }
}

async function onFiltersChange() {
  showFilters.value = false
  await getData()
}

onMounted(async () => {
  setDates()
  await getData()
})
</script>

<route lang="yaml">
name: Detailed Report
</route>
