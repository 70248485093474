<template>
  <div class="h-full w-full overflow-visible">
    <FileInfo :data="row" />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue"
import FileInfo from "@/modules/documents/components/FileInfo.vue"

const props = defineProps({
  params: {
    type: Object,
    default: () => ({}),
  },
})

const row = computed(() => {
  return props.params?.data || {}
})
</script>
