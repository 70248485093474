<template>
  <component
    :is="eventPath ? 'router-link' : 'div'"
    :to="eventPath"
    :class="{
      'cursor-pointer': eventPath,
      'cursor-default': !eventPath,
    }"
    class="calendar-event px-1 md:px-2 py-2 flex space-x-1 md:space-x-2 w-full min-h-[40px] no-underline"
  >
    <div v-if="eventIcon" class="flex items-center">
      <component
        :is="eventIcon"
        :style="{ color: event?.ui?.borderColor }"
        class="w-4 h-4"
      />
    </div>
    <div :title="event?.title" class="calendar-event-title text-base-content flex items-center flex-wrap truncate">
      <span v-if="timeText" class="mr-1">{{ timeText }}</span>
      <span class="truncate text-xs event-title">{{ event?.title }}</span>
    </div>
    <div
      v-if="employee?.id"
      :class="{
        'absolute right-1 bottom-1': event?.allDay === false,
      }"
      class="text-base-content flex-1 flex justify-end"
    >
      <EmployeeAvatar
        v-if="employee?.id"
        :data="employee"
        :show-name="false"
      />
    </div>
  </component>
</template>

<script setup lang="ts">
import { computed } from "vue"
import { useRoute } from 'vue-router'
import { CakeIcon, MegaphoneIcon, NewspaperIcon, PresentationChartLineIcon, UserIcon } from "@heroicons/vue/24/outline"
import EmployeeAvatar from "@/modules/employees/components/EmployeeAvatar.vue"
import { useEmployeeStore } from "@/modules/employees/store/employeeStore"
import { TimelineTypes } from "@/modules/dashboard/store/dashboardStore"
import Rest from "@/assets/icons/rest.svg"
import National from "@/assets/icons/national.svg"
import { useAuth } from "@/modules/auth/composables/useAuth"
import { can } from "@/plugins/permissionPlugin"

const props = defineProps({
  data: {
    type: Object,
    default: () => ({}),
  },
})

const event = computed(() => props.data?.event?._def)
const timeText = computed(() => props.data?.timeText)
const employeeStore = useEmployeeStore()

const eventType = computed(() => {
  return event.value?.extendedProps?.type
})

const employeeId = computed(() => {
  return event.value?.extendedProps?.employee_id
})

const evenSourceId = computed(() => {
  return event.value?.extendedProps?.event_id
})

const employee = computed(() => {
  const { employee_id, employee } = event.value?.extendedProps || {}
  if (!employee_id) {
    const employeeObject = employee || {}
    return {
      id: employeeObject.id,
      attributes: employeeObject,
    }
  }
  return employeeStore.getEmployeeById(employee_id)
})

const route = useRoute()
const isSharePage = computed(() => {
  return route.path.includes('share')
})
const { userEmployeeId } = useAuth()

const eventPath = computed(() => {
  if (isSharePage.value) {
    return
  }
  const pathMap = {
    [TimelineTypes.Birthday]: `/employees/${employeeId.value}/details`,
    [TimelineTypes.Evaluation]: '/evaluations',
    [TimelineTypes.Holiday]: `/holidays/${evenSourceId.value}/view`,
    [TimelineTypes.LegalHoliday]: '/account/holidays/national',
  }
  const isSameEmployee = userEmployeeId.value !== employeeId.value
  const isHolidayType = eventType.value === TimelineTypes.Holiday
  const canManageHolidays = can('manageHolidays')

  if (!canManageHolidays && isHolidayType && isSameEmployee) {
    return
  }
  return pathMap[eventType.value]
})

const eventIcon = computed(() => {
  const iconMap = {
    [TimelineTypes.Birthday]: CakeIcon,
    [TimelineTypes.Evaluation]: PresentationChartLineIcon,
    [TimelineTypes.Holiday]: Rest,
    [TimelineTypes.LegalHoliday]: National,
    [TimelineTypes.News]: NewspaperIcon,
    [TimelineTypes.Announcement]: MegaphoneIcon,
    [TimelineTypes.Personal]: UserIcon,
  }
  return iconMap[eventType.value]
})
</script>
