<template>
  <ElDropdown
    :hide-on-click="false"
    ref="dropdown"
    trigger="click"
    placement="bottom-end">
      <span class="el-dropdown-link hover:bg-primary/5">
        <slot name="trigger">
          <EllipsisHorizontalIcon class="w-5 text-primary"/>
        </slot>
      </span>
    <template #dropdown>
      <ElDropdownMenu class="min-w-[150px]">
        <ElDropdownItem
          v-for="action in actions"
          :key="action.label"
          :class="{
              'dropdown-item-loading relative': actionsLoading[action.label]
            }"
          @click.native="triggerAction(action)"
        >
          <div
            :class="{
                [action?.class]: action?.class,
                'opacity-0': actionsLoading[action.label]
              }"
            class="flex space-x-2 items-center">
            <component v-if="action.icon" :is="action.icon" class="w-4 h-4 text-primary"/>
            <span class="text-sm">{{ action.label }}</span>
          </div>
          <div
            v-if="actionsLoading[action.label]"
            v-loading="actionsLoading[action.label] && !actionsFinished[action.label]"
            class="absolute flex justify-center items-center inset-0 w-full h-full action-loading">
            <CheckIcon
              v-if="actionsFinished[action.label]"
              class="w-5 h-5 text-primary transition-opacity duration-100"/>
          </div>
        </ElDropdownItem>
      </ElDropdownMenu>
    </template>
  </ElDropdown>
</template>

<script setup lang="ts">

import { CheckIcon, EllipsisHorizontalIcon } from '@heroicons/vue/24/outline'
import { computed, PropType, ref } from "vue";
import { useRouter } from "vue-router";
import { RowAction } from "@/components/table/tableTypes";

const emit = defineEmits([])

const props = defineProps({
  actions: {
    type: Array as PropType<RowAction[]>,
    default: () => [],
  },
})

const router = useRouter()

type ActionsLoading = {
  [key: string]: boolean
}
const actionsLoading = ref<ActionsLoading>({})
const actionsFinished = ref<ActionsLoading>({})
const dropdown = ref()

async function triggerAction(action: RowAction) {
  try {
    actionsLoading.value[action.label] = true
    if (action.skipActionAwait) {
      dropdown.value?.handleClose()
    }
    await action.action()
    actionsFinished.value[action.label] = true
    setTimeout(() => {
      actionsFinished.value[action.label] = false
      actionsLoading.value[action.label] = false
      dropdown.value?.handleClose()
    }, 1000)
  } catch (err) {
    dropdown.value?.handleClose()
    actionsLoading.value[action.label] = false
  }
}

const actions = computed(() => {
  return props.actions
    .map((action: RowAction) => {
      return {
        ...action,
        action: async () => {
          if (!action.action) {
            return
          }
          await action.action()
        },
        show: () => {
          if (!action.show) {
            return true
          }
          return action.show()
        }
      }
    })
    .filter((action: RowAction) => action.show())
})
</script>
<style>
.dropdown-item-loading {
  --el-mask-color: theme('colors.gray.50');
  --el-loading-spinner-size: 24px;
  @apply bg-gray-50;
}
</style>
