<template>
  <BaseDataTable
    ref="table"
    url="/restify/invoice-reply-mails"
    :columns="columns"
    :hide-pagination="true"
    :hide-search="true"
    :hide-refresh="true"
    :dialog-form="ReplyToEmailForm"
    :row-height="45"
    actions="add,delete"
    dom-layout="autoHeight"
    @after-create="onValidate"
  >
    <template #default="{ row }">
      <div class="flex justify-end">
        <BaseButton v-if="!row.attributes.validated" outline size="xs" @click="onValidate(row)">
          {{ $t('Validate') }}
        </BaseButton>
        <BaseButton
          v-if="row.attributes.validated && !row.attributes.is_default"
          :loading="row.attributes.loading"
          outline
          size="xs"
          @click="makeDefault(row)"
        >
          {{ $t('Make default') }}
        </BaseButton>
        <Status v-if="row.attributes.is_default" :params="{ value: true }">
          {{ $t('Default') }}
        </Status>
      </div>
    </template>
  </BaseDataTable>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { useI18n } from "vue-i18n"
import axios from "axios"
import ReplyToEmailForm from "./ReplyToEmailForm.vue"
import BaseDataTable from "@/components/table/BaseDataTable.vue"
import { $confirm } from "@/components/common/modal/modalPlugin"
import Data = API.Data
import { error, success } from "@/components/common/NotificationPlugin"

const { t } = useI18n()
const columns = computed(() => {
  return [
    {
      headerName: t('Email'),
      field: 'attributes.email',
      minWidth: 160,
    },
    {
      headerName: t(''),
      field: 'default',
      minWidth: 160,
    },
  ]
})

async function onValidate(row: Data<any>) {
  await $confirm({
    title: t('Validate email address'),
    description: t(`Enter the confirmation code sent to {email} to validate the email address.`, {
      email: row.attributes.email,
    }),
    buttonText: t('Confirm'),
    buttonType: 'primary',
    hasInput: true,
    inputType: 'otp',
    inputLabel: t('Enter confirmation code sent via email'),
    closeOnClick: false,
    onResolve: async (data: any) => {
      const code = data.extraInputText
      await axios.post(`/restify/invoice-reply-mails/${row.id}/actions?action=validate`, {
        code,
      })
      await refresh()
    },
  })
}

const table = ref()
async function refresh() {
  table.value?.refresh()
}

async function makeDefault(row: Data<any>) {
  try {
    row.attributes.loading = true
    await axios.post(`/restify/invoice-reply-mails/${row.id}/actions?action=make-default`)
    await refresh()
    success(t('Email address set as default'))
  } catch (err: any) {
    if (!err.handled) {
      error(t('An error occurred while trying to set the email address as default'))
    }
  } finally {
    row.attributes.loading = false
  }
}
</script>

