<template>
  <BaseFormDialog
    class="timesheet-form"
    :loading="loading"
    :submit-text="$t('Save Row')"
    :title="$t('Add row to timesheet')"
    width="50vw"
    v-bind="$attrs"
    @submit="onSubmit"
  >
    <FormKit
      v-model="model.project_id"
      :label="$t('Project')"
      :employee-id="employeeId"
      type="projectSelect"
      validation="required"
      outer-class="col-span-6"
      clearable
      @update:modelValue="onProjectChange"
    />

    <FormKit
      v-model="model.task_id"
      :label="$t('Task')"
      :disabled="!model.project_id"
      :options="projectTasks"
      type="taskSelect"
      validation="required"
      outer-class="col-span-6"
      clearable
    />
  </BaseFormDialog>
</template>

<script lang="ts" setup>
import type { PropType } from 'vue'
import { ref, toRef } from "vue"
import { useStorage } from "@vueuse/core"
import { useTimesheetForm } from "@/modules/time/composables/useTimesheetForm";
import Data = API.Data;
import Timesheet = App.Domains.TimeSheets.Models.Timesheet;
import { useTimeSheetStore } from "@/modules/time/store/timeSheetStore";

const props = defineProps({
  date: {
    type: String,
    required: true,
  },
  employeeId: {
    type: String,
    required: true,
  },
  otherLogs: {
    type: Array as PropType<Data<Timesheet>[]>,
    default: () => [],
  },
})
const loading = ref(false)
const lastSelectedProject = useStorage<any>('lastSelectedProject', null)

const model = ref({
  project_id: lastSelectedProject.value || null,
  task_id: null,
  employee_id: props.employeeId,
  description: '',
  date: props.date,
  worked_minutes: 0,
})

const emit = defineEmits(['save'])

const { onProjectChange, projectTasks } = useTimesheetForm(model, toRef(props.otherLogs))
const timeSheetStore = useTimeSheetStore()

async function onSubmit() {
  const timeSheet = await timeSheetStore.createTimeSheet(model.value)
  emit('save', timeSheet)
}
</script>
