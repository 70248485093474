<template>
  <div
    v-if="entity"
    :class="{ 'print:hidden': files.length === 0 }"
    class="bg-gray-50 border border-gray-200 rounded pt-3 pb-2 px-4 my-4 print:px-2 print:pt-1 print:pb-2"
  >
    <h4 class="font-medium text-base print:text-print mb-1">
      {{ $t(title) }}
    </h4>
    <FileAttachmentsLoading v-if="loadingFiles && !filesToDisplay.length" />
    <div
      v-if="filesToDisplay.length"
      :class="{
        'mb-4 print:mb-2': !showMore && !showLess,
        'mb-2 print:mb-0': showMore || showLess,
      }"
    >
      <div
        v-if="authorizedToAttachFiles"
        class="w-full lg:w-1/2 text-sm print:text-print mb-1">
        <span class="font-medium">{{ $t('Heads up:') }}</span>
        {{
          $t('Users who have access to this page will be able to see and download all attached files.  The max file size is 10 MB.')
        }}
      </div>
      <div
        v-for="file in filesToDisplay"
        :key="file.uuid || file.name"
        class="flex justify-between group py-1.5"
      >
        <PendingFileInfo
          :file="mapFile(file)"
          :file-name="file?.name || file?.attributes?.file_name"
          :progress="fileProgress[file.id]"
          :can-remove="authorizedToAttachFiles"
          @close="removeFile(file)"
        />
      </div>
      <div class="-mb-2">
        <BaseButton
          v-if="showMore"
          variant="link"
          @click="onShowMoreClick"
        >
          {{ $t(`more files`, moreFilesCount) }}
        </BaseButton>

        <BaseButton
          v-if="showLess"
          variant="link"
          @click="onShowLessClick"
        >
          {{ $t(`Show less`) }}
        </BaseButton>
      </div>
    </div>

    <FileUploadButton
      v-if="authorizedToAttachFiles"
      :accept="acceptedFileTypes"
      multiple
      class="print:hidden"
      @input="onChange"
    >
      {{ $t('Attach files') }}
    </FileUploadButton>
  </div>
</template>

<script>
import axios from "axios"
import { orderBy } from "lodash-es"
import FileAttachmentsLoading from "./FileAttachmentsLoading.vue"
import { acceptedFileTypes } from "@/modules/documents/utils/documentUtils"
import FileUploadButton from "@/modules/invoices/components/FileUploadButton.vue"
import { $deleteConfirm } from "@/components/common/modal/modalPlugin"
import PendingFileInfo from "@/modules/documents/components/PendingFileInfo.vue"

export default {
  components: {
    PendingFileInfo,
    FileUploadButton,
    FileAttachmentsLoading,
  },
  props: {
    entity: {
      type: String,
      required: true,
    },
    entityId: {
      type: [String, Number],
    },
    title: {
      type: String,
      default: 'File Attachments',
    },
    authorizedToAttachFiles: {
      type: Boolean,
      default: true,
    },
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      files: [],
      fileProgress: {},
      initialDisplayLimit: 4,
      displayLimit: 4,
      loadingFiles: false,
      showPreviewDialog: false,
      previewData: null,
      acceptedFileTypes,
    }
  },
  computed: {
    filesToDisplay() {
      return this.files.slice(0, this.displayLimit)
    },
    showMore() {
      return this.files.length > this.displayLimit
    },
    showLess() {
      return this.files.length === this.displayLimit && this.files.length > this.initialDisplayLimit
    },
    moreFilesCount() {
      return this.files.length - this.displayLimit
    },
    routeId() {
      return this.entityId || this.$route.params.id
    },
  },
  watch: {
    files(value) {
      this.updateAttachmentsCount(value)
    },
  },
  mounted() {
    this.files = this.data || []
  },
  methods: {
    async onChange(event) {
      event.stopPropagation()
      const files = event.target.files
      const fileArray = Array.from(files)

      fileArray.forEach((file) => {
        file.id = new Date().getTime() + file.name
        this.fileProgress[file.id] = 0
      })
      this.files.push(...fileArray)
      if (!this.routeId) {
        return
      }
      await this.uploadFiles(fileArray)
    },
    async uploadFile(file) {
      const data = new FormData()
      data.append('path', file)
      data.append('fileable_id', this.routeId)
      data.append('fileable_type', this.entity)

      await axios.post(`/restify/files`, data, {
        onUploadProgress: (progressEvent) => {
          this.fileProgress[file.id] = Math.floor((progressEvent.loaded * 100) / progressEvent.total)
        },
      })
    },
    async uploadFiles(files = []) {
      if (!files.length) {
        files = this.files
      }
      for (const file of files) {
        await this.uploadFile(file)
      }
      await this.getFileAttachments()
    },
    async getFileAttachments() {
      if (!this.routeId || !this.entity) {
        return
      }
      try {
        this.loadingFiles = true
        const { data } = await axios.get(`/restify/files`, {
          params: {
            sort: 'created_at',
            fileable_type: this.entity,
            fileable_id: this.routeId,
          },
        })
        this.files = orderBy(data, 'attributes.created_at', 'asc')
      } finally {
        this.loadingFiles = false
      }
    },
    mapFile(file) {
      if (!file.attributes) {
        return file
      }
      if (file.attributes?.downloadable_path) {
        return file.attributes.downloadable_path
      }
      
      return {
        ...(file?.attributes || {}),
        name: file?.attributes?.file_name,
      }
    },
    async removeFile(file) {
      const confirmed = await $deleteConfirm({
        title: this.$t('Remove attachment'),
        description: this.$t('This action will remove the attachment. Are you sure?'),
        buttonText: this.$t('Remove'),
      })
      if (!confirmed) {
        return
      }
      this.files = this.files.filter((f) => {
        if (file.id) {
          return file.id !== f.id
        }
        return file.id !== f.id
      })
      if (file.attributes) {
        await axios.delete(`/restify/files/${file.id}`)
      }
    },
    onShowMoreClick() {
      this.displayLimit = this.files.length
    },
    onShowLessClick() {
      this.displayLimit = this.initialDisplayLimit
    },
    updateAttachmentsCount() {
      this.$emit('update:attachments-count', this.files.length)
    },
  },
}
</script>

<style>
</style>
