<template>
  <BaseContentCard>
    <div class="flex justify-center mb-5">
      <BaseAvatar
        class="w-[120px] h-[120px] rounded-full"
        :src="props.employee.avatar"
        :name="getEmployeeFullName(props.employee)"
      />
    </div>
    <div class="w-full flex flex-col items-center">
      <h3>{{ props.employee.first_name }} {{ props.employee.last_name }}</h3>
      <p class="text-base-300 mb-2 mt-1">
        {{ props.employee?.relationships?.position?.attributes?.name }}
      </p>
      <Status :params="{ value: props.employee.status }" />
    </div>
    <div class="mt-8 text-sm flex flex-col space-y-4">
      <div class="flex space-x-3">
        <CakeIcon class="h-4 w-4 text-primary" />
        <span>{{ $formatDate(props.employee.birth_date, FriendlyDateFormat) }}</span>
      </div>
      <div class="flex space-x-3 items-center">
        <DocumentDuplicateIcon class="h-4 w-4 text-primary" />
        <span>{{ getContractTypeLabel(props.employee.contract_type) || '- -' }}</span>
      </div>
    </div>
    <slot name="actions" />
  </BaseContentCard>
</template>

<script lang="ts" setup>
import { CakeIcon, DocumentDuplicateIcon } from "@heroicons/vue/24/outline"
import { PropType } from "vue"
import { getContractTypeLabel } from "../utils/employeeUtils"
import { FriendlyDateFormat } from "../../common/utils/dateUtils"
const props = defineProps({
  employee: {
    type: Object as PropType<Employee>,
    default: () => ({}),
    required: true,
  },
})

import Employee = App.Domains.Employees.Models.Employee
import { getEmployeeFullName } from "@/modules/clients/utils/employeeUtils";

</script>
