<template>
  <router-view />
</template>

<script setup lang="ts">
import { computed, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { useHead } from '@vueuse/head'
import { useInvoiceStore } from '@/modules/invoices/store/invoiceStore'
import { DEFAULT_APP_TITLE } from '@/modules/common/config'

const route = useRoute()
const id = computed(() => {
  return route.params.id
})
const invoiceStore = useInvoiceStore()
onMounted(async () => {
  await invoiceStore.getSharableInvoice(id.value as string)
})
const { t } = useI18n()
const pageTitle = computed(() => {
  return `${t('Invoice {id}', { id: invoiceStore.currentInvoice?.attributes?.invoice_id })} - ${DEFAULT_APP_TITLE}`
})
useHead({
  title: pageTitle,
})
</script>
<route lang="yaml">
name: Share invoice
meta:
  layout: emptyLayout
</route>
