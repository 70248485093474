<template>
  <div>
    <div v-for="document in documents" :key="document.id">
      <FileInfo :data="document" query-param="file-id" />
    </div>

    <FilePreviewModal
      :file-id="$route.query['file-id'] as string"
    />
  </div>
</template>

<script lang="ts" setup>
import { PropType, ref } from "vue"
import FileInfo from "@/modules/documents/components/FileInfo.vue"
import FilePreviewModal from "@/modules/documents/components/FilePreviewModal.vue"
import Data = API.Data
import Document = App.Domains.Documents.Models.Document

defineProps({
  holiday: {
    type: Object,
    default: () => ({}),
  },
  documents: {
    type: Array as PropType<Data<Document>[]>,
    default: () => [],
  },
})

const selectedId = ref<string>()
</script>
