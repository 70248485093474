// We need this because url search params decodes + as space
export function extractEmailFromString(inputString: string): string {
  const regex = /email=([^&]+)/;
  const match = inputString.match(regex);
  return match ? decodeURIComponent(match[1]) : '';
}

export function isUuid(value: string) {
  return value.length === 26
}

export function getLogoFromUrl(website: string): string {
  if (!website) {
    return ''
  }
  try {
    const url = new URL(website)
    if (!url.host) {
      return ''
    }
    return `https://cdn.brandfetch.io/${url.host}?c=1id966fOtOLk85ap79J`
  } catch (err) {
  }
  return ''
}
