<template>
  <div class="h-full">
    <BaseDataTable
      ref="table"
      url="/restify/templates"
      :columns="columns"
      :actions="actions"
      :row-height="75"
      :extra-actions="rowActions"
      :create-text="$t('Create template')"
      :edit-text="$t('Edit template')"
      :add-text="$t('Add template')"
      :delete-title="$t('Delete template')"
      :delete-description="$t('After deleting, the template will be permanently deleted.')"
      :dialog-form="TemplateForm"
      @edit="onTemplateEdit"
    >
      <template #actions-before>
        <BaseButton
          v-if="$can('manageTemplates')"
          size="sm"
          outline
          variant="primary"
          @click="showImportTemplateDialog = true"
        >
          {{ t('Import Template') }}
        </BaseButton>
      </template>
      <template #attributes.name="{ row }">
        <router-link
          :to="`/settings/templates?id=${row.id}`"
          class="cursor-pointer no-underline text-base-content"
        >
          {{ row.attributes.name }}
        </router-link>
      </template>
    </BaseDataTable>

    <ImportTemplateForm
      v-if="showImportTemplateDialog"
      v-model="showImportTemplateDialog"
      @save="onImportTemplate"
      @cancel="showImportTemplateDialog = false"
    />
    <TemplatePreviewModal
      :id="$route.query.id"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue"
import { ColDef } from "@ag-grid-community/core"
import { useI18n } from "vue-i18n"
import { CloudArrowDownIcon, EyeIcon } from "@heroicons/vue/24/outline"
import { useRoute, useRouter } from "vue-router"
import { capitalize } from "lodash-es"
import i18n from "@/i18n"
import { RowAction } from "@/components/table/tableTypes"
import { can } from "@/plugins/permissionPlugin"
import { useTemplateStore } from "@/modules/templates/store/templateStore"
import TemplateForm from "@/modules/templates/components/TemplateForm.vue"
import TemplatePreviewModal from "@/modules/templates/components/TemplatePreviewModal.vue"
import Data = API.Data
import Document = App.Domains.Documents.Models.Document
import { ColumnTypes } from "@/components/table/cells/tableCellComponents"
import ImportTemplateForm from "@/modules/templates/components/ImportTemplateForm.vue"

const showImportTemplateDialog = ref(false)

const columns = ref<ColDef[]>([
  {
    headerName: i18n.t('Name'),
    field: 'attributes.name',
    minWidth: 250,
  },
  {
    headerName: i18n.t('Used For'),
    field: 'attributes.entities',
    valueFormatter: (params: any) => {
      const entities = params.value || []
      return entities.map((entity: string) => i18n.t(capitalize(entity))).join(', ')
    },
    minWidth: 100,
    maxWidth: 250,
  },
  {
    headerName: i18n.t('Auto Generate'),
    field: 'attributes.autogenerate',
    type: ColumnTypes.Status,
    minWidth: 160,
    maxWidth: 200,
  },
])

const actions = computed(() => {
  if (can('manageTemplates')) {
    return 'add,delete,edit'
  }
  return ''
})
const { t } = useI18n()
const router = useRouter()
const route = useRoute()
const templateStore = useTemplateStore()

const rowActions = computed<RowAction[]>(() => {
  return [
    {
      label: t('Download'),
      icon: CloudArrowDownIcon,
      action: async (template: Data<any>) => {
        await templateStore.downloadTemplate(template)
      },
    },
    {
      label: i18n.t('View'),
      icon: EyeIcon,
      action: async (document: Data<Document>) => {
        await router.push({
          path: route.path,
          query: {
            id: document.id,
          },
        })
      },
    },
  ]
})

function onImportTemplate(token: string) {
  router.push(`/settings/templates?add=true&share_token=${token}`)
  showImportTemplateDialog.value = false
}

function onTemplateEdit(data: Data<any>) {
  templateStore.selectedTemplateEntity = data.attributes.key
}
</script>
