<template>
  <div>
    <BaseDataTable
      url="/restify/feedback"
      :url-params="urlParams"
      :columns="columns"
      :add-text="$t('Add feedback')"
      :row-height="60"
      :extra-actions="rowActions"
      :suppress-cell-focus="true"
      :delete-extra-confirmation="true"
      entity="feedback"
      :actions="actions"
      class="h-full"
      @add="onAdd"
    >
      <template #attributes.type="{ row }">
        <router-link
          :to="`/feedback/${row.id}/view`"
          class="no-underline text-base-content capitalize flex items-center space-x-2"
        >
          {{ row.attributes.type }}
        </router-link>
      </template>
      <template #attributes.feedbackable_id="{ row }">
        <div>
          <FeedbackForRelation :feedback="row" :show-for="true" :show-for-label="false" />
        </div>
      </template>
      <template #attributes.notes="{ row }">
        <div
          v-if="row.attributes.notes"
          class="truncate prose prose-sm max-h-10"
          v-html="row.attributes.notes"
        />
      </template>
    </BaseDataTable>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue"
import { ColDef } from "@ag-grid-community/core"
import { useI18n } from "vue-i18n"
import { useRouter } from "vue-router"
import { ColumnTypes } from "@/components/table/cells/tableCellComponents"
import { can } from "@/plugins/permissionPlugin"
import { RowAction } from "@/components/table/tableTypes"
import { FilterTypes } from "@/components/table/filters/filterTypes"
import { FeedbackType } from "@/modules/feedback/store/feedbackStore"
import FeedbackForRelation from "@/modules/feedback/components/FeedbackForRelation.vue"

const props = defineProps({
  feedbackableId: {
    type: String,
  },
  feedbackableType: {
    type: String,
    default: FeedbackType.Employee,
  },
})

const urlParams = ref({
  related: 'employeeCreator',
  active_employee: true,
  sort: '-given_at',
  feedbackable_id: props.feedbackableId,
  feedbackable_type: props.feedbackableType,
})

const { t } = useI18n()

const columns = computed<ColDef[]>(() => {
  return [
    {
      headerName: t('Type'),
      field: 'attributes.type',
      type: 'custom',
      minWidth: 120,
      maxWidth: 120,
    },
    {
      headerName: t('From'),
      field: 'attributes.created_by_employee_id',
      type: ColumnTypes.EmployeeLink,
      filter: FilterTypes.CreatedByEmployee,
    },
    {
      headerName: t('For'),
      field: 'attributes.feedbackable_id',
      filter: FilterTypes.EmployeeFeedbackbleId,
    },
    {
      headerName: t('Shared'),
      field: 'attributes.is_visible_to_employee',
      type: ColumnTypes.Status,
      minWidth: 160,
      hide: props.feedbackableType !== 'employee',
    },
    {
      headerName: t('Given At'),
      field: 'attributes.given_at',
      type: ColumnTypes.Date,
      minWidth: 140,
    },
  ]
})

const actions = computed(() => {
  const baseActions = []
  baseActions.push('view')
  if (can('manageFeedback')) {
    baseActions.push('add')
    baseActions.push('edit')
    baseActions.push('delete')
  }
  return baseActions.join(',')
})

const rowActions = computed<RowAction[]>(() => [])

const router = useRouter()

async function onAdd() {
  await router.push({
    path: '/feedback/create',
    query: {
      feedbackable_id: props.feedbackableId,
      feedbackable_type: props.feedbackableType,
    },
  })
}
</script>
