import { defineStore } from 'pinia'
import axios from "axios"
import i18n from "@/i18n"
import Cache from '@/modules/common/Cache'
import { formatDate } from "@/modules/common/utils/dateUtils"
import Data = API.Data

export const FeedbackType = {
  Employee: 'employee',
  Client: 'client',
}

export const useFeedbackStore = defineStore('feedback', {
  state: () => {
    return {
      currentFeedback: {} as Data<any>,
      currentFeedbackLoading: false as boolean,
      currentFeedbackError: null as any,
    }
  },
  actions: {
    async getFeedback(id: string) {
      if (!id) {
        return
      }
      try {
        this.currentFeedbackLoading = true
        const { data } = await Cache.getRequest(`/restify/feedback/${id}`, {
          params: {
            related: 'employeeCreator',
            temporary_urls: true,
          },
        })
        this.currentFeedback = data
        this.currentFeedbackError = null
      } catch (err: any) {
        this.currentFeedbackError = err
      } finally {
        this.currentFeedbackLoading = false
      }
    },

    async createFeedback(data: FormData) {
      return await axios.post(`/restify/feedback`, data)
    },

    async updateFeedback(id: string, data: FormData) {
      await axios.post(`/restify/feedback/${id}`, data)
      return await this.getFeedback(id)
    },

    async deleteFeedback(id: string) {
      await axios.delete(`/restify/feedback/${id}`)
    },
  },
  getters: {
    feedbackName(state): string {
      const { given_at, type } = state.currentFeedback?.attributes || {}
      return i18n.t('Feedback title', {
        type,
        date: formatDate(given_at),
      })
    },
  },
})
