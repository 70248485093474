<template>
  <BaseFormDialog
    v-bind="$attrs"
    :title="$t('Send invoice')"
    :submit-text="$t('Send')"
    :loading="invoiceStore.sendLoading"
    @submit="onSubmit"
  >
    <FormKit
      v-model="model.reply_to_email"
      type="replyToSelect"
      outer-class="col-span-6"
      :label="$t('Send as')"
    />
    <FormKit
      v-model="model.recipients"
      type="clientContactSelect"
      outer-class="col-span-6"
      value-key="attributes.email"
      :label="$t('Recipients')"
      :client-id="invoice?.attributes?.client_id"
      validation="required"
    />

    <FormKit
      :model-value="defaultSubject"
      type="text"
      outer-class="col-span-6"
      :label="$t('Message subject')"
      validation="required"
      @update:model-value="model.subject = $event"
    />

    <FormKit
      :model-value="defaultBody"
      type="textarea"
      outer-class="col-span-6"
      :rows="12"
      :label="$t('Message body')"
      validation="required"
      @update:model-value="model.body = $event"
    />

    <FormKit
      v-model="model.attach_pdf"
      :label="$t('Include a PDF version of the invoice')"
      outer-class="col-span-6 mt-4"
      type="checkbox"
    />
  </BaseFormDialog>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import BaseFormDialog from "@/components/form/BaseFormDialog.vue"
import { useInvoiceStore } from '@/modules/invoices/store/invoiceStore'
import { error, success } from "@/components/common/NotificationPlugin"
import { useSettingsStore } from "@/modules/settings/store/settingsStore"
import { SettingKeys } from "@/modules/auth/types/enums"
import { formatDate } from "@/modules/common/utils/dateUtils"
import { useClientStore } from "@/modules/clients/store/clientStore"
import { formatPrice } from "@/plugins/formatPrice"

const props = defineProps({
  invoiceId: {
    type: String,
    default: '',
  },
})

const emit = defineEmits(['submit'])

const invoiceStore = useInvoiceStore()
const settingsStore = useSettingsStore()
const clientStore = useClientStore()

const { t } = useI18n()

const invoice = computed(() => {
  return invoiceStore.currentInvoice
})

const model = ref({
  recipients: [],
  subject: '',
  reply_to_email: '',
  body: '',
  attach_pdf: true,
})

const defaultSubject = computed(() => {
  return t('Invoice #{number} from {company}', {
    number: invoice.value?.attributes?.invoice_number,
    company: settingsStore.getSetting(SettingKeys.CompanyName),
  })
})

const defaultBody = computed(() => {
  const { invoice_number, total, due_date, issue_date, client_id, currency } = invoice.value?.attributes || {}
  return t('Invoice body', {
    invoice_number,
    due_date: formatDate(due_date),
    issue_date: formatDate(issue_date),
    client_name: invoice.value?.relationships?.client?.attributes?.company_name,
    amount: formatPrice(total, { currency }),
  })
})

function prepareModel() {
  if (!model.value.subject) {
    model.value.subject = defaultSubject.value
  }
  if (!model.value.body) {
    model.value.body = defaultBody.value
  }
}

async function onSubmit() {
  try {
    prepareModel()
    await invoiceStore.sendInvoice(model.value, props.invoiceId)
    emit('submit')
    const recipients = model.value.recipients.map(r => r).join(', ')
    success(t('Invoice sent to {recipients}', { recipients }))
  } catch (err) {
    if (!err.handled) {
      error(t('Failed to send invoice'))
    }
  }
}
</script>

