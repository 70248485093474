<template>
  <div class="flex flex-col">
    <div
      v-if="currentFeedbackError || currentFeedbackLoading"
      class="flex-1 flex justify-center items-center h-[50vh]"
    >
      <LoadingTable v-if="currentFeedbackLoading"/>
      <div v-else class="flex flex-col items-center">
        <IconBox class="mb-4" color="error">
          <ExclamationTriangleIcon class="w-8 h-8"/>
        </IconBox>
        <h2>{{ $t('An error occurred while trying to access the feedback information') }}</h2>
      </div>
    </div>
    <RouterView v-else class="flex-1"/>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, watch } from "vue"
import { storeToRefs } from "pinia"
import { ExclamationTriangleIcon } from "@heroicons/vue/24/outline"
import { useHead } from "@vueuse/head"
import { useRoute } from "vue-router"
import { useI18n } from "vue-i18n"
import { useFeedbackStore } from "@/modules/feedback/store/feedbackStore";

const { t } = useI18n()

const feedbackStore = useFeedbackStore()
const { currentFeedbackLoading, currentFeedbackError } = storeToRefs(feedbackStore)
const route = useRoute()
const id = computed(() => route.params.id as string)

const { feedbackName } = storeToRefs(feedbackStore)

useHead({
  title: feedbackName,
})
watch(() => id.value, async (value: string) => {
  await feedbackStore.getFeedback(value)
})
onMounted(async () => {
  await feedbackStore.getFeedback(id.value)
})
</script>

<route lang="yaml">
name: Feedback
redirect:
  name: Feedback Details
</route>
