<template>
  <FilterInput
    v-bind="$attrs"
    :data="statuses"
    value-key="value"
    label-key="label"
  />
</template>

<script lang="ts" setup>
import { ref } from "vue"
import { useI18n } from "vue-i18n"
import FilterInput from "@/components/table/filters/base/FilterInput.vue"

const { t } = useI18n()

const statuses = ref([
  {
    value: 'approved',
    label: t('Approved'),
  },
  {
    value: 'draft',
    label: t('Pending'),
  },
  {
    value: 'cancelled',
    label: t('Cancelled'),
  },
  {
    value: 'rejected',
    label: t('Rejected'),
  },
])
</script>
