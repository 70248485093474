<template>
  <BaseTooltip :content="tooltipContent">
    <div v-bind="$attrs" class="flex space-x-2 items-center overflow-visible">
      <CheckCircleIcon v-if="statusName === ExpenseStatus.Paid" class="w-3 h-3 text-primary -mr-1 -ml-[1px]" />
      <span v-else :class="statusClass" class="w-2 h-2 rounded-full" />
      <span v-if="showName" class="capitalize text-sm">
        {{ $t(statusName) }}
      </span>
    </div>
  </BaseTooltip>
</template>

<script setup lang="ts">
import { computed } from "vue"
import { useI18n } from "vue-i18n"
import { CheckCircleIcon } from "@heroicons/vue/24/solid"
import { ExpenseStatus } from "@/modules/auth/types/enums"
import BaseTooltip from "@/components/common/BaseTooltip.vue"

const props = defineProps({
  params: {
    type: Object,
    default: () => ({}),
  },
  showName: {
    type: Boolean,
    default: true,
  },
  useValue: {
    type: Boolean,
    default: false,
  },
})

interface StatusMapping {
  [key: string]: string
}
const { t } = useI18n()

const statusMappings: StatusMapping = {
  [ExpenseStatus.Rejected]: 'bg-red-500',
  [ExpenseStatus.Approved]: 'bg-green-500',
  [ExpenseStatus.Paid]: 'bg-primary',
  [ExpenseStatus.Canceled]: 'bg-red-500',
  [ExpenseStatus.Pending]: 'bg-yellow-500',
}

const statusName = computed(() => {
  if (props.useValue) {
    const value = props.params?.value
    const mapping: Record<string, string> = {
      rejected: ExpenseStatus.Rejected,
      approved: ExpenseStatus.Approved,
      paid: ExpenseStatus.Paid,
      cancelled: ExpenseStatus.Canceled,
      pending: ExpenseStatus.Pending,
    }
    return mapping[value] || ExpenseStatus.Pending
  }
  const { approved_at, rejected_at, cancelled_at, paid_at } = props.params?.data?.attributes || {}
  if (cancelled_at) {
    return ExpenseStatus.Canceled
  }
  if (paid_at) {
    return ExpenseStatus.Paid
  }
  if (approved_at) {
    return ExpenseStatus.Approved
  }
  if (rejected_at) {
    return ExpenseStatus.Rejected
  }
  return ExpenseStatus.Pending
})

const tooltipContent = computed(() => {
  const status_explanation = props.params?.data?.attributes?.status_explanation || ''
  if (status_explanation) {
    return `${t(statusName.value)}: ${status_explanation}`
  }
  return `${t(statusName.value)}`
})

const statusClass = computed<string>(() => {
  return statusMappings[statusName.value] || ''
})
</script>
