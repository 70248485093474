import { Editor } from "@tiptap/core";

export function prepareEditor(editor: Editor) {
  return editor.chain().focus()
}

export function onInsertBulletList(editor: Editor) {
  prepareEditor(editor).toggleBulletList().run()
}

export function onInsertTable(editor: Editor) {
  prepareEditor(editor).insertTable({ rows: 3, cols: 3, withHeaderRow: false }).run()
}

export function onInsertTaskList(editor: Editor) {
  prepareEditor(editor).toggleTaskList().run()
}
