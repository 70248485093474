<template>
  <BaseSelect
    v-bind="allAttrs"
    :model-value="value"
    :options="options"
    :label-formatter="labelFormatter"
    :data-loading="allEmployeesLoading"
    value-key="id"
    filterable
    popper-class="employee-select"
    @update:model-value="handleChange"
    @update:full-value="fullValue = $event"
  >
    <template #prefix >
      <EmployeeAvatar
        v-if="fullValue && !Array.isArray(fullValue) && !allAttrs.multiple"
        :data="fullValue"
        :show-name="false"
        :show-position="false"
      >
      </EmployeeAvatar>
    </template>
    <template #default="{ option }">
      <div class="flex flex-col">
        <EmployeeAvatar
          :data="option"
          :show-position="true"
        />
        <div v-if="allAttrs['show-email']" class="text-gray-400 -mt-1 text-sm ml-8 truncate">
          {{ option.attributes.email }}
        </div>
      </div>
    </template>
  </BaseSelect>
</template>

<script setup lang="ts">
import { computed, ref } from "vue"
import { storeToRefs } from "pinia"
import { useFormKitInput } from "@/components/formkit/useFormkitInput"
import EmployeeAvatar from "@/modules/employees/components/EmployeeAvatar.vue"
import { useEmployeeStore } from "@/modules/employees/store/employeeStore"
import Employee = App.Models.Employee
import Data = API.Data

const props = defineProps({
  context: Object,
})
const employeeStore = useEmployeeStore()
const { allEmployees, allEmployeesLoading } = storeToRefs(employeeStore)
const { value, allAttrs, handleChange } = useFormKitInput(props)

const fullValue = ref()

const options = computed(() => {
  const options = allAttrs.value.options
  if (options) {
    return options
  }
  return allEmployees.value
})
function labelFormatter(row: Data<Employee>) {
  const { first_name, last_name } = row.attributes
  return `${first_name} ${last_name}`
}
</script>

<style>
.employee-select .el-select-dropdown__item {
  height: 44px;
  @apply flex items-center;
}
</style>
