<template>
  <div>
    <BaseFormCard
      :disabled="loading || currentDocumentLoading"
      :submit-text="isEdit ? $t('Update') : $t('Upload')"
      :is-dialog="isDialog"
      v-bind="$attrs"
      @submit="onSubmit"
      @close="onCancel"
    >
      <FormCardSection
        :is-dialog="isDialog"
      >
        <FileDropzone
          v-model="model.files"
          :file-progress="fileProgress"
          :file-name="currentDocument?.attributes?.file_name"
          :accept="acceptedFileTypes"
          class="mb-9 mt-2 col-span-6"
          @update:modelValue="onFilesChange"
        />

        <FormKit
          v-model="model.name"
          :label="$t('Document name')"
          :placeholder="$t('Software agreement contract')"
          validation="required"
          type="text"
          outer-class="col-span-6 md:col-span-3"
        />
        <FormKit
          v-model="model.type_id"
          :label="$t('Document type')"
          :placeholder="$t('Document type')"
          type="documentTypeSelect"
          validation="required"
          outer-class="col-span-6 md:col-span-3"
        />

        <FormKit
          v-model="model.start_date"
          type="customDatePicker"
          :label="$t('Start Date')"
          :placeholder="$t('Start Date')"
          value-format=""
          outer-class="col-span-6 md:col-span-3"
        />

        <FormKit
          v-model="model.end_date"
          type="customDatePicker"
          :label="$t('Expiration Date')"
          :placeholder="$t('Expiration Date')"
          outer-class="col-span-6 md:col-span-3"
        />

        <FormKit
          v-model="model.tag_ids"
          :label="$t('Tags')"
          :placeholder="$t('Select tags...')"
          type="documentTagSelect"
          outer-class="col-span-6"
        />

        <FormKit
          v-if="!props.employeeId && $can('manageDocuments')"
          v-model="model.employee_id"
          :label="$t('Employee')"
          :placeholder="$t('John Doe')"
          type="employeeSelect"
          validation="required"
          outer-class="col-span-6"
        />
      </FormCardSection>
    </BaseFormCard>
  </div>
</template>

<script lang="ts" setup>
import axios from "axios"
import { ref, watch } from "vue"
import { useI18n } from "vue-i18n"
import { storeToRefs } from "pinia"
import { useRouter } from "vue-router"
import FileDropzone from '@/modules/documents/components/FileDropzone.vue'
import { error, success } from "@/components/common/NotificationPlugin"
import { FileProgress } from "@/modules/documents/types/documentTypes"
import {
  acceptedFileTypes,
  getFileExtension,
  getFileWithoutExtension,
  updateFileProgress,
} from "@/modules/documents/utils/documentUtils"
import { useAuth } from "@/modules/auth/composables/useAuth"
import Cache from "@/modules/common/Cache"
import { useDocumentsStore } from "@/modules/documents/store/documentsStore"

const props = defineProps({
  employeeId: {
    type: String,
  },
  isEdit: {
    type: Boolean,
    default: false,
  },
  isDialog: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['close', 'save'])

const { t } = useI18n()

const { userEmployeeId } = useAuth()
const documentsStore = useDocumentsStore()
const { currentDocument, currentDocumentLoading } = storeToRefs(documentsStore)
const router = useRouter()

const model = ref({
  id: null,
  name: '',
  html: '',
  type_id: '',
  employee_id: props.employeeId || userEmployeeId.value || '',
  start_date: '',
  end_date: '',
  files: [] as any[],
  tag_ids: [] as string[],
})

watch(() => currentDocument.value, (value) => {
  if (!props.isEdit) {
    return
  }
  const { name, type_id, employee_id, path, start_date, end_date } = value?.attributes || {}
  const tag_ids = value?.relationships?.tags?.map(t => t.id) || []

  model.value = {
    id: value.id,
    name,
    start_date,
    end_date,
    tag_ids: tag_ids || [],
    employee_id: employee_id?.toString(),
    type_id: type_id?.toString(),
    files: [path],
  }
}, { immediate: true })

const loading = ref(false)
const fileProgress = ref<FileProgress>({})

function prepareFormData(): FormData {
  const formData = new FormData()
  const firstFile = model.value.files[0] as File
  let { id, start_date, end_date, name, type_id, employee_id, tag_ids } = model.value

  if (id) {
    formData.append('id', id)
  }
  formData.append('name', name)
  formData.append('type_id', type_id)
  formData.append('employee_id', employee_id)

  if (tag_ids?.length) {
    tag_ids.forEach((tagId: string) => {
      formData.append('tag_ids[]', tagId)
    })
  }

  if (start_date && typeof start_date === 'object') {
    start_date = start_date.toISOString()
  }
  if (end_date && typeof end_date === 'object') {
    end_date = end_date.toISOString()
  }
  if (start_date) {
    formData.append('start_date', start_date)
  }
  if (end_date) {
    formData.append('end_date', end_date)
  }

  if (typeof firstFile !== 'object') {
    return formData
  }

  updateFileProgress({ file: firstFile, progress: 0, fileProgress })
  const extension = getFileExtension(firstFile?.name)
  const fileName = `${model.value.name}.${extension}`
  formData.append('path', firstFile, fileName)

  return formData
}

function onFilesChange(files: File[]) {
  const firstFile = files[0] as File
  if (firstFile) {
    model.value.name = getFileWithoutExtension(firstFile.name)
  } else {
    model.value.name = ''
  }
}

async function onSubmit() {
  try {
    if (model.value.files?.length === 0) {
      error(t('Please select at least one file'))
    }
    loading.value = true
    const formData = prepareFormData()
    const firstFile = model.value.files[0] as File

    const url = props.isEdit ? `/restify/documents/${model.value.id}` : '/restify/documents'
    await axios.post(url, formData, {
      onUploadProgress: ({ progress }: any) => {
        updateFileProgress({ file: firstFile, progress: progress * 100, fileProgress })
      },
    })
    updateFileProgress({ file: firstFile, progress: 100, fileProgress })
    if (props.isEdit) {
      success(t('Document updated successfully'))
    } else {
      success(t('Document uploaded successfully'))
    }
    emit('save')
  } catch (err: any) {
    if (err.handled) {
      return
    }
  } finally {
    loading.value = false
  }
}

async function onCancel() {
  emit('close')
}
</script>
