<template>
  <FeedbackForm />
</template>

<script setup lang="ts">
import { useHead } from "@vueuse/head"
import { useI18n } from "vue-i18n"
import FeedbackForm from "@/modules/feedback/components/FeedbackForm.vue";
const { t } = useI18n()

useHead({
  title: t('Add feedback'),
})
</script>

<route lang="yaml">
name: Add feedback
</route>
