<template>
  <div class="flex items-center space-x-2 text-sm">
    <HolidayPolicyIcon :policy-id="policyId" class="w-4 h-4"/>
    <span v-if="holidayPolicy?.attributes?.name">{{ holidayPolicy?.attributes?.name }}</span>
  </div>
</template>
<script setup lang="ts">
import { computed } from "vue";
import { useSettingsStore } from "@/modules/settings/store/settingsStore";
import HolidayPolicyIcon from "@/modules/settings/components/holidays/HolidayPolicyIcon.vue";

const props = defineProps({
  policyId: {
    type: String,
    default: '',
  }
})
const settingsStore = useSettingsStore()

const holidayPolicy = computed(() => {
  return settingsStore.getPolicyById(props.policyId)
})
</script>
