<template>
  <BaseFormDialog
    v-bind="$attrs"
  >
    <Vue3Lottie
      v-if="showAnimation"
      background="transparent"
      :animation-link="lottieAnimationLink"
      :loop="false"
      :speed="2"
      style="position: absolute; z-index: 100;"
      @on-complete="showAnimation = false"
    />
    <div class="col-span-6">
      <div class="prose prose-lg !max-w-none">
        <h2 class="text-center">
          {{ $t('Welcome to Growee!') }} 🎉
        </h2>
        <p>{{ $t(`We're thrilled to have you on board. To help you get started, here are a few resources that might come in handy:`) }}</p>
        <ul>
          <li><strong>📺 <a href="https://www.youtube.com/playlist?list=PLLpfa51rGFjzsaLNoIzkDEI6WZsV9ec5E" target="_blank" rel="noopener">{{ $t('Video Tutorials') }}</a></strong>: {{ $t('Dive into videos that guide you through all the key features and best practices.') }}</li>
          <li><strong>📚 <a href="https://docs.growee.net" target="_blank" rel="noopener">{{ $t('Read the Documentation') }}</a></strong>: {{ $t('Find detailed instructions, tips, and everything you need to know to make the most of Growee.') }}</li>
          <li><strong>📰 <a :href="blogLink" target="_blank" rel="noopener">{{ $t('Check out our Blog') }}</a></strong>: {{ $t('Stay updated with the latest news, insights, and tips from our team.') }}</li>
        </ul>
        <p>{{ $t(`Feel free to explore, and don't hesitate to reach out if you have any questions. We're here to help!`) }}</p>
      </div>
    </div>
    <div class="col-span-6 mt-4">
      <iframe width="100%" height="450" src="https://www.youtube.com/embed/?listType=playlist&list=PLLpfa51rGFjzsaLNoIzkDEI6WZsV9ec5E" frameborder="0" allowfullscreen />
    </div>
    <template #footer>
      <span />
    </template>
  </BaseFormDialog>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { useI18n } from "vue-i18n"
import { Vue3Lottie } from 'vue3-lottie'
import BaseFormDialog from "@/components/form/BaseFormDialog.vue"
const { locale } = useI18n()

const showAnimation = ref(true)
const lottieAnimationLink = `https://lottie.host/f0be69e7-d025-45f1-a30e-88845a728c02/J1zvVy1zPM.json`

const blogLink = computed(() => {
  return locale.value === 'en' ? 'https://growee.net/blog' : 'https://growee.ro/blog'
})
</script>
