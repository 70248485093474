<template>
  <div class="weekly-view overflow-x-auto max-w-[90vw] md:max-w-[80vw]">
    <div class="relative flex border-b border-gray-300">
      <div class="task-col" />
      <div
        v-for="day in weekdays"
        :key="day.value"
        class="flex day-col flex-col px-2 capitalize text-gray-500 cursor-pointer pb-2 border-b-2 border-transparent"
      >
        <span>
          {{ day.label }}
        </span>
        <div class="text-xs flex items-center space-x-2">
          <span>{{ formatDate(day.value, 'dd MMM') }}</span>
        </div>
      </div>
      <div class="total-col flex flex-col items-end total-row pb-2 text-gray-500" />
    </div>
    <div v-if="loading">
      <LoadingTable class="min-h-[320px]" />
    </div>
    <div v-if="timeLogs.length === 0 && !loading">
      <EmptyState
        :title="`${$t('No time entries for this week')}`"
        :description="$t('You can add time entries by clicking the plus button.')"
        :icon="ClockIcon"
        class="min-h-[320px] mt-0 flex flex-col justify-center bg-gray-50 rounded-b mb-0"
      />
    </div>
    <div v-else-if="timeLogs.length && !loading" class="divide-y divide-gray-200 border-b border-200">
      <div
        v-for="(group, key) in groupedLogs"
        :key="key" class="relative flex"
      >
        <div v-if="group[0]" class="task-col">
          <div class="font-semibold">
            {{ getProjectName(group[0]) }}
          </div>
          <div class="text-gray-700 leading-5">
            {{ getTaskName(group[0]) }}
          </div>
        </div>
        <div
          v-for="day in weekdays"
          :key="day.value"
          class="flex day-col flex-col capitalize text-gray-500 cursor-pointer pb-2 border-b-2 border-transparent"
          :class="{
            '!bg-primary/10': isToday(day.value),
            'bg-primary/5': !!getDayLog(group, day.value)?.attributes.timer_started_at,
          }"
        >
          <div class="px-2 time-input-wrapper h-full">
            <FormKit
              v-if="!getDayLog(group, day.value)?.attributes.timer_started_at"
              :model-value="getWorkedTimeForDay(group, day.value)"
              validation="timeLog"
              @change="(evt: any) => updateLogTime(group, day.value, evt.target.value)"
            />
            <TimeWithTimer
              v-else
              :log="getDayLog(group, day.value)"
            />
          </div>
        </div>
        <div class="total-col flex flex-row space-x-2 items-center w-full justify-end">
          <span class="font-semibold">
            {{ minutesToHours(getTotalMinutes(group)) }}
          </span>
          <BaseButton
            size="xs"
            square
            variant="secondary"
            @click="removeRow(group)"
          >
            <XMarkIcon class="w-4 h-4" />
          </BaseButton>
        </div>
      </div>
    </div>
    <div class="py-3 relative flex">
      <div class="task-col">
        <BaseButton size="sm" variant="secondary" @click="showCreateDialog = true">
          <PlusIcon class="w-5 h-5 mr-1" />
          <span>{{ $t('Add row') }}</span>
        </BaseButton>
      </div>
      <template v-if="!loading && timeLogs.length">
        <div
          v-for="day in weekdays"
          :key="day.value"
          class="day-col text-right"
        >
          <span class="font-semibold mr-2">
            {{ minutesToHours(getTotalMinutesForDay(logs, day.value)) }}
          </span>
        </div>
        <div class="total-col flex justify-end">
          <span class="font-semibold mr-8">
            {{ minutesToHours(totalMinutesForWeek) }}
          </span>
        </div>
      </template>
    </div>
    <TimeSheetWeeklyForm
      v-if="showCreateDialog"
      v-model="showCreateDialog"
      :is-dialog="true"
      :employee-id="employeeId as string"
      :date="weekdays[0]?.value"
      :other-logs="logs"
      @close="showCreateDialog = false"
      @cancel="showCreateDialog = false"
      @save="onAddRow"
    />
  </div>
</template>

<script lang="ts" setup>
import type { PropType } from "vue"
import { computed, ref, watch } from 'vue'
import { ClockIcon, PlusIcon, XMarkIcon } from "@heroicons/vue/24/outline"
import { groupBy, sumBy } from "lodash-es"
import { isSameDay } from "date-fns"
import { useTimeUtils } from "@/modules/time/composables/useTimeUtils"
import { formatDate } from "@/modules/common/utils/dateUtils"
import EmptyState from "@/components/data/EmptyState.vue"
import { minutesToHours, parseHoursToMinutes } from "@/modules/common/utils/parseHours"
import { getTimeSheetsForDay, getTotalMinutesForDay } from "@/modules/time/util/timeUtils"
import { useTimeSheetStore } from "@/modules/time/store/timeSheetStore"
import { $deleteConfirm } from "@/components/common/modal/modalPlugin"
import i18n from "@/i18n"
import TimeSheetWeeklyForm from "@/modules/time/components/TimeSheetWeeklyForm.vue"
import Timesheet = App.Domains.TimeSheets.Models.Timesheet
import Data = API.Data
import TimeWithTimer from "@/modules/time/components/TimeWithTimer.vue"
import LoadingTable from "@/components/table/LoadingTable.vue"

const props = defineProps({
  from: {
    type: Date,
  },
  to: {
    type: Date,
  },
  timeLogs: {
    type: Array as PropType<Data<Timesheet>[]>,
    default: () => [],
  },
  loading: {
    type: Boolean,
    default: false,
  },
  employeeId: {
    type: String,
  },
})

const logs = ref(props.timeLogs)
const showCreateDialog = ref(false)

watch(() => props.timeLogs, (newLogs) => {
  logs.value = newLogs
})

const emit = defineEmits(['update:from', 'update:to'])

const { weekdays, getProjectName, getTaskName, getTotalMinutes } = useTimeUtils(props as any)

const totalMinutesForWeek = computed(() => {
  return sumBy(logs.value, 'attributes.worked_minutes')
})

const groupedLogs = computed(() => {
  return groupBy(logs.value, (log: Data<Timesheet>) => {
    const { task_id, project_id } = log.attributes
    return project_id + task_id
  })
})

function getWorkedTimeForDay(group: Data<Timesheet>[], day: Date) {
  const log = getDayLog(group, day)
  return log ? minutesToHours(log.attributes.worked_minutes) : ''
}

function getDayLog(group: Data<Timesheet>[], day: Date) {
  const timeLogs = getTimeSheetsForDay(group, day)
  return timeLogs[0]
}

const timeSheetStore = useTimeSheetStore()

async function updateLogTime(group: Data<Timesheet>[], day: Date, time: string) {
  const timeLogs = getTimeSheetsForDay(group, day)
  const log = timeLogs[0]
  const worked_minutes = parseHoursToMinutes(time)
  if (!worked_minutes) {
    return
  }
  if (log) {
    const index = logs.value.findIndex((l: Data<Timesheet>) => l.id === log.id)
    log.attributes.worked_minutes = worked_minutes
    logs.value[index].attributes.worked_minutes = worked_minutes
    await timeSheetStore.patchTimeSheet(log.id, {
      worked_minutes,
    })
  } else {
    const timeSheetToCopyFrom = group[0]
    const { project_id, task_id, employee_id } = timeSheetToCopyFrom?.attributes || {}
    const newLog = {
      id: undefined,
      project_id,
      task_id,
      employee_id,
      description: '',
      worked_minutes,
      date: day,
    }
    const timeSheet = await timeSheetStore.createTimeSheet(newLog)
    logs.value.push(timeSheet)
  }
}

async function removeRow(timeLogs: Data<Timesheet>[]) {
  const ids: string[] = timeLogs.map((log: Data<Timesheet>) => log.id?.toString())
  const confirm = await $deleteConfirm({
    title: i18n.t('Delete time entries for this task ?'),
    description: i18n.t('This action will delete all time entries for this task for the selected week. This action cannot be undone.'),
  })
  if (!confirm) {
    return
  }
  logs.value = logs.value.filter((log: Data<Timesheet>) => !ids.includes(log.id?.toString()))
  await timeSheetStore.deleteTimesheets(ids)
}

function onAddRow(log: Data<Timesheet>) {
  logs.value.push(log)
  showCreateDialog.value = false
}

function isToday(day: Date) {
  return isSameDay(day, new Date())
}
</script>

<style lang="scss">
.weekly-view {
  .task-col {
    @apply min-w-[320px] md:min-w-[450px] pl-2 py-3;
  }

  .day-col {
    @apply w-[75px] min-w-[75px] py-3;
  }

  .total-col {
    @apply w-[100px] min-w-[100px] pr-2 py-3;
  }

  .time-input-wrapper input {
    @apply px-1 text-right text-gray-800;
  }

  .time-input-wrapper .formkit-messages {
    @apply hidden;
  }
}
</style>
