<template>
  <EmployeeForm
    :employee="currentEmployee"
    :loading="currentEmployeeLoading"
  />
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia";
import { useEmployeeStore } from "@/modules/employees/store/employeeStore";
import EmployeeForm from '@/modules/employees/components/EmployeeForm.vue';

const employeeStore = useEmployeeStore()
const { currentEmployee, currentEmployeeLoading } = storeToRefs(employeeStore);
</script>
<route lang="yaml">
name: Employee Edit
meta:
  permission: manageEmployees
</route>
