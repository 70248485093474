<template>
  <FeedbackForm :data="currentFeedback"/>
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia"
import { useFeedbackStore } from "@/modules/feedback/store/feedbackStore"
import FeedbackForm from "@/modules/feedback/components/FeedbackForm.vue"

const feedbackStore = useFeedbackStore()
const { currentFeedback } = storeToRefs(feedbackStore)
</script>

<route lang="yaml">
name: Feedback Edit
</route>
