<template>
  <FormKit
    v-model="model.start_date"
    :label="$t('Start Date')"
    :placeholder="$t('Start Date')"
    :show-holidays="true"
    picker-only
    clearable
    type="datepicker"
    validation="required"
    :help="$t('When does the holiday start?')"
    :disabled-days="disableWeekends"
    :week-start="1"
    format="MMMM DD, YYYY"
    outer-class="col-span-6 md:col-span-3"
    @update:modelValue="onStartDateChange"
  >
    <template #day="{ classes, day }">
      <DayCell
        :start-date="model.start_date"
        :end-date="model.end_date"
        :classes="classes"
        :day="day"
      />
    </template>
  </FormKit>
  <FormKit
    v-model="model.end_date"
    :label="$t('End Date')"
    :placeholder="$t('End Date')"
    :show-holidays="true"
    :min-date="new Date(model.start_date)"
    picker-only
    clearable
    type="datepicker"
    validation="required"
    :help="$t('When does the holiday end?')"
    format="MMMM DD, YYYY"
    outer-class="col-span-6 md:col-span-3"
    :disabled-days="disableWeekends"
    :week-start="1"
    @update:modelValue="onEndDateChange"
  >
    <template #day="{ classes, day }">
      <DayCell
        :start-date="model.start_date"
        :end-date="model.end_date"
        :classes="classes"
        :day="day"
      />
    </template>
  </FormKit>
</template>

<script lang="ts" setup>
import { FormKitNode } from "@formkit/core"
import { nextTick, ref } from "vue"
import DayCell from "@/modules/holidays/components/DayCell.vue";

const props = defineProps({
  data: {
    type: Object,
    default: () => ({}),
  },
})

const emit = defineEmits(["change"])
const model = ref({
  start_date: props.data?.start_date || null,
  end_date: props.data?.end_date || null,
  interval: props.data?.interval || null,
})
function disableWeekends(node: FormKitNode, date: Date) {
  // Re-implement min-date and max-date
  if (node.props.minDate && date < node.props.minDate) {
    return true
  }
  return !!(node.props.maxDate && date > node.props.maxDate)
}
function onStartDateChange(value: string) {
  const start = new Date(value)?.getTime()
  const end = new Date(model.value.end_date)?.getTime()
  if ((value && !model.value.end_date) || start > end) {
    model.value.end_date = value
  }
  emit('change', model.value)
}

async function onEndDateChange() {
  await nextTick()
  if (model.value.start_date && model.value.end_date) {
    const start = new Date(model.value.start_date)
    const end = new Date(model.value.end_date)
    model.value.interval = [start, end]
  }
  emit('change', model.value)
}
</script>
