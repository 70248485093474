import { RowHeightParams } from "@ag-grid-community/core";

export const ViewModes = {
  List: 'list',
  Group: 'group',
}

export function getExpenseRowHeight(params: RowHeightParams) {
  if (params?.data?.fullWidth) {
    const currencyTotals = params?.data?.meta?.by_currency || []
    const totalCurrencies = (currencyTotals?.length || 1) + 1
    return totalCurrencies * 30
  }
}
