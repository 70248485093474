<template>
  <FilterInput
    v-bind="$attrs"
    :data="statuses"
    :item-component="InvoiceStatusOption"
    value-key="value"
    label-key="label"
  />
</template>

<script lang="ts" setup>
import { ref } from "vue"
import { useI18n } from "vue-i18n"
import FilterInput from "@/components/table/filters/base/FilterInput.vue"
import { InvoiceStatus } from "@/modules/invoices/types/invoicEnums"
import InvoiceStatusOption from "@/components/table/filters/InvoiceStatusOption.vue"

const { t } = useI18n()

const statuses = ref([
  {
    value: InvoiceStatus.Draft,
    label: t('Draft'),
  },
  {
    value: InvoiceStatus.Sent,
    label: t('Sent'),
  },
  {
    value: InvoiceStatus.Due,
    label: t('Due'),
  },
  {
    value: InvoiceStatus.Paid,
    label: t('Paid'),
  },
])
</script>
