<template>
  <div class="group feedback-content">
    <div class="flex items-start justify-between">
      <router-link :to="`/feedback/${data.id}/view`" class="no-underline">
        <div class="flex space-x-2 items-center">
          <h5 class="text-gray-800">
            {{ data.attributes.type }}
          </h5>
          <BaseTooltip :content="tooltipText">
            <CheckBadgeIcon v-if="data.attributes.is_visible_to_employee" class="w-4 h-4 text-green-600" />
            <LockClosedIcon v-else class="w-4 h-4 text-gray-600" />
          </BaseTooltip>
        </div>

        <span v-if="data.attributes.given_at" class="text-xs text-base-300">
          {{ $formatDate(data.attributes.given_at) }}
        </span>
        <FeedbackForRelation
          class="mt-2"
          :show-creator="true"
          :show-for="showFor"
          :feedback="data"
        />
      </router-link>
      <div
        v-if="$can('manageFeedback')"
        class="flex items-center opacity-0 group-hover:opacity-100">
        <BaseButton variant="link" @click="onView">
          <EyeIcon class="w-5 h-5"/>
        </BaseButton>
        <template v-if="$can('manageFeedback')">
          <BaseButton variant="link" class="-ml-2" @click="onEdit">
            <PencilSquareIcon class="w-5 h-5"/>
          </BaseButton>
          <BaseButton variant="link" class="-ml-2" @click="onDelete">
            <TrashIcon class="w-5 h-5 text-red-500"/>
          </BaseButton>
        </template>
      </div>
    </div>
    <div class="rounded-sm border border-gray-200 flex w-full items-center justify-between mt-5 px-4 py-5">
      <div>
        <HtmlEditor
          v-if="data.attributes.notes"
          :model-value="data.attributes.notes"
          :read-only="true"
          :auto-height="true"
        />

        <AudioRecorder
          v-if="data?.attributes?.audio_file"
          :convert-speech-to-text="false"
          :can-record="false"
          :file-url="data?.attributes?.audio_file"
          :load-on-play="true"
          class="w-full min-w-[300px]"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { CheckBadgeIcon, EyeIcon, LockClosedIcon, PencilSquareIcon, TrashIcon } from '@heroicons/vue/24/outline'
import BaseTooltip from "@/components/common/BaseTooltip.vue"
import BaseButton from "@/components/common/buttons/BaseButton.vue"
import { $deleteConfirm } from "@/components/common/modal/modalPlugin"
import { useFeedbackStore } from "@/modules/feedback/store/feedbackStore"
import HtmlEditor from "@/components/formkit/html/HtmlEditor.vue"
import AudioRecorder from "@/components/audio/components/AudioRecorder.vue"
import FeedbackForRelation from "@/modules/feedback/components/FeedbackForRelation.vue";

const props = defineProps({
  data: {
    type: Object,
    default: () => ({}),
  },
  showFor: {
    type: Boolean,
    default: false,
  },
})
const { t } = useI18n()
const emit = defineEmits(['delete'])

const expanded = ref(false)

const isExpandable = computed(() => {
  if (!props.data.attributes?.notes) {
    return false
  }
  // Create a temporary element to measure the height of the text
  const el = document.createElement('div')
  el.className = 'line-clamp-6 prose prose-sm break-words max-w-3xl'
  el.style.visibility = 'hidden'
  el.style.position = 'absolute'
  el.textContent = props.data.attributes.notes

  document.body.appendChild(el)

  const clampedHeight = el.offsetHeight
  el.classList.remove('line-clamp-6')
  const actualHeight = el.offsetHeight

  document.body.removeChild(el)

  return actualHeight > clampedHeight // If true, text overflows
})
const tooltipText = computed(() => {
  if (props.data.attributes.is_visible_to_employee) {
    return t('This feedback is visible to the employee')
  }
  return t('This feedback is not visible to the employee')
})

const feedbackStore = useFeedbackStore()
const router = useRouter()

function onView() {
  router.push(`/feedback/${props.data.id}/view`)
}

function onEdit() {
  router.push(`/feedback/${props.data.id}/edit?feedbackable_id=${props.data.attributes.feedbackable_id}`)
}

async function onDelete() {
  const confirmed = await $deleteConfirm({
    title: t('Delete Feedback'),
    description: t('Are you sure you want to delete this feedback ? The data will be removed on our servers. This action cannot be undone.'),
    extraConfirmation: true,
  })
  if (!confirmed) {
    return
  }
  await feedbackStore.deleteFeedback(props.data.id)
  emit('delete', props.data.id)
}
</script>
