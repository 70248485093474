<template>
  <EvaluationForm :evaluation="currentEvaluation" />
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia"
import EvaluationForm from "@/modules/evaluations/components/EvaluationForm.vue"
import { useEvaluationStore } from "@/modules/evaluations/store/evaluationStore"

const evaluationStore = useEvaluationStore()
const { currentEvaluation } = storeToRefs(evaluationStore)
</script>

<route lang="yaml">
name: Evaluation Edit
</route>
