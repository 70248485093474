<template>
  <Surface
    v-bind="$attrs"
    :class="panelClass"
    :with-shadow="!noShadow"
  >
    <slot />
  </Surface>
</template>

<script setup>
import { computed } from 'vue'
import Surface from "@/components/formkit/html/components/Surface.vue"

// Define props
const props = defineProps({
  spacing: {
    type: String,
    default: '',
    validator: value => ['medium', 'small'].includes(value),
  },
  noShadow: Boolean,
})

// Compute the class names based on props
const panelClass = computed(() => {
  let baseClass = 'p-2'
  if (props.spacing === 'small') {
    baseClass += ' p-[0.2rem]'
  }
  return baseClass
})
</script>
