import axios from "axios"
import { defineStore } from "pinia"
import { CreditCardIcon } from '@heroicons/vue/24/outline'
import i18n from '@/i18n'
import { error } from "@/components/common/NotificationPlugin"
import { $confirm } from "@/components/common/modal/modalPlugin"
import router from "@/router/router"
import { useAuth } from '@/modules/auth/composables/useAuth'

const t = i18n.t

export interface Plan {
  active: boolean
  created_at: string
  currency: string
  extra: null | any
  frequency: 'month | year'
  id: string
  identifier: string
  name: string
  nickname: string
  price: number
  product_id: string
  role_id: null | string
  stripe_id: string
  trial_days: number
  updated_at: string
}
export const useBillingStore = defineStore('billing', {
  persistedState: {
    persist: true,
  },
  state: () => {
    return {
      seatDataLoading: false,
      seatData: {
        available_seats: 0,
        total_active_seats: 0,
        total_subscription_seats: 0,
      },
      plans: [] as Plan[],
      activePlan: null as Plan | null,
      activeSubscription: null as any,
      paidFeatures: {
        canCreateProjects: false,
        hasInvoiceAccess: false,
        hasReportAccess: false,
        hasTimeloggingAccess: false,
      },
      billingUrl: '' as string,
      showSeatsDialog: false,
      subscriptionLoading: true,
    }
  },
  actions: {
    async getSeatData() {
      try {
        this.seatDataLoading = true
        const { data } = await axios.get('/restify/employees/getters/available-seats')
        this.setSeatData(data)
      } finally {
        this.seatDataLoading = false
      }
    },
    setSeatData(data: any) {
      if (!data) {
        return
      }
      this.seatData = data
    },
    async getPlans() {
      const { data } = await axios.get('/plans')
      this.plans = data
    },
    async getActiveSubscription() {
      try {
        this.subscriptionLoading = true
        const { data } = await axios.get('/restify/tenants/getters/active-plan')
        this.setActivePlan(data)
      } finally {
        this.subscriptionLoading = false
      }
    },
    setActivePlan(data: any) {
      if (!data) {
        return
      }
      this.activePlan = data?.subscription?.plan
      this.activeSubscription = data?.subscription
      this.paidFeatures = {
        canCreateProjects: data?.canCreateProjects || false,
        hasInvoiceAccess: data?.hasInvoiceAccess || false,
        hasReportAccess: data?.hasReportAccess || false,
        hasTimeloggingAccess: data?.hasTimeloggingAccess || false,
      }
      this.billingUrl = data?.billingUrl
    },
    async onPlanSelected(name: string) {
      try {
        const plan = this.plans.find(plan => plan.name === name)
        if (plan?.id === this.activePlan?.id) {
          window.location.href = this.billingUrl
          return
        }
        if (!plan) {
          return
        }
        const { data } = await axios.post(`/checkout/${plan.id}`, {
          quantity: this.seatData.total_subscription_seats,
          successUrl: window.location.href,
          cancelUrl: window.location.href,
        })
        window.location.href = data.url
      } catch (err: any) {
        if (err.handled) {
          return
        }
        if (err.handleLocally && err.response?.data?.message) {
          error(t(err.response?.data?.message))
          return
        }
        error(t('Could not update the subscription. Please try again.'))
      }
    },
    async updateSeats(quantity: number) {
      if (quantity < this.seatData.total_active_seats || !this.activeSubscription.id) {
        return
      }
      await axios.post(`/subscription/${this.activeSubscription.id}/update`, {
        quantity: +quantity,
      })
      await Promise.all([
        this.getActiveSubscription(),
        this.getSeatData(),
      ])
      this.seatData.total_subscription_seats = +quantity
    },
    onEmployeeCreated() {
      this.seatData.available_seats -= 1
    },
    onEmployeeArchived() {
      this.seatData.available_seats += 1
    },
    async showAddSeatsModal() {
      const { isAdmin } = useAuth()
      if (!isAdmin.value) {
        error(t('Please contact your administrator to add more members'))
        return
      }
      if (this.isFreePlan) {
        const confirm = await $confirm({
          title: t('Upgrade to a paid plan'),
          description: t('Free plan is limited to 3 members only. You need to upgrade to a paid plan to add more members.'),
          buttonText: t('Upgrade'),
          buttonType: 'primary',
          icon: CreditCardIcon,
        })
        if (confirm) {
          await router.push('/settings/billing')
        }
        return
      }
      this.showSeatsDialog = true
    },
    closeAddSeatsModal() {
      this.showSeatsDialog = false
    },
  },
  getters: {
    isFreePlan(state) {
      return state.activePlan?.name === 'Free'
    },
    isPaidPlan(state) {
      const planPrice = state.activePlan?.price || 0
      return planPrice > 0
    },
    canAddSeat(state) {
      return state.seatData.available_seats > 0
    },
    hasActiveSubscription(state) {
      if (state.subscriptionLoading) {
        return true
      }
      return state.activeSubscription !== null && state.activeSubscription?.stripe_status === 'active'
    },
  },
})
