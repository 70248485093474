<template>
  <FormKit
    type="colorpicker"
    :label="$t('Color')"
    :options="colorOptions"
    panel-controls="false"
    panel-format="false"
    inline
    v-bind="$attrs"
  />
</template>

<script lang="ts" setup>
import { computed } from "vue"
import { TagColors } from "@/modules/settings/types/settingTypes"

const colorOptions = computed(() => {
  return [
    {
      label: 'primary',
      value: TagColors.Primary,
    },
    {
      name: "yellow",
      value: TagColors.Yellow,
    },
    {
      name: "orange",
      value: TagColors.Orange,
    },
    {
      name: "red",
      value: TagColors.Red,
    },
    {
      name: "green",
      value: TagColors.Green,
    },
    {
      name: "cyan",
      value: TagColors.Cyan,
    },
    {
      name: "blue",
      value: TagColors.Blue,
    },
    {
      name: "indigo",
      value: TagColors.Indigo,
    },
    {
      name: "magenta",
      value: TagColors.Magenta,
    },
    {
      name: "violet",
      value: TagColors.Violet,
    },
    {
      name: "pink",
      value: TagColors.Pink,
    },
    {
      name: "gray",
      value: TagColors.Gray,
    },
  ]
})
</script>
