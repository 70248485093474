import i18n from "@/i18n";

export const InvoiceStatus = {
  Draft: 'draft',
  Sent: 'sent',
  Paid: 'paid',
  Due: 'due',
}

export const statusNames = {
  [InvoiceStatus.Draft]: i18n.t('Draft'),
  [InvoiceStatus.Sent]: i18n.t('Sent'),
  [InvoiceStatus.Paid]: i18n.t('Paid'),
  [InvoiceStatus.Due]: i18n.t('Due'),
}
