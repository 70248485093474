<template>
  <div>
    <SettingsCrudPage
      :title="$t('National Holidays')"
      :modal-component="LegalHolidayModal"
      :add-text="$t('Add national holiday')"
      :edit-text="$t('Edit national holiday')"
      :has-description="false"
      :url-params="urlParams"
      :extra-columns="extraColumns"
      entity="legal-holidays"
      entity-path="holidays/national"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import { ColDef } from "@ag-grid-community/core"
import { useI18n } from "vue-i18n"
import SettingsCrudPage from "@/modules/settings/components/SettingsCrudPage.vue"
import LegalHolidayModal from "@/modules/settings/components/LegalHolidayModal.vue"
import { ColumnTypes } from "@/components/table/cells/tableCellComponents"
import { FilterTypes } from "@/components/table/filters/filterTypes"

const { t } = useI18n()
const extraColumns = computed<ColDef[]>(() => {
  return [
    {
      field: 'attributes.date',
      headerName: t('Date'),
      type: ColumnTypes.Date,
      minWidth: 140,
    },
    {
      field: 'year',
      headerName: t('Year'),
      hide: true,
      filter: FilterTypes.Year,
    },
  ]
})

const urlParams = ref({
  year: new Date().getFullYear(),
})
</script>

<route lang="yaml">
name: Edit National Holidays
</route>
