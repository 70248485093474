<template>
  <div class="flex flex-col">
    <ReportData :report-type="ReportTypes.Tasks" />
  </div>
</template>

<script lang="ts" setup>
import { ReportTypes, useReportsStore } from '@/modules/reports/store/reportsStore'
import ReportData from '@/modules/reports/components/ReportData.vue'
const reportStore = useReportsStore()
reportStore.resetIdFilters()
</script>

<route lang="yaml">
name: Tasks report
</route>
