<template>
  <ElConfigProvider
    :locale="elementLocale"
  >
    <RouterView />
  </ElConfigProvider>
</template>

<script setup lang="ts">
import { computed, inject } from 'vue'
import { useRoute } from 'vue-router'
import { useHead } from "@vueuse/head"
import dayjs from 'dayjs'
import { useI18n } from "vue-i18n"
import { elementLocale, setLocale } from "@/i18n"
import { DEFAULT_APP_TITLE } from '@/modules/common/config'

// set first day of week to Monday
dayjs.Ls.en.weekStart = 1
const route = useRoute()
const { t, locale } = useI18n()

const pageTitle = computed(() => {
  const routeName = route.name ? String(route.name) : ''
  return t(routeName) ? `${t(routeName)} - ${DEFAULT_APP_TITLE}` : DEFAULT_APP_TITLE
})
useHead({
  title: pageTitle,
})

const url = new URL(window.location.href)
const formkitConfig: any = inject(Symbol.for('FormKitConfig'))
if (url.searchParams.has('locale')) {
  const localeParam = url.searchParams.get('locale')
  setLocale(localeParam as string)
  formkitConfig.locale = localeParam
}
</script>
