<template>
  <ProjectForm
    :is-edit="true"
    :data="currentProject"
    :loading="currentProjectLoading"
  />
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import ProjectForm from '@/modules/projects/components/ProjectForm.vue'
import { useProjectStore } from '@/modules/projects/store/projectStore'

const projectStore = useProjectStore()
const { currentProject, currentProjectLoading } = storeToRefs(projectStore)
</script>

<route lang="yaml">
name: Project Edit
meta:
  permission: manageProjects
</route>
