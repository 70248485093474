<template>
  <div v-if="!isHidden" class="flex space-x-2 items-center">
    <span :class="statusClass" class="w-2 h-2 rounded-full"></span>
    <span class="capitalize text-sm">
      {{ statusName }}
    </span>
  </div>
</template>
<script setup lang="ts">

import { computed } from "vue";
import { DocumentStatus, DocumentStatusLabels } from "@/modules/documents/types/documentStatus";

const props = defineProps({
  params: {
    type: Object,
    default: () => ({}),
  }
})

type StatusMapping = {
  [key: string]: string
}
const statusMappings: StatusMapping = {
  [DocumentStatus.Canceled]: 'bg-red-500',
  [DocumentStatus.Expired]: 'bg-red-500',
  [DocumentStatus.Declined]: 'bg-red-500',
  [DocumentStatus.ErrorConverting]: 'bg-red-500',
  [DocumentStatus.ErrorSending]: 'bg-red-500',
  [DocumentStatus.Signed]: 'bg-green-500',
  [DocumentStatus.SignedAndDownloaded]: 'bg-green-500',
  [DocumentStatus.Downloaded]: 'bg-green-500',
  [DocumentStatus.Converting]: 'bg-gray-500',
  [DocumentStatus.New]: 'bg-gray-500',
  [DocumentStatus.Viewed]: 'bg-blue-500',
  [DocumentStatus.Sent]: 'bg-blue-500',
}

const statusClass = computed<string>(() => {
  return statusMappings[props.params?.value] || ''
})

const isHidden = computed(() => {
  return [DocumentStatus.New].includes(props.params?.value) || !props.params?.value
})

const statusName = computed(() => {
  const value = props.params?.value
  return DocumentStatusLabels[value] || value
})
</script>
