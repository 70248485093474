<template>
  <NodeViewWrapper as="span" class="cursor-pointer font-medium bg-primary/20 px-1 py-0.5 rounded-sm">
    {{ nodeAttrs?.label ?? nodeAttrs?.id }}
  </NodeViewWrapper>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { NodeViewWrapper, nodeViewProps } from '@tiptap/vue-3'

const props = defineProps(nodeViewProps)
const nodeAttrs = computed(() => props.node.attrs)
</script>
