<template>
  <span
    :class="invoiceClass"
    class="inline-flex items-center rounded-full px-2 py-1 text-xs font-medium ring-1 ring-inset"
  >
    {{ $t(invoiceStatus) }}
  </span>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { InvoiceStatus, statusNames } from '@/modules/invoices/types/invoicEnums'

const props = defineProps({
  status: {
    type: String,
    required: true,
  },
})

const classMap = {
  [InvoiceStatus.Draft]: 'bg-gray-50 text-gray-700 ring-gray-600/10',
  [InvoiceStatus.Sent]: 'bg-blue-50 text-blue-700 ring-blue-600/10',
  [InvoiceStatus.Paid]: 'bg-green-50 text-green-700 ring-green-600/10',
  [InvoiceStatus.Due]: 'bg-red-50 text-red-700 ring-red-600/10',
}

const invoiceClass = computed(() => {
  return classMap[props.status] || classMap[InvoiceStatus.Draft]
})
const invoiceStatus = computed(() => {
  return statusNames[props.status] || statusNames[InvoiceStatus.Draft]
})
</script>
