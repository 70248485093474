<template>
  <div class="pt-5">
    <PageTitle show-back>
      {{ $t('Integrations') }}
    </PageTitle>

    <div class="flex flex-wrap gap-3">
      <SettingsCard
        v-for="item in integrationCards"
        :key="item.title"
        :data="item"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue"
import { useI18n } from "vue-i18n"
import { SettingCard } from "@/modules/settings/types/settingTypes"
import SettingsCard from "@/modules/settings/components/SettingsCard.vue"
import GoogleCalendarIcon from "@/modules/settings/components/icons/GoogleCalendarIcon.vue"
import SlackIcon from "@/modules/settings/components/icons/SlackIcon.vue"
import { can } from "@/plugins/permissionPlugin";

const { t } = useI18n()
const integrationCards = computed<SettingCard[]>(() => {
  const cards = [
    {
      title: t('Google Calendar'),
      description: t('Connect to a Google Calendar to sync approved holidays.'),
      path: '/settings/integrations/google-calendar',
      iconSize: 'lg',
      icon: GoogleCalendarIcon,
      show: () => can('manageHolidayPolicies'),
    },
    {
      title: t('Slack'),
      description: t('Send reminders and notifications to your Slack channels.'),
      path: '/settings/integrations/slack',
      iconSize: 'lg',
      icon: SlackIcon,
      show: () => can('manageHolidayPolicies'),
    },
    {
      title: t('Personal Google Calendar'),
      description: t('Connect your personal Google Calendar to sync with Growee events.'),
      path: '/settings/integrations/personal-google-calendar',
      iconSize: 'lg',
      icon: GoogleCalendarIcon,
      show: () => true,
    },
  ]
  return cards.filter(card => card.show())
})
</script>

<route lang="yaml">
name: Integrations List
</route>
