<template>
  <BaseSelect
    v-bind="allAttrs"
    :options="mappedRoles"
    :data-loading="rolesLoading"
    value-key="id"
    label-key="attributes.name"
    filterable
    :model-value="value"
    @update:model-value="handleChange"
  />
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia"
import { computed, onMounted } from "vue"
import { useI18n } from "vue-i18n"
import { useSettingsStore } from "@/modules/settings/store/settingsStore"
import { useFormKitInput } from "@/components/formkit/useFormkitInput"
import Role = App.Domains.Permissions.Models.Role
import Data = API.Data
const props = defineProps({
  context: Object,
})
const settingsStore = useSettingsStore()
const { roles, rolesLoading } = storeToRefs(settingsStore)
const { t } = useI18n()
const mappedRoles = computed(() => {
  return roles.value.map((role: Data<Role>) => {
    role.attributes.name = t(role.attributes.name)
    return role
  })
})

const { value, allAttrs, handleChange } = useFormKitInput(props)

onMounted(async () => {
  await settingsStore.getRoles()
})
</script>
