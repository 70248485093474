<template>
  <div class="h-full">
    <BaseDataTable
      ref="table"
      url="/restify/documents"
      :url-params="urlParams"
      :columns="columns"
      :add-text="t('Upload document')"
      :row-height="75"
      :suppress-cell-focus="true"
      :extra-actions="rowActions"
      :delete-title="t('Delete document')"
      :delete-description="t('After deleting, the document will be permanently deleted and the employee will no longer have access to it.')"
      entity="documents"
      actions="add,delete"
      @add="showUploadDialog = true"
    >
      <template v-if="$slots['actions-before']" #actions-before>
        <slot name="actions-before" />
      </template>
      <template
        v-if="$slots['bulk-actions']"
        #bulk-actions
      >
        <slot name="bulk-actions" />
      </template>

      <template #tags="{ row }">
        <DocumentTagList :tags="row.relationships.tags" />
      </template>
    </BaseDataTable>

    <FilePreviewModal
      :file-id="$route.query.id"
    />

    <FileUploadForm
      v-if="showEditDialog"
      v-model="showEditDialog"
      :is-dialog="true"
      :is-edit="true"
      :employee-id="employeeId"
      :title="t('Edit document')"
      @close="showEditDialog = false"
      @save="onDocumentUploaded"
    />

    <FileUploadForm
      v-if="showUploadDialog"
      v-model="showUploadDialog"
      :is-dialog="true"
      :employee-id="employeeId"
      :title="t('Upload document')"
      @close="showUploadDialog = false"
      @save="onDocumentUploaded"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue"
import { ColDef } from "@ag-grid-community/core"
import { storeToRefs } from "pinia"
import { useI18n } from "vue-i18n"
import { CloudArrowDownIcon, EyeIcon, PencilSquareIcon } from "@heroicons/vue/24/outline"
import { useRoute, useRouter } from "vue-router"
import { ColumnTypes } from "@/components/table/cells/tableCellComponents"
import { useAuthStore } from "@/modules/auth/store/authStore"
import FilePreviewModal from "@/modules/documents/components/FilePreviewModal.vue"
import { useDocumentsStore } from "@/modules/documents/store/documentsStore"
import { can } from "@/plugins/permissionPlugin"
import i18n from "@/i18n"
import FileUploadForm from "@/modules/documents/components/FileUploadForm.vue"
import { RowAction } from "@/components/table/tableTypes"
import { FilterTypes } from "@/components/table/filters/filterTypes"
import Employee = App.Models.Employee
import Data = API.Data
import Document = App.Domains.Documents.Models.Document
import { isAcceptedFile } from "@/modules/documents/utils/documentUtils"
import DocumentTagList from "@/components/data/DocumentTagList.vue"
const props = defineProps({
  employeeId: {
    type: String,
  },
})

const urlParams = computed(() => {
  return {
    related: 'employee,employee.position,documentType,tags',
    temporary_urls: true,
    active_employee: true,
    employee_id: props.employeeId || undefined,
  }
})
const { t } = useI18n()
const columns = ref<ColDef[]>([
  {
    headerName: t('File'),
    field: 'attributes.file_name',
    type: ColumnTypes.FileLink,
    cellClass: 'icon-cell',
    minWidth: 250,
  },
  {
    headerName: t('Type'),
    field: 'relationships.documentType.attributes.name',
    minWidth: 120,
    filter: FilterTypes.DocumentType,
  },
  {
    headerName: t('Document name'),
    field: 'attributes.name',
    minWidth: 200,
  },
  {
    headerName: t('Start Date'),
    field: 'attributes.start_date',
    minWidth: 140,
    type: ColumnTypes.Date,
    hide: true,
  },
  {
    headerName: t('Expiration Date'),
    field: 'attributes.end_date',
    minWidth: 140,
    type: ColumnTypes.Date,
    filter: FilterTypes.ExpirationDate,
  },
  {
    headerName: t('Tags'),
    field: 'tags',
    minWidth: 160,
    filter: FilterTypes.Tags,
  },
  {
    headerName: t('Employee'),
    field: 'relationships.employee',
    type: ColumnTypes.EmployeeLink,
    cellRendererParams: {
      showPosition: true,
    },
    minWidth: 150,
    filter: FilterTypes.Employee,
    initialHide: !!props.employeeId || !can('manageDocuments'),
  },
])

const router = useRouter()
const route = useRoute()
const documentsStore = useDocumentsStore()

const showEditDialog = ref(false)
const rowActions = computed<RowAction[]>(() => {
  return [
    {
      label: t('Download'),
      icon: CloudArrowDownIcon,
      action: async (document: Data<Document>) => {
        await documentsStore.downloadDocument(document)
      },
      show: (document: Data<Document>) => {
        return isAcceptedFile({ name: document?.attributes?.file_name } as File)
      },
    },
    {
      label: i18n.t('View'),
      icon: EyeIcon,
      action: async (document: Data<Document>) => {
        await router.push({
          path: route.path,
          query: {
            id: document.id,
          },
        })
      },
    },
    {
      label: i18n.t('Edit'),
      icon: PencilSquareIcon,
      action: async (document: Data<Document>) => {
        documentsStore.currentDocument = document
        showEditDialog.value = true
      },
    },
  ]
})

const showUploadDialog = ref(false)

const { profile } = storeToRefs(useAuthStore())

const table = ref<any>(null)

function onDocumentUploaded() {
  showUploadDialog.value = false
  showEditDialog.value = false
  table.value?.refresh()
}

function refresh() {
  table.value?.refresh()
}

watch(() => route.query.open, (value) => {
  if (value) {
    showUploadDialog.value = true
  }
}, { immediate: true })

defineExpose({
  refresh,
})
</script>
