<template>
  <div>
    <div class="flex flex-wrap items-center w-full justify-between">
      <PageTitle
        class="mb-2"
        :show-back="true"
        :loading="currentFeedbackLoading"
      >
        {{ feedbackName }}
      </PageTitle>
      <div class="flex flex-1 justify-end mb-2">
        <EditButton
          v-if="$can('manageFeedback')"
          @click="goToEdit"
        />
      </div>
    </div>

    <BaseContentCard v-if="currentFeedback.id">
      <FeedbackTimelineContent :data="currentFeedback" :show-for="true"/>
    </BaseContentCard>
  </div>
</template>

<script lang="ts" setup>
import { storeToRefs } from "pinia"
import { useRoute, useRouter } from "vue-router"
import PageTitle from "@/components/common/PageTitle.vue"
import EditButton from "@/components/common/buttons/EditButton.vue"
import { FeedbackType, useFeedbackStore } from "@/modules/feedback/store/feedbackStore"
import FeedbackTimelineContent from "@/modules/feedback/components/FeedbackTimelineContent.vue"
import FeedbackForRelation from "@/modules/feedback/components/FeedbackForRelation.vue"

const feedbackStore = useFeedbackStore()
const { feedbackName, currentFeedbackLoading, currentFeedback } = storeToRefs(feedbackStore)
const router = useRouter()

const route = useRoute()

function goToEdit() {
  const path = route.fullPath.replace('view', 'edit')
  router.push({ path })
}
</script>
