<template>
  <div class="h-full flex flex-col">
    <PageTitle show-back>
      {{ title }}
    </PageTitle>

    <slot name="after-title" />

    <div class="flex-1">
      <BaseDataTable
        ref="table"
        :url="`/restify/${entity}`"
        :url-params="{ withMeta: true, ...urlParams }"
        :columns="columns"
        :add-text="addText"
        :edit-url="`/settings/${entityPath || entity}?id={id}`"
        entity="departments"
        actions="add,edit,delete"
        v-bind="$attrs"
        @add="showAddDialog = true"
        @edit="onEdit"
        @after-delete="onDeleteEntity"
        @grid-ready="gridApi = $event"
      >
        <template #attributes.name="{ row }">
          <router-link
            v-if="row"
            :to="`/settings/${entityPath || entity}?id=${row?.id}`"
            class="text-base-content no-underline hover:text-primary active:text-primary"
            @click="onEdit(row)"
          >
            {{ row.attributes.name }}
          </router-link>
        </template>

        <template #attributes.color="{ row }">
          <div
            v-if="row.attributes.color"
            class="w-5 h-5 rounded"
            :style="{ backgroundColor: row.attributes.color }"
          />
        </template>
      </BaseDataTable>
    </div>
    <component
      :is="modalComponent"
      v-if="showAddDialog"
      v-model="showAddDialog"
      :title="addText"
      @close="showAddDialog = false"
      @save="onAddEntity"
    />

    <component
      :is="modalComponent"
      v-if="showEditDialog"
      v-model="showEditDialog"
      :title="editText"
      :data="rowToEdit"
      @close="closeEditDialog"
      @save="onUpdateEntity"
    />
  </div>
</template>

<script setup lang="ts">
import { PropType, ref } from "vue"
import { ColDef, GridApi, ValueSetterParams } from "@ag-grid-community/core"
import { useRouter } from "vue-router"
import axios from "axios"
import { useI18n } from "vue-i18n"
import { useSettingsStore } from "@/modules/settings/store/settingsStore"

import Data = API.Data;
const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  entity: {
    type: String,
    required: true,
  },
  entityPath: {
    type: String,
  },
  addText: {
    type: String,
    required: true,
  },
  editText: {
    type: String,
    required: true,
  },
  modalComponent: {
    type: Object,
    required: true,
  },
  hasDescription: {
    type: Boolean,
    default: false,
  },
  extraColumns: {
    type: Array as PropType<ColDef[]>,
    default: () => [],
  },
  urlParams: {
    type: Object,
    default: () => ({}),
  },
})

const showAddDialog = ref(false)
const showEditDialog = ref(false)

const rowToEdit = ref(null)

const { t } = useI18n()
const descriptionColumn = {
  headerName: t('Description'),
  field: 'attributes.description',
  editable: true,
  cellEditor: 'agLargeTextCellEditor',
  cellEditorPopup: true,
  cellEditorParams: {
    maxLength: 150,
    rows: 5,
    cols: 50,
  },
  valueSetter: (params: ValueSetterParams) => {
    if (!params.newValue) {
      return false
    }
    params.data.attributes.description = params.newValue
    updateRow(params.data)
    return true
  },
}

const columns = ref<ColDef[]>([
  {
    headerName: t('Name'),
    field: 'attributes.name',
    editable: true,
    valueSetter: (params: ValueSetterParams) => {
      if (!params.newValue) {
        return false
      }
      params.data.attributes.name = params.newValue
      updateRow(params.data)
      return true
    },
  },
])

if (props.hasDescription) {
  columns.value.push(descriptionColumn)
}

if (props.extraColumns?.length) {
  columns.value.push(...props.extraColumns)
}

function onEdit(row: any) {
  showEditDialog.value = true
  rowToEdit.value = row
}

const router = useRouter()
const table = ref<any>(null)
async function updateRow(row: any) {
  const data: Data<any> = await axios.put(`/restify/${props.entity}/${row.id}`, row.attributes)
  onUpdateEntity(data)
}
const settingsStore = useSettingsStore()
const gridApi = ref<GridApi>()
function getList() {
  const listMap: any = {
    "positions": settingsStore.positions,
    "evaluation-types": settingsStore.evaluationTypes,
    "documents": settingsStore.documentTypes,
    "departments": settingsStore.departments,
    "levels": settingsStore.levels,
    "document-types": settingsStore.documentTypes,
    "tags": settingsStore.documentTags,
    "legal-holidays": settingsStore.legalHolidays,
  }
  return listMap[props.entity] || []
}

function onDeleteEntity(data: Data<any>) {
  const list = getList()
  const id = data.id
  const index = list.findIndex((item: any) => item.id === id)
  if (index === -1) {
    return
  }
  list.splice(index, 1)
}

function onAddEntity(data: Data<any>) {
  showAddDialog.value = false
  const list = getList()
  list.push(data)
  list.sort((a: any, b: any) => a.attributes?.name?.localeCompare(b.attributes?.name))
  gridApi.value!.applyTransaction({
    add: [data],
    addIndex: 0,
  })
}

function onUpdateEntity(data: Data<any>) {
  closeEditDialog()
  gridApi!.value?.applyTransaction({ update: [data] })
  const list = getList()
  const index = list.findIndex((item: any) => item.id === data.id)
  if (index === -1) {
    return
  }
  list.splice(index, 1, data)
}
async function closeEditDialog() {
  showEditDialog.value = false
  rowToEdit.value = null
  await router.replace(`/settings/${props.entityPath || props.entity}`)
}

defineExpose({
  updateRow,
  onEdit,
})
</script>
