<template>
  <div>
    <h1 class="text-center">
      {{ t('Create new account') }}
    </h1>
    <div class="flex justify-center space-x-2 mt-2">
      <p>{{ t(`Already have an account?`) }}</p>
      <RouterLink to="/login">{{ t('Sign In') }}</RouterLink>
    </div>
    <SocialButtons>
      {{t('Sign up with Google')}}
    </SocialButtons>

    <div class="mt-10">
      <FormKit
        #default="{ state: { loading } }"
        :actions="false"
        type="form"
        @submit="onSubmit"
      >
        <FormkitEmailInput
          v-model="model.email"
          :label="t('Email')"
          :placeholder="t('Enter your email')"
          validation="required|email"
          validation-visibility="dirty"
        />

        <FormKitPasswordInput
          v-model="model.password"
          :label="t('Password')"
          :placeholder="t('Enter your password')"
          validation="required|length:8|contains_numeric|contains_symbol|contains_uppercase"
          validation-visibility="dirty"
        />

        <div class="w-full mt-10">
          <FormKit
            type="submit"
            :disabled="loading"
            :classes="{ input: 'w-full' }"
          >
            <span>{{ t('Sign Up') }}</span>
            <ArrowLongRightIcon class="w-4 h-4 ml-2"/>
          </FormKit>
        </div>
      </FormKit>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ArrowLongRightIcon } from "@heroicons/vue/24/outline";
import { reactive } from 'vue'
import { error } from '@/components/common/NotificationPlugin'
import FormKitPasswordInput from '@/components/formkit/FormkitPasswordInput.vue'
import FormkitEmailInput from '@/components/formkit/FormkitEmailInput.vue'
import SocialButtons from '@/modules/auth/components/SocialButtons.vue'
import { useAuthStore } from "@/modules/auth/store/authStore";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import RegisterStep1 = Auth.RegisterStep1;
import { RegisterSteps } from "@/modules/auth/types/enums";
import { trackCreateAccountStarted } from "@/util/analytics";

const router = useRouter()
const { t } = useI18n()
const model: RegisterStep1 = reactive({
  email: '',
  password: '',
  password_confirmation: '',
  onboarding_step: RegisterSteps.EmailSetup,
})

const emit = defineEmits(['success'])
const authStore = useAuthStore()

async function onSubmit() {
  try {
    trackCreateAccountStarted()
    model.password_confirmation = model.password
    const data = await authStore.registerStep1(model)
    emit('success', data)
  } catch (err: any) {
    console.error(err)
    if (err.handled) {
      return
    }
    error(t('An error occurred while trying to register'))
  }
}
</script>
