<template>
  <BubbleMenu
    :editor="editor"
    plugin-key="tableRowMenu"
    :update-delay="0"
    :tippy-options="tippyOptions"
    :should-show="shouldShow"
  >
    <ToolbarWrapper is-vertical>
      <PopoverItem
        icon="ArrowUpToLine"
        :close="false"
        label="Add row before"
        @click="onAddRowBefore"
      />
      <PopoverItem
        icon="ArrowDownToLine"
        :close="false"
        label="Add row after"
        @click="onAddRowAfter"
      />
      <PopoverItem
        icon="Trash"
        :close="false"
        label="Delete row"
        @click="onDeleteRow"
      />
    </ToolbarWrapper>
  </BubbleMenu>
</template>

<script setup>
import { BubbleMenu } from "@tiptap/vue-3"
import isRowGripSelected from "./utils"
import ToolbarWrapper from "@/components/formkit/html/components/toolbar/ToolbarWrapper.vue"
import PopoverItem from "@/components/formkit/html/components/popover/PopoverItem.vue"

// Props passed to this component
const props = defineProps({
  editor: Object,
  appendTo: Object, // Assuming this is a ref object
})

const tippyOptions = {
  appendTo: () => {
    return props.appendTo
  },
  placement: 'left',
  offset: [0, 15],
  popperOptions: {
    modifiers: [{ name: 'flip', enabled: false }],
  },
}

const shouldShow = ({ view, state, from }) => {
  if (!state || !from) {
    return false
  }

  return isRowGripSelected({ editor: props.editor, view, state, from })
}

const onAddRowBefore = () => {
  props.editor.chain().focus().addRowBefore().run()
}

const onAddRowAfter = () => {
  props.editor.chain().focus().addRowAfter().run()
}

const onDeleteRow = () => {
  props.editor.chain().focus().deleteRow().run()
}
</script>
