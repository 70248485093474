<template>
  <BaseSelect
    v-bind="allAttrs"
    :fetch-at-start="true"
    :transform-data="transformData"
    :model-value="value"
    url="/restify/invoice-reply-mails"
    value-key="attributes.email"
    label-key="attributes.email"
    filterable
    @update:model-value="handleChange"
  />
</template>

<script setup lang="ts">
import { orderBy } from "lodash-es"
import { useFormKitInput } from "@/components/formkit/useFormkitInput"
import { useSettingsStore } from "@/modules/settings/store/settingsStore"
import Data = API.Data

const props = defineProps({
  context: Object,
})

const { value, allAttrs, handleChange } = useFormKitInput(props)

function transformData(options: Data<any>[]) {
  options = options.filter((option: any) => option.attributes.validated)
  const defaultValue = options.find((option: any) => option.attributes.is_default)
  if (defaultValue) {
    handleChange(defaultValue?.attributes?.email)
  }
  return orderBy(options, 'attributes.email')
}
</script>
