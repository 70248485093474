<template>
  <div
    :class="dividerClassName"
    v-bind="$attrs"
  />
</template>

<script setup lang="ts">
import { computed, toRefs } from 'vue'

const props = defineProps({
  horizontal: {
    type: Boolean,
    default: false,
  },
})

const { horizontal } = toRefs(props)

const dividerClassName = computed(() => {
  return [
    'bg-neutral-200 dark:bg-neutral-800',
    horizontal.value
      ? 'w-full min-w-[1.5rem] h-[1px] my-1 first:mt-0 last:mb-0'
      : 'h-full min-h-[1.5rem] w-[1px] mx-1 first:ml-0 last:mr-0',
  ].filter(Boolean).join(' ')
})
</script>
