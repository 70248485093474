<template>
  <BaseSwitch
    v-bind="allAttrs"
    :model-value="context._value"
    @update:model-value="handleChange"
  />
</template>

<script setup lang="ts">
import { useFormKitInput } from "@/components/formkit/useFormkitInput";

const props = defineProps({
  context: Object,
})

const { allAttrs, handleChange } = useFormKitInput(props)
</script>

<script lang="ts">
export default {
  inheritAttrs: false,
}
</script>
