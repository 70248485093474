<template>
  <FormKit
    v-bind="$attrs"
    type="password"
    prefix-icon="lock-closed"
    suffix-icon="eye-slash"
    @suffix-icon-click="handleIconClick"
  />
</template>

<script setup lang="ts">
const handleIconClick = (node: any) => {
  node.props.suffixIcon = node.props.suffixIcon === 'eye' ? 'eye-slash' : 'eye'
  node.props.type = node.props.type === 'password' ? 'text' : 'password'
}
</script>
