import startOfWeek from "date-fns/startOfWeek";
import endOfWeek from "date-fns/endOfWeek";
import i18n from "@/i18n";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import { subMonths } from "date-fns";
import startOfQuarter from "date-fns/startOfQuarter";
import endOfQuarter from "date-fns/endOfQuarter";
import startOfYear from "date-fns/startOfYear";
import endOfYear from "date-fns/endOfYear";
import addYears from "date-fns/addYears";
import { IntervalTypes } from "@/modules/reports/store/reportsStore";
import { formatDate } from "@/modules/common/utils/dateUtils";

export function getIntervalDetails(type: string) {
  const now = new Date()
  const monthStart = startOfMonth(now)
  const lastMonthStart = subMonths(startOfMonth(now), 1)
  const intervalMap = {
    [IntervalTypes.Week]: {
      from: startOfWeek(now),
      to: endOfWeek(now),
      title: i18n.t('Week'),
    },
    [IntervalTypes.Month]: {
      from: startOfMonth(now),
      to: endOfMonth(now),
      title: `${i18n.t('This Month')} (${formatDate(monthStart, 'MMMM')})`,
    },
    [IntervalTypes.LastMonth]: {
      from: subMonths(startOfMonth(now), 1),
      to: subMonths(endOfMonth(now), 1),
      title: `${i18n.t('Last Month')} (${formatDate(lastMonthStart, 'MMMM')})`,
    },
    [IntervalTypes.Quarter]: {
      from: startOfQuarter(now),
      to: endOfQuarter(now),
      title: i18n.t('This Quarter'),
    },
    [IntervalTypes.LastQuarter]: {
      from: subMonths(startOfQuarter(now), 3),
      to: subMonths(endOfQuarter(now), 3),
      title: i18n.t('Last Quarter'),
    },
    [IntervalTypes.Year]: {
      from: startOfYear(now),
      to: endOfYear(now),
      title: i18n.t('This Year'),
    },
    [IntervalTypes.AllTime]: {
      from: addYears(now, -10),
      to: new Date(),
      title: i18n.t('All Time'),
    },
  }
  return intervalMap[type]
}
