<template>
  <div>
    <PageTitle
      class="mb-2"
      :show-back="true"
      :loading="currentFeedbackLoading"
    >
      <template v-if="isEdit">
        {{ feedbackName }}
      </template>
      <template v-else>
        {{ $t('Add feedback') }}
      </template>
    </PageTitle>
    <BaseFormCard
      :submit-text="isEdit ? $t('Update') : $t('Add')"
      :disabled="loading"
      @submit="createOrUpdateFeedback"
      @cancel="onCancel"
    >
      <FormCardSection :title="$t('Feedback Information')">
        <FormKit
          v-if="feedbackableType === FeedbackType.Employee"
          id="feedbackable_id"
          v-model="model.feedbackable_id"
          type="employeeSelect"
          :disabled="feedbackableId"
          :label="$t('Feedback for')"
          :placeholder="$t('John Doe')"
          outer-class="col-span-6 md:col-span-2"
          validation="required"
          @update:fullValue="onEmployeeChange"
        />

        <FormKit
          v-if="feedbackableType === FeedbackType.Client"
          id="feedbackable_id"
          v-model="model.feedbackable_id"
          type="clientSelect"
          :disabled="feedbackableId"
          :label="$t('Client')"
          :placeholder="$t('Client Name')"
          outer-class="col-span-6 md:col-span-2"
          validation="required"
          @update:fullValue="onClientChange"
        />

        <FormKit
          v-model="model.type"
          prefix-icon="cog-8-tooth"
          :label="$t('Type')"
          :placeholder="$t('1:1')"
          validation="required"
          outer-class="col-span-6 md:col-span-1"
        />

        <FormKit
          v-model="model.given_at"
          type="customDatePicker"
          :label="$t('Feedback date')"
          :placeholder="$t('21.05.2023')"
          validation="required"
          outer-class="col-span-6 md:col-span-2"
          @change="onFeedbackGivenAtChange"
        />

        <FormKit
          v-if="isEmployeeFeedback"
          v-model="model.is_visible_to_employee"
          type="checkbox"
          :name="$t('Share with employee')"
          :label="$t('Share with employee')"
          outer-class="col-span-6 md:col-span-3 mt-6"
        />
      </FormCardSection>

      <FormCardSection :title="$t('Feedback')">
        <div class="col-span-6 mt-4">
          <div class="text-gray-500 text-sm mb-1 ml-2">
            {{ $t('You can record your feedback as audio which will be automatically converted to text.') }}
          </div>
          <AudioRecorder
            :convert-speech-to-text="true"
            @audio-recorded="model.audio_file = $event"
            @audio-converted="onAudioConverted"
          />
        </div>
        <FormKit
          v-model="model.notes"
          type="html"
          validation="required"
          :label="$t('Feedback Notes')"
          outer-class="col-span-6"
        />
      </FormCardSection>

      <FormCardSection :title="$t('Additional File')">
        <FileDropzone
          v-model="model.file"
          :file-progress="fileProgress"
          :file-name="model?.file_name"
          :accept="acceptedFileTypes"
          :multiple="false"
          class="mb-9 mt-2 col-span-6"
          @update:modelValue="onFilesChange"
        />
      </FormCardSection>
    </BaseFormCard>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from "vue"
import { useI18n } from "vue-i18n"
import { useRoute, useRouter } from "vue-router"
import { storeToRefs } from "pinia"
import Data = API.Data
import PageTitle from "@/components/common/PageTitle.vue"
import { useFilterTabs } from "@/modules/common/composables/useFilterTabs"
import { useEmployeeStore } from "@/modules/employees/store/employeeStore"
import Employee = App.Domains.Employees.Models.Employee
import Client = App.Domains.Clients.Models.Client
import EmployeeTag from "@/components/data/EmployeeTag.vue"
import ClientTag from "@/components/data/ClientTag.vue"
import { API_DATE_FORMAT, formatDate, removeTimezoneFromDate } from "@/modules/common/utils/dateUtils"
import { FeedbackType, useFeedbackStore } from "@/modules/feedback/store/feedbackStore"
import AudioRecorder from "@/components/audio/components/AudioRecorder.vue"
import { acceptedFileTypes, getFileWithoutExtension } from "@/modules/documents/utils/documentUtils"
import FileDropzone from "@/modules/documents/components/FileDropzone.vue"
import { FileProgress } from "@/modules/documents/types/documentTypes"

const props = defineProps({
  data: {
    type: Object,
    default: () => ({}),
  },
  loading: Boolean,
})

const { t } = useI18n()
const route = useRoute()
const router = useRouter()

const employeeId = computed(() => route.query.employee_id as string)

const feedbackableId = computed(() => route.query.feedbackable_id as string)
const feedbackableType = computed(() => {
  // If editing (data exists), get type from feedback data
  if (props.data?.attributes?.feedbackable_type) {
    return props.data.attributes.feedbackable_type
  }
  // For new feedback, get from route query
  return route.query.feedbackable_type as string || FeedbackType.Employee
})

const model = ref({
  feedbackable_type: feedbackableType.value,
  feedbackable_id: feedbackableId.value,
  type: 'General',
  given_at: new Date() as Date | null,
  notes: '',
  file: null,
  file_name: undefined,
  is_visible_to_employee: false,
  audio_file: undefined,
})

const loading = ref(false)
const fileProgress = ref<FileProgress>({})

const isEdit = computed<boolean>(() => props.data?.id !== undefined)
const isEmployeeFeedback = computed(() => model.value.feedbackable_type === FeedbackType.Employee)
const isClientFeedback = computed(() => model.value.feedbackable_type === FeedbackType.Client)

const feedbackStore = useFeedbackStore()
const { feedbackName, currentFeedbackLoading } = storeToRefs(feedbackStore)

function onFeedbackTypeChange() {
  model.value.feedbackable_id = null
  model.value.is_visible_to_employee = false
}

function onEmployeeChange(employee: Data<Employee>) {
  if (!employee) {
    return
  }
  if (employee.id === props.data?.attributes?.feedbackable_id) {
    return
  }
  model.value.feedbackable_id = employee.id?.toString()
}

function onClientChange(client: Data<Client>) {
  if (!client) {
    return
  }
  if (client.id === props.data?.attributes?.feedbackable_id) {
    return
  }
  model.value.feedbackable_id = client.id?.toString()
}

function onFilesChange(files: File[]) {
  const firstFile = files[0] as File
  if (firstFile) {
    model.value.file_name = getFileWithoutExtension(firstFile.name)
  } else {
    model.value.file_name = undefined
  }
}

function onAudioConverted(audioText: string) {
  model.value.notes += audioText
}

function onFeedbackGivenAtChange(dateStr: string) {
  if (!dateStr) {
    model.value.given_at = null
    return
  }
  let date = new Date(dateStr)
  date = removeTimezoneFromDate(date)
  model.value.given_at = date
}

// const { getSaveRedirectPath } = useFilterTabs('feedback')

function getSaveRedirectPath() {
  const { feedbackable_id, feedbackable_type } = model.value

  // If editing, use the values from the feedback data
  if (isEdit.value && props.data?.attributes) {
    const type = props.data.attributes.feedbackable_type
    const id = props.data.attributes.feedbackable_id

    return type === 'employee'
      ? `/employees/${id}/feedback`
      : `/clients/${id}/feedback`
  }

  // For new feedback
  return feedbackable_type === 'employee'
    ? `/employees/${feedbackable_id}/feedback`
    : `/clients/${feedbackable_id}/feedback`
}

function prepareFormData() {
  const formData = new FormData()
  for (const key in model.value) {
    let value = model.value[key]
    if (key === 'given_at') {
      value = formatDate(value, API_DATE_FORMAT)
    }
    if (value === true || value === false) {
      value = value ? '1' : '0'
    }
    if (value !== undefined && value !== null) {
      formData.append(key, value)
    }
  }
  return formData
}

function getRedirectPath() {
  const employeePath = `/employees/${model.value.feedbackable_id}/feedback`
  const clientPath = `/clients/${model.value.feedbackable_id}/feedback`
  const map = {
    [FeedbackType.Employee]: employeePath,
    [FeedbackType.Client]: clientPath,
  }
  return map[model.value.feedbackable_type]
}

async function createOrUpdateFeedback() {
  try {
    loading.value = true
    const formData = prepareFormData()
    if (isEdit.value) {
      await feedbackStore.updateFeedback(props.data.id, formData)
    } else {
      await feedbackStore.createFeedback(formData)
    }
    await router.push(getRedirectPath())
  } catch (err: any) {
    if (err.handled) {
      return
    }
  } finally {
    loading.value = false
  }
}

const employeeStore = useEmployeeStore()
const defaultEmployee = computed(() => employeeStore.getEmployeeById(employeeId.value))

watch(() => defaultEmployee.value, (value) => {
  if (value && isEmployeeFeedback.value) {
    onEmployeeChange(value as Data<Employee>)
  }
}, { immediate: true })

onMounted(() => {
  let givenAt: any = model.value.given_at
  if (givenAt?.toISOString) {
    givenAt = givenAt.toISOString()
  }
  onFeedbackGivenAtChange(givenAt)
})

watch(() => props.data, (feedback) => {
  if (!feedback?.attributes) {
    return
  }

  model.value = {
    ...model.value,
    feedbackable_type: feedback.attributes.feedbackable_type || FeedbackType.Employee,
    feedbackable_id: feedback.attributes.feedbackable_id || employeeId.value,
    type: feedback.attributes.type || null,
    given_at: feedback.attributes.given_at || new Date(),
    notes: feedback.attributes.notes || '',
    file: feedback.attributes.file || null,
    file_name: feedback.attributes.file_name,
    is_visible_to_employee: feedback.attributes.is_visible_to_employee || false,
    audio_file: feedback.attributes.audio_file,
  }
}, { immediate: true })

async function onCancel() {
  await router.push(getRedirectPath())
}
</script>
