<template>
  <div class="h-full flex flex-col account-page">
    <PageTitle class="mb-2">
      {{ $t('Slack Integration') }}
    </PageTitle>
    <BaseFormCard
      :submit-text="$t('Save')"
      :disabled="loading"
      class="flex-1"
      @submit="onSubmit"
      @cancel="$router.push('/settings/integrations')"
    >
      <FormCardSection class="h-full">
        <div class="col-span-6 pb-2">
          <IconBox size="sm" class="mb-3">
            <SlackIcon class="w-7 h-7" />
          </IconBox>
          <div class="prose prose-sm max-w-lg mb-6">
            <span>
              {{ $t('In order to receive notifications via Slack, you first need to configure an') }}
              <a href="https://slack.com/apps/A0F7XDUAZ-incoming-webhooks" target="_blank" rel="noopener">
                {{ $t('Incoming Webhook') }}
              </a>
              {{ $t('in your slack workspace') }}
            </span>
            <p>{{ $t('Below are the events that we will send notifications for:') }}</p>
            <ul>
              <li>{{ $t('Holiday approved') }}</li>
              <li>{{ $t('Holiday rejected') }}</li>
            </ul>
          </div>
          <FormKit
            v-model="model.url"
            :label="$t('Slack Incoming Webhook URL')"
            :placeholder="$t('Enter your Slack Incoming Webhook URL')"
            name="url"
            validation="url|slackUrl"
            validation-visibility="dirty"
            :validation-messages="{
              slackUrl: $t('URL must be a valid slack webhook URL'),
            }"
          />
        </div>
      </FormCardSection>
    </BaseFormCard>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, watch } from "vue"
import { useI18n } from "vue-i18n"
import { useSettingsStore } from "@/modules/settings/store/settingsStore"
import { SettingKeys } from "@/modules/auth/types/enums"
import SlackIcon from "@/modules/settings/components/icons/SlackIcon.vue"
import { error, success } from "@/components/common/NotificationPlugin"

const loading = ref(false)
const { t } = useI18n()
const settingsStore = useSettingsStore()

const model = ref({
  url: '',
})
const slackIncomingHookUrl = computed(() => {
  return settingsStore.getSetting(SettingKeys.SlackIncomingHook) || ''
})

async function onSubmit() {
  try {
    loading.value = true
    await settingsStore.updateSetting(SettingKeys.SlackIncomingHook, model.value.url)
    success(t('Slack webhook url saved successfully'))
  } catch (e: any) {
    error(t('Could not save the slack webhook url'))
  } finally {
    loading.value = false
  }
}

async function getSettings() {
  try {
    loading.value = true
    await settingsStore.getSettings()
  } finally {
    loading.value = false
  }
}

watch(() => slackIncomingHookUrl.value, (value) => {
  model.value.url = value
}, { immediate: true })

onMounted(async () => {
  await getSettings()
})
</script>

<route lang="yaml">
name: Slack Integration
</route>
