<template>
  <div class="flex flex-col h-full">
    <EmployeeHolidayOverview :employee-id="currentEmployee.id" />
    <HolidaysTable :employee-id="currentEmployee.id" class="flex-1"/>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia";
import { useEmployeeStore } from "@/modules/employees/store/employeeStore"
import HolidaysTable from "@/modules/holidays/components/HolidaysTable.vue"
import EmployeeHolidayOverview from "@/modules/employees/components/EmployeeHolidayOverview.vue"
const { currentEmployee } = storeToRefs(useEmployeeStore())
</script>
<route lang="yaml">
name: Employee Holidays
</route>
