<template>
  <div>
    <PageTitle
      class="mb-2"
      :show-back="true"
      :loading="currentEvaluationLoading"
    >
      <template v-if="isEdit">
        {{ evaluationName }}
      </template>
      <template v-else>
        {{ $t('Create Evaluation') }}
      </template>
    </PageTitle>
    <BaseFormCard
      :submit-text="isEdit ? $t('Update') : $t('Create')"
      :disabled="loading"
      @submit="createOrUpdateEvaluation"
      @cancel="onCancel"
    >
      <FormCardSection :title="$t('Evaluation Information')">
        <FormKit
          id="employee_id"
          v-model="model.employee_id"
          type="employeeSelect"
          :disabled="employeeId"
          :label="$t('Evaluation for')"
          :placeholder="$t('John Doe')"
          outer-class="col-span-6 md:col-span-2"
          validation="required"
          @update:fullValue="onEmployeeChange"
        />

        <FormKit
          id="assignees"
          v-model="model.assignees"
          type="employeeSelect"
          multiple
          filterable
          collapse-tags
          :filter-function="filterEmployees"
          :label="$t('Assignees')"
          :placeholder="$t('John Doe')"
          outer-class="col-span-6 md:col-span-2"
          validation="required"
        />

        <FormKit
          v-model="model.type_id"
          type="evaluationTypeSelect"
          prefix-icon="cog-8-tooth"
          :label="$t('Evaluation type')"
          :placeholder="$t('Technical')"
          validation="required"
          outer-class="col-span-6 md:col-span-1"
        />
        <div class="col-span-6"/>

        <FormKit
          v-model="model.evaluated_at"
          type="customDatePicker"
          :label="$t('Evaluation date')"
          :placeholder="$t('21.05.2023')"
          validation="required"
          outer-class="col-span-6 md:col-span-2"
          @change="onEvaluatedAtChange"
        />

        <FormKit
          v-model="model.notify_at"
          type="customDatePicker"
          :label="$t('Notify date')"
          :placeholder="$t('20.05.2023')"
          outer-class="col-span-6 md:col-span-2"
          :validation="[['date_before', model.evaluated_at]]"
          validation-visibility="dirty"
        />

        <FormKit
          key="next_evaluation_at"
          v-model="model.next_evaluation_at"
          type="customDatePicker"
          :label="$t('Next evaluation date')"
          :placeholder="$t('21.05.2024')"
          outer-class="col-span-6 md:col-span-2"
        />

        <FormKit
          v-model="model.level_before_id"
          type="levelSelect"
          :label="$t('Level before')"
          :placeholder="$t('Middle')"
          outer-class="col-span-6 md:col-span-1"
        />

        <FormKit
          v-model="model.position_before_id"
          type="positionSelect"
          :label="$t('Position before')"
          :placeholder="$t('Frontend Developer')"
          outer-class="col-span-6 md:col-span-2"
        />

        <FormKit
          v-model="model.level_after_id"
          type="levelSelect"
          :label="$t('Level after')"
          :placeholder="$t('Senior')"
          outer-class="col-span-6 md:col-span-1"
        />

        <FormKit
          v-model="model.position_after_id"
          type="positionSelect"
          :label="$t('Position after')"
          :placeholder="$t('Frontend Developer')"
          outer-class="col-span-6 md:col-span-2"
        />

        <FormKit
          v-model="model.salary_before"
          type="number"
          step="0.01"
          :label="$t('Salary Before')"
          :placeholder="$t('3000 EUR')"
          validation="min:0"
          outer-class="col-span-6 md:col-span-2"
        />

        <FormKit
          v-model="model.salary_after"
          type="number"
          step="0.01"
          :label="$t('Salary After')"
          :placeholder="$t('3000 EUR')"
          validation="min:0"
          outer-class="col-span-6 md:col-span-2"
        />

        <FormKit
          v-model="model.currency"
          type="currencySelect"
          :label="$t('Currency')"
          :placeholder="$t('EUR')"
          validation="required"
          outer-class="col-span-6 md:col-span-1"
        />
      </FormCardSection>
      <FormCardSection :title="$t('Notes')">
        <FormKit
          v-model="model.external_evaluation_url"
          type="url"
          prefix-icon="arrow-top-right-on-square"
          :label="$t('Evaluation link')"
          :placeholder="$t('https://docs.google.com/document/d/1/edit')"
          outer-class="col-span-6"
        />

        <FormKit
          v-model="model.notes"
          type="html"
          :label="$t('Notes')"
          :placeholder="$t('John demonstrated strong technical skills and a willingness to continue learning and improving in their role.')"
          outer-class="col-span-6"
        />
      </FormCardSection>
    </BaseFormCard>
  </div>
</template>

<script setup lang="ts">
import { addYears, isWeekend, subDays } from "date-fns"
import { computed, onMounted, ref, watch } from "vue"
import { useI18n } from "vue-i18n"
import { useRoute, useRouter } from "vue-router"
import Cache from "@/modules/common/Cache"
import { useEvaluationStore } from "@/modules/evaluations/store/evaluationStore"
const props = defineProps({
  evaluation: {
    type: Object,
    default: () => ({}),
  },
  loading: Boolean,
})
import Data = API.Data
import { storeToRefs } from "pinia"
import PageTitle from "@/components/common/PageTitle.vue"
import { useFilterTabs } from "@/modules/common/composables/useFilterTabs"
import { useEmployeeStore } from "@/modules/employees/store/employeeStore";
import Employee = App.Domains.Employees.Models.Employee;
import { removeTimezoneFromDate } from "@/modules/common/utils/dateUtils";
const { t } = useI18n()
const route = useRoute()

const employeeId = computed(() => {
  return route.query.employee_id as string
})
const model = ref({
  employee_id: employeeId.value as string,
  type_id: null,
  evaluated_at: new Date() as Date | null,
  notify_at: new Date() as Date | null,
  next_evaluation_at: addYears(new Date(), 1) as Date | null,
  salary_before: 0,
  salary_after: 0,
  currency: 'EUR' as string | null,
  external_evaluation_url: null,
  notes: '',
  assignees: [],
  position_before_id: null as string | null,
  position_after_id: null as string | null,
  level_before_id: null as string | null,
  level_after_id: null as string | null,
})

const loading = ref(false)

const isEdit = computed<boolean>(() => {
  return props.evaluation?.id !== undefined
})

const dateValueFormat = 'YYYY-MM-DD'

const router = useRouter()
const evaluationStore = useEvaluationStore()
const { evaluationName, currentEvaluationLoading } = storeToRefs(evaluationStore)

function filterEmployees(employee: any) {
  return employee.id !== model.value.employee_id
}

function onEmployeeChange(employee: Data<Employee>) {
  if (!employee) {
    return
  }
  const { salary, salary_currency, position_id, level_id } = employee.attributes
  if (employee.id === props.evaluation?.attributes?.employee_id) {
    return
  }
  model.value.salary_before = salary || 0
  model.value.salary_after = salary || 0
  model.value.currency = salary_currency || null
  model.value.position_before_id = position_id?.toString() || null
  model.value.position_after_id = position_id?.toString() || null
  model.value.level_before_id = level_id?.toString() || null
  model.value.employee_id = employee.id?.toString()
}

function getWorkingDayBefore(dateStr: string): Date {
  const date = new Date(dateStr)
  const dayBefore = subDays(date, 1)
  if (isWeekend(dayBefore)) {
    return getWorkingDayBefore(dayBefore?.toISOString())
  }
  return dayBefore
}
function onEvaluatedAtChange(dateStr: string) {
  if (!dateStr) {
    model.value.notify_at = null
    model.value.next_evaluation_at = null
    return
  }
  let date = new Date(dateStr)
  date = removeTimezoneFromDate(date)
  model.value.evaluated_at = date
  model.value.notify_at = getWorkingDayBefore(dateStr)
  model.value.next_evaluation_at = addYears(date, 1)
}
const { getSaveRedirectPath } = useFilterTabs('evaluations')
async function createOrUpdateEvaluation() {
  try {
    loading.value = true
    if (isEdit.value) {
      await evaluationStore.updateEvaluation(model.value)
    } else {
      await evaluationStore.createEvaluation(model.value)
    }
    await router.push(getSaveRedirectPath())
  } catch (err: any) {
    if (err.handled) {
      return
    }
  } finally {
    loading.value = false
  }
}

const employeeStore = useEmployeeStore()

const defaultEmployee = computed(() => {
  return employeeStore.getEmployeeById(employeeId.value)
})

watch(() => defaultEmployee.value, (value) => {
  onEmployeeChange(value as Data<Employee>)
}, { immediate: true })

onMounted(() => {
  let evaluatedAt: any = model.value.evaluated_at
  if (evaluatedAt?.toISOString) {
    evaluatedAt = evaluatedAt.toISOString()
  }
  onEvaluatedAtChange(evaluatedAt)
})

watch(() => props.evaluation, (evaluation) => {
  if (!evaluation) {
    return
  }

  model.value = {
    ...model.value,
    ...evaluation.attributes,
  }
}, { immediate: true })

async function onCancel() {
  await router.push(getSaveRedirectPath())
}
</script>
