<template>
  <div
    v-if="totalHours > 0"
    class="flex flex-wrap items-center md:space-x-2">
    <span class="text-xs min-w-[120px] text-gray-500">
      {{ t('Task breakdown sorted by hours') }}
    </span>
    <div class="h-[36px] w-[300px] flex rounded overflow-hidden mt-2 md:mt-0">
      <BaseTooltip
        v-for="(item, index) in hourValues"
        :key="item.id"
        :content="getTooltip(item)"
      >
        <div
          :style="getStyles(item, index)"
          class="h-full"
        />
      </BaseTooltip>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { sumBy } from 'lodash-es'
import { useI18n } from 'vue-i18n'
import { getTaskColor } from '@/modules/reports/enum/reportEnums'
import { ReportItem } from "@/modules/reports/store/reportsStore";
import Data = API.Data;

const props = defineProps({
  data: {
    type: Array,
  },
})
const { t } = useI18n()
const hourValues = computed(() => {
  return props.data?.map((item: Data<ReportItem>) => {
    return {
      ...item,
      hours: item?.attributes.total_worked_minutes / 60,
    }
  })
})

const totalHours = computed(() => {
  return sumBy(hourValues.value, 'hours')
})

function getTaskPercentage(item: any) {
  return (item.hours / totalHours.value) * 100
}

function getStyles(item: any, index: number) {
  const percent = getTaskPercentage(item)
  const width = `${percent}%`
  const backgroundColor = getTaskColor(index)
  return {
    width,
    backgroundColor,
  }
}

function getTooltip(item: any) {
  const percent = getTaskPercentage(item)
  return `${item?.attributes.entity_name} (${percent.toFixed(2)}%)`
}
</script>
