<template>
  <ExpenseBundleForm :data="currentExpense" :is-edit="true"/>
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia"
import ExpenseBundleForm from "@/modules/expenses/components/ExpenseBundleForm.vue"
import { useExpenseStore } from "@/modules/expenses/store/expenseStore"

const expenseStore = useExpenseStore()
const { currentExpense } = storeToRefs(expenseStore)
</script>

<route lang="yaml">
name: Expense bundle edit
</route>
