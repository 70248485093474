<template>
  <div class="py-2 px-4 flex justify-between mr-[100px]">
    <div class="font-semibold">
      {{ $t('Totals') }}
    </div>
    <div class="flex space-x-10">
      <div class="flex items-start space-x-10 text-right font-medium">
        <div v-for="(total, status) in statusTotals" :key="status">
          <ExpenseStatus :params="{ value: status }" :use-value="true" class="!justify-end mb-1.5"/>
          <div v-for="(subtotal, currencyKey) in total" :key="currencyKey">
            {{ formatPrice(subtotal?.total_amount || 0, { currency: subtotal.currency }) }}
          </div>
        </div>
      </div>
      <div class="text-right font-semibold flex flex-col justify-end">
        <div class="mb-1.5">
          {{ $t('Total') }}
        </div>
        <div v-for="(total, index) in currencyTotals" :key="index">
          {{ formatPrice(total.amount, { currency: total.currency }) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { formatPrice } from "../../../plugins/formatPrice"
import ExpenseStatus from "@/components/table/cells/ExpenseStatus.vue"
const props = defineProps({
  params: {
    type: Object,
    default: () => ({}),
  },
})

const currencyTotals = computed<any>(() => {
  return props.params?.data?.meta?.by_currency || []
})

const statusTotals = computed(() => {
  return props.params?.data?.meta?.by_status || {}
})
</script>
