<template>
  <div>
    <PageTitle>
      {{ t('Import Employees from Excel') }}
    </PageTitle>
    <BaseFormCard
      :submit-text="t('Import')"
      :disabled="loading"
      @submit="onSubmit"
      @cancel="onCancel"
    >
      <FormCardSection :title="t('File to import')">
        <div class="flex w-full justify-end col-span-6">
          <DownloadButton
            :loading="downloadingTemplate"
            outline
            size="xs"
            @click="downloadTemplate"
          >
            {{ t('Download template') }}
          </DownloadButton>
        </div>
        <FileDropzone
          v-model="model.file"
          :multiple="false"
          :accept="acceptedFileTypes"
          class="mb-6 mt-4 col-span-6"
          @update:modelValue="onFileChange"
        />
      </FormCardSection>
      <FormCardSection
        v-if="model.file"
        :title="t('Import Preview')"
      >
        <WarningAlert class="col-span-6 my-2 text-sm">
          <span>{{ t('Please check the preview before importing employees. Note that all employees will be imported but not invited to the company. If you want to invite them, you have to do it manually using Send Invitation button.') }}</span>
        </WarningAlert>
        <SpreadsheetPreview
          :file="model.file"
          class="col-span-6"
          @file-loaded="onFileLoaded"
        />
      </FormCardSection>
    </BaseFormCard>
  </div>
</template>

<script lang="ts" setup>
import { computed, defineAsyncComponent, ref } from 'vue'
import { useI18n } from "vue-i18n"
import { useRouter } from 'vue-router'
import axios from "axios"
import PageTitle from "@/components/common/PageTitle.vue"
import FileDropzone from "@/modules/documents/components/FileDropzone.vue"
import FormCardSection from "@/components/form/FormCardSection.vue"
import DownloadButton from "@/components/common/buttons/DownloadButton.vue"
import { downloadFileFromLink } from "@/modules/common/utils/downloadFileLocally"
import { error, success } from "@/components/common/NotificationPlugin"
import WarningAlert from "@/components/common/WarningAlert.vue"
import Cache from "@/modules/common/Cache"
import { useBillingStore } from "@/modules/settings/store/billingStore"
const SpreadsheetPreview = defineAsyncComponent(() => import('@/modules/common/components/SpreadsheetPreview.vue'))

const { t } = useI18n()
const loading = ref(false)
const router = useRouter()

const acceptedFileTypes = '.xlsx,.xls'

const model = ref({
  file: null as File | null,
})

const downloadingTemplate = ref(false)

async function downloadTemplate() {
  try {
    downloadingTemplate.value = true
    const url = `${window.location.origin}/templates/employee-import.xlsx`
    downloadFileFromLink(url, 'Employee Import Template.xlsx')
  } finally {
    downloadingTemplate.value = false
  }
}

function onFileChange(file: File) {
  model.value.file = file
}

const employeeCount = ref(1)
function onFileLoaded(data: any[]) {
  employeeCount.value = data?.length
}

const billingStore = useBillingStore()
const availableSeats = computed(() => {
  return billingStore.seatData.available_seats
})

async function onSubmit() {
  try {
    if (availableSeats.value < employeeCount.value) {
      error(t('You have reached the maximum number of employees allowed by your subscription. Please upgrade your seat count to add more employees.'))
      await billingStore.showAddSeatsModal()
      return
    }
    loading.value = true
    const formData = new FormData()
    formData.append('file', model.value.file as File)
    const { data } = await axios.post('/restify/employees/actions?action=import-employees', formData)
    const message = data[0]
    if (typeof message === 'string') {
      success(t(message))
    } else {
      success(t('Employee creation in progress!'))
    }
    await billingStore.getSeatData()
    setTimeout(async () => {
      Cache.removeForEntity('employees')
      await router.push('/employees')
    }, 500)
  } finally {
    loading.value = false
  }
}

async function onCancel() {
  await router.push('/employees')
}
</script>

<route lang="yaml">
name: Import Employees
</route>
