<template>
  <div class="overflow-x-auto">
    <table class="min-w-full divide-y divide-gray-300 invoice-line-items">
      <thead>
        <tr>
          <th scope="col" class="table-col w-[50px]" />
          <th scope="col" class="table-col min-w-[220px] text-left">
            {{ t('Description') }}
          </th>
          <th scope="col" class="table-col text-right w-[100px]">
            {{ t('Quantity') }}
          </th>
          <th scope="col" class="table-col text-right w-[100px]">
            {{ t('Unit price') }}
          </th>
          <th scope="col" class="table-col text-right w-[120px]">
            {{ t('Amount') }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(lineItem, index) in _lineItems"
          :key="lineItem._local_id || index"
          :data-index="index"
        >
          <td>
            <div class="flex w-full justify-start">
              <button
                type="button"
                class="btn btn-outline btn-square btn-xs border border-gray-400 hover:bg-gray-100 hover:border-gray-400"
                @click="removeLineItem(index)"
              >
                <XMarkIcon class="w-4 h-4 text-gray-400" />
              </button>
            </div>
          </td>
          <td class="table-cell">
            <FormKit
              v-model="lineItem.name"
              :name="t(`Description ${index + 1}`)"
              type="textarea"
              validation="required"
            />
          </td>
          <td class="table-cell text-right">
            <FormKit
              v-model="lineItem.quantity"
              type="text"
              @blur="computeLineItemTotal(lineItem)"
            />
          </td>
          <td class="table-cell text-right">
            <FormKit
              v-model="lineItem.unit_price"
              type="text"
              @blur="computeLineItemTotal(lineItem)"
            />
          </td>
          <td class="table-cell text-right">
            <span>
              {{ formatPriceWithCurrency(lineItem.total) }}
            </span>
          </td>
        </tr>
        <tr class="border-t">
          <td colspan="2" class="table-cell-total min-w-[180px] !pt-4">
            <button
              type="button"
              class="btn flex btn-outline btn-xs border text-gray-600 -ml-3 font-medium border-gray-400 hover:bg-gray-100 hover:border-gray-400 hover:text-gray-700"
              @click="addLineItem"
            >
              <PlusIcon class="w-4 h-4 mr-2" />
              <span>{{ t('Add item') }}</span>
            </button>
          </td>
          <td />
          <td class="table-cell-total !pt-4 text-right">
            {{ t('Subtotal') }}
          </td>
          <td class="table-cell-total !pt-4 text-right">
            <span>{{ formatPriceWithCurrency(subTotal) }}</span>
          </td>
        </tr>
        <tr>
          <td colspan="3" />
          <td class="table-cell-total text-right">
            <span>{{ t('Tax') }}</span>
            <span v-if="taxPercent">
              ({{ taxPercent?.toFixed(2) }}%)
            </span>
          </td>
          <td class="table-cell-total text-right">
            <span>{{ formatPriceWithCurrency(taxAmount) }}</span>
          </td>
        </tr>
        <tr>
          <td colspan="3" />
          <td class="table-cell-total text-right">
            <span class="font-semibold">{{ t('Amount Due') }}</span>
          </td>
          <td class="table-cell-total text-right">
            <span class="font-semibold">{{ formatPriceWithCurrency(totalAmount) }}</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts" setup>
import { PropType, computed, inject, nextTick, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { PlusIcon, XMarkIcon } from '@heroicons/vue/24/outline'
import { cloneDeep, sumBy } from 'lodash-es'
import { LineItem } from '@/modules/invoices/types/invoiceTypes'
import { formatPrice } from '@/plugins/formatPrice'
import { useInvoiceStore } from '@/modules/invoices/store/invoiceStore'
import { useClientStore } from '@/modules/clients/store/clientStore'

const props = defineProps({
  invoice_id: {
    type: String,
  },
  invoice: {
    type: Object as PropType<any>,
    default: () => ({}),
  },
  lineItems: {
    type: Array as PropType<LineItem[]>,
    default: () => ([]),
  },
})
const { t } = useI18n()
const emptyLineItem: LineItem = {
  invoice_id: props.invoice_id,
  name: '',
  quantity: '1.00',
  unit_price: '0.00',
  total: 0,
}
const _lineItems = ref<LineItem[]>([
  {
    _local_id: crypto.randomUUID(),
    ...emptyLineItem,
  },
])

watch(() => props.lineItems, (value: LineItem[]) => {
  if (value.length) {
    _lineItems.value = cloneDeep(value)
  }
}, { immediate: true })

const clientStore = useClientStore()
const currency = inject('currency')

function formatPriceWithCurrency(price: number) {
  return formatPrice(price, {
    currency: currency.value,
  })
}

function computeLineItemTotal(lineItem: LineItem) {
  let quantity = Number(lineItem.quantity)
  let unit_price = Number(lineItem.unit_price)
  if (isNaN(quantity)) {
    quantity = 0
  }
  if (isNaN(unit_price)) {
    unit_price = 0
  }
  lineItem.quantity = quantity.toFixed(2)
  lineItem.unit_price = unit_price.toFixed(2)
  lineItem.total = +lineItem.quantity * +lineItem.unit_price
}

const taxPercent = computed(() => {
  const taxPercent = Number(props.invoice?.tax)
  if (isNaN(taxPercent)) {
    return
  }
  return taxPercent
})

const subTotal = computed(() => {
  return sumBy(_lineItems.value, 'total')
})

const taxAmount = computed(() => {
  if (!taxPercent.value) {
    return 0
  }
  return subTotal.value * (taxPercent.value / 100)
})

const totalAmount = computed(() => {
  return subTotal.value + taxAmount.value
})

async function removeLineItem(index: number) {
  const lineItem = _lineItems.value[index]
  _lineItems.value.splice(index, 1)
  if (lineItem.id) {
    await useInvoiceStore().deleteLineItem(lineItem)
  }
}

async function addLineItem() {
  _lineItems.value.push({
    _local_id: crypto.randomUUID(),
    ...emptyLineItem,
  })
  await nextTick()
  const index = _lineItems.value.length - 1
  const input = document.querySelector(`.invoice-line-items [data-index="${index}"] textarea`)
  // @ts-expect-error
  input?.focus()
}

defineExpose({
  subTotal,
  totalAmount,
  taxAmount,
  lineItems: _lineItems,
})
</script>

<style lang="scss">
.invoice-line-items {
  input[type="number"].input {
    @apply px-2 text-right;
  }
}
</style>
