<template>
  <HolidayPolicyForm
    :policy="currentPolicy"
    :loading="currentPolicyLoading"
  />
</template>
<script lang="ts" setup>
import { useSettingsStore } from "@/modules/settings/store/settingsStore";
import { storeToRefs } from "pinia";
import HolidayPolicyForm from "@/modules/settings/components/holidays/HolidayPolicyForm.vue";

const settingsStore = useSettingsStore()
const { currentPolicy, currentPolicyLoading } = storeToRefs(settingsStore)
</script>
<route lang="yaml">
name: Policy Edit
</route>
