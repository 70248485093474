import { Ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

type PreviewInitProps = {
  props: any;
  fetchFunction: (id: string) => Promise<any>;
  showDialog: Ref<boolean>
}

export function usePreviewInit({ props, fetchFunction, showDialog }: PreviewInitProps)
{
  const router = useRouter()
  const route = useRoute()

  async function onClose() {
    const path = route.path

    await router.push({
      path,
    })
  }

  watch(() => props.id, async (id: any) => {
    showDialog.value = !!id;
    await fetchFunction?.(id);
  })
  
  return {
    onClose,
  }
}
