<template>
  <div class="flex items-center space-x-3 text-sm">
    <template v-if="holidayPolicy?.attributes?.paid">
      <CheckIcon class="w-4 h-4 text-primary"/>
      <span>{{ $t('Paid') }}</span>
    </template>
    <template v-else>
      <XMarkIcon class="w-4 h-4"/>
      <span>{{ $t('Unpaid') }}</span>
    </template>
  </div>
</template>
<script setup lang="ts">
import { computed } from "vue";
import { useSettingsStore } from "@/modules/settings/store/settingsStore";
import { CheckIcon, XMarkIcon } from "@heroicons/vue/24/outline";

const props = defineProps({
  policyId: {
    type: String,
    default: '',
  }
})
const settingsStore = useSettingsStore()

const holidayPolicy = computed(() => {
  return settingsStore.getPolicyById(props.policyId)
})
</script>
