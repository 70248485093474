<template>
  <BaseSelect
    v-bind="allAttrs"
    url="/restify/expense-categories"
    value-key="id"
    label-key="attributes.name"
    filterable
    allow-create
    :transform-data="transformData"
    :model-value="value"
    @update:model-value="onChange"
  >
    <template #default="{ option }">
      <ExpenseCategoryTag :category="option.attributes" />
    </template>
  </BaseSelect>
</template>

<script setup lang="ts">
import axios from "axios"
import { orderBy } from "lodash-es"
import { storeToRefs } from "pinia"
import { useFormKitInput } from "@/components/formkit/useFormkitInput"
import Data = API.Data
import { isUuid } from "@/modules/common/utils/stringUtils"
import { useSettingsStore } from "@/modules/settings/store/settingsStore"
import DocumentTag from "@/components/data/DocumentTag.vue";
import ExpenseCategoryTag from "@/components/data/ExpenseCategoryTag.vue";

const props = defineProps({
  context: Object,
})

const settingsStore = useSettingsStore()
const { expenseCategories } = storeToRefs(settingsStore)
const { value, allAttrs, handleChange } = useFormKitInput(props)

function transformData(options: Data<any>[]) {
  const orderedOptions = orderBy(options, 'attributes.name')
  expenseCategories.value = orderedOptions as Data<any>[]
  return orderedOptions
}

async function onChange(val: string) {
  if (!val) {
    handleChange(val)
    return
  }
  const isId = isUuid(val)
  if (isId) {
    handleChange(val)
    return
  }
  const { data } = await axios.post(`/restify/expense-categories`, {
    name: val,
    requires_receipt: false,
  })
  settingsStore.expenseCategories.push(data)
  const categoryId = data?.id
  if (!categoryId) {
    return
  }
  handleChange(categoryId)
}
</script>
