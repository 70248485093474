import { VueRenderer } from '@tiptap/vue-3'
import tippy from 'tippy.js'
import { useTemplateStore } from "@/modules/templates/store/templateStore"
import VariableList from "@/components/formkit/html/VariableList.vue"
import VariableExtension from "@/components/formkit/html/variableExtension"

export const suggestion = {
  char: '::',
  items: ({ query }: any) => {
    const templateStore = useTemplateStore()
    return templateStore.getPlaceholdersForEntity(templateStore.selectedTemplateEntity).filter(item => item.label.toLowerCase().startsWith(query.toLowerCase())).slice(0, 10)
  },

  render: () => {
    let component: any
    let popup: any

    return {
      onStart: (props: any) => {
        component = new VueRenderer(VariableList, {
          // using vue 2:
          // parent: this,
          // propsData: props,
          // using vue 3:
          props,
          editor: props.editor,
        })

        if (!props.clientRect) {
          return
        }

        popup = tippy('body', {
          getReferenceClientRect: props.clientRect,
          appendTo: () => document.body,
          content: component.element,
          showOnCreate: true,
          interactive: true,
          trigger: 'manual',
          placement: 'bottom-start',
        })
      },

      onUpdate(props: any) {
        component.updateProps(props)

        if (!props.clientRect) {
          return
        }

        popup[0].setProps({
          getReferenceClientRect: props.clientRect,
        })
      },

      onKeyDown(props: any) {
        if (props.event.key === 'Escape') {
          popup[0].hide()

          return true
        }

        return component.ref?.onKeyDown(props)
      },

      onExit() {
        popup[0].destroy()
        component.destroy()
      },
    }
  },
}

export default VariableExtension.configure({
  HTMLAttributes: {
    class: 'placeholder-variable',
  },
  suggestion,
})
