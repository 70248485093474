<template>
  <BaseTooltip
    :content="tooltipContent"
    :disabled="!isTimerStarted"
  >
    <BaseButton
      :outline="!isTimerStarted"
      size="sm"
      class="-mr-2"
      :variant="isTimerStarted ? 'primary' : 'outline'"
      @click="toggleTimer"
    >
      <ClockAnimation
        class="w-4 h-4 mr-2"
        :running="isTimerStarted"
      />
      <template v-if="isTimerStarted">
        {{ $t('Stop') }}
      </template>
      <template v-else>
        {{ $t('Start') }}
      </template>
    </BaseButton>
  </BaseTooltip>
</template>

<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, ref } from "vue"
import ClockAnimation from "@/modules/time/components/ClockAnimation.vue"
import Data = API.Data
import Timesheet = App.Domains.TimeSheets.Models.Timesheet
import { useTimeSheetStore } from "@/modules/time/store/timeSheetStore"
import { success } from "@/components/common/NotificationPlugin";
import i18n from "@/i18n";
import { addMinutes, differenceInMinutes, isSameDay } from "date-fns";
const props = defineProps<Props>()
const emit = defineEmits(['update:timer_started_at', 'update:worked_minutes'])
import BaseTooltip from "@/components/common/BaseTooltip.vue"
import { minutesToHours } from "@/modules/common/utils/parseHours"
import { formatDate } from "@/modules/common/utils/dateUtils"
import { $confirm } from "@/components/common/modal/modalPlugin"
interface Props {
  time: Data<Timesheet>
  otherLogs: Data<Timesheet>[]
}

const isTimerStarted = computed(() => {
  return props.time?.attributes?.timer_started_at !== null
})

const initialWorkedMinutes = computed(() => {
  return +props.time?.attributes?.initial_worked_minutes || 0
})

const currentWorkedMinutes = computed(() => {
  return +props.time?.attributes?.worked_minutes || 0
})

const timerStartedAt = computed(() => {
  const startedAt = props.time?.attributes?.timer_started_at
  if (!startedAt) {
    return
  }
  const startedAtDate = new Date(startedAt as string)
  return addMinutes(startedAtDate, initialWorkedMinutes.value)
})

const tooltipContent = computed(() => {
  const difference = currentWorkedMinutes.value - initialWorkedMinutes.value
  const formattedStart = formatDate(timerStartedAt.value, 'HH:mm')
  return i18n.t('Timer started at', { date: formattedStart, timeAgo: minutesToHours(difference) })
})

const timesheetStore = useTimeSheetStore()
const loading = ref(false)

function isTodayTimer() {
  const date = props.time.attributes.date
  return isSameDay(new Date(), new Date(date as string))
}

async function checkTimerDay() {
  if (isTodayTimer()) {
    return true
  }
  return await $confirm({
    title: i18n.t('Timer is not started today'),
    description: i18n.t('This timer is not for today. Are you sure you want to start it?'),
    buttonText: i18n.t('Restart Timer'),
  })
}

async function toggleTimer() {
  try {
    loading.value = true
    if (!isTimerStarted.value) {
      const confirmed = await checkTimerDay()
      if (!confirmed) {
        return
      }
      await timesheetStore.startTimer(props.time, props.otherLogs)
      success(i18n.t('Timer started'))
    } else {
      await timesheetStore.stopTimer(props.time)
      success(i18n.t('Timer stopped'))
    }
  } finally {
    loading.value = false
  }
}

function checkTimer() {
  const now = new Date()
  const timerStartedAt = props.time?.attributes?.timer_started_at
  if (!timerStartedAt) {
    return
  }
  const startedAt = new Date(timerStartedAt as string)
  const diffInMinutes = differenceInMinutes(now, startedAt)
  if (diffInMinutes > 0) {
    emit('update:worked_minutes', diffInMinutes)
  }
}

const interval = ref<any>()

onMounted(() => {
  checkTimer()
  interval.value = setInterval(checkTimer, 10000)
})

onBeforeUnmount(() => {
  clearInterval(interval.value)
})
</script>

<style scoped>

</style>
