<template>
  <div>

    <h1 class="text-center">
      {{ $t('Forgot Password') }}
    </h1>
    <div class="flex justify-center space-x-2 mt-2">
      <p>{{ $t(`Changed your mind?`) }}</p>
      <RouterLink to="/login">{{ $t('Sign In') }}</RouterLink>
    </div>

    <div class="mt-10">
      <FormKit
        #default="{ state: { loading } }"
        :actions="false"
        type="form"
        class="mt-10"
        @submit="onSubmit"
      >
        <FormkitEmailInput
          v-if="!successMessage"
          v-model="model.email"
          :label="$t('Email')"
          :placeholder="$t('Enter your email')"
          validation="required|email"
          validation-visibility="dirty"
        />
        <template v-if="successMessage">
          <div class="flex justify-center mb-4 mt-10">
            <CheckIcon class="w-6 h-6 text-primary"/>
          </div>
          <p class="my-5 text-center">{{ successMessage }}</p>
        </template>

        <div class="w-full mt-12">
          <div v-if="!successMessage" class="text-base-300 mb-6 text-center w-full">
            {{ $t('A link will be sent to your email') }}
          </div>
          <FormKit
            type="submit"
            :disabled="loading"
            :classes="{ input: 'w-full' }"
          >
            <span v-if="!successMessage">{{ $t('Send email') }}</span>
            <span v-else>{{ $t('Resend email') }}</span>
            <PaperAirplaneIcon class="w-4 h-4 ml-2" />
          </FormKit>
        </div>
      </FormKit>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ArrowLongRightIcon, CheckIcon, PaperAirplaneIcon } from "@heroicons/vue/24/outline";
import { reactive, ref } from 'vue'
import { error } from '@/components/common/NotificationPlugin'
import FormkitEmailInput from '@/components/formkit/FormkitEmailInput.vue'
import { useAuthStore } from "@/modules/auth/store/authStore";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import ForgotPasswordRequestParams = Auth.ForgotPasswordRequestParams;

const router = useRouter()
const { t } = useI18n()
const model: ForgotPasswordRequestParams = reactive({
  email: '',
})

const successMessage = ref('')

const authStore = useAuthStore()

async function onSubmit() {
  try {
    await authStore.forgotPassword(model)
    successMessage.value = t(`Forgot password email sent`, {
      email: model.email,
    })
  } catch (err: any) {
    console.error(err)
    if (err.handled) {
      return
    }
    error(t('An error occurred while trying to login'))
  }
}
</script>

<route lang="yaml">
name: Forgot Password
meta:
  layout: authLayout
</route>
