<template>
  <div class="w-full flex justify-center">
    <BaseButton
      outline
      variant="primary" class="max-w-[190px] w-full text-primary-500 bg-primary/10 btn-primary flex"
      :loading="loading"
      @click="emit('click')"
    >
      <slot>
        <component :is="icon" v-if="icon && !loading" class="w-4 h-4 mr-2" />
        <span>{{ title }}</span>
      </slot>
    </BaseButton>
  </div>
</template>

<script setup lang="ts">
import { PropType } from "vue"

const props = defineProps({
  icon: {
    type: Object as PropType<any>,
  },
  title: {
    type: String,
  },
  loading: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['click'])
</script>
