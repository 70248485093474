<template>
  <div class="bg-gray-100 px-4 flex items-center h-full">
    <router-link v-if="clientId" :to="`/clients/${clientId}/details`" class="no-underline text-gray-900 font-semibold">
      <ClientAvatar :data="client"/>
    </router-link>
    <span v-else class="text-gray-900 font-semibold">
      {{ $t('No client') }}
    </span>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import ClientAvatar from "@/modules/clients/components/ClientAvatar.vue";

const props = defineProps({
  params: {
    type: Object,
  },
})

const client = computed(() => {
  return props.params?.data?.client || {}
})

const clientId = computed(() => {
  return props.params?.data?.client_id
})
</script>
