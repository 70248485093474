<template>
  <div>
    <BaseContentCard>
      <div class="flex flex-wrap space-y-2 justify-between items-center mb-6">
        <h3 class="leading-none flex items-center space-x-4">
          <HolidayPolicyIcon
            class="w-6 h-6"
            :policy-id="policyId"
          />
          <span>
            {{ holidayName }}
          </span>
        </h3>
        <div class="flex flex-1 justify-end items-center space-x-4">
          <HolidayRequestStatus :params="{ data: currentHoliday }" />
          <ActionsDropdown
            v-if="hasVisibleActions"
            :actions="holidayActions"
          >
            <template #trigger>
              <button
                class="btn btn-outline border border-input flex items-center space-x-3
              hover:bg-white hover:border-transparent hover:text-base-content hover:shadow-lg"
              >
                <span>{{ $t('Actions') }}</span>
                <ChevronDownIcon class="w-4 h-4 text-primary" />
              </button>
            </template>
          </ActionsDropdown>
        </div>
      </div>
      <ContentCardRow :title="$t('Interval')">
        <HolidayInterval :data="currentHoliday" />
      </ContentCardRow>
      <ContentCardRow :title="$t('Working Days')">
        <div class="flex space-x-3 items-center text-sm">
          <CheckIcon class="w-4 h-4 text-primary" />
          <span>{{ currentHoliday.attributes?.days_off }}</span>
        </div>
      </ContentCardRow>
    </BaseContentCard>

    <BaseContentCard class="mt-3 relative">
      <ContentCardRow :title="$t('Employee')">
        <EmployeeAvatar
          v-if="currentHoliday?.attributes?.employee_id"
          :data="employeeStore.getEmployeeById(currentHoliday?.attributes?.employee_id)"
        />
      </ContentCardRow>
      <ContentCardRow v-if="employeeRole" :title="$t('Role')">
        <div class="flex space-x-3 items-center">
          <BriefcaseIcon class="w-4 h-4 text-primary" />
          <span>{{ employeeRole?.attributes?.name }}</span>
        </div>
      </ContentCardRow>
      <ContentCardRow :title="$t('Contract Type')">
        <ContractTypeTag :contract_type="employee?.contract_type" />
      </ContentCardRow>
      <ContentCardRow :title="$t('Holiday Type')">
        <HolidayPolicyName
          :policy-id="policyId"
        />
      </ContentCardRow>
      <ContentCardRow :title="$t('Paid')">
        <HolidayPolicyPaidStatus
          :policy-id="policyId"
        />
      </ContentCardRow>
      <ContentCardRow :title="$t('Note')">
        <span class="text-sm">
          {{ currentHoliday.attributes?.notes || $t('No notes') }}
        </span>
      </ContentCardRow>
      <ContentCardRow :title="$t('Assignees')">
        <EmployeeLink
          v-if="currentHoliday?.attributes?.requested_approver_id"
          :params="{ value: currentHoliday.attributes.requested_approver_id}"
        />
        <div v-else class="text-sm">
          {{ $t('No assignees') }}
        </div>
      </ContentCardRow>
      <ContentCardRow :title="$t('Document')">
        <PendingFileInfo
          v-if="currentHoliday?.attributes?.document_path"
          :file="currentHoliday?.attributes?.document_path"
          :file-name="currentHoliday?.attributes?.file_name"
          :can-remove="false"
        />
        <div v-else class="text-sm">
          {{ $t('No document uploaded') }}
        </div>
      </ContentCardRow>

      <ContentCardRow :title="$t('Related Documents')">
        <HolidayRelatedDocuments
          :holiday="currentHoliday"
          :documents="currentHoliday?.relationships?.documents"
        />
      </ContentCardRow>

      <div class="md:absolute bottom-4 right-4">
        <CardActionButton
          v-if="showApproveButton"
          class="min-w-[150px] mt-6 md:mt-0"
          :icon="ShieldCheckIcon"
          :title="$t('Approve')"
          :loading="approveLoading"
          @click="holidayStore.approveHoliday(currentHoliday)"
        />
      </div>
    </BaseContentCard>

    <GenerateDocumentsModal
      v-if="showGenerateModal"
      v-model="showGenerateModal"
      :entity-id="currentHoliday?.id"
      :entity="TemplateEntities.Holiday"
      :employee="employee"
      @close="showGenerateModal = false"
      @save="onDocumentGenerated"
    />
  </div>
</template>

<script lang="ts" setup>
import { storeToRefs } from "pinia"
import { computed, ref } from "vue"
import {
  BriefcaseIcon,
  CalendarIcon,
  CheckIcon,
  ChevronDownIcon, DocumentPlusIcon,
  ExclamationCircleIcon,
  PencilSquareIcon,
  ShieldCheckIcon,
  XCircleIcon,
} from "@heroicons/vue/24/outline"
import { useI18n } from "vue-i18n"
import { useRouter } from "vue-router"
import { isWeekend, parseISO } from "date-fns"
import { useEmployeeStore } from "@/modules/employees/store/employeeStore"
import { useHolidayStore } from "@/modules/holidays/store/holidayStore"
import { formatDate } from "@/modules/common/utils/dateUtils"
import { useSettingsStore } from "@/modules/settings/store/settingsStore"
import HolidayPolicyIcon from "@/modules/settings/components/holidays/HolidayPolicyIcon.vue"
import EmployeeAvatar from "@/modules/employees/components/EmployeeAvatar.vue"
import ContractTypeTag from "@/modules/settings/components/ContractTypeTag.vue"
import HolidayPolicyName from "@/modules/settings/components/holidays/HolidayPolicyName.vue"
import HolidayPolicyPaidStatus from "@/modules/settings/components/holidays/HolidayPolicyPaidStatus.vue"
import EmployeeTag from "@/components/data/EmployeeTag.vue"
import PendingFileInfo from "@/modules/documents/components/PendingFileInfo.vue"
import HolidayRequestStatus from "@/components/table/cells/HolidayRequestStatus.vue"
import { isHolidayApproved, isNationalHoliday } from "@/modules/holidays/utils/holidayUtils"
import ActionsDropdown from "@/components/common/ActionsDropdown.vue"
import i18n from "@/i18n"
import { RowAction } from "@/components/table/tableTypes"
import HolidayInterval from "@/modules/holidays/components/HolidayInterval.vue"
import HolidayRelatedDocuments from "@/modules/holidays/components/HolidayRelatedDocuments.vue"
import { TemplateEntities } from "@/modules/templates/store/templateStore"
import GenerateDocumentsModal from "@/modules/employees/components/GenerateDocumentsModal.vue"
import { can } from "@/plugins/permissionPlugin";
import EmployeeLink from "@/components/table/cells/EmployeeLink.vue";

const holidayStore = useHolidayStore()
const settingsStore = useSettingsStore()
const { currentHoliday, holidayName } = storeToRefs(holidayStore)

const employeeStore = useEmployeeStore()
const showGenerateModal = ref(false)

const employee = computed(() => {
  const employee = employeeStore.getEmployeeById(currentHoliday.value?.attributes?.employee_id)
  return {
    id: employee?.id,
    relationships: employee?.relationships,
    ...(employee?.attributes || {}),
  }
})

const employeeRole = computed(() => {
  const roleId = employee.value?.role_id
  return settingsStore.getRoleById(roleId)
})

const policyId = computed<string>(() => {
  return currentHoliday.value?.attributes?.holiday_policy_id?.toString()
})
const holidayPolicy = computed(() => {
  return settingsStore.getPolicyById(policyId.value)
})

const approveLoading = ref(false)

const showApproveButton = computed(() => {
  return holidayStore.canApproveHoliday(currentHoliday.value)
})

const { t } = useI18n()
const router = useRouter()

const holidayActions = computed<RowAction[]>(() => {
  return [
    {
      icon: PencilSquareIcon,
      label: t('Edit'),
      action: async () => {
        await router.push(`/holidays/${currentHoliday.value.id}/edit`)
      },
      show: () => {
        return holidayStore.canEditHoliday(currentHoliday.value)
      },
    },
    {
      label: i18n.t('Approve'),
      icon: ShieldCheckIcon,
      action: async () => {
        await holidayStore.approveHoliday(currentHoliday.value)
      },
      show: () => {
        return holidayStore.canApproveHoliday(currentHoliday.value)
      },
    },
    {
      label: i18n.t('Reject'),
      icon: ExclamationCircleIcon,
      skipActionAwait: true,
      action: async () => {
        await holidayStore.rejectHoliday(currentHoliday.value)
      },
      show: () => {
        return holidayStore.canRejectHoliday(currentHoliday.value)
      },
    },
    {
      label: i18n.t('Generate Documents'),
      icon: DocumentPlusIcon,
      action: async () => {
        showGenerateModal.value = true
      },
      show: () => {
        return can('manageDocuments') && isHolidayApproved(currentHoliday.value)
      },
    },
    {
      label: i18n.t('Cancel Request'),
      icon: XCircleIcon,
      action: async () => {
        await holidayStore.cancelHoliday(currentHoliday.value)
      },
      show: () => {
        return holidayStore.canCancelHoliday(currentHoliday.value)
      },
    },
  ]
})

const hasVisibleActions = computed(() => {
  return holidayActions.value.filter((action: RowAction) => action.show()).length > 0
})

const { legalHolidays } = storeToRefs(settingsStore)
const activeYear = new Date().getFullYear()

function onDocumentGenerated() {
  setTimeout(async () => {
    await holidayStore.getHoliday(currentHoliday.value.id as string)
  }, 1000)
}
function isNationalHolidayDate(date: Date) {
  return isNationalHoliday({
    date,
    nationalHolidays: legalHolidays.value,
    year: activeYear,
  })
}

function isSelectedDate(date: Date) {
  const startDate = parseISO(currentHoliday.value?.attributes?.start_date)
  const endDate = parseISO(currentHoliday.value?.attributes?.end_date)
  return date >= startDate && date <= endDate
}
</script>

<style lang="scss">
.interval-calendar {
  --el-calendar-cell-width: 32px;
  max-width: 400px;
  .el-calendar-day {
    @apply p-0 flex items-center justify-center;
  }
}
</style>
