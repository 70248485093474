<template>
  <BaseFormDialog
    class="goal-form"
    :loading="loading"
    :is-edit="isEdit"
    :is-dialog="true"
    v-bind="$attrs"
    @cancel="onCancel"
    @submit="onSubmit"
  >
    <FormKit
      v-model="model.name"
      :label="$t('Goal name')"
      :placeholder="$t('Goal name')"
      validation="required"
      type="text"
      outer-class="col-span-3"
    />

    <FormKit
      v-model="model.priority"
      type="customSelect"
      :options="goalPriorities"
      :label="$t('Priority')"
      :placeholder="$t('Priority')"
      outer-class="col-span-1"
    />

    <FormKit
      v-model="model.due_date"
      type="customDatePicker"
      :label="$t('Due Date')"
      :placeholder="$t('Due Date')"
      validation="required"
      outer-class="col-span-2"
    />

    <FormKit
      v-model="model.description"
      type="html"
      :label="$t('Description')"
      :placeholder="$t('Description')"
      outer-class="col-span-6"
    />

    <FormKit
      v-model="model.progress"
      type="slider"
      :label="$t('Progress')"
      :placeholder="$t('Progress')"
      tooltip="true"
      fill-class="primary"
      :tooltip-format="tooltipFormat"
      outer-class="col-span-6"
    />
  </BaseFormDialog>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import { useI18n } from "vue-i18n"
import axios from "axios"
import { useAuth } from "@/modules/auth/composables/useAuth"
import { useFormInit } from "@/modules/common/composables/useFormInit"
import { error, success } from "@/components/common/NotificationPlugin"
import { GoalPriority, GoalProgressStatus } from "@/modules/employees/utils/employeeUtils"

const props = defineProps({
  isEdit: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  employeeId: {
    type: String,
    default: null,
  },
  data: {
    type: Object,
    default: () => ({}),
  },
})
const emit = defineEmits(['close', 'save'])
const { t } = useI18n()
const { userEmployeeId } = useAuth()
const model = ref({
  employee_id: props.employeeId,
  requested_id: userEmployeeId.value,
  name: '',
  description: '',
  progress: 0,
  progress_status: GoalProgressStatus.NotStarted,
  due_date: null,
  priority: GoalPriority.Medium,
})

const goalPriorities = computed(() => {
  return [
    {
      label: t('Low'),
      value: GoalPriority.Low,
    },
    {
      label: t('Medium'),
      value: GoalPriority.Medium,
    },
    {
      label: t('High'),
      value: GoalPriority.High,
    },
  ]
})

const loading = ref(false)

useFormInit(props, model)

function tooltipFormat(value: number) {
  if (value === 100) {
    return '😍'
  }
  return `${value}%`
}

function onCancel() {
  emit('close')
}

async function onSubmit() {
  try {
    loading.value = false
    if (model.value.progress === 0) {
      model.value.progress_status = GoalProgressStatus.NotStarted
    }
    if (model.value.progress > 0) {
      model.value.progress_status = GoalProgressStatus.InProgress
    }
    if (model.value.progress === 100) {
      model.value.progress_status = GoalProgressStatus.Completed
    }
    if (props.data.id) {
      await axios.put(`/restify/goals/${props.data.id}`, model.value)
      success(t('Goal updated successfully'))
    } else {
      await axios.post(`/restify/goals`, model.value)
      success(t('Goal created successfully'))
    }
    emit('close')
    emit('save')
  } catch (err: any) {
    if (err.handled) {
      return
    }
    error(t('Could not save the goal'))
  } finally {
    loading.value = false
  }
}
</script>

<style lang="scss">
[data-type="slider"] .formkit-tooltip,
[data-type='slider'] .formkit-fill.primary {
  background-color: theme('colors.primary');
}
[data-type="slider"] .formkit-tooltip::after {
  border-top-color: theme('colors.primary');
}
</style>
