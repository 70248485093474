<template>
  <div
    v-if="fullCategory"
    class="flex space-x-2 items-center h-full truncate">
    <span
      :style="{ backgroundColor: fullCategory.color || defaultColor }"
      class="w-2 h-2 min-w-[8px] min-h-[8px] rounded-full"
    />
    <span :title="fullCategory.name" class="capitalize text-sm truncate">
      {{ fullCategory.name }}
    </span>
  </div>
</template>

<script setup lang="ts">
import { PropType, computed } from "vue"
import { TagColors } from "@/modules/settings/types/settingTypes"
import { useSettingsStore } from "@/modules/settings/store/settingsStore"

const props = defineProps({
  id: {
    type: String,
  },
  category: {
    type: Object as PropType<any>,
  },
})

const fullCategory = computed(() => {
  if (props.category) {
    return props.category
  }
  if (!props.id) {
    return
  }
  return useSettingsStore().getExpenseCategoryById(props.id)?.attributes
})

const defaultColor = TagColors.Primary
</script>
