export function jsonToFormData(data: Record<string, any>) {
  const formData = new FormData()
  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      let value = data[key]
      if (value === null && key !== 'avatar') {
        value = ''
      }
      formData.append(key, value)
    }
  }
  return formData
}
