<template>
  <div class="h-full flex flex-col account-page">
    <BaseFormCard
      :submit-text="t('Save')"
      :disabled="loading"
      class="flex-1 h-auto"
      @submit="onSubmit"
    >
      <FormCardSection class="h-auto">
        <div class="col-span-6">
          <IconBox size="sm" class="mb-3">
            <FlagIcon class="w-4 h-4" />
          </IconBox>
          <h4 class="mb-3">
            {{ t('Language') }}
          </h4>
          <h5>{{ t('Change language') }}</h5>

          <FormKit
            v-model="model.locale"
            type="customSelect"
            :name="t('Profile Language')"
            :label="t('Profile Language')"
            :options="languageOptions"
            :help="can('manageCompany') ? t('Changing this will affect only your personal profile language') : ''"
            validation="required"
            validation-visibility="dirty"
            outer-class="col-span-6 md:col-span-4 mt-2 mb-6"
          />

          <FormKit
            v-if="$can('manageCompany')"
            v-model="model.companyLocale"
            type="customSelect"
            :name="t('Company Language')"
            :label="t('Company Language')"
            :options="languageOptions"
            :help="t('Changing this will set the default language for all company members.')"
            validation="required"
            validation-visibility="dirty"
            outer-class="col-span-6 md:col-span-4 mt-2 mb-6"
          />
        </div>
      </FormCardSection>
    </BaseFormCard>
  </div>
</template>

<script setup lang="ts">
import { computed, inject, ref, watch } from 'vue'
import { FlagIcon } from '@heroicons/vue/24/outline'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { error, success } from '@/components/common/NotificationPlugin'
import { useAuth } from '@/modules/auth/composables/useAuth'
import { useAuthStore } from '@/modules/auth/store/authStore'
import i18n, { setLocale } from '@/i18n'
import { can } from '@/plugins/permissionPlugin'

const loading = ref(false)
const { user, tenant } = useAuth()
const initialCompanyLocale = tenant.value.locale || i18n.locale
const model = ref({
  locale: user.value?.locale || i18n.locale,
  companyLocale: initialCompanyLocale,
})

watch(() => tenant.value.locale, (locale: string) => {
  model.value.companyLocale = locale
})

const { t } = useI18n()

const languageOptions = computed(() => {
  return [
    {
      label: t('English'),
      value: 'en',
    },
    {
      label: t('Romanian'),
      value: 'ro',
    },
  ]
})
const authStore = useAuthStore()

const formKitConfig: any = inject(Symbol.for('FormKitConfig'))
function onLanguageSwitch(locale: string) {
  setLocale(locale)
  formKitConfig.locale = locale
}

async function onSubmit() {
  try {
    loading.value = true
    const data = {
      ...user.value,
      locale: model.value.locale,
    }
    onLanguageSwitch(model.value.locale)
    await authStore.updateProfile(data)
    await updateCompanyLocale()
    success(t('Language has been successfully updated'))
  } catch (err: any) {
    if (err.handled) {
      return
    }
    error(t('Could not update the locale.'))
  } finally {
    loading.value = false
  }
}

async function updateCompanyLocale() {
  // Uncomment when ready
  if (can('manageCompany') && model.value.companyLocale !== initialCompanyLocale) {
    await authStore.updateCompanyLocale(model.value.companyLocale)
  }
}

const router = useRouter()
</script>

<route lang="yaml">
name: Language
</route>
