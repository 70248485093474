<template>
  <button class="mt-8 btn border-base-300 btn-outline w-full" @click="onGoogleLogin">
    <img class="w-4 h-4 mr-2" src="/logos/google.png" alt="google logo">
    <span>
      <slot>
        {{ $t('Sign in with Google ') }}
      </slot>
    </span>
  </button>
</template>

<script lang="ts" setup>
import { ROOT_API_BASE } from "@/modules/common/config";

function onGoogleLogin() {
  window.location.href = `${ROOT_API_BASE}/auth/login/google`
}
</script>
