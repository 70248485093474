<template>
  <div class="flex flex-col">
    <PageTitle :show-back="false" :show-bread-crumbs="false">
      {{ $t($route.name) }}
    </PageTitle>
    <TabLinks v-if="tabs.length > 1" v-model="activeTab" :tabs="tabs" class="mb-0" />
    <ExpenseBundlesTable :employee-id="employeeId" class="flex-1" />
  </div>
</template>

<script setup lang="ts">
import PageTitle from "@/components/common/PageTitle.vue"
import { useFilterTabs } from "@/modules/common/composables/useFilterTabs"
import ExpenseBundlesTable from "@/modules/expenses/components/ExpenseBundlesTable.vue"

const props = defineProps({
  employeeId: {
    type: String,
  },
})

const { activeTab, tabs } = useFilterTabs('expenses')
</script>

<route lang="yaml">
name: Expenses List
redirect:
  name: My Expenses
</route>
