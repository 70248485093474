<template>
  <div>
    <Tabs :tabs="tabs" />
    <router-view />
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

const { t } = useI18n()
const route = useRoute()
const tabs = computed(() => {
  const projectId = route.params.id
  return [
    {
      label: t('Tasks'),
      path: `/reports/projects/${projectId}/tasks`,
    },
    {
      label: t('Team'),
      path: `/reports/projects/${projectId}/team`,
    },
  ]
})
</script>
