<template>
  <div class="flex items-center space-x-2 min-w-[140px] md:min-w-[200px] justify-between">
    <div v-if="!visible">
      <span>*** {{ $t('Hidden') }} ***</span>
    </div>
    <div v-else>
      <div>
        <slot />
      </div>
    </div>
    <BaseTooltip
      :content="visible ? $t('Hide Details') : $t('Show Details')"
      :show-after="700"
    >
      <button
        class="hide-toggle-button"
        type="button"
        @click="visible = !visible"
      >
        <EyeSlashIcon v-if="visible" class="w-4 h-4" />
        <EyeIcon v-else class="w-4 h-4" />
      </button>
    </BaseTooltip>
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue"
import { EyeIcon, EyeSlashIcon } from "@heroicons/vue/24/outline"
import BaseTooltip from "@/components/common/BaseTooltip.vue"

const visible = ref(false)
</script>

<style lang="scss">
.hide-toggle-button {
  @apply hover:bg-primary hover:text-white p-1 rounded-md;
}
</style>
