<template>
  <FormKit v-bind="allAttrs"
           :prefix-icon="context.prefixIcon" 
           :suffix-icon="context.suffixIcon" 
           outer-class="mb-0"
  >
    <template #input>
      <BaseSelect
        v-bind="allAttrs"
        :model-value="context._value"
        @update:modelValue="handleChange"
      />
    </template>
  </FormKit>
</template>

<script setup lang="ts">
import { useFormKitInput } from "@/components/formkit/useFormkitInput";

const props = defineProps({
  context: Object,
})

const { allAttrs, handleChange } = useFormKitInput(props)
</script>
