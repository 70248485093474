<template>
  <div class="flex space-x-2 items-center">
    <span :class="statusClass" class="w-2 h-2 rounded-full" />
    <span class="capitalize text-sm">
      {{ $t(statusName) }}
    </span>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue"
import { useI18n } from "vue-i18n"
import { HolidayStatus } from "@/modules/auth/types/enums"

const props = defineProps({
  params: {
    type: Object,
    default: () => ({}),
  },
})

interface StatusMapping {
  [key: string]: string
}
const { t } = useI18n()

const statusMappings: StatusMapping = {
  [HolidayStatus.Rejected]: 'bg-red-500',
  [HolidayStatus.Approved]: 'bg-green-500',
  [HolidayStatus.Canceled]: 'bg-red-500',
  [HolidayStatus.Pending]: 'bg-yellow-500',
}

const statusName = computed(() => {
  const { approved_at, rejected_at, cancelled_at } = props.params?.data?.attributes || {}
  if (cancelled_at) {
    return HolidayStatus.Canceled
  }
  if (approved_at) {
    return HolidayStatus.Approved
  }
  if (rejected_at) {
    return HolidayStatus.Rejected
  }
  return HolidayStatus.Pending
})

const statusClass = computed<string>(() => {
  return statusMappings[statusName.value] || ''
})
</script>
