<template>
  <component
    v-if="params.value"
    :is="component"
    :to="`/settings/positions?search=${position.name}`"
    class="no-underline text-base-content"
  >
    {{ position.name }}
  </component>
  <div>
    - -
  </div>
</template>
<script lang="ts" setup>
import { computed } from "vue";
import { storeToRefs } from "pinia";
import { useSettingsStore } from "@/modules/settings/store/settingsStore";
import { useAuthStore } from "@/modules/auth/store/authStore";
import { useAuth } from "@/modules/auth/composables/useAuth";

const props = defineProps({
  params: {
    type: Object,
    default: () => ({}),
  }
})

const { can } = useAuth()
const component = computed(() => {
  if (can('managePositions')) {
    return 'router-link'
  }
  return 'div'
})

const settingsStore = useSettingsStore()
const { positions } = storeToRefs(settingsStore)

const position = computed(() => {
  return positions.value.find(p => p.id?.toString() === props.params.value?.toString())?.attributes || {}
})
</script>
