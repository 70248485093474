<template>
  <div class="flex flex-col space-y-2 mt-4">
    <div v-for="holiday in getLegalHolidaysByYear(year)"
         :key="holiday.id"
         class="flex items-center">
      <div class="flex items-center space-x-2 max-w-xs w-full">
        <National class="h-4"/>
        <span class="text-sm text-base-content">{{ holiday.attributes.name }}</span>
      </div>
      <div class="flex items-center space-x-2 max-w-xs w-full">
        <CalendarIcon class="h-4 text-primary"/>
        <span class="text-sm text-base-content">
          {{ $formatDate(holiday.attributes.date, dateFormat) }}
        </span>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { CalendarIcon } from "@heroicons/vue/24/outline";
import National from '@/assets/icons/national.svg';
import { useSettingsStore } from "@/modules/settings/store/settingsStore";
import { useI18n } from 'vue-i18n'
import { storeToRefs } from "pinia";
import { ref } from "vue";
const props = defineProps({
  year: {
    type: Number,
    required: true,
    default: new Date().getFullYear(),
  }
})
const { locale } = useI18n()
let format = "do 'of' MMMM"
if (locale.value !== 'en') {
  format = "do MMMM"
}
const dateFormat = ref(format)
const settingsStore = useSettingsStore();
const { getLegalHolidaysByYear } = storeToRefs(settingsStore);
</script>
