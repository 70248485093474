import { defineStore } from 'pinia'
import axios from "axios"
import i18n from "@/i18n"
import Cache from '@/modules/common/Cache'
import { downloadFile } from "@/modules/documents/utils/documentUtils"
import { error } from "@/components/common/NotificationPlugin"
import Data = API.Data
import { ROOT_HOST } from "@/modules/common/config"
import { copyToClipboard } from "@/plugins/copyToClipboard"

export const TemplateEntities = {
  Employee: 'employee',
  Holiday: 'holiday',
  Project: 'project',
  Client: 'client',
}
export const useTemplateStore = defineStore('templates', {
  state: () => {
    return {
      currentTemplate: {} as any,
      currentTemplateLoading: false as boolean,
      currentTemplateError: null as any,
      selectedTemplateEntity: '' as string,
      sharedTemplateToken: null as string | null,
      templateEntities: [
        {
          value: TemplateEntities.Employee,
          label: i18n.t('Employee'),
        },
        {
          value: TemplateEntities.Holiday,
          label: i18n.t('Holiday'),
        },
      ],
      templatePlaceholders: {} as Record<string, string[]>,
    }
  },
  actions: {
    async getTemplate(id: string) {
      if (!id) {
        return
      }
      try {
        this.currentTemplateLoading = true
        const { data } = await Cache.getRequest(`/restify/templates/${id}`, {
          params: {
            temporary_urls: true,
          },
        })
        this.currentTemplate = data
        this.selectedTemplateEntity = data?.attributes?.key
        this.currentTemplateError = null
      } catch (err: any) {
        this.currentTemplateError = err
      } finally {
        this.currentTemplateLoading = false
      }
    },

    async updateTemplate(data: any) {
      if (!data.id) {
        return
      }
      await axios.put(`/restify/templates/${data.id}`, data)
    },

    async createTemplate(data: any) {
      await axios.post(`/restify/templates`, data)
    },

    async downloadCurrentTemplate() {
      await this.downloadTemplate(this.currentTemplate)
    },
    async downloadTemplate(document: Data<any>) {
      try {
        const res: Blob = await axios.get(`/restify/templates/${document.id}/getters/download`, {
          responseType: 'blob',
        })
        const url = URL.createObjectURL(res)
        downloadFile(url, `${document.attributes.name}.docx`)
      } catch (err: any) {
        if (err.handled) {
          return
        }
        error(i18n.t('An error occurred while downloading the document.'))
      }
    },
    async getSharedTemplate(token: string) {
      try {
        const shareToken = token || this.sharedTemplateToken
        const { data } = await axios.get(`/shared-templates/${shareToken}`)
        this.sharedTemplateToken = null
        return data.template
      } catch (err) {
        this.sharedTemplateToken = null
        error(i18n.t('The provided template share identifier is invalid.'))
      }
    },
    async shareTemplate(id: string) {
      try {
        const { data } = await axios.post(`/restify/templates/${id}/actions?action=share`)
        const url = `${window.location.origin}/share/template/${data.token}`
        copyToClipboard(url, i18n.t('Template share link copied to clipboard.'))
        return data
      } catch (err) {
        error(i18n.t('An error occurred while sharing the template.'))
      }
    },
    async importPublicTemplate(token: string) {
      window.location.href = `${ROOT_HOST}/login?shared_template=${token}`
    },
    copyShareIdentifier(token: string) {
      copyToClipboard(token, i18n.t('Template share identifier copied to clipboard.'))
    },
    async getTemplatePlaceholders() {
      const { data } = await Cache.getRequest('restify/templates/getters/placeholders?entities=true')
      this.templatePlaceholders = data
      return data
    },
  },
  getters: {
    getPlaceholdersForEntity: state => (entity: string) => {
      let placeholders = state.templatePlaceholders[entity] || []
      const globalPlaceholders = state.templatePlaceholders.globals || []
      placeholders = placeholders.concat(globalPlaceholders)
      return placeholders.map((placeholder) => {
        return {
          value: placeholder,
          label: i18n.t(placeholder),
        }
      })
    },
  },
})
