import differenceInDays from "date-fns/differenceInDays"
import { Invoice } from "@/modules/invoices/types/invoiceTypes"
import Data = API.Data
import i18n from "@/i18n"
import { computed } from "vue"

function padNumber(num: string) {
  const currentNumber = parseInt(num, 10)
  const nextNumber = currentNumber + 1
  return nextNumber.toString().padStart(3, '0')
}
export function incrementInvoiceNumber(invoiceNumber: string): string {
  const parts = invoiceNumber.split('-')
  if (parts.length === 2 && /^[A-Z]{2}-\d{3}$/.test(invoiceNumber)) {
    const prefix = parts[0]
    const paddedNextNumber = padNumber(parts[1])
    return `${prefix}-${paddedNextNumber}`
  } else {
    return padNumber(invoiceNumber)
  }
}

export function getDueDays(date: string) {
  const parsedDate = new Date(date)
  return differenceInDays(parsedDate, new Date())
}

export function isInvoiceDue(invoice?: Data<Invoice> | null) {
  if (!invoice) {
    return false
  }
  if (invoice.attributes.paid_at) {
    return false
  }
  const dueDays = getDueDays(invoice.attributes.due_date)
  return dueDays < 0
}

export function getDueDaysText(date: string | undefined) {
  if (!date) {
    return
  }
  const daysDiff = getDueDays(date)
  if (!daysDiff || isNaN(daysDiff)) {
    return i18n.t('No due date')
  }
  if (daysDiff >= 0) {
    return i18n.t('Due in days', daysDiff)
  } else {
    return i18n.t('Due days ago', -daysDiff)
  }
}

const t = i18n.t

export function getDueDateOptions() {
  return [
    { label: t('Upon receipt'), value: 0 },
    { label: t('Net 15'), value: 15 },
    { label: t('Net 30'), value: 30 },
    { label: t('Net 45'), value: 45 },
    { label: t('Net 60'), value: 60 },
    { label: t('Custom'), value: 'custom' },
  ]
}

