<template>
  <div class="flex flex-col h-full">
    <div class="flex w-full justify-end">
      <button class="btn btn-sm btn-primary" @click="showCreateForm = true">
        <PlusIcon class="w-4 h-4 mr-1" />
        <span>{{ t('New goal') }}</span>
      </button>
    </div>
    <BaseContentCard class="mt-4 flex-1">
      <LoadingTable v-if="loadingGoals" class="mt-20" />
      <div v-if="goals.length > 0 && !loadingGoals">
        <ul role="list" class="divide-y divide-gray-100">
          <GoalCard
            v-for="goal in goals"
            :key="goal.id"
            :goal="goal"
            @edit="onEdit"
            @delete="onDeleteGoal"
          />
        </ul>
      </div>
      <EmptyState
        v-if="goals.length === 0 && !loadingGoals"
        :title="isOwnEmployee ? t('You have not set any goals') : t('There are no goals set for this employee')"
        :description="t('Use the button above to set a new goal.')"
        :icon="ChartBarSquareIcon"
        class="mt-20"
      />
      <GoalForm
        v-if="showCreateForm"
        v-model="showCreateForm"
        :employee-id="employeeId"
        :title="t('Create new goal')"
        @close="showCreateForm = false"
        @save="getGoals"
      />

      <GoalForm
        v-if="showEditDialog"
        v-model="showEditDialog"
        :data="goalToEdit"
        :employee-id="employeeId"
        :title="t('Update goal')"
        :is-edit="true"
        @close="showEditDialog = false"
        @save="getGoals"
      />
    </BaseContentCard>
  </div>
</template>

<script lang="ts" setup>
import { ChartBarSquareIcon, PlusIcon } from '@heroicons/vue/24/outline'
import { computed, onMounted, ref } from "vue"
import { useI18n } from "vue-i18n"
import { useRoute } from "vue-router"
import axios from "axios"
import { useEmployeeStore } from "@/modules/employees/store/employeeStore"
import { useAuth } from "@/modules/auth/composables/useAuth"
import GoalForm from "@/modules/employees/components/GoalForm.vue"
import GoalCard from "@/modules/employees/components/GoalCard.vue"
import { $confirm } from "@/components/common/modal/modalPlugin"

const props = defineProps({
  employeeId: {
    type: String,
    required: true,
  },
})

const { t } = useI18n()

const employeeStore = useEmployeeStore()
const route = useRoute()
const { isOwnEmployee } = useAuth()

const showCreateForm = ref(false)

const employeeId = computed(() => {
  return props.employeeId || route.params.id as string
})

const goals = computed(() => {
  return employeeStore.goals || []
})

const loadingGoals = computed(() => {
  return employeeStore.loadingGoals
})

const showEditDialog = ref(false)
const goalToEdit = ref(null)
function onEdit(goal: any) {
  goalToEdit.value = goal
  showEditDialog.value = true
}

async function onDeleteGoal(goal: any) {
  const confirm = await $confirm({
    title: t('Delete goal'),
    description: t('Are you sure you want to delete this goal?'),
    buttonText: t('Delete'),
  })
  if (!confirm) {
    return
  }
  await axios.delete(`/restify/goals/${goal.id}`)
  await getGoals()
}

async function getGoals() {
  await employeeStore.getEmployeeGoals(employeeId.value as string)
}
onMounted(() => {
  getGoals()
})
</script>

<route lang="yaml">
name: Employee Goals
</route>
