<template>
  <ElDatePicker
    v-bind="allAttrs"
    :type="pickerType"
    class="w-full"
    :format="format"
    :value-format="valueFormat"
    :model-value="value"
    :cell-class-name="cellClassName"
    @update:modelValue="onChange"
  />
</template>

<script setup lang="ts">
import { computed } from "vue"
import { storeToRefs } from "pinia"
import { isWeekend } from "date-fns"
import { useFormKitInput } from "@/components/formkit/useFormkitInput"
import { useSettingsStore } from "@/modules/settings/store/settingsStore"
import { isNationalHoliday } from "@/modules/holidays/utils/holidayUtils"
import { removeTimezoneFromDate } from "@/modules/common/utils/dateUtils";

const props = defineProps({
  context: Object,
})

const pickerType = computed(() => {
  return allAttrs.value['picker-type'] || 'date'
})

const showHolidays = computed(() => {
  return allAttrs.value['show-holidays'] || false
})

const format = computed(() => {
  if (pickerType.value === 'daterange') {
    return allAttrs.value.format
  }
  return allAttrs.value.format || 'DD.MM.YYYY'
})

const valueFormat = computed(() => {
  return allAttrs?.value.valueFormat
})

const settingsStore = useSettingsStore()
const { legalHolidays } = storeToRefs(settingsStore)
function cellClassName(date: Date) {
  const isNationalDate = isNationalHoliday({ date, nationalHolidays: legalHolidays.value })
  const isWeekendDate = isWeekend(date)
  if (isNationalDate && showHolidays.value) {
    return 'bg-primary/50'
  }
  if (isWeekendDate) {
    return 'is-weekend border border-gray-100'
  }
  return 'border border-gray-100'
}

const { allAttrs, value, handleChange } = useFormKitInput(props)

function onChange(val: Date | null) {
  if (val?.getTime()) {
    val = removeTimezoneFromDate(val)
  }
  handleChange(val)
}
</script>
