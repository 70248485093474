<template>
  <div class="flex items-center space-x-2">
    <component
      v-bind="filter"
      :is="getFilterComponent(filter.component)"
      v-for="(filter, index) in filters"
      :key="index"
      :model-value="getFilterValue(filter)"
      :filter-label="$t(filter.filterLabel as string)"
      @update:modelValue="setFilterValue(filter, $event)"
    />
    <button
      v-if="hasFilters"
      class="flex items-center justify-center rounded-sm space-x-2 text-sm hover:bg-gray-100 h-8 px-2"
      @click="resetFilters"
    >
      <span>
        {{ $t('Reset') }}
      </span>
      <XMarkIcon class="w-4 h-4 mt-0.5" />
    </button>
  </div>
</template>

<script lang="ts" setup>
import { PropType, computed, ref } from "vue"
import { XMarkIcon } from "@heroicons/vue/24/outline"
import { FilterType, getFilterComponent } from "@/components/table/filters/filterTypes"

const props = defineProps({
  filters: {
    type: Array as PropType<FilterType[]>,
    default: () => [],
  },
  modelValue: {
    type: Object,
  },
})

const emit = defineEmits(['update:modelValue'])
interface Filters {
  [key: string]: any
}
const currentFilters = ref<Filters>({})

function initFilters() {
  if (props.modelValue) {
    currentFilters.value = parseModelValue()
  }
}
initFilters()

const hasFilters = computed(() => {
  const values = Object.values(currentFilters.value)
  return values.length > 0 && values.some(value => value !== undefined && value !== '')
})
function getFilterValue(filter: FilterType) {
  return currentFilters.value[filter.key]
}

function setFilterValue(filter: FilterType, value: any) {
  currentFilters.value[filter.key] = value
  prepareModelValue()
}

function resetFilters() {
  const allKeys = Object.keys(currentFilters.value)
  for (let i = 0; i < allKeys.length; i++) {
    const key = allKeys[i]
    currentFilters.value[key] = undefined
  }
  prepareModelValue()
}

function parseValue(value: string, key: string) {
  const filter = props.filters.find(f => f.key === key)
  if (value.includes(',') || filter?.multiple) {
    return value.split(',').map(item => item.trim())
  }
  if (value === 'false') {
    return false
  }
  if (value === 'true') {
    return true
  }
  return value
}

function parseModelValue() {
  const parsedValue: any = {}
  for (const key in props.modelValue) {
    const filter = props.filters.find(filter => filter.key === key)
    if (filter) {
      parsedValue[key] = parseValue(props.modelValue[key], key)
    }
  }
  return parsedValue
}

function prepareModelValue() {
  const preparedValue: any = {}
  for (const key in currentFilters.value) {
    const value = currentFilters.value[key]
    if (Array.isArray(value)) {
      preparedValue[key] = value.join(',')
    } else {
      preparedValue[key] = value
    }
  }
  emit("update:modelValue", preparedValue)
}
</script>
