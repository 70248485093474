<template>
  <div class="mt-4">
    <el-tabs
      v-model="activeYear"
      @update:modelValue="$emit('year-change', activeYear)"
    >
      <el-tab-pane v-for="year in yearsList" :key="year" :label="year" :name="year" lazy>
        <NationalHolidayList :year="year" class="w-full" />
        <div
          class="legal-holidays mb-8 mx-auto grid max-w-3xl grid-cols-1 gap-x-8 gap-y-16 sm:grid-cols-2 xl:max-w-none xl:grid-cols-3 2xl:grid-cols-4"
        >
          <ElCalendar v-for="month in getMonths(year)" :model-value="month.date" :key="month">
            <template #header>
              <span>
                {{ getMonthName(month.date) }}
              </span>
            </template>
            <template #date-cell="{ data }">
              <div
                :class="{
                  'bg-primary text-white': isNationalHolidayDate(data.date),
                  'is-weekend': isWeekend(data.date) && !isNationalHolidayDate(data.date),
                }"
                class="w-full h-full flex items-center justify-center text-sm p-2"
              >
                {{ $formatDate(data.date, 'dd') }}
              </div>
            </template>
          </ElCalendar>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue"
import { storeToRefs } from "pinia"
import { isWeekend } from "date-fns"
import { useSettingsStore } from "@/modules/settings/store/settingsStore"
import { formatDate } from "@/modules/common/utils/dateUtils"
import { isNationalHoliday } from "@/modules/holidays/utils/holidayUtils"
import NationalHolidayList from "@/modules/settings/components/holidays/NationalHolidayList.vue"
import { getMonthName, getMonths } from "@/modules/holidays/utils/dateUtils"

const settingsStore = useSettingsStore()
const { legalHolidays } = storeToRefs(settingsStore)

const months = computed(() => {
  const arr = []
  const year = new Date().getFullYear()
  for (let i = 0; i < 12; i++) {
    arr.push({
      date: new Date(year, i, 1),
    })
  }
  return arr
})

const activeYear = ref<number>(new Date().getFullYear())

const yearsList = computed(() => {
  const arr = []
  const maxYears = 5
  const yearsBefore = Math.floor(maxYears / 2)
  const year = new Date().getFullYear()
  for (let i = 0; i < maxYears; i++) {
    arr.push(year + i - yearsBefore)
  }
  return arr
})
function isNationalHolidayDate(date: Date) {
  return isNationalHoliday({
    date,
    nationalHolidays: legalHolidays.value,
    year: activeYear.value,
  })
}
</script>

<style lang="scss">
.legal-holidays {
  .el-calendar {
    --el-calendar-border: 1px solid theme('colors.gray.200');
    --el-calendar-selected-bg-color: theme('colors.primary/10%');
    --el-fill-color-blank: transparent;
  }

  .el-calendar__header {
    @apply text-center border-b-0 flex justify-center py-0 font-semibold text-gray-900;
  }

  .el-calendar-day {
    @apply p-0;
    height: 32px;
  }

  .el-calendar-table th {
    @apply text-center text-gray-500 leading-6 py-2 text-sm;
  }

  .el-calendar__body {
    @apply p-0;
  }
  .next .el-calendar-day,
  .prev .el-calendar-day {
    @apply bg-gray-50 opacity-50;
  }
}
</style>
