import ObjectValues = API.ObjectValues;

export type FileProgress = {
  [key: string]: {
    progress: number;
    error: string;
  };
}

export const Sizes = {
  Large: 'lg',
  Medium: 'md',
  Small: 'sm',
} as const;
export type SizeType = ObjectValues<typeof Sizes>;
