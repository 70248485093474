<template>
  <div class="w-full flex justify-center items-center pt-6">
    <div class="max-w-4xl w-full">
      <template v-if="template">
        <div class="flex space-x-2 justify-end my-4">
          <BaseButton
            variant="primary"
            outline
            size="sm"
            @click="templateStore.copyShareIdentifier(templateToken)">
            {{ $t('Copy share identifier') }}
          </BaseButton>
          <BaseButton
            variant="primary"
            outline
            size="sm"
            @click="templateStore.importPublicTemplate(templateToken)">
            {{ $t('Import in your account') }}
          </BaseButton>
          <BaseButton
            variant="primary"
            size="sm"
            @click="copyTemplateContent">
            {{ $t('Copy content') }}
          </BaseButton>
        </div>
        <h1 class="text-center mt-8">
          {{ template.name }}
        </h1>
        <HtmlEditor
          ref="editorRef"
          :model-value="template?.content"
          :read-only="true"
        />
      </template>
      <div v-else class="min-h-[50vh] flex items-center justify-center">
        <LoadingTable/>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { useTemplateStore } from "@/modules/templates/store/templateStore";
import LoadingTable from "@/components/table/LoadingTable.vue";
import BaseButton from "@/components/common/buttons/BaseButton.vue";
import { success } from "@/components/common/NotificationPlugin";
import HtmlEditor from "@/components/formkit/html/HtmlEditor.vue";

const template = ref()

const route = useRoute()
const { t } = useI18n()
const templateStore = useTemplateStore()

const templateToken = computed(() => {
  return route.params.id as string
})

async function getSharedTemplate() {
  template.value = await templateStore.getSharedTemplate(templateToken.value as string)
}

const editorRef = ref()

function copyTemplateContent() {
  const htmlContent = editorRef.value?.editor?.getHTML() || '';

  // Create a temporary editable element
  const tempEl = document.createElement('div');
  tempEl.contentEditable = 'true';
  tempEl.style.position = 'absolute';
  tempEl.style.left = '-9999px'; // Position off-screen

  // Append to the body and set its content
  document.body.appendChild(tempEl);
  tempEl.innerHTML = htmlContent;

  // Select the content
  const range = document.createRange();
  range.selectNodeContents(tempEl);
  const selection = window.getSelection();
  selection?.removeAllRanges();
  selection?.addRange(range);

  // Copy the selection
  try {
    document.execCommand('copy');
    success(t('Content copied to clipboard'))
  } catch (err) {
    console.error('Failed to copy:', err);
  }
  
  selection?.removeAllRanges();
  document.body.removeChild(tempEl);
}

onMounted(() => {
  getSharedTemplate()
})
</script>

<route lang="yaml">
name: Shared Template
meta:
  layout: emptyLayout
</route>
