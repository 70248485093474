<template>
  <BaseSelect
    v-bind="allAttrs"
    value-key="code"
    label-key="name"
    :data-loading="loading"
    :options="currencyOptions"
    :label-formatter="formatLabel"
    :local-filter="true"
    filterable
    :model-value="value"
    @update:model-value="handleChange"
  />
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { useI18n } from "vue-i18n"
import { useFormKitInput } from "@/components/formkit/useFormkitInput"
import Cache from '@/modules/common/Cache'

const props = defineProps({
  context: Object,
})

function formatLabel(option: any) {
  return `${option.code} - ${option.name} (${option.symbol})`
}

const { value, allAttrs, handleChange } = useFormKitInput(props)

const currencyOptions = ref([])
const loading = ref(false)

async function getOptions() {
  try {
    loading.value = true
    currencyOptions.value = await Cache.getRequest('/restify/settings/getters/available-currencies')
  } finally {
    loading.value = false
  }
}

onMounted(async () => {
  await getOptions()
})
</script>
