import { defineStore } from 'pinia'
import axios from 'axios'
import Cache from '@/modules/common/Cache'
import Client = App.Domains.Employees.Models.Employee
import Data = API.Data

export const useClientStore = defineStore('client', {
  state: () => {
    return {
      allClients: [] as Data<Client>[],
      allClientsLoading: false as boolean,
      currentClientError: false as boolean | null,
      currentClientLoading: false as boolean,
      currentClient: {} as Client,
    }
  },
  actions: {
    async getAllClients() {
      try {
        if (this.allClientsLoading) {
          return
        }
        this.allClientsLoading = true
        const { data } = await Cache.getRequest('/restify/clients', {
          params: {
            perPage: 500,
          },
        })
        this.allClients = data
      } finally {
        this.allClientsLoading = false
      }
    },
    async getClient(id: string, invalidateCache = false) {
      if (!id) {
        return
      }
      try {
        this.currentClientError = true
        const { data } = await Cache.getRequest(`/restify/clients/${id}`, {
          invalidateCache,
          params: {
            related: 'position,user.invitations,user.roles',
          },
        })
        this.currentClient = {
          ...(data?.attributes || {}),
          meta: data.meta,
        }
        this.currentClientError = null
      } catch (err: any) {
        this.currentClientError = err
      } finally {
        this.currentClientError = false
      }
    },
    resetCurrentClient() {
      this.currentClient = {} as Client
    },
    async updateClient(data: any) {
      await axios.put(`/restify/clients/${data.id}`, data)
      await this.getClient(data.id)
    },
  },
  getters: {
    currentClientName(state) {
      const { company_name } = state.currentClient || {}
      if (!company_name) {
        return ''
      }

      return company_name
    },
    getClientById: (state) => (id: string | null) => {
      return state.allClients.find((client: any) => client.id === id)
    },
    getClientCurrency: state => (id: string) => {
      // @ts-expect-error
      return state.allClients.find(client => client.id === id)?.attributes?.prefered_currency
    },
  },
})
