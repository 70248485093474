<template>
  <BaseSelect
    v-bind="allAttrs"
    filterable
    :options="countryOptions"
    :model-value="context._value"
    @update:model-value="handleChange"
  />
</template>

<script setup lang="ts">
import { computed } from 'vue'
import countries from './countries.json'
import { useFormKitInput } from "@/components/formkit/useFormkitInput"

const props = defineProps({
  context: Object,
})

const { allAttrs, handleChange } = useFormKitInput(props)

const selectByCode = computed(() => {
  return allAttrs.value?.['select-by-code'] || false
})

const countryOptions = computed(() => {
  return countries.map((c: any) => {
    let value = c.name
    if (selectByCode.value) {
      value = c.code
    }
    return {
      value,
      label: c.name,
    }
  })
})
</script>
