<template>
  <img
    v-if="!hasError"
    :src="avatarSvg"
    :alt="name"
    loading="lazy"
    class="object-top object-cover"
    @error="onLoadError"
  >
  <span v-else class="text-white text-xs flex items-center justify-center rounded-full bg-primary">
    {{ nameChars }}
  </span>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue"

const props = defineProps({
  src: String,
  name: String,
})
const hasError = ref(false)
const avatarSvg = computed(() => {
  if (props.src && !hasError.value) {
    return props.src
  }
  const seed = props.name || 'default'
  return `https://api.dicebear.com/7.x/initials/svg?seed=${seed}`
})

const nameChars = computed(() => {
  if (!props.name) {
    return 'N/A'
  }
  return props.name.split(' ').map((word: string) => word.charAt(0)).join('')
})

function onLoadError() {
  hasError.value = true
}

watch(() => props.src, () => {
  hasError.value = false
})
</script>
