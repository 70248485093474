import { TimelineTypes } from "@/modules/dashboard/store/dashboardStore"

interface ColorMapType {
  [key: string]: {
    backgroundColor: string
    borderColor: string
  }
}
export const colorMap: ColorMapType = {
  [TimelineTypes.Birthday]: {
    backgroundColor: '#fbebfc',
    borderColor: '#de5ae3',
  },
  [TimelineTypes.Evaluation]: {
    backgroundColor: '#e8f3fc',
    borderColor: '#439ee0',
  },
  [TimelineTypes.NationalHoliday]: {
    backgroundColor: '#fde9e9',
    borderColor: '#ec484e',
  },
  [TimelineTypes.Holiday]: {
    backgroundColor: '#fdf2ea',
    borderColor: '#ef9654',
  },
  [TimelineTypes.Personal]: {
    backgroundColor: '#ecf7f8',
    borderColor: '#149ea3',
  },
  [TimelineTypes.ExternalCalendar]: {
    backgroundColor: '#039BE5',
    borderColor: '#039BE5',
  },
  default: {
    backgroundColor: '#ecf7f8',
    borderColor: '#149ea3',
  },
}
