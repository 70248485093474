<template>
  <div>
    <div class="block">
      <div class="border-b border-gray-200">
        <nav class="-mb-px flex space-x-8" aria-label="Tabs">
          <router-link
            v-for="tab in tabs"
            :key="tab.label"
            :to="tab.path"
            class="whitespace-nowrap border-b-2 py-3 px-1 text-sm font-medium" :class="[isCurrent(tab)
              ? 'border-primary text-primary no-underline'
              : 'no-underline border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700']"
            :aria-current="isCurrent(tab) ?   'page' : undefined"
          >
            {{ tab.label }}
          </router-link>
        </nav>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRoute, useRouter } from 'vue-router'

const props = defineProps({
  tabs: {
    type: Array,
    default: () => [],
  },
})

const route = useRoute()
const router = useRouter()

function isCurrent(tab) {
  return tab.path === route.path
}

async function onTabChange(path) {
  await router.push(path)
}
</script>
