<template>
  <HtmlEditor
    :model-value="value as string"
    :read-only="isReadOnly"
    :show-variables="showVariables"
    @update:model-value="handleChange"
  />
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import { useFormKitInput } from "@/components/formkit/useFormkitInput"
import HtmlEditor from "@/components/formkit/html/HtmlEditor.vue"

const props = defineProps({
  maxLimit: {
    type: Number,
    default: null,
  },
  context: Object,
})

const { allAttrs, value, handleChange } = useFormKitInput(props)
const isReadOnly = computed(() => {
  return allAttrs.value['read-only'] || false
})

const showVariables = computed(() => {
  return allAttrs.value['show-variables'] || false
})
</script>

