<template>
  <div>
    <h1 class="text-center">
      {{ $t('Sign In') }}
    </h1>
    <div class="flex justify-center space-x-2 mt-2">
      <p>{{ $t(`Don't have an account yet?`) }}</p>
      <RouterLink v-if="isRootDomain()" to="/register">{{ $t('Sign Up') }}</RouterLink>
      <a v-else :href="`${ROOT_HOST}/register`">{{ $t('Sign Up') }}</a>
    </div>
    <SocialButtons />

    <div class="mt-10">
      <FormKit
        #default="{ state: { loading } }"
        :actions="false"
        type="form"
        @submit="onSubmit"
      >
        <FormkitEmailInput
          v-model="model.email"
          :label="$t('Email')"
          name="email"
          :placeholder="$t('Enter your email')"
          validation="required|email"
          validation-visibility="dirty"
        />

        <FormKitPasswordInput
          v-model="model.password"
          :label="$t('Password')"
          :placeholder="$t('Enter your password')"
          name="password"
          validation="required"
          validation-visibility="dirty"
        />
        <div class="flex justify-end">
          <RouterLink to="/forgot-password" class="text-sm mt-1">{{ $t('> Forgot password') }}</RouterLink>
        </div>

        <div class="w-full mt-10">
          <FormKit
            type="submit"
            :classes="{ input: 'w-full' }"
            :disabled="loading"
            name="login-submit"
          >
            <span>{{ $t('Sign In') }}</span>
            <ArrowLongRightIcon class="w-4 h-4 ml-2"/>
          </FormKit>
        </div>
      </FormKit>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ArrowLongRightIcon } from "@heroicons/vue/24/outline";
import { reactive } from 'vue'
import { error } from '@/components/common/NotificationPlugin'
import FormKitPasswordInput from '@/components/formkit/FormkitPasswordInput.vue'
import FormkitEmailInput from '@/components/formkit/FormkitEmailInput.vue'
import SocialButtons from '@/modules/auth/components/SocialButtons.vue'
import { useAuthStore } from "@/modules/auth/store/authStore";
import LoginRequestParams = Auth.LoginRequestParams;
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { isRootDomain, ROOT_HOST } from "@/modules/common/config";

const router = useRouter()
const route = useRoute()
const { t } = useI18n()
const model: LoginRequestParams = reactive({
  email: '',
  password: '',
})

const authStore = useAuthStore()
const { token, loginToken } = route.query
const authToken = token || loginToken
if (authToken) {
  authStore.loginWithToken(authToken as string)
}
async function onSubmit() {
  try {
    const valid = await authStore.login(model)
    if (!valid) {
      return
    }
    if (route.query?.from) {
      await router.push(route.query.from as string)
      return
    }
    await router.push('/dashboard')
  } catch (err: any) {
    console.error(err)
    if (err.handled) {
      return
    }
    error(t('An error occurred while trying to login'))
  }
}
</script>

<route lang="yaml">
name: Login
meta:
  layout: authLayout
</route>
