<template>
  <BaseContentCard class="events-calendar h-auto flex flex-col shared-calendar">
    <div class="flex items-center space-x-5 mb-5">
      <IconBox size="sm">
        <CalendarIcon class="w-4 h-4"/>
      </IconBox>
      <h3 v-if="!loading">
        {{ title }}
      </h3>
      <div v-else class="rounded bg-gray-100 animate-pulse h-8 w-[250px]">
        
      </div>
      <div class="flex flex-1 justify-end items-center space-x-2">
        <BaseButton
          v-if="!isCalendarConnected"
          outline
          size="xs"
          variant="primary"
          @click="$router.push('/settings/integrations/personal-google-calendar')"
        >
          <GoogleCalendarIcon class="w-5 h-5 mr-2"/>
          <span>{{ $t('Connect Google Calendar') }}</span>
        </BaseButton>
        <ShareButton
          v-if="!shared"
          class="btn-xs"
          @click="sharePersonalCalendar"
        />
      </div>
    </div>
    <BaseCalendar
      :events="calendarEvents"
      :time-view="timeView"
      :options="calendarOptions"
      @date-select="onDateSelect"
      class="flex-1"/>
  </BaseContentCard>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { CalendarIcon } from '@heroicons/vue/24/outline'
import { computed, onMounted, ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useRoute } from 'vue-router'
import { TimelineTypes, useDashboardStore } from '@/modules/dashboard/store/dashboardStore'
import { addDays, parseISO } from "date-fns";
import { API_DATE_FORMAT, CalendarDateFormat, formatDate } from "@/modules/common/utils/dateUtils";
import { colorMap } from "@/modules/dashboard/enum/eventColorMap";
import { CalendarOptions, EventInput } from "@fullcalendar/core";
import BaseCalendar from "@/modules/dashboard/components/calendar/BaseCalendar.vue";
import LegalHoliday = App.Domains.LegalHolidays.Models.LegalHoliday;
import ShareButton from "@/components/common/buttons/ShareButton.vue";
import axios from "axios";
import { useClipboard } from "@vueuse/core";
import { Providers, useCalendarIntegration } from "@/modules/settings/composables/useCalendarIntegration";
import GoogleCalendarIcon from "@/modules/settings/components/icons/GoogleCalendarIcon.vue";
import { useHead } from "@vueuse/head";
import { success } from "@/components/common/NotificationPlugin";
const dashboardStore = useDashboardStore()
const { personalSharedTimelineEvents, personalSharedTimelineEventsLoading, sharedTimelineEventsLoading } = storeToRefs(dashboardStore)
const { t } = useI18n()

const props = defineProps({
  shared: {
    type: Boolean,
    default: false,
  },
  timeView: {
    type: Boolean,
    default: false,
  },
  projectId: {
    type: String,
  },
})

const filters = ref({
  start_date: undefined as string | undefined,
  end_date: undefined as string | undefined,
})

const calendarOptions = computed(() => {
  const params: CalendarOptions = {
    contentHeight: 600,
  }
  if (props.timeView) {
    params.firstDay = 0
  }
  return params
})

const {
  isCalendarConnected,
} = useCalendarIntegration(Providers.GoogleEmployee, props.shared)

const title = computed(() => {
  if (props.projectId) {
    const projectName = personalSharedTimelineEvents.value.projects || '' 
    return t('Events for', { projectName })
  }
  const employeeName = personalSharedTimelineEvents.value.employee_name || ''
  return t('Users events', { name: employeeName })
})

const loading = computed(() => {
  return personalSharedTimelineEventsLoading.value || sharedTimelineEventsLoading.value
})

useHead({
  title: title,
})

async function onDateSelect(selectInfo: any) {
  const start_date = formatDate(selectInfo.start, CalendarDateFormat)
  const end_date = formatDate(selectInfo.end, CalendarDateFormat)
  if (start_date === filters.value.start_date && end_date === filters.value.end_date) {
    return
  }
  filters.value.start_date = start_date
  filters.value.end_date = end_date
  await getData()
}


const calendarEvents = computed(() => {
  const events = personalSharedTimelineEvents.value.timeline || []
  const birthdays = personalSharedTimelineEvents.value.birthdays || []
  const legalHolidays = personalSharedTimelineEvents.value.legal_holidays || []
  let calendarEvents = personalSharedTimelineEvents.value.google_calendar_events || []
  if (props.projectId) {
    calendarEvents = []
  }
  
  const employeeEvents = events.map((event: any) => {
    let {
      start_date,
      end_date,
      type,
      created_at,
      title,
      employee,
      timelineable_type,
      timelineable_id,
    } = event.attributes

    const isHoliday = type === TimelineTypes.Holiday
    if (isHoliday && employee) {
      title = t('Holiday of', { name: employee.name })
    }
    if (!start_date) {
      start_date = created_at
    }
    if (end_date && end_date !== start_date) {
      end_date = parseISO(end_date)
      end_date = addDays(end_date, 1)
      end_date = formatDate(end_date, CalendarDateFormat)
    }
    start_date = formatDate(start_date, CalendarDateFormat)
    const { backgroundColor, borderColor } = colorMap[type] || colorMap.default
    const mappedEvent: EventInput = {
      title: t(title),
      start: start_date,
      end: end_date,
      backgroundColor,
      borderColor,
      allDay: true,
      extendedProps: {
        type,
        employee,
        timelineable_type,
        event_id: timelineable_id,
      },
    }
    return mappedEvent
  })

  const birthdayEvents = birthdays.map((item: any) => {
    const { name, birthday } = item
    const start: any = parseISO(birthday as string)
    start.setFullYear(new Date().getFullYear())
    
    return {
      title: t('Birthday of', { name }),
      start,
      end: start,
      allDay: true,
      ...colorMap[TimelineTypes.Birthday],
      extendedProps: {
        employee: item,
        type: TimelineTypes.Birthday,
      },
    }
  })
  
  const legalHolidayEvents = legalHolidays.map((holiday: LegalHoliday) => {
    const { date, name } = holiday
    return {
      title: name,
      start: date,
      end: date,
      allDay: true,
      ...colorMap[TimelineTypes.NationalHoliday],
      extendedProps: {
        type: TimelineTypes.LegalHoliday,
      },
    }
  })
  
  const otherEvents = calendarEvents.map((event: any) => {
    const { start_date, end_date } = event.attributes || {}
    return {
      title: t('busy'),
      start: start_date,
      end: end_date,
      ...colorMap[TimelineTypes.ExternalCalendar],
      extendedProps: {
        type: TimelineTypes.ExternalCalendar,
      },
    }
  })

  return [...employeeEvents, ...birthdayEvents, ...legalHolidayEvents, ...otherEvents]
})

const route = useRoute()
const CalendarTypes = {
  Personal: 'personal-calendar',
  General: 'calendar',
}

const queryParams = computed(() => {
  let type = CalendarTypes.Personal
  if (props.projectId) {
    type = CalendarTypes.General
  }
  return {
    type,
    ...route.query,
    ...filters.value,
    project_id: props.projectId,
  }
})

async function getData() {
  if (!props.shared) {
    await dashboardStore.getPersonalCalendarEvents(queryParams.value)
    return
  }
  await dashboardStore.getPersonalSharedCalendarEvents({
    id: route.params.id,
    ...queryParams.value
  })
}

async function sharePersonalCalendar() {
  const { data } = await axios.get('/calendar/share', {
    params: queryParams.value,
  })
  const apiUrl = new URL(data.url)
  const token = data.token
  let url = `${window.location.origin}/share/personal-calendar/${token}${apiUrl.search}`
  if (props.projectId) {
    url = `${window.location.origin}/share/calendar/${token}${apiUrl.search}`
  }
  const { copy } = useClipboard({ source: url })
  await copy()
  success(t('Calendar share link copied to clipboard'))
}
</script>
<style lang="scss">
.shared-calendar .base-calendar {
  @apply h-full;
}
.shared-calendar .fc-timegrid-event .calendar-event {
  @apply min-h-0 py-0 max-h-full flex items-center;
  .calendar-event-title {
    @apply text-white text-xs;
    flex-wrap: nowrap;
  }
}
</style>
