<template>
  <div class="calendar-wrapper">
    <FullCalendar
      ref="calendar"
      class="base-calendar"
      :options="calendarOptions"
    >
      <template #eventContent="data">
        <CalendarEvent :data="data" />
      </template>
    </FullCalendar>
  </div>
</template>

<script setup lang="ts">
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import listPlugin from '@fullcalendar/list'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import { PropType, computed, onMounted, ref } from 'vue'
import { useMediaQuery } from '@vueuse/core'
import { CalendarOptions, EventInput } from '@fullcalendar/core'
import enLocale from '@fullcalendar/core/locales/en-gb'
import roLocale from '@fullcalendar/core/locales/ro'
import { useI18n } from 'vue-i18n'
import CalendarEvent from '@/modules/dashboard/components/calendar/CalendarEvent.vue'

const props = defineProps({
  events: {
    type: Array as PropType<EventInput[]>,
    default: () => [],
    required: true,
  },
  timeView: {
    type: Boolean,
    default: false,
  },
  options: {
    type: Object,
    default: () => ({}),
  },
})

const emit = defineEmits(['dateSelect'])
const { locale, t } = useI18n()
const isSmallScreen = useMediaQuery('(max-width: 768px)')

const calendar = ref()
const calendarOptions = computed<CalendarOptions>(() => {
  const maxEvents = isSmallScreen.value ? 5 : 7
  let headerToolbar = {
    right: 'today listWeek listMonth dayGridMonth',
    left: 'prev,next title',
  }
  if (props.timeView) {
    headerToolbar = {
      right: 'today timeGridDay timeGridWeek',
      left: 'prev,next title',
    }
  }
  let initialView = 'dayGridMonth'
  if (isSmallScreen.value && !props.timeView) {
    initialView = 'listWeek'
  }
  if (props.timeView) {
    initialView = 'timeGridWeek'
  }
  let timeZone = 'UTC'
  if (props.timeView) {
    timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  }
  return {
    contentHeight: 'auto',
    plugins: [
      dayGridPlugin,
      timeGridPlugin,
      interactionPlugin,
      listPlugin,
    ],
    headerToolbar,
    buttonText: {
      listWeek: t('List week'),
      listMonth: t('List month'),
      dayGridMonth: t('Calendar month'),
      timeGridDay: t('Calendar day'),
      timeGridWeek: t('Calendar week'),
    },
    locales: [enLocale, roLocale],
    locale: locale.value,
    initialView,
    timeZone,
    slotDuration: '00:30:00',
    scrollTime: '07:00:00',
    stickyHeaderDates: true,
    nowIndicator: true,
    firstDay: 1,
    events: props.events,
    eventMaxStack: maxEvents,
    dayMaxEvents: maxEvents,
    datesSet: handleDateSelect,
    ...props.options,
  }
})

function handleDateSelect(selectInfo: any) {
  emit('dateSelect', selectInfo)
}

onMounted(() => {
  window.calendar = calendar.value
})
</script>

<style lang="scss">
.base-calendar {
  --fc-button-bg-color: theme('colors.transparent');
  --fc-button-border-color: theme('colors.transparent');
  --fc-button-hover-bg-color: theme('colors.primary-light');
  --fc-button-hover-border-color: theme('colors.primary');
  --fc-button-active-bg-color: theme('colors.primary-light');
  --fc-button-active-border-color: theme('colors.primary');
  --fc-button-text-color: theme('colors.base-300');
  --fc-today-bg-color: theme('colors.primary-light');

  --fc-border-color: theme('colors.base-200');

  .fc-timegrid-axis-cushion,
  .fc-timegrid-slot-label-cushion,
  .fc-daygrid-day-number,
  .fc-col-header-cell-cushion {
    @apply text-base-300 text-xs no-underline font-normal capitalize;
  }

  .fc-toolbar.fc-header-toolbar {
    @apply border border-base-200 py-2 px-2 mb-0 border-b-0 flex flex-wrap;
  }

  .fc-header-toolbar .fc-button {
    @apply text-sm capitalize;
  }

  .fc-header-toolbar .fc-button-primary:not(:disabled).fc-button-active {
    @apply text-primary;
  }

  .fc-header-toolbar .fc-button-primary:not(:disabled).fc-button-active:focus,
  .fc-header-toolbar .fc-button-primary:not(:disabled):focus {
    @apply shadow-none;
  }

  .fc-header-toolbar .fc-button-primary:not(.fc-button-active):focus {
    @apply ring-1 ring-primary;
  }

  .fc-header-toolbar .fc-toolbar-chunk:first-child {
    @apply flex justify-center items-center;

    .fc-button {
      @apply px-1 py-0.5 text-primary;
    }
  }

  .fc-header-toolbar .fc-toolbar-chunk:last-child .fc-button {
    @apply min-w-[60px] md:min-w-[90px];
  }

  .fc-timegrid-slot-minor,
  .fc-timegrid-slot.fc-timegrid-slot-label {
    @apply border-none;
  }

  .fc-header-toolbar .fc-toolbar-title {
    @apply text-sm font-semibold leading-none capitalize;
  }

  .fc-list-day-cushion.fc-cell-shaded {
    @apply bg-gray-100;
    a {
      @apply text-gray-700 no-underline text-base font-medium capitalize;
    }
  }

  .fc-list-event-time {
    @apply hidden;
  }

  .fc-list-event-graphic {
    vertical-align: inherit;
  }

  .fc-list-event-title .event-title {
    @apply text-sm;
  }

  .fc-list-empty {
    @apply bg-gray-100;
  }
}
</style>
