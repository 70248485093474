<template>
  <span class="isolate inline-flex rounded-md shadow-sm">
    <BaseButton
      variant="secondary"
      :class="{
        'bg-primary/20 !border-primary': modelValue === timeViewTypes.Daily,
      }"
      class="rounded-r-none" size="sm"
      @click="emit('update:modelValue', timeViewTypes.Daily)"
    >
      {{ $t('Day') }}
    </BaseButton>
    <BaseButton
      variant="secondary"
      :class="{
        'bg-primary/20 !border-primary': modelValue === timeViewTypes.Weekly,
      }"
      class="border-l-none rounded-l-none" size="sm"
      @click="emit('update:modelValue', timeViewTypes.Weekly)"
    >
      {{ $t('Week') }}
    </BaseButton>
  </span>
</template>

<script lang="ts" setup>
import { timeViewTypes } from "@/modules/time/enum/timeEnums"

const props = defineProps({
  modelValue: String,
})
const emit = defineEmits(['update:modelValue'])
</script>
