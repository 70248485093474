<template>
  <PreviewDialog
    v-model="showDialog"
    :name="currentTemplate?.attributes?.name"
    :loading="currentTemplateLoading"
    @closed="onClose"
  >
    <template #header-right>
      <div class="flex space-x-2 items-center">
        <ShareButton size="xs" @click="templateStore.shareTemplate(id as string)" />
        <BaseTooltip :content="$t('Download')">
          <BaseButton
            outline
            square
            type="button"
            size="sm"
            variant="primary"
            @click="templateStore.downloadCurrentTemplate"
          >
            <FolderArrowDownIcon class="w-4 h-4" />
          </BaseButton>
        </BaseTooltip>
      </div>
    </template>
    <div class="prose prose-sm mt-4 max-w-full">
      <div v-if="currentTemplateLoading" class="h-[32px] w-full bg-gray-100 rounded animate-pulse" />
      <FormKit
        v-else
        :model-value="currentTemplate?.attributes?.content"
        :read-only="true"
        type="html"
      />
    </div>
  </PreviewDialog>
</template>

<script setup lang="ts">
import { ref } from "vue"
import { storeToRefs } from "pinia"
import { FolderArrowDownIcon } from "@heroicons/vue/24/outline"
import { useTemplateStore } from "@/modules/templates/store/templateStore"
import BaseTooltip from "@/components/common/BaseTooltip.vue"
import { usePreviewInit } from "@/modules/common/composables/usePreviewInit"
import BaseButton from "@/components/common/buttons/BaseButton.vue"
import ShareButton from "@/components/common/buttons/ShareButton.vue"

const props = defineProps({
  id: {
    type: String,
  },
})

const showDialog = ref<boolean>(!!props.id)
const templateStore = useTemplateStore()
const { currentTemplate, currentTemplateLoading } = storeToRefs(templateStore)

const { onClose } = usePreviewInit({
  props,
  showDialog,
  fetchFunction: templateStore.getTemplate,
})
</script>
