import { Ref, watch } from 'vue'
export function useFormInit(props: any, model: Ref<any>, cb?: (value: any) => void) {
  watch(() => props.data, (value) => {
    if (!props.isEdit) {
      return
    }

    const attributes = value?.id && value.attributes ? value.attributes : value
    model.value = {
      id: value.id,
      ...(attributes || {}),
      ...(value?.relationships || {}),
    }
    if (cb) {
      cb(attributes)
    }
  }, { immediate: true })
}
