<template>
  <component
    :is="(isArchived || !showLink || $can('manageProjects')) ? 'div' : 'router-link'"
    v-if="row?.id && params.value"
    :to="to || `/projects/${row.id}/details`"
    class="no-underline text-base-content"
  >
    <ClientAvatar
      v-if="row?.attributes?.logo_path"
      :data="row"
      :show-name="true"
    />
    <span
      v-else
      :title="row?.attributes?.name"
      class="font-semibold truncate"
    >
      {{ row?.attributes?.name }}
    </span>
  </component>
  <div v-else>
    - -
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { storeToRefs } from 'pinia'
import ClientAvatar from "@/modules/clients/components/ClientAvatar.vue"
import { useProjectStore } from "@/modules/projects/store/projectStore"

const props = defineProps({
  params: {
    type: Object,
    default: () => ({}),
  },
  to: {
    type: [String, Object],
  },
  showLink: {
    type: Boolean,
    default: true,
  },
})

const projectStore = useProjectStore()
const { allProjects } = storeToRefs(projectStore)
const row = computed(() => {
  let projectRelationship = props.params.data?.relationships?.project
  if (!projectRelationship) {
    projectRelationship = allProjects.value.find(project => project.id?.toString() === props.params.value?.toString())
  }
  if (projectRelationship === undefined) {
    return props.params?.data
  }
  return projectRelationship || {}
})

const isArchived = computed(() => {
  return row.value.attributes?.status === 'archived'
})
</script>
