<template>
  <div class="flex-1 h-full w-full bg-white border border-base-200 rounded px-6 pt-8 pb-4 ">
    <CalendarDaysIcon
      class="w-6 h-6 text-primary map-icon"
    />
    <h5 class="leading-none mt-2 mb-1">
      {{ $t('Selected Interval') }}
    </h5>
    <div class="mt-2">
      <ContentCardRow :title="$t('Start Date')">
        <span class="text-sm text-base-300 capitalize">{{ formattedStart }}</span>
      </ContentCardRow>
      <ContentCardRow :title="$t('End Date')">
        <span class="text-sm text-base-300 capitalize">{{ formattedEnd }}</span>
      </ContentCardRow>
      <ContentCardRow :title="$t('Day Count')">
        <div v-if="selectedDaysCount" class="flex items-center text-sm mr-6">
          <CheckIcon class="text-primary w-4 h-4 mr-1" />
          <span>{{ $t('days', selectedDaysCount) }} {{ $t('of holiday') }}</span>
        </div>
        <span v-else>
          --
        </span>
      </ContentCardRow>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { CalendarDaysIcon, CheckIcon } from "@heroicons/vue/24/outline"
import { format } from "date-fns"
import { formatDate, FriendlyDateFormat } from "@/modules/common/utils/dateUtils"
import { storeToRefs } from "pinia";
import { useSettingsStore } from "@/modules/settings/store/settingsStore";
import { differenceInWorkingDays } from "@/modules/holidays/utils/holidayUtils";

const props = defineProps({
  start: {
    type: Date,
  },
  end: {
    type: Date,
  },
})

const formattedStart = computed(() => {
  if (!props.start) {
    return '--'
  }
  return formatDate(props.start, FriendlyDateFormat)
})

const formattedEnd = computed(() => {
  if (!props.end) {
    return '--'
  }
  return formatDate(props.end, FriendlyDateFormat)
})

const settingsStore = useSettingsStore()
const { legalHolidays } = storeToRefs(settingsStore)

const selectedDaysCount = computed(() => {
  if (!props.start || !props.end) {
    return ''
  }
  const start = new Date(props.start.getTime())
  const end = new Date(props.end.getTime())
  return differenceInWorkingDays(start, end, legalHolidays.value)
})
</script>

<style>
</style>
