<template>
  <WarningAlert v-if="timesheetStore.hasPastTimer" class="mb-4 ml-20">
    <div class="flex items-end">
      <div class="font-semibold">
        {{ t('You have a timer running in the past.') }}
      </div>
      <div class="text-sm text-gray-600 -mb-1">
        <BaseButton
          variant="link"
          size="xs"
          @click="goToTimesheet"
        >
          {{ $t('Navigate back to edit it') }}
        </BaseButton>
      </div>
    </div>
  </WarningAlert>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue'
import { useI18n } from "vue-i18n"
import BaseButton from "@/components/common/buttons/BaseButton.vue"
import { useTimeSheetStore } from "@/modules/time/store/timeSheetStore";

const props = defineProps({
  employeeId: {
    type: String,
  },
})
const emit = defineEmits(['changeDay'])

const { t } = useI18n()
const timesheetStore = useTimeSheetStore()

function goToTimesheet() {
  const date = new Date(timesheetStore.pastTimer?.attributes.date as string)
  emit('changeDay', date)
}

onMounted(() => {
  timesheetStore.checkForPastTimers(props.employeeId as string)
})
</script>
