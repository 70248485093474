import { ColDef, GridOptions, GridReadyEvent } from "@ag-grid-community/core"
import { Ref } from "vue"

interface ExtraProps {
  addRowOnTab: boolean
  canCopyCells: boolean
  editable: boolean
}
export function useTableEditing(grid: Ref<GridReadyEvent | null | undefined>, props: any) {
  const editableTableProps: Partial<GridOptions & ExtraProps> = {
    pagination: false,
    addRowOnTab: true,
    detailRowAutoHeight: true,
    enableRangeSelection: true,
    canCopyCells: true,
    editable: true,
    domLayout: 'autoHeight',
  }
  function addRow(row?: any) {
    if (!row && props.getEmptyRow) {
      row = props.getEmptyRow()
    }
    const firstCol = grid.value!.columnApi?.columnModel?.displayedColumns[0]
    grid.value!.api.applyTransaction({
      add: [row],
      addIndex: props.addNewRowAtTop ? 0 : undefined,
    })

    const colDefs: any = grid.value!.api.getColumnDefs()
    const colKey = colDefs.find((col: ColDef) => !col.hide)?.field
    const rowNode: any = grid.value!.api.getRowNode(row._localId)
    grid.value!.api?.setFocusedCell(rowNode.rowIndex, colKey)

    grid.value!.api?.startEditingCell({
      rowIndex: rowNode.rowIndex,
      colKey: firstCol.colId,
    })
  }

  return {
    addRow,
    editableTableProps,
  }
}
