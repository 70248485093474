<template>
  <ExpenseBundleForm />
</template>

<script setup lang="ts">
import { useHead } from "@vueuse/head"
import { useI18n } from "vue-i18n"
import ExpenseBundleForm from "@/modules/expenses/components/ExpenseBundleForm.vue";
const { t } = useI18n()

useHead({
  title: t('Create expense bundle'),
})
</script>

<route lang="yaml">
name: Create expense bundle
</route>
