<template>
  <h1 class="text-center">
    {{ $t('Signing out...') }}
  </h1>
</template>

<script setup lang="ts">
import { onMounted } from "vue"
import { useRouter } from "vue-router"
import axios from "axios"
import { useAuthStore } from "@/modules/auth/store/authStore"

const authStore = useAuthStore()
const router = useRouter()

onMounted(async () => {
  await axios.post('/logout').catch(() => {})
  authStore.logout()
  setTimeout(async () => {
    await router.push('/login')
  }, 500)
})
</script>

<route lang="yaml">
name: Sign out
meta:
  layout: authLayout
</route>
