<template>
  <component
    v-if="row?.id"
    :is="(!showLink) ? 'div' : 'router-link'"
    :to="to || `/clients/${row.id}/details`"
    class="no-underline text-base-content"
  >
    <ClientAvatar
      :data="row"
    />
  </component>
  <div v-else>
    - -
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import ClientAvatar from "@/modules/clients/components/ClientAvatar.vue";
import { useClientStore } from "@/modules/clients/store/clientStore";

const props = defineProps({
  params: {
    type: Object,
    default: () => ({}),
  },
  to: {
    type: [String, Object],
  },
  showLink: {
    type: Boolean,
    default: true,
  },
})

const clientStore = useClientStore()
const row = computed(() => {
  let clientRelationship = props.params.data?.relationships?.client
  if (!clientRelationship) {
    clientRelationship = clientStore.allClients.find(client => client.id?.toString() === props.params.value?.toString())
  }
  if (clientRelationship === undefined) {
    return props.params?.data
  }
  return clientRelationship || {}
})
</script>
