<template>
  <ExpensesPage :employee-id="userEmployeeId" />
</template>

<script setup lang="ts">
import ExpensesPage from './index.vue'
import { useAuth } from "@/modules/auth/composables/useAuth"
const { userEmployeeId } = useAuth()
</script>

<route lang="yaml">
name: My Expenses
</route>
