/**
 * Validate CNP (Romanian Personal Numeric Code)
 * @param   {string}  value CNP
 * @returns {boolean} Valid or not
 */
function validateCNP(value: string) {
  const ctrlDigits = [2, 7, 9, 1, 4, 6, 3, 5, 8, 2, 7, 9];
  let cnp = []

  let i = 0
  let year = 0
  let ctrlDigit = 0

  if (value.length !== 13) {
    return false;
  }

  for (i = 0; i < 13; i++) {
    cnp[i] = +value.charAt(i);

    if (isNaN(cnp[i])) {
      return false;
    }
    const isLastDigit = i === 12
    if (!isLastDigit) {
      ctrlDigit = ctrlDigit + (cnp[i] * ctrlDigits[i]);
    }
  }
  ctrlDigit = ctrlDigit % 11;
  if (ctrlDigit === 10) {
    ctrlDigit = 1;
  }
  year = (cnp[1] * 10) + cnp[2];
  const firstDigit = cnp[0]

  if ([1, 2].includes(firstDigit)) {
    year += 1900;
  }
  if ([3, 4].includes(firstDigit)) {
    year += 1800;
  }
  if ([5, 6].includes(firstDigit)) {
    year += 2000;
  }
  if ([7, 8, 9].includes(firstDigit)) {
    year += 2000;
    const currentYear = new Date().getFullYear()
    if (year > (currentYear - 14)) {
      year -= 100;
    }
  }
  if (year < 1800 || year > 2099) {
    return false;
  }

  const lastDigit = cnp[12]
  return lastDigit === ctrlDigit
}

export function formKitCNPValidation(node: any): boolean {
  return validateCNP(node.value);
}
