<template>
  <div class="grid grid-cols-7 gap-x-1 w-full items-center">
    <FileInfo class="col-span-3" size="md" :data="data" />
    <div class="col-span-2 truncate">
      {{ type?.attributes?.name }}
    </div>
    <div v-if="props.employeeId" class="truncate flex justify-end col-span-2">
      <EmployeeAvatar
        v-if="props.employeeId"
        :data="employeeStore.getEmployeeById(props.employeeId)"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { PropType, computed } from "vue"
import FileInfo from "@/modules/documents/components/FileInfo.vue"
import { useSettingsStore } from "@/modules/settings/store/settingsStore"
import { useEmployeeStore } from "@/modules/employees/store/employeeStore"
import EmployeeAvatar from "@/modules/employees/components/EmployeeAvatar.vue"
import Data = API.Data
import Document = App.Domains.Documents.Models.Document

const props = defineProps({
  data: {
    type: Object as PropType<Data<Document>>,
    default: () => ({}),
  },
  employeeId: {
    type: String,
  },
})

const settingsStore = useSettingsStore()
const type = computed(() => {
  const typeId = props.data?.attributes?.type_id
  return settingsStore.getDocumentTypeById(typeId)
})

const employeeStore = useEmployeeStore()
</script>
