<template>
  <BaseSelect
    v-bind="allAttrs"
    :options="evaluationTypes"
    :data-loading="evaluationTypesLoading"
    value-key="id"
    :label-formatter="formatLabel"
    filterable
    :model-value="value"
    @update:model-value="handleChange"
  />
</template>

<script setup lang="ts">
import { useFormKitInput } from "@/components/formkit/useFormkitInput";
import { storeToRefs } from "pinia";
import { useSettingsStore } from "@/modules/settings/store/settingsStore";
import EvaluationType = App.Domains.Evaluations.Models.EvaluationType;
import Data = API.Data;
import { capitalize } from "lodash-es";

const props = defineProps({
  context: Object,
})

const { value, allAttrs, handleChange } = useFormKitInput(props)
const settingsStore = useSettingsStore()
const { evaluationTypes, evaluationTypesLoading } = storeToRefs(settingsStore)

function formatLabel(option: Data<EvaluationType>) {
  return capitalize(option.attributes?.name)
}
</script>
