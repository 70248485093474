<template>
  <div ref="el">
    <ElPopover
      :visible="visible"
      trigger="click"
      popper-class="filter-popover"
      placement="bottom-start"
      width="370px"
      @after-enter="focusOnInput"
    >
      <template #reference>
        <FilterButton
          class="group"
          @click="setVisible(!visible)"
        >
          {{ filterLabel }}
          <template v-if="model?.length > 0">
            <div class="shrink-0 bg-gray-200 w-[1px] mx-2 h-4" />
            <FilterTag>
              {{ formattedRange }}
            </FilterTag>
          </template>
          <FilterClearButton
            v-if="model?.length > 0"
            @click="resetModel"
          />
        </FilterButton>
      </template>

      <div class="p-2">
        <div
          v-if="visible"
          class="fixed inset-0 bg-black-[2%]"
          @click="visible = false"
        />
        <el-date-picker
          ref="picker"
          v-model="model"
          :shortcuts="computedShortcuts"
          :value-format="valueFormat"
          :popper-class="popperClass"
          :type="type"
          range-separator="To"
          start-placeholder="Start date"
          end-placeholder="End date"
        />
      </div>
    </ElPopover>
  </div>
</template>

<script setup lang="ts">
import { computed, nextTick, ref } from 'vue'
import { useI18n } from "vue-i18n"
import {
  addDays,
  addMonths,
  format,
  parse,
  startOfMonth,
  startOfWeek,
  startOfYear,
  subDays,
  subMonths,
} from "date-fns"
import FilterButton from "@/components/table/filters/base/FilterButton.vue"
import FilterTag from "@/components/table/filters/base/FilterTag.vue"
import FilterClearButton from "@/components/table/filters/base/FilterClearButton.vue"

const props = defineProps({
  filterLabel: {
    type: [String, Object],
    default: 'Status',
  },
  modelValue: {
    type: Array,
    default: () => [],
  },
  valueFormat: {
    type: String,
    default: 'YYYY-MM-DD',
  },
  shortcuts: {
    type: Array,
    default: () => [],
  },
  popperClass: {
    type: String,
  },
  type: {
    type: String,
    default: 'daterange',
  },
})

const emit = defineEmits(['update:modelValue'])
const visible = ref(false)
const model = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
    visible.value = false
  },
})

function resetModel() {
  model.value = []
}

const formattedRange = computed(() => {
  if (props.type === 'year') {
    return model.value
  }
  if (model.value?.length === 0) {
    return
  }
  const dateFormat = 'MMM dd'
  const parseFormat = ('yyyy-MM-dd')
  const now = new Date()
  let [start, end] = model.value
  start = parse(start as string, parseFormat, now)
  end = parse(end as string, parseFormat, now)

  return `${format(start as Date, dateFormat)} - ${format(end as Date, dateFormat)}`
})

const { t } = useI18n()

const shortcuts = [
  {
    text: t('This week'),
    value: () => {
      const end = new Date()
      const weekStart = startOfWeek(end)
      return [weekStart, end]
    },
  },
  {
    text: t('Last week'),
    value: () => {
      const end = new Date()
      const lastWeekStart = subDays(startOfWeek(end), 7)
      const lastWeekEnd = addDays(lastWeekStart, 7)
      return [lastWeekStart, lastWeekEnd]
    },
  },
  {
    text: t('This month'),
    value: () => {
      const end = new Date()
      const monthStart = startOfMonth(end)
      return [monthStart, end]
    },
  },
  {
    text: t('Last month'),
    value: () => {
      const end = new Date()
      const monthStart = startOfMonth(end)
      const lastMonthStart = subMonths(monthStart, 1)
      const lastMonthEnd = addMonths(lastMonthStart, 1)
      return [lastMonthStart, lastMonthEnd]
    },
  },
  {
    text: t('This year'),
    value: () => {
      const end = new Date()
      const yearStart = startOfYear(end)
      return [yearStart, end]
    },
  },
]

const computedShortcuts = computed(() => {
  if (props.type === 'year') {
    return []
  }
  if (props.shortcuts?.length) {
    return props.shortcuts
  }
  return shortcuts
})

const picker = ref()

async function setVisible(value: boolean) {
  visible.value = value
  await nextTick()
  if (value) {
    picker.value?.handleOpen()
  }
}
function focusOnInput() {
  picker.value?.focus()
}

const el = ref()
</script>
