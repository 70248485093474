<template>
  <div class="form-control">
    <label class="flex items-center space-x-2 cursor-pointer">
      <input
        v-bind="$attrs"
        :checked="checked"
        type="checkbox"
        class="checkbox checkbox-primary"
        @change="onChange"
      >
      <span class="label-text">
        <slot>
          {{ label }}
        </slot>
      </span>
    </label>
  </div>
</template>

<script setup lang="ts">
import { PropType } from 'vue'

const props = defineProps({
  label: String,
  size: {
    type: String as PropType<`${CheckboxSizes}`>,
  },
  checked: Boolean,
})

const emit = defineEmits(['change', 'update:checked'])

enum CheckboxSizes {
  Small = 'sm',
  Medium = 'md',
  Large = 'lg',
}

function onChange(event: any) {
  emit('change', event)
  emit('update:checked', event.target.checked)
}
</script>

<script lang="ts">
export default {
  inheritAttrs: false,
}
</script>
