import { RouteRecord, Router } from "vue-router"
import { ORGANIZATION_NAME, isExceptionDomain } from "@/modules/common/config"
import { error } from "@/components/common/NotificationPlugin"
import { PermissionUnion, can } from "@/plugins/permissionPlugin"
import { useAuthStore } from "@/modules/auth/store/authStore"
import { useTemplateStore } from "@/modules/templates/store/templateStore";

const errMessage = 'You are not authorized to view this page.'

const loginRoute = {
  path: '/login',
}

function redirectToLogin(next: any, fromPath: RouteRecord | string) {
  return next(`${loginRoute.path}?from=${fromPath}`)
}

function redirectBack(next: any, to: RouteRecord) {
  error(errMessage)
  return next(to.path)
}

let subdomainValid = false
async function isOrganizationValid() {
  try {
    if (subdomainValid || isExceptionDomain(ORGANIZATION_NAME)) {
      return true
    }
    const authStore = useAuthStore()
    await authStore.getTenant()
    subdomainValid = true
    return true
  } catch (err) {
    return false
  }
}

/**
 * Middleware to check if user has the correct right to access a certain page.
 * Checks are performed based on route meta fields `meta: { requiresAuth: true }`.
 * @param {object} router Vue router instance
 */
export default function authMiddleware(router: Router) {
  router.beforeEach(async (to, from, next) => {
    const skipOrganizationCheck = to.matched.some(record => record.meta.skipOrganizationCheck)

    if (!skipOrganizationCheck) {
      const isValidOrganization = await isOrganizationValid()
      if (!isValidOrganization) {
        return next('/no-organization')
      }
    }

    const publicLayouts = ['emptyLayout', 'authLayout']
    const requiresAuth = !publicLayouts.includes(to.meta.layout as string)

    if (!requiresAuth) {
      return next()
    }
    const token = localStorage.getItem('token') || to.query.token

    if (!token) {
      const templateStore = useTemplateStore()
      if (to.query?.shared_template) {
        templateStore.sharedTemplateToken = to.query.shared_template as string
      }
      return redirectToLogin(next, to.fullPath)
    }

    const permission = to.meta.permission
    const hasPermission = can(permission as PermissionUnion)
    if (!hasPermission) {
      error('You are not authorized to view this page.')
      return next(from)
    }

    return next()
  })
}
