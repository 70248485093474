<template>
  <FilterInput
    v-bind="$attrs"
    :data="statuses"
    value-key="value"
    label-key="label"
  />
</template>

<script lang="ts" setup>
import { ref } from "vue"
import { useI18n } from "vue-i18n"
import FilterInput from "@/components/table/filters/base/FilterInput.vue"
import { HolidayStatus } from "@/modules/auth/types/enums"

const { t } = useI18n()

const statuses = ref([
  {
    value: 'active',
    label: t('Active'),
  },
  {
    value: 'archived',
    label: t('Archived'),
  },
])
</script>
