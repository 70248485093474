<template>
  <div class="h-full">
    <FeedbackTimeline
      :title="$t('Feedback Activity')"
      :feedbackable-id="currentClient.id"
      :feedbackable-type="FeedbackType.Client"
    />
  </div>
</template>

<script lang="ts" setup>
import { storeToRefs } from "pinia"
import { useClientStore } from "@/modules/clients/store/clientStore"
import FeedbackTimeline from "@/modules/feedback/components/FeedbackTimeline.vue"
import { FeedbackType } from "@/modules/feedback/store/feedbackStore";

const { currentClient } = storeToRefs(useClientStore())
</script>

<route lang="yaml">
name: Client Feedback
</route>
