<template>
  <div
    class="flex flex-col max-w-[250px] w-full items-end h-full justify-center pt-2 pr-4"
  >
    <span class="text-sm text-gray-600">{{ title }}</span>
    <span v-if="currentReportLoading" class="h-7 bg-gray-100 animate-pulse w-24 rounded"/>
    <span v-else class="text-xl font-semibold">
      <slot>
        {{ value }}
      </slot>
    </span>
  </div>
</template>

<script lang="ts" setup>
import { storeToRefs } from "pinia"
import { formatPrice } from "@/plugins/formatPrice"
import { useReportsStore } from "@/modules/reports/store/reportsStore"

const props = defineProps({
  title: {
    type: String,
  },
  value: {
    type: [String, Number],
  }
})
const reportsStore = useReportsStore()
const {
  currentReportLoading,
  currentReportTotalCost,
} = storeToRefs(reportsStore)
</script>

<style>
</style>
