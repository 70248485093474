<template>
  <div>
    <InvoiceForm
      :invoice="invoiceStore.currentInvoice as Data<Invoice>"
      :is-edit="true"
    />
  </div>
</template>

<script lang="ts" setup>
import InvoiceForm from "@/modules/invoices/components/InvoiceForm.vue"
import { useInvoiceStore } from "@/modules/invoices/store/invoiceStore"
import Data = API.Data
import { Invoice } from "@/modules/invoices/types/invoiceTypes"
const invoiceStore = useInvoiceStore()
</script>
