<template>
  <table class="min-w-full divide-y divide-gray-300 invoice-line-items">
    <thead>
      <tr>
        <th scope="col" class="table-col text-left">
          {{ t('Description') }}
        </th>
        <th scope="col" class="table-col text-right w-[100px]">
          {{ t('Quantity') }}
        </th>
        <th scope="col" class="table-col text-right w-[100px]">
          {{ t('Unit price') }}
        </th>
        <th scope="col" class="table-col text-right w-[120px]">
          {{ t('Amount') }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(lineItem, index) in lineItems"
        :key="index"
        class="border-b border-gray-300 even:bg-gray-50"
      >
        <td class="table-cell-sm border-r border-gray-300">
          {{ lineItem.name }}
        </td>
        <td class="table-cell-sm text-right border-r border-gray-300">
          {{ lineItem.quantity }}
        </td>
        <td class="table-cell-sm text-right border-r border-gray-300">
          {{ formatPriceWithCurrency(lineItem.unit_price) }}
        </td>
        <td class="table-cell-sm text-right border-l border-gray-300">
          {{ formatPriceWithCurrency(lineItem.total) }}
        </td>
      </tr>
      <tr>
        <td colspan="2" />
        <td
          :class="{ '!pt-4': !taxPercent }"
          class="table-cell-total text-right">
          <span>{{ t('Subtotal') }}</span>
        </td>
        <td
          :class="{ '!pt-4': !taxPercent }"
          class="table-cell-total text-right">
          <span>{{ formatPriceWithCurrency(invoice?.attributes?.subtotal) }}</span>
        </td>
      </tr>
      <tr v-if="taxPercent">
        <td colspan="2" />
        <td class="table-cell-total text-right">
          <span>{{ t('Tax') }}</span>
          <span v-if="taxPercent">
            ({{ taxPercent?.toFixed(2) }}%)
          </span>
        </td>
        <td class="table-cell-total text-right">
          <span>{{ formatPriceWithCurrency(taxAmount) }}</span>
        </td>
      </tr>
      <tr v-if="invoice?.attributes.paid_at">
        <td colspan="2" />
        <td
          class="table-cell-total text-right">
          <span>{{ t('Payments') }}</span>
        </td>
        <td
          class="table-cell-total text-right">
          <span>-{{ formatPriceWithCurrency(invoice?.attributes?.total) }}</span>
        </td>
      </tr>
      <tr>
        <td colspan="2" />
        <td
          class="table-cell-total text-right">
          <span class="font-semibold">{{ t('Amount Due') }}</span>
        </td>
        <td
          class="table-cell-total text-right">
          <span class="font-semibold">{{ formatPriceWithCurrency(openAmount) }}</span>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script lang="ts" setup>
import { PropType, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { LineItem } from '@/modules/invoices/types/invoiceTypes'
import { formatPrice } from '@/plugins/formatPrice'

const props = defineProps({
  invoice_id: {
    type: String,
  },
  invoice: {
    type: Object as PropType<any>,
    default: () => ({}),
  },
  lineItems: {
    type: Array as PropType<LineItem[]>,
    default: () => ([]),
  },
})
const { t } = useI18n()
const currency = computed(() => {
  return props.invoice?.attributes?.currency
})

function formatPriceWithCurrency(price: number) {
  return formatPrice(price, {
    currency: currency.value,
  })
}

const taxPercent = computed(() => {
  const taxPercent = Number(props.invoice?.attributes?.tax)
  if (isNaN(taxPercent)) {
    return
  }
  return taxPercent
})

const taxAmount = computed(() => {
  if (!taxPercent.value) {
    return props.invoice?.attributes?.subtotal
  }
  return props.invoice?.attributes?.subtotal * (taxPercent.value / 100)
})
const openAmount = computed(() => {
  if (props.invoice?.attributes?.paid_at) {
    return 0
  }
  return props.invoice?.attributes?.total
})
</script>
