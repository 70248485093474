<template>
  <ReportData
    :report-type="ReportTypes.Projects"
    :show-employees-without-logs="true"
  />
</template>

<script setup lang="ts">
import { useRoute } from 'vue-router'
import { storeToRefs } from 'pinia'
import { ReportTypes, useReportsStore } from '@/modules/reports/store/reportsStore'
import ReportData from '@/modules/reports/components/ReportData.vue'
import { useProjectStore } from "@/modules/projects/store/projectStore";

const reportsStore = useReportsStore()
const projectStore = useProjectStore()
const { currentReport } = storeToRefs(reportsStore)
const route = useRoute()
currentReport.value.employee_id = route.params.id as string
</script>

<route lang="yaml">
name: Team projects report
</route>
