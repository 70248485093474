<template>
  <input
    v-bind="$attrs"
    type="checkbox"
    class="toggle toggle-primary"
    :checked="modelValue"
    @click="triggerToggle"
    @keydown.enter.prevent="triggerToggle"
    @keydown.space.prevent="triggerToggle"
    @blur="$emit('blur', $event)"
  >
</template>

<script setup lang="ts">
const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['update:modelValue', 'blur'])

function triggerToggle() {
  emit('update:modelValue', !props.modelValue)
}
</script>

<script lang="ts">
export default {
  inheritAttrs: false,
}
</script>
