import { App, ref } from 'vue'
import { createI18n } from 'vue-i18n'
import en from './locales/en.json'
import elementEn from 'element-plus/dist/locale/en.mjs'
import elementRo from 'element-plus/dist/locale/ro.mjs'

const elementLocaleMap: any = {
  'en': elementEn,
  'ro': elementRo,
}
const locale = localStorage.getItem('locale') || 'en'
type MessageSchema = typeof en

const i18n = createI18n<[MessageSchema]>({
  legacy: false,
  locale: locale,
  fallbackLocale: 'en',
  warnHtmlInMessage: 'off',
  missingWarn: false,
  fallbackWarn: false,
})

export async function loadLocaleMessages(locale: string) {
  if (!locale) {
    return
  }
  const messages = await import(`./locales/${locale}.json`)
  i18n.global.setLocaleMessage(locale, messages.default)
}

loadLocaleMessages(locale).catch((err: any) => console.error('Could not load locale', err))

export const elementLocale = ref(elementEn)
setElementLocale(locale)

function setElementLocale(locale: string) {
  elementLocale.value = elementLocaleMap[locale] || elementLocaleMap.en
}

export function setLocale(locale: string) {
  loadLocaleMessages(locale).then(() => {
    i18n.global.locale.value = locale
    setElementLocale(locale)
    localStorage.setItem('locale', locale)
  })
}

export default {
  i18n,
  install(app: App) {
    app.config.globalProperties.$t = i18n.global.t
    app.config.globalProperties.$tc = i18n.global.tc
    app.config.globalProperties.$i18n = i18n
    app.use(i18n)
  },
  t: i18n.global.t,
  locale: i18n.global.locale,
}
