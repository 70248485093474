<template>
  <div>
    <SendInvoiceDialog
      v-if="isModalOpen"
      v-model="isModalOpen"
      :invoice-id="invoiceId"
      @close="isModalOpen = false"
      @cancel="isModalOpen = false"
      @submit="updateInvoiceStatus"
    />
    <BaseButton
      v-bind="$attrs"
      variant="primary"
      size="xs"
      class="print:hidden"
      :loading="sending"
      @click="isModalOpen = true"
    >
      <PaperAirplaneIcon class="w-4 h-4 mr-2"/>
      <span>{{ t('Send invoice') }}</span>
    </BaseButton>
  </div>
</template>

<script setup lang="ts">
import { PaperAirplaneIcon } from '@heroicons/vue/24/outline'
import { useI18n } from 'vue-i18n'
import { ref } from 'vue'
import { useRoute } from 'vue-router'
import { useInvoiceStore } from '@/modules/invoices/store/invoiceStore'
import SendInvoiceDialog from "@/modules/invoices/components/SendInvoiceDialog.vue";
import { InvoiceStatus } from "@/modules/invoices/types/invoicEnums";

const props = defineProps({
  invoiceId: {
    type: String,
    required: true,
  },
})
const invoiceStore = useInvoiceStore()
const { t } = useI18n()
const sending = ref(false)
const route = useRoute()
const isModalOpen = ref(false)

function updateInvoiceStatus() {
  invoiceStore.currentInvoice!.attributes.status = InvoiceStatus.Sent
  isModalOpen.value = false
}
</script>
