<template>
  <div class="h-full flex flex-col data-page">
    <PageTitle class="mb-2">
      {{ $t('Company data') }}
    </PageTitle>
    <BaseFormCard
      :submit-text="$t('Save')"
      :disabled="loading"
      :show-actions="false"
      class="flex-1"
    >
      <FormCardSection class="h-full">
        <div class="col-span-6">
          <IconBox size="sm" class="mb-3">
            <CloudArrowDownIcon class="w-4 h-4" />
          </IconBox>
          <h4 class="mb-3">
            {{ $t('Data') }}
          </h4>
          <h5>{{ $t('Download all documents') }}</h5>

          <FormKit
            type="button"
            :classes="{ input: 'min-w-[128px] mt-4' }"
            :disabled="loading"
            @click="downloadAll"
          >
            <CloudArrowDownIcon class="w-4 h-4 mr-2" />
            <span>{{ $t('Download documents') }}</span>
          </FormKit>
        </div>
      </FormCardSection>
    </BaseFormCard>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { CloudArrowDownIcon } from "@heroicons/vue/24/outline"
import axios from "axios"
import { useI18n } from 'vue-i18n'
import { success } from "@/components/common/NotificationPlugin"

const loading = ref(false)

const { t } = useI18n()
async function downloadAll() {
  try {
    loading.value = true
    const archive_name = `${new Date().toISOString()}-documents.zip`
    await axios.post(`/restify/documents/actions?action=download-all-documents`, {
      archive_name,
    })
    success(t('An email with a link to download the archive has been sent to your email address.'))
  } finally {
    loading.value = false
  }
}
</script>

<route lang="yaml">
name: Data
meta:
  permission: manageDocuments
</route>

<style>
.data-page .formkit-form {
  @apply h-full;
}
</style>
