<template>
  <BaseFormDialog
    :loading="loading"
    :is-edit="isEdit"
    v-bind="$attrs"
    @submit="onSubmit"
  >
    <FormKit
      v-model="model.name"
      name="name"
      type="text"
      :label="t('Name')"
      :placeholder="t('Name')"
      validation="required"
      outer-class="col-span-6 md:col-span-3"
    />
    <FormKit
      v-model="model.email"
      name="email"
      type="text"
      :label="t('Email')"
      :placeholder="t('Email')"
      validation="required|email"
      outer-class="col-span-6 md:col-span-3"
    />
    <FormKit
      v-model="model.phone"
      name="phone"
      :label="t('Phone')"
      :placeholder="t('0741 234 567')"
      validation="phone"
      outer-class="col-span-6 md:col-span-3"
    />
    <FormKit
      v-model="model.position"
      name="position"
      :label="t('Position')"
      :placeholder="t('CEO')"
      outer-class="col-span-6 md:col-span-3"
    />
  </BaseFormDialog>
</template>

<script setup lang="ts">
import { ref } from "vue"
import { useI18n } from "vue-i18n"
import { useRoute } from "vue-router"
import axios from "axios"
import { useFormInit } from "@/modules/common/composables/useFormInit"
import { success } from "@/components/common/NotificationPlugin";

const props = defineProps({
  isEdit: {
    type: Boolean,
    default: false,
  },
  data: {
    type: Object,
    default: () => ({}),
  },
})

const { t } = useI18n()
const model = ref({
  name: '',
  email: '',
  phone: undefined,
  position: undefined,
})

useFormInit(props, model, (value) => {
})

const loading = ref(false)
const route = useRoute()
const emit = defineEmits(['save'])

async function onSubmit() {
  try {
    loading.value = true
    const data = {
      ...model.value,
      position: model.value.position || undefined,
      client_id: route.params.id,
    }
    if (props.isEdit) {
      await axios.put(`/restify/client-contacts/${props.data.id}`, data)
    } else {
      await axios.post(`/restify/client-contacts`, data)
    }
    if (props.isEdit) {
      success(t('Contact updated successfully'))
    } else {
      success(t('Contact created successfully'))
    }
    emit('save')
  } finally {
    loading.value = false
  }
}
</script>

