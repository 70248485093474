import { formatDate } from "@/modules/common/utils/dateUtils";

export function getMonths(year: number = new Date().getFullYear()) {
  const arr = []
  for (let i = 0; i < 12; i++) {
    arr.push({
      date: new Date(year, i, 1),
    })
  }
  return arr
}

export function getCurrentMonthDate() {
  const date = new Date()
  return new Date(date.getFullYear(), date.getMonth(), 1)
}

export function getMonthName(date: Date) {
  return formatDate(date, 'MMMM')
}
