<template>
  <div class="flex flex-col">
    <div class="flex items-end w-full justify-between">
      <PageTitle
        show-back
        :loading="currentDocumentLoading"
      >
        {{ documentName }}
      </PageTitle>
      <div>
        <EditButton
          v-if="!isEditPage"
          @click="goToEdit"
        />
      </div>
    </div>
    <div
      v-if="currentDocumentError || currentDocumentLoading"
      class="flex-1 flex justify-center items-center h-[50vh]"
    >
      <LoadingTable v-if="currentDocumentLoading"/>
      <div v-else class="flex flex-col items-center">
        <IconBox class="mb-4" color="error">
          <ExclamationTriangleIcon class="w-8 h-8"/>
        </IconBox>
        <h2>{{ $t('An error occurred while trying to access the document information') }}</h2>
      </div>
    </div>
    <router-view v-else class="flex-1 mt-4"/>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { ExclamationTriangleIcon } from "@heroicons/vue/24/outline";
import { storeToRefs } from "pinia";
import { useHead } from "@vueuse/head";
import { useDocumentsStore } from "@/modules/documents/store/documentsStore";
import EditButton from "@/components/common/buttons/EditButton.vue";
import PageTitle from "@/components/common/PageTitle.vue";

const activeTab = ref('Details')
const route = useRoute()

const documentsStore = useDocumentsStore()
const { currentDocument, currentDocumentLoading, currentDocumentError } = storeToRefs(documentsStore)
const id = computed(() => route.params.id as string)

const isEditPage = computed(() => {
  return route.path.endsWith('edit')
})

const pageTitle = computed(() => {
  const { name } = currentDocument.value?.attributes || {}
  if (!name) {
    return activeTab.value
  }
  return `${activeTab.value} - ${name}`
})

const documentName = computed(() => {
  const { name } = currentDocument.value?.attributes || {}
  if (!name) {
    return ''
  }
  return name
})

useHead({
  title: pageTitle
})
watch(() => id.value, async (value: string) => {
  await documentsStore.getDocument(value)
})
onMounted(async () => {
  await documentsStore.getDocument(id.value)
})

const router = useRouter()

async function goToEdit() {
  await router.push(`/documents/${id.value}/edit`)
}
</script>
<route lang="yaml">
name: Document
redirect:
  name: Document Details
</route>
