import { Placeholder as OriginalPlaceholder } from "@tiptap/extension-placeholder"
import i18n from "@/i18n";

export const Placeholder: any = OriginalPlaceholder.configure({
  includeChildren: true,
  showOnlyCurrent: false,
  placeholder: () => {
    return i18n.t('Type / to insert a block')
  },
})
