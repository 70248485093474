<template>
  <div>
    Work in progress...
  </div>
</template>

<script setup lang="ts">
</script>
<route lang="yaml">
name: Employee Departments
</route>
