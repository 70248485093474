<template>
  <InvoicesTable :client_id="route.params.id" :all-invoices="true"/>
</template>

<script setup lang="ts">
import { useRoute } from 'vue-router'
import InvoicesTable from "@/modules/invoices/components/InvoicesTable.vue";

const route = useRoute()
</script>
<route lang="yaml">
name: Client Invoices
</route>
