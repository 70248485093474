import { isSameDay } from "date-fns"
import { sumBy } from "lodash-es"
import Timesheet = App.Domains.TimeSheets.Models.Timesheet;
import Data = API.Data;

export function getTotalMinutesForDay(timeLogs: Data<Timesheet>[], dayToFilter: Date) {
  const logs = getTimeSheetsForDay(timeLogs, dayToFilter)
  return sumBy(logs, 'attributes.worked_minutes')
}

export function getTimeSheetsForDay(timeLogs: Data<Timesheet>[], date: Date) {
  return timeLogs.filter((timeLog) => {
    // @ts-expect-error
    const day = new Date(timeLog.attributes?.date)
    return isSameDay(day, date)
  })
}

export function hasRunningTimers(timeLogs: Data<Timesheet>[], dayToFilter: Date) {
  return getTimeSheetsForDay(timeLogs, dayToFilter).some((timeLog) => {
    return timeLog.attributes?.timer_started_at !== null
  })
}
