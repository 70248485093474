<template>
  <BaseContentCard
    class="relative w-[270px] cursor-pointer"
    @mouseenter="isHovered = true"
    @mouseleave="isHovered = false"
    @click="onClick"
  >
    <IconBox :color="isHovered ? 'primary' : 'gray'" class="mb-3">
      <component
        :is="data.icon"
        :class="{
          'text-base-300': !isHovered,
          'text-primary': isHovered,
          'w-5 h-5': !data.iconSize,
          'w-8 h-8': data.iconSize === 'lg',
        }"
        class="w-5 h-5"
      />
    </IconBox>
    <router-link
      :to="data.path"
      class="text-base text-base-content no-underline font-semibold"
    >
      {{ data.title }}
    </router-link>
    <p class="text-sm text-base-300 max-w-[190px]">
      {{ data.description }}
    </p>
    <div class="absolute right-[16px] bottom-[16px]">
      <ArrowLongRightIcon
        :class="{
          'text-input': !isHovered,
          'text-primary': isHovered,
        }"
        class="w-5 h-5"
      />
    </div>
  </BaseContentCard>
</template>

<script setup lang="ts">
import { PropType, ref } from "vue"
import { ArrowLongRightIcon } from "@heroicons/vue/24/outline"
import { useRouter } from "vue-router"
import { useI18n } from "vue-i18n"
import { SettingCard } from "@/modules/settings/types/settingTypes"
import { useBillingStore } from "@/modules/settings/store/billingStore"
import { error } from "@/components/common/NotificationPlugin"

const props = defineProps({
  data: {
    type: Object as PropType<SettingCard>,
    required: true,
  },
  paid: {
    type: Boolean,
    default: false,
  },
})

const isHovered = ref(false)
const router = useRouter()
const billingStore = useBillingStore()
const { t } = useI18n()

async function onClick(event: Event) {
  const target = event.target as HTMLElement
  if (target?.nodeName === 'A') {
    return
  }
  if (props.paid && !billingStore.isPaidPlan) {
    error(t('Upgrade to a paid plan to access this feature'))
    return
  }
  await router.push(props.data?.path)
}
</script>
