<template>
  <div
    class="hidden group-hover:block -mr-2"
  >
    <button
      class="btn btn-link btn-xs text-gray-500"
      @click.stop="emit('click')"
    >
      <XCircleIcon class="w-4 h-4" />
    </button>
  </div>
</template>

<script lang="ts" setup>
import { XCircleIcon } from "@heroicons/vue/24/outline"

const emit = defineEmits(['click'])
</script>
