import { flare } from "@flareapp/js"
import { flareVue } from "@flareapp/vue"
import { App } from "vue"

// Only enable Flare in production, we don't want to waste your quota while you're developing:
if (process.env.NODE_ENV === 'production') {
  flare.light()
}

export default {
  install(Vue: App) {
    Vue.use(flareVue)
  },
}
