<template>
  <IndexPage :employee-id="userEmployeeId"/>
</template>
<script setup lang="ts">
import IndexPage from './index.vue'
import { useAuth } from "@/modules/auth/composables/useAuth";
const { userEmployeeId } = useAuth()
</script>
<route lang="yaml">
name: My Holidays
</route>
