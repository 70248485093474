<template>
  <div class="flex space-x-2 items-center">
    <BaseAvatar
      class="rounded-full"
      :class="{
        'w-6 h-6 min-w-[24px]': size === 'sm',
        'w-8 h-8 min-w-[32px]': size === 'md',
        'w-12 h-12 min-w-[48px]': size === 'lg',
      }"
      :name="name"
      :src="row?.attributes?.logo_path"
    />
    <div v-if="showName" class="flex flex-col leading-5 truncate">
      <span
        :title="name"
        class="font-semibold truncate"
      >
        {{ name }}
      </span>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue"
import { getEmployeeFullName } from "@/modules/clients/utils/employeeUtils"

const props = defineProps({
  data: {
    type: Object,
    default: () => ({}),
  },
  showName: {
    type: Boolean,
    default: true,
  },
  size: {
    type: String,
    default: 'sm', // sm | md | lg
  },
})

const row = computed(() => {
  const clientRelationship = props.data?.relationships?.client
  if (clientRelationship === undefined) {
    return props?.data
  }
  return clientRelationship || {}
})

const name = computed(() => {
  const { company_name, name } = row?.value?.attributes || {}
  return company_name || name
})
</script>
