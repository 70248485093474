<template>
  <div class="p-4">
    <ExpenseBundleDetails :show-title="false"/>
  </div>
</template>

<script lang="ts" setup>
import { useExpenseStore } from "@/modules/expenses/store/expenseStore";
import ExpenseBundleDetails from "@/modules/expenses/components/ExpenseBundleDetails.vue";

const props = defineProps({
  params: {
    type: Object,
    default: () => ({}),
  },
})

const expenseStore = useExpenseStore()
expenseStore.currentExpense = props.params.data
</script>
