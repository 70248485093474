<template>
  <BaseSelect
    v-bind="allAttrs"
    :options="options"
    value-key="id"
    label-key="attributes.name"
    filterable
    default-first-option
    :model-value="value"
    @update:model-value="handleChange"
  />
</template>

<script setup lang="ts">
import { useFormKitInput } from "@/components/formkit/useFormkitInput";
import { computed } from "vue";

const props = defineProps({
  context: Object,
})
const { value, allAttrs, handleChange } = useFormKitInput(props)

const options = computed(() => {
  return allAttrs.value?.options || []
})
</script>
