import { computed } from "vue"
import { useI18n } from "vue-i18n"
import {
  BriefcaseIcon,
  BuildingOffice2Icon,
  CalendarDaysIcon,
  ClipboardDocumentCheckIcon,
  CloudArrowDownIcon,
  CreditCardIcon,
  DocumentDuplicateIcon,
  LinkIcon,
  PresentationChartLineIcon,
  ReceiptPercentIcon,
  Squares2X2Icon,
  UserCircleIcon,
  UserIcon,
} from "@heroicons/vue/24/outline"
import { BarsArrowUpIcon } from "@heroicons/vue/20/solid"
import { SettingCard } from "@/modules/settings/types/settingTypes"
import { can } from "@/plugins/permissionPlugin"

export function useSettingPages() {
  const { t } = useI18n()
  const pages = computed<SettingCard[]>(() => {
    const cards: any[] = [
      {
        title: t('Account'),
        description: t('Configure your account preferences'),
        path: '/settings/account',
        icon: UserIcon,
        show: () => true,
      },
      {
        title: t('Billing'),
        description: t('Manage your billing details and subscriptions'),
        path: '/settings/billing',
        icon: CreditCardIcon,
        show: () => can('manageBilling'),
      },
      {
        title: t('Company'),
        description: t('Keep your company details up to date'),
        path: '/settings/company',
        icon: BriefcaseIcon,
        show: () => false,
      },
      {
        title: t('Company'),
        description: t('Manage your company settings'),
        path: '/settings/company',
        icon: BuildingOffice2Icon,
        show: () => can('manageCompany'),
      },
      {
        title: t('Data'),
        description: t('Manage your company data'),
        path: '/settings/data',
        icon: CloudArrowDownIcon,
        show: () => can('manageDocuments'),
      },
      {
        title: t('Documents'),
        description: t('Prepare all the details for your company documents'),
        path: '/settings/documents',
        icon: DocumentDuplicateIcon,
        show: () => can('manageDocumentTypes'),
      },
      {
        title: t('Departments'),
        description: t('Define company level departments'),
        path: '/settings/departments',
        icon: Squares2X2Icon,
        show: () => can('manageDepartments'),
      },
      {
        title: t('Evaluations'),
        description: t('Make sure all the settings for evaluations are ready'),
        path: '/settings/evaluations',
        icon: PresentationChartLineIcon,
        show: () => can('manageEvaluationTypes'),
      },
      {
        title: t('Expense categories'),
        description: t('Define expense categories'),
        path: '/settings/expenses',
        icon: ReceiptPercentIcon,
        show: () => can('manageExpenses'),
      },
      {
        title: t('Holidays'),
        description: t('Configure holiday types and policies'),
        path: '/settings/holidays',
        icon: CalendarDaysIcon,
        show: () => can('manageHolidayPolicies'),
      },
      {
        title: t('Integrations'),
        description: t('Integrate with third party applications'),
        path: '/settings/integrations',
        icon: LinkIcon,
        show: () => true,
      },
      {
        title: t('Positions'),
        description: t('Define company level positions'),
        path: '/settings/positions',
        icon: UserCircleIcon,
        show: () => can('managePositions'),
      },
      {
        title: t('Levels'),
        description: t('Define employee level positions'),
        path: '/settings/levels',
        icon: BarsArrowUpIcon,
        show: () => can('managePositions'),
      },
      {
        title: t('Templates'),
        description: t('Define document templates'),
        path: '/settings/templates',
        icon: ClipboardDocumentCheckIcon,
        show: () => can('manageTemplates'),
      },
    ]
    return cards
      .filter(card => card.show())
      .map((card: any) => {
        card.name = card.title
        card.isSettingPage = true
        return card
      })
  })
  return {
    pages,
  }
}
