<template>
  <div ref="el">
    <h1 class="text-center">
      {{ $t('Confirm your email') }}
    </h1>
    <div class="flex justify-center space-x-2 mt-2">
      <p v-if="hasEmail">{{ $t(`Enter the code received by email down below`) }}</p>
      <p v-else>{{ $t(`Send confirmation code by email`) }}</p>
    </div>

    <div class="mt-10">
      <FormKit
        v-slot="{ state: { loading } }"
        :actions="false"
        :disabled="resending"
        type="form"
        @submit="onSubmit"
      >
        <FormKit
          v-if="hasEmail"
          v-model="model.code"
          type="otp"
          :label="$t('Confirmation code')"
          validation="required"
          validation-visibility="dirty"
        />
        <FormKit
          v-if="!hasEmail"
          v-model="model.email"
          validation="required|email"
          :label="$t('Email')"
        />
        <div class="w-full mt-10">
          <div
            v-if="showResendCode && hasEmail"
            class="w-full flex justify-center no-underline"
            @click="resendCode"
          >
            <button class="btn btn-link" type="button">
              {{ t('Resend code') }}
            </button>
          </div>
          <FormKit
            type="submit"
            :disabled="loading || resending"
            :classes="{ input: 'w-full' }"
          >
            <span v-if="hasEmail">{{ $t('Confirm') }}</span>
            <span v-else>{{ t('Resend code') }}</span>
            <CheckIcon class="w-4 h-4 ml-2" />
          </FormKit>
        </div>
      </FormKit>
    </div>
  </div>
</template>

<script setup lang="ts">
import { CheckIcon } from '@heroicons/vue/24/outline'
import RegisterStep2 = Auth.RegisterStep2;
import { computed, onMounted, reactive, ref, watch } from "vue";
import { useAuthStore } from "@/modules/auth/store/authStore";
import { error, success } from "@/components/common/NotificationPlugin";
import { useI18n } from "vue-i18n";
import { RegisterSteps } from "@/modules/auth/types/enums";
import { useStorage } from "@vueuse/core";
const props = defineProps({
  token: {
    type: String,
    default: '',
  },
  email: {
    type: String,
    default: '',
  },
})
const emit = defineEmits(['success'])
const el = ref()
const model: RegisterStep2 = reactive({
  email: props.email,
  code: '',
  onboarding_step: RegisterSteps.ConfirmEmail,
  onboarding_token: props.token,
})
const { t } = useI18n()
const authStore = useAuthStore()

onMounted(() => {
  const firstInput = el.value?.querySelector('input')
  firstInput?.focus()
})

const resending = ref(false)
const showResendCode = ref(!!props.email)
const codeSent = useStorage<boolean>('email-code-sent', false)

const hasEmail = computed(() => !!props.email)

watch(() => codeSent.value, (value) => {
  if (!value) {
    return
  }
  showResendCode.value = true
})

async function resendCode(event?: Event) {
  try {
    resending.value = true
    event?.preventDefault()
    event?.stopPropagation()
    await authStore.resendCode(model.email)
    success(t('An email with a new code has been sent to your email address'))
  } catch (err: any) {
    if (err.handled) {
      return
    }
    error(t('An error occurred while trying to resend the code'))
  } finally {
    resending.value = false
  }
}
async function onSubmit() {
  try {
    if (!hasEmail.value) {
      await resendCode()
      return
    }
    await authStore.registerStep2(model)
    emit('success', {
      onboarding_step: RegisterSteps.CompanySetup,
    })
    codeSent.value = true
  } catch (err: any) {
    console.error(err)
    if (err.handled) {
      return
    }
    error(t('An error occurred while trying to login'))
  }
}
</script>
