<template>
  <EmployeeDetailLayout
    class="employee-profile"
    :employee-id="userEmployeeId"
    :is-profile-page="true"
  />
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router'
import EmployeeDetailLayout from '@/modules/employees/components/EmployeeDetailLayout.vue'
import { useAuth } from '@/modules/auth/composables/useAuth'

const { userEmployeeId } = useAuth()
const router = useRouter()
</script>

<style>
.employee-profile .employee-edit-button {
  @apply absolute right-0 top-[-190px]
}
</style>

<route lang="yaml">
name: My Employee Profile
redirect: /settings/account/employee-profile/details
</route>
