<template>
  <div class="pt-5">
    <h2 class="mb-4">
      {{ t('Configurations') }}
    </h2>
    <div class="flex justify-center md:justify-start flex-wrap gap-3">
      <SettingsCard
        v-for="item in settingCards"
        :key="item.title"
        :data="item"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from "vue-i18n"
import SettingsCard from "@/modules/settings/components/SettingsCard.vue"
import { useSettingPages } from "@/modules/common/composables/useSettingPages"

const { t } = useI18n()
const { pages: settingCards } = useSettingPages()
</script>

<route lang="yaml">
name: Configurations
</route>
