<template>
  <BubbleMenu
    :editor="editor"
    plugin-key="tableColumnMenu"
    :update-delay="0"
    :tippy-options="tippyOptions"
    :should-show="shouldShow"
  >
    <ToolbarWrapper is-vertical>
      <PopoverItem
        icon="ArrowLeftToLineIcon"
        :close="false"
        label="Add column before"
        @click="onAddColumnBefore"
      />
      <PopoverItem
        icon="ArrowRightToLineIcon"
        :close="false"
        label="Add column after"
        @click="onAddColumnAfter"
      />
      <PopoverItem
        icon="Trash"
        :close="false"
        label="Delete column"
        @click="onDeleteColumn"
      />
    </ToolbarWrapper>
  </BubbleMenu>
</template>

<script setup>
import { BubbleMenu } from "@tiptap/vue-3"
import isColumnGripSelected from "./utils"
import PopoverItem from "@/components/formkit/html/components/popover/PopoverItem.vue"
import ToolbarWrapper from "@/components/formkit/html/components/toolbar/ToolbarWrapper.vue"

// Props passed to this component
const props = defineProps({
  editor: Object,
  appendTo: Object, // Assuming this is a ref object
})

const tippyOptions = {
  appendTo: () => {
    return props.appendTo
  },
  offset: [0, 15],
  popperOptions: {
    modifiers: [{ name: 'flip', enabled: false }],
  },
}

const shouldShow = ({ view, state, from }) => {
  if (!state) {
    return false
  }

  return isColumnGripSelected({ editor: props.editor, view, state, from: from || 0 })
}

const onAddColumnBefore = () => {
  props.editor.chain().focus().addColumnBefore().run()
}

const onAddColumnAfter = () => {
  props.editor.chain().focus().addColumnAfter().run()
}

const onDeleteColumn = () => {
  props.editor.chain().focus().deleteColumn().run()
}
</script>
