<template>
  <a v-if="formattedAddress"
     :href="locationLink"
     target="_blank"
     class="text-base-content no-underline hover:underline cursor-pointer"
  >
    <address>
      {{ formattedAddress }}
    </address>
  </a>
  <span v-else>- -</span>
</template>
<script setup lang="ts">
import { computed } from "vue";
import { formatAddress } from "@/modules/employees/utils/employeeUtils";

const props = defineProps({
  address: {
    type: Object,
    default: () => ({})
  }
})

const formattedAddress = computed(() => {
  const address = props.address
  if (address && false && address?.length > 0) {
    return address
  }
  return formatAddress(address)
})
const locationLink = computed(() => {
  return `https://www.google.com/maps/search/?api=1&query=${formattedAddress.value}`
})
</script>
