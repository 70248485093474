<template>
  <BaseSelect
    v-bind="allAttrs"
    :options="documentTags"
    value-key="id"
    label-key="attributes.name"
    multiple
    filterable
    allow-create
    :model-value="value"
    @update:model-value="onChange"
  >
    <template #default="{ option }">
      <DocumentTag :tag="option.attributes" />
    </template>
  </BaseSelect>
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia"
import { useFormKitInput } from "@/components/formkit/useFormkitInput"
import { useSettingsStore } from "@/modules/settings/store/settingsStore"
import DocumentTag from "@/components/data/DocumentTag.vue"
import { isUuid } from "@/modules/common/utils/stringUtils"
import { TagColors } from "@/modules/settings/types/settingTypes";
import axios from "axios";

const props = defineProps({
  context: Object,
})
const settingsStore = useSettingsStore()
const { documentTags } = storeToRefs(settingsStore)

const { value, allAttrs, handleChange } = useFormKitInput(props)

async function onChange(val: string[]) {
  if (val?.length === 0 || !val) {
    handleChange(val)
    return
  }
  const lastVal = val[val.length - 1]
  const isId = isUuid(lastVal)
  if (isId) {
    handleChange(val)
    return
  }
  const { data } = await axios.post(`/restify/tags`, {
    name: lastVal,
    color: TagColors.Primary,
  })
  documentTags.value.push(data)
  const tagId = data?.id
  if (!tagId) {
    return
  }
  const tagList = [...val.slice(0, val.length - 1), tagId]
  handleChange(tagList)
}
</script>
