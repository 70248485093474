<template>
  <div class="flex flex-col">
    <div
      v-if="currentExpenseError || currentExpenseLoading"
      class="flex-1 flex justify-center items-center h-[50vh]"
    >
      <LoadingTable v-if="currentExpenseLoading"/>
      <div v-else class="flex flex-col items-center">
        <IconBox class="mb-4" color="error">
          <ExclamationTriangleIcon class="w-8 h-8"/>
        </IconBox>
        <h2>{{ $t('An error occurred while trying to access the expense bundle information') }}</h2>
      </div>
    </div>
    <RouterView v-else class="flex-1"/>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, watch } from "vue"
import { storeToRefs } from "pinia"
import { ExclamationTriangleIcon } from "@heroicons/vue/24/outline"
import { useHead } from "@vueuse/head"
import { useRoute } from "vue-router"
import { useI18n } from "vue-i18n"
import { useExpenseStore } from "@/modules/expenses/store/expenseStore"

const { t } = useI18n()

const expenseStore = useExpenseStore()
const { currentExpenseLoading, currentExpenseError, expenseName } = storeToRefs(expenseStore)
const route = useRoute()
const id = computed(() => route.params.id as string)

useHead({
  title: expenseName,
})
watch(() => id.value, async (value: string) => {
  await expenseStore.getExpense(value)
})
onMounted(async () => {
  await expenseStore.getExpense(id.value)
})
</script>

<route lang="yaml">
name: Expense
redirect:
  name: Expense bundle details
</route>
