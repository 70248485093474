<template>
  <div class="flex items-center space-x-2 px-3 py-1.5 bg-primary-light rounded-sm">
    <DocumentTextIcon class="w-4 h-4 text-primary"/>
    <span class="text-sm text-primary">
      {{ contractType?.attributes?.heading }}
    </span>
  </div>
</template>
<script setup lang="ts">
import { useSettingsStore } from "@/modules/settings/store/settingsStore";
import { computed } from "vue";
import { DocumentTextIcon } from "@heroicons/vue/24/outline";

const props = defineProps({
  contract_type: {
    type: String,
    default: '',
  }
})
const settingsStore = useSettingsStore()
const contractType = computed(() => {
  return settingsStore.getContractTypeByName(props.contract_type)
})
</script>
