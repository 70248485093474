import { createPinia } from "pinia"
import stringify from "json-stringify-safe"
import localforage from "localforage"
import { createPersistedStatePlugin } from "pinia-plugin-persistedstate-2"

const pinia = createPinia()
const installPersistedStatePlugin = createPersistedStatePlugin({
  persist: false,
  storage: {
    getItem: async (key) => {
      return localforage.getItem(key)
    },
    setItem: async (key, value) => {
      return localforage.setItem(key, value)
    },
    removeItem: async (key) => {
      return localforage.removeItem(key)
    },
  },
  serialize: (value: any) => {
    return stringify(value)
  },
})
pinia.use((context: any) => installPersistedStatePlugin(context))

export default pinia
