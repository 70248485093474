<template>
  <h4 class="text-2xl font-semibold flex items-center space-x-2 capitalize">
    <span v-if="title" class="font-semibold">
      {{ title }}
    </span>
    <span>
      {{ formattedSelectedDate }}
    </span>
  </h4>
</template>
<script lang="ts" setup>
import { computed } from 'vue'
import { formatDate } from "@/modules/common/utils/dateUtils";
import { startCase } from 'lodash-es'
import { IntervalTypes } from "@/modules/reports/store/reportsStore";

const props = defineProps({
  from: {
    type: Date,
  },
  to: {
    type: Date,
  },
  title: String,
  intervalType: String,
})

const formattedSelectedDate = computed(() => {
  const toFormat = 'dd, MMM yyyy'
  const fromFormatMap = {
    [IntervalTypes.Week]: 'dd',
    [IntervalTypes.Month]: 'dd',
    [IntervalTypes.Quarter]: 'dd, MMM',
    [IntervalTypes.Custom]: toFormat,
    default: 'dd'
  }
  const fromFormat = fromFormatMap[props.intervalType as string] || fromFormatMap.default 
  if (!props.from || !props.to || props.intervalType === IntervalTypes.AllTime || !fromFormat) {
    return
  }
  if (props.intervalType === IntervalTypes.Year) {
    return formatDate(props.from, 'yyyy')
  }
  let from = formatDate(props.from, fromFormat)
  const to = startCase(formatDate(props.to, toFormat))
  return `${from} - ${to}`
})
</script>
