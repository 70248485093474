<template>
  <Surface class="p-2">
    <form class="flex items-center gap-2" @submit="handleSubmit">
      <label class="flex items-center gap-2 p-2 rounded-lg bg-neutral-100 dark:bg-neutral-900 cursor-text">
        <Icon name="Link" class="flex-none text-black dark:text-white" />
        <input
          v-model="url"
          type="url"
          class="flex-1 bg-transparent outline-none min-w-[12rem] text-black text-sm dark:text-white"
          placeholder="Enter URL"
        >
      </label>
      <Button variant="primary" button-size="small" type="submit" :disabled="!isValidUrl">
        Set Link
      </Button>
    </form>
    <div class="mt-3">
      <label
        class="flex items-center justify-start gap-2 text-sm font-semibold cursor-pointer select-none text-neutral-500 dark:text-neutral-400"
      >
        Open in new tab
        <Switch
          v-model:checked="openInNewTab"
        />
      </label>
    </div>
  </Surface>
</template>

<script setup>
import { computed, ref } from 'vue'
import Surface from "@/components/formkit/html/components/Surface.vue"
import Icon from "@/components/formkit/html/components/Icon.vue"
import Button from "@/components/formkit/html/components/Button.vue"
import { Switch } from "@/components/formkit/html/components/switch"

const props = defineProps({
  initialUrl: String,
  initialOpenInNewTab: Boolean,
})

const url = ref(props.initialUrl || '')
const openInNewTab = ref(props.initialOpenInNewTab || false)

const isValidUrl = computed(() => /^(\S+):(\/\/)?\S+$/.test(url.value))
const emit = defineEmits(['set'])
const handleSubmit = (e) => {
  e.preventDefault()
  if (isValidUrl.value) {
    emit('set', url.value, openInNewTab.value)
  }
}
</script>
