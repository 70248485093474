<template>
  <div>
    <span
      class="btn btn-primary btn-sm"
      @click="showDialog = true"
    >
      <CloudArrowDownIcon class="w-4 h-4 text-white"/>
    </span>

    <BaseFormDialog
      v-if="showDialog"
      v-model:open="showDialog"
      :title="t(`Export model`, {model: modelName})"
      append-to-body
    >
      <div class="grid grid-cols-2 gap-6 items-end">
        <FormKit
          id="export_to"
          v-model="exportTo"
          type="customSelect"
          label="Export To"
          :placeholder="$t('Export To')"
          :options="exportOptions"
        />
        <FormKit
          v-model="fileNameToExport"
          :label="$t('File name')"
          :placeholder="$t(entity)"
          :name="$t('File name')"
          validation="required"
          validation-visibility="dirty"
        />
      </div>
      <FormKit
        v-model="selectedColumns"
        class="w-full"
        type="customSelect"
        :options="columnOptions"
        :label="$t('Columns')"
        :name="$t('Columns')"
        :placeholder="$t('Choose columns for export')"
        multiple
      />
      <div class="flex justify-end mt-6 gap-6">
        <button
          class="btn btn-outline"
          @click="showDialog = false"
        >
          {{ $t('Cancel') }}
        </button>
        <button
          class="btn btn-primary"
          @click="download"
        >
          {{ $t('Export') }}
        </button>
      </div>
    </BaseFormDialog>
  </div>
</template>

<script setup lang="ts">
import axios from 'axios'
import { startCase } from 'lodash-es'
import { CloudArrowDownIcon } from '@heroicons/vue/24/outline'
import { computed, onMounted, ref } from 'vue'
import { downloadFileLocally } from '@/modules/common/utils/downloadFileLocally'
import { formatDate } from '@/modules/common/utils/dateUtils'
import { error, success } from '@/components/common/NotificationPlugin'
import i18n from '@/i18n'
import { useI18n } from "vue-i18n";

const { t } = useI18n()
const props = defineProps({
  url: {
    type: String,
    default: '',
  },
  entity: {
    type: String,
    default: '',
  },
  requestParams: {
    type: Object,
    default: () => ({}),
  },
  columns: {
    type: Array,
    default: () => [],
  },
})

const columnOptions = computed(() => {
  return props.columns.map((column: string) => ({
    label: i18n.t(startCase(column)),
    value: column,
  }))
})

const loading = ref(false)
const showDialog = ref(false)
const fileNameToExport = ref('')
const exportOptions = [
  {
    label: 'Excel',
    value: 'xlsx',
  },
  {
    label: 'CSV',
    value: 'csv',
  },
]
const exportTo = ref(exportOptions[0].value)
const selectedColumns = ref([])

const modelName = computed(() => {
  return startCase(props.entity)
})

async function download() {
  try {
    loading.value = true
    const format = exportTo.value

    const requestParams = {
      ...props.requestParams,
    }
    delete requestParams.total
    delete requestParams.last_page

    const postData = {
      format,
      columns: selectedColumns.value,
      repositories: 'all',
    }

    const summary = await axios.post(`${props.url}/actions?action=export`, postData, {
      params: requestParams,
      responseType: 'blob',
    })

    downloadFileLocally(summary, `${fileNameToExport.value}.${format}`)

    showDialog.value = false
    success(i18n.t('Data exported successfully!'))
  } catch (err: any) {
    if (err.handled) {
      return
    }
    error(i18n.t('Something went wrong please try again.'))
  } finally {
    loading.value = false
  }
}

function composeFileName() {
  fileNameToExport.value = `${startCase(props.entity)} - ${formatDate(new Date())}`
}

onMounted(() => {
  composeFileName()
})
</script>

