<template>
  <BaseButton
    v-bind="$attrs"
    variant="primary"
    outline
    class="print:hidden"
    :loading="downloading"
    :left-icon="FolderArrowDownIcon"
    @click="onDownload"
  >
    <span>{{ t('Download') }}</span>
  </BaseButton>
</template>

<script setup lang="ts">
import { FolderArrowDownIcon } from '@heroicons/vue/24/outline'
import { useI18n } from 'vue-i18n'
import { ref } from 'vue'
import { useRoute } from 'vue-router'
import { useInvoiceStore } from '@/modules/invoices/store/invoiceStore'

const invoiceStore = useInvoiceStore()
const { t } = useI18n()
const downloading = ref(false)
const route = useRoute()
async function onDownload() {
  try {
    downloading.value = true
    await invoiceStore.shareInvoice(route.params.id as string, true)
    await invoiceStore.downloadInvoiceAsPdf()
  } finally {
    downloading.value = false
  }
}
</script>
