<template>
  <BaseSelect
    v-bind="allAttrs"
    url="/restify/generic-industries"
    value-key="id"
    label-key="attributes.name"
    filterable
    :transform-data="transformData"
    :model-value="value"
    @update:model-value="handleChange"
  />
</template>

<script setup lang="ts">
import { orderBy } from "lodash-es"
import { useFormKitInput } from "@/components/formkit/useFormkitInput"
import { useSettingsStore } from "@/modules/settings/store/settingsStore"
import Data = API.Data

const props = defineProps({
  context: Object,
})

const settingsStore = useSettingsStore()

const { value, allAttrs, handleChange } = useFormKitInput(props)

function transformData(options: Data<any>[]) {
  return orderBy(options, 'attributes.name')
}
</script>
