<template>
  <BaseSelect
    v-bind="allAttrs"
    :options="positions"
    :data-loading="positionsLoading"
    value-key="id"
    label-key="attributes.name"
    filterable
    :model-value="value"
    @update:model-value="handleChange"
  />
</template>

<script setup lang="ts">
import { useFormKitInput } from "@/components/formkit/useFormkitInput";
import { useSettingsStore } from "@/modules/settings/store/settingsStore";
import { storeToRefs } from "pinia";
const props = defineProps({
  context: Object,
})

const settingsStore = useSettingsStore()
const { positions, positionsLoading } = storeToRefs(settingsStore)

const { value, allAttrs, handleChange } = useFormKitInput(props)
</script>
