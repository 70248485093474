import { uniq } from "lodash-es"
import type { Ref } from 'vue'
import { computed, nextTick, ref } from "vue"
import { useStorage } from "@vueuse/core"
import { useProjectStore } from "@/modules/projects/store/projectStore"
import Timesheet = App.Domains.TimeSheets.Models.Timesheet
import Data = API.Data

export function useTimesheetForm(model: Ref<any>, otherLogs: Ref<Data<Timesheet>[]> = ref([])) {
  const lastSelectedProject = useStorage<any>('lastSelectedProject', null)
  const lastSelectedTask = useStorage<string>('lastSelectedTask', null)
  const lastDescription = useStorage<string | null>('lastDescription', null)
  const projectStore = useProjectStore()

  interface SelectionMap {
    [key: string]: {
      task_id: string | null | undefined
    }
  }

  const selectionMap = useStorage<SelectionMap>('timeSheetSelectionMap', {})
  const project = computed(() => {
    return projectStore.allProjects.find(p => p.id === model.value.project_id)
  })

  const projectTasks = computed(() => {
    const tasks = project.value?.relationships?.tasks || []
    const otherLogTasks = uniq(otherLogs.value.map(log => log.attributes.task_id))
    return tasks.filter((t: any) => !otherLogTasks.includes(t.id))
  })

  async function onProjectChange(projectId: string) {
    lastSelectedProject.value = projectId
    lastSelectedTask.value = null
    model.value.task_id = null
    const lasSelectedTaskId = selectionMap.value[projectId]?.task_id
    const isValidTask = isValidProjectTask(lasSelectedTaskId)
    if (isValidTask) {
      model.value.task_id = lasSelectedTaskId
    } else {
      model.value.task_id = null
    }
    updateSelectionMap(projectId as string, model.value.task_id)
    await nextTick()
    if (!model.value.task_id) {
      const firstTask = projectTasks.value[0]
      model.value.task_id = firstTask?.id
    }
  }

  function onTaskChange(task: string) {
    lastSelectedTask.value = task
    updateSelectionMap(model.value.project_id as string, task)
  }

  function isValidProjectTask(taskId: string | null | undefined) {
    return projectTasks.value.some((task: any) => task.id === taskId)
  }

  function updateSelectionMap(projectId: string, taskId?: string | null) {
    const hasProject = selectionMap.value[projectId]
    if (hasProject) {
      selectionMap.value[projectId].task_id = taskId
    } else {
      selectionMap.value[projectId] = {
        task_id: taskId,
      }
    }
  }

  return {
    lastSelectedProject,
    lastSelectedTask,
    lastDescription,
    selectionMap,
    projectTasks,
    onProjectChange,
    onTaskChange,
  }
}
