<template>
  <div ref="dragHandleElement" :class="className">
    <slot />
  </div>
</template>

<script setup lang="ts">
import { onBeforeUnmount, onMounted, ref } from 'vue'
import { DragHandlePlugin, dragHandlePluginDefaultKey } from '@tiptap-pro/extension-drag-handle'
import { Editor } from "@tiptap/vue-3"

const props = defineProps({
  className: {
    type: String,
    default: 'drag-handle',
  },
  editor: Object,
  pluginKey: {
    type: String,
    default: dragHandlePluginDefaultKey,
  },
  onNodeChange: Function,
  tippyOptions: {
    type: Object,
    default: () => ({}),
  },
})

const dragHandleElement = ref(null)
const emit = defineEmits(['node-change'])

onMounted(() => {
  if (!dragHandleElement.value || props.editor?.isDestroyed) {
    return
  }

  const dragHandlePluginInstance = DragHandlePlugin({
    editor: props.editor as Editor,
    element: dragHandleElement.value,
    pluginKey: props.pluginKey,
    tippyOptions: props.tippyOptions,
    onNodeChange: data => emit('node-change', data),
  })

  props.editor?.registerPlugin(dragHandlePluginInstance)
})
onBeforeUnmount(() => {
  props.editor?.unregisterPlugin(props.pluginKey)
})
</script>
