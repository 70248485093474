<template>
  <RouterView />
</template>

<script setup lang="ts">
import { onMounted } from "vue"
import { useSettingsStore } from "@/modules/settings/store/settingsStore"
import { useProjectStore } from "@/modules/projects/store/projectStore";

const settingStore = useSettingsStore()
const projectStore = useProjectStore()
onMounted(() => {
  settingStore.getExpenseCategories()
  projectStore.getAllProjects()
})
</script>

<route lang="yaml">
name: Expenses
redirect:
  name: My Expenses
</route>
