<template>
  <div>
    <BaseDataTable
      ref="table"
      url="/restify/holidays"
      :url-params="urlParams"
      :columns="columns"
      :add-text="t('Request holiday')"
      :row-height="75"
      :extra-actions="rowActions"
      :suppress-cell-focus="true"
      entity="holidays"
      actions="add"
      class="h-full"
      @add="showAddDialog = true"
      @grid-ready="grid = $event"
      @data-updated="checkForDialogToOpen(route.query.id)"
    >
      <template
        v-if="can('manageHolidays')"
        #bulk-actions="{ filters }"
      >
        <li>
          <button
            :disabled="holidayStore.exportLoading"
            class="btn-sm"
            @click="holidayStore.exportHolidays(ExportFormats.Xlsx, filters)"
          >
            {{ t('Export to Excel') }}
          </button>
        </li>
        <li>
          <button
            :disabled="holidayStore.exportLoading"
            class="btn-sm"
            @click="holidayStore.exportHolidays(ExportFormats.CSV, filters)"
          >
            {{ t('Export to CSV') }}
          </button>
        </li>
      </template>
      <template #policy.name="{ row }">
        <div class="py-2 md:py-0">
          <HolidayLink :dialog-link="true" :holiday="row" />
          <HolidayInterval class="ml-5 md:hidden" :data="row" />
          <HolidayRequestStatus class="ml-5 md:hidden" :params="{ data: row }" />
        </div>
      </template>
    </BaseDataTable>
    <HolidayRequestForm
      v-if="showAddDialog"
      v-model="showAddDialog"
      :dialog="true"
      :title="t('Request holiday')"
      :employee-id="props.employeeId"
      @save="onHolidaySave"
      @close="showAddDialog = false"
    />

    <HolidayRequestForm
      v-if="showEditDialog"
      v-model="showEditDialog"
      :dialog="true"
      :title="t('Edit Holiday Request')"
      :employee-id="props.employeeId"
      :holiday="currentHoliday"
      @save="onHolidaySave"
      @close="showEditDialog = false"
    />

    <HolidayPreviewDialog
      v-if="showDetailsDialog"
      :id="$route.query.id"
      v-model="showDetailsDialog"
    />

    <GenerateDocumentsModal
      v-if="showGenerateModal"
      v-model="showGenerateModal"
      :entity-id="currentHoliday?.id"
      :entity="TemplateEntities.Holiday"
      :employee="employee"
      @close="showGenerateModal = false"
      @save="onDocumentGenerated"
    />
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n"
import { PropType, computed, ref, watch } from "vue"
import { ColDef, GridApi, GridReadyEvent, ICellEditorParams } from "@ag-grid-community/core"
import {
  DocumentPlusIcon,
  ExclamationCircleIcon,
  EyeIcon,
  PencilSquareIcon,
  ShieldCheckIcon,
  XCircleIcon,
} from "@heroicons/vue/24/outline"
import { storeToRefs } from "pinia"
import { useRoute, useRouter } from "vue-router"
import i18n from "@/i18n"
import { ColumnTypes } from "@/components/table/cells/tableCellComponents"
import { HolidayTypes } from "@/modules/settings/types/settingTypes"
import HolidayRequestForm from "@/modules/holidays/components/HolidayRequestForm.vue"
import { can } from "@/plugins/permissionPlugin"
import HolidayLink from "@/modules/holidays/components/HolidayLink.vue"
import { useHolidayStore } from "@/modules/holidays/store/holidayStore"
import { ExportFormats, isHolidayApproved, isHolidayPending } from "@/modules/holidays/utils/holidayUtils"
import { getTableData } from "@/modules/common/utils/tableUtils"
import Data = API.Data
import ObjectValues = API.ObjectValues
import Holiday = App.Domains.Holidays.Models.Holiday
import { RowAction } from "@/components/table/tableTypes"
import HolidayDetails from "@/modules/holidays/components/HolidayDetails.vue"
import { useSettingsStore } from "@/modules/settings/store/settingsStore"
import { FilterTypes } from "@/components/table/filters/filterTypes"
import HolidayRequestStatus from "@/components/table/cells/HolidayRequestStatus.vue"
import HolidayInterval from "@/modules/holidays/components/HolidayInterval.vue"
import { TemplateEntities } from "@/modules/templates/store/templateStore"
import GenerateDocumentsModal from "@/modules/employees/components/GenerateDocumentsModal.vue"
import HolidayPreviewDialog from "@/modules/holidays/components/HolidayPreviewDialog.vue"
import { useEmployeeStore } from "@/modules/employees/store/employeeStore"
const props = defineProps({
  employeeId: {
    type: String,
  },
  type: {
    type: String as PropType<ObjectValues<typeof HolidayTypes>>,
  },
})
const urlParams = computed(() => {
  return {
    employee_id: props.employeeId,
    temporary_urls: true,
    active_employee: true,
    related: 'policy',
  }
})

const table = ref()
const showAddDialog = ref(false)
const showEditDialog = ref(false)
const showDetailsDialog = ref(false)
const showGenerateModal = ref(false)
const { t } = useI18n()

const holidayStore = useHolidayStore()
const settingsStore = useSettingsStore()
const { currentHoliday } = storeToRefs(holidayStore)

const employeeStore = useEmployeeStore()
const employee = computed(() => {
  const employee = employeeStore.getEmployeeById(currentHoliday.value?.attributes?.employee_id)
  return {
    id: employee?.id,
    relationships: employee?.relationships,
    ...(employee?.attributes || {}),
  }
})

const columns = computed<ColDef[]>(() => {
  return [
    {
      headerName: t('Type'),
      field: 'policy.name',
      minWidth: 160,
      autoHeight: true,
    },
    {
      headerName: t('Employee'),
      field: 'attributes.employee_id',
      type: ColumnTypes.EmployeeLink,
      minWidth: 200,
      initialHide: !can('approveRejectHolidays'),
      filter: props.employeeId ? null : FilterTypes.Employee,
    },
    {
      headerName: t('Start Date'),
      field: 'attributes.start_date',
      type: ColumnTypes.Date,
      minWidth: 140,
      maxWidth: 180,
      filter: FilterTypes.StartDate,
    },
    {
      headerName: t('End Date'),
      field: 'attributes.end_date',
      type: ColumnTypes.Date,
      minWidth: 140,
      maxWidth: 180,
    },
    {
      headerName: t('Paid'),
      field: 'relationships.policy.attributes.paid',
      type: ColumnTypes.YesNoStatus,
      minWidth: 80,
      maxWidth: 80,
      filter: FilterTypes.PaidStatus,
    },
    {
      headerName: t('Days off'),
      field: 'attributes.days_off',
      minWidth: 80,
      maxWidth: 140,
      cellClass: 'flex justify-center',
    },
    {
      headerName: t('Status'),
      field: 'attributes.status',
      minWidth: 140,
      type: ColumnTypes.HolidayRequestStatus,
      filter: FilterTypes.HolidayStatus,
    },
  ]
})

const grid = ref<GridApi>()

const router = useRouter()
const route = useRoute()

const rowActions = computed<RowAction[]>(() => {
  return [
    {
      label: i18n.t('View'),
      icon: EyeIcon,
      action: async (holiday: Data<Holiday>) => {
        await router.push(`/holidays/${holiday.id}/view`)
      },
    },
    {
      label: i18n.t('Edit'),
      icon: PencilSquareIcon,
      action: async (holiday: Data<Holiday>) => {
        holidayStore.currentHoliday = holiday
        showEditDialog.value = true
      },
      show: (row: Data<Holiday>) => {
        return holidayStore.canEditHoliday(row)
      },
    },
    {
      label: i18n.t('Approve'),
      icon: ShieldCheckIcon,
      action: async (row: Data<Holiday>, params: ICellEditorParams) => {
        await approveHoliday(row, params)
      },
      show: (row: Data<Holiday>) => {
        return holidayStore.canApproveHoliday(row)
      },
    },
    {
      label: i18n.t('Reject'),
      icon: ExclamationCircleIcon,
      action: async (row: Data<Holiday>, params: ICellEditorParams) => {
        await rejectHoliday(row, params)
      },
      show: (row: Data<Holiday>) => {
        return holidayStore.canRejectHoliday(row)
      },
    },
    {
      label: i18n.t('Generate Documents'),
      icon: DocumentPlusIcon,
      action: async (row: Data<Holiday>) => {
        showGenerateModal.value = true
        currentHoliday.value = row
      },
      show: (row: Data<Holiday>) => {
        return can('manageDocuments') && isHolidayApproved(row)
      },
    },
    {
      label: i18n.t('Cancel Request'),
      icon: XCircleIcon,
      action: async (row: Data<Holiday>, params: ICellEditorParams) => {
        await cancelHoliday(row, params)
      },
      show: (row: Data<Holiday>) => {
        return holidayStore.canCancelHoliday(row)
      },
    },
  ]
})

async function approveHoliday(row: Data<Holiday>, params: ICellEditorParams | GridReadyEvent) {
  await holidayStore.approveHoliday(row)
  params?.api?.redrawRows()
  await settingsStore.getHolidayBalances({ employee_id: row.attributes.employee_id })
}

async function rejectHoliday(row: Data<Holiday>, params: ICellEditorParams | GridReadyEvent) {
  await holidayStore.rejectHoliday(row)
  params?.api?.redrawRows()
  await settingsStore.getHolidayBalances({ employee_id: row.attributes.employee_id })
}

async function cancelHoliday(row: Data<Holiday>, params: ICellEditorParams | GridReadyEvent) {
  await holidayStore.cancelHoliday(row)
  params?.api?.redrawRows()
  await settingsStore.getHolidayBalances({ employee_id: row.attributes.employee_id })
}

function checkForDialogToOpen(id: string) {
  if (!id) {
    showDetailsDialog.value = false
    return
  }
  const data = getTableData<Data<Holiday>>(grid.value)
  const holiday = data.find(holiday => holiday.id === id)
  if (holiday) {
    holidayStore.currentHoliday = holiday
    showDetailsDialog.value = true
  }
}

function onDocumentGenerated() {
  showGenerateModal.value = false
  const holidayId = currentHoliday.value?.id
  setTimeout(() => {
    router.push(`/holidays/${holidayId}/view`)
  }, 500)
}

watch(() => route.query.id, (newId) => {
  checkForDialogToOpen(newId as string)
}, { immediate: true })

watch(() => route.query.open, (value) => {
  if (value) {
    showAddDialog.value = true
  }
}, { immediate: true })

watch(() => showDetailsDialog.value, async (value) => {
  if (!value) {
    await router.replace({ ...route, query: {} })
  }
})

function onHolidaySave() {
  showAddDialog.value = false
  showEditDialog.value = false
  table.value?.refresh()
}
</script>
