import { orderBy } from 'lodash-es'
import axios from 'axios'
import { defineStore } from 'pinia'
import { differenceInDays, parseISO } from 'date-fns'
import Cache from '@/modules/common/Cache'
import { useAuthStore } from '@/modules/auth/store/authStore'
import { useEmployeeStore } from '@/modules/employees/store/employeeStore'
import i18n from '@/i18n'
import Employee = App.Domains.Employees.Models.Employee
import Data = API.Data
import { DashboardData } from "@/modules/dashboard/models/dashboardModels"

export const DataTypes = {
  Holiday: 'holidays',
  Evaluation: 'evaluations',
  Document: 'documents',
  Root: 'timelines',
  UpcomingMembers: 'upcomingMembers',
  UpcomingBirthdays: 'upcomingBirthdays',
}

export const TimelineTypes = {
  Holiday: 'holiday',
  NationalHoliday: 'national_holiday',
  Announcement: 'announcement',
  News: 'news',
  Personal: 'personal',
  Evaluation: 'evaluation',
  Birthday: 'birthday',
  LegalHoliday: 'legal_holiday',
  ExternalCalendar: 'external_calendar',
  Other: 'other',
}

export const useDashboardStore = defineStore('dashboard', {
  state: () => {
    return {
      timelineEvents: [] as any,
      dashboardData: {
        legal_holidays: [],
        holidays: [],
        evaluations: [],
        birthdays: [],
        timelines_personal: [],
      } as DashboardData,
      sharedTimelineEvents: [] as any,
      sharedTimelineEventsLoading: false,
      personalSharedTimelineEvents: [] as any,
      personalSharedTimelineEventsLoading: false,
      timelineEventsLoading: false,
      myActivity: {} as any,
      myActivityLoading: false,
    }
  },
  actions: {
    async getCalendarEvents({ project_ids, start_date, end_date }: {
      project_ids: string[]
      start_date?: string
      end_date?: string
    }) {
      try {
        this.timelineEventsLoading = true
        const projectIds = JSON.stringify(project_ids)
        const query = `?event_types=["holidays", "evaluations", "legal_holidays", "birthdays", "timelines_personal"]&project_ids=${projectIds}`
        const res = await axios.get(`/restify/employees/getters/dashboard-events${query}`, {
          params: {
            start_date,
            end_date,
          },
        })
        this.dashboardData = res.data
      } finally {
        this.timelineEventsLoading = false
      }
    },
    async getSharedCalendarEvents(params: any) {
      try {
        this.sharedTimelineEventsLoading = true
        this.sharedTimelineEvents = await Cache.getRequest(`/calendar/${params.id}`, {
          params,
        })
      } finally {
        this.sharedTimelineEventsLoading = false
      }
    },
    async getPersonalSharedCalendarEvents(params: any) {
      try {
        this.personalSharedTimelineEventsLoading = true
        const { data } = await Cache.getRequest(`/calendar/${params.id}`, {
          params: {
            ...params,
            id: undefined,
          },
        })
        this.personalSharedTimelineEvents = data
      } finally {
        this.personalSharedTimelineEventsLoading = false
      }
    },
    async getPersonalCalendarEvents(params: any) {
      try {
        this.personalSharedTimelineEventsLoading = true
        const { data } = await Cache.getRequest(`/calendar`, {
          params,
        })
        this.personalSharedTimelineEvents = data
      } finally {
        this.personalSharedTimelineEventsLoading = false
      }
    },
    async shareCalendar() {
      const { data } = await axios.post('/restify/shareables', {
        type: 'calendar',
      })
      return data
    },
    async getMyActivity(force = false) {
      try {
        if (force) {
          this.myActivity = {}
        }
        const authStore = useAuthStore()
        this.myActivityLoading = true
        const page = this.myActivity?.meta?.current_page + 1 || 1
        const lastPage = this.myActivity?.meta?.last_page
        if (lastPage && page > lastPage) {
          return
        }
        const data: any = await axios.get('/restify/timelines', {
          params: {
            employee_id: authStore.userEmployeeId,
            related: 'timelineable',
            page: this.myActivity?.meta?.current_page + 1 || 1,
          },
        })
        if (page === 1) {
          this.myActivity = data
        } else {
          this.myActivity.meta = data?.meta
          this.myActivity.data = this.myActivity.data.concat(data.data)
        }
      } finally {
        this.myActivityLoading = false
      }
    },
  },
  getters: {
    companyNews() {
      const employeeStore = useEmployeeStore()
      const employees = employeeStore.allEmployees
      const upcomingMembers = employees.filter((employee: Data<Employee>) => {
        const startDate = parseISO(employee.attributes.start_date || '')
        return startDate?.getTime() > new Date().getTime()
      })
      let upcomingBirthdays: any = employees.filter((employee: Data<Employee>) => {
        const birthDate = parseISO(employee.attributes.birth_date || '')
        const now = new Date()
        birthDate.setFullYear(now.getFullYear())

        const daysDiff = differenceInDays(birthDate, now)
        const maxDays = 30
        return daysDiff > 0 && daysDiff <= maxDays
      })
      upcomingBirthdays = orderBy(upcomingBirthdays, 'attributes.birth_date', 'asc')
      const response: any = {
        data: [],
      }
      if (upcomingMembers.length > 0) {
        response.data.push({
          id: 1,
          type: DataTypes.UpcomingMembers,
          attributes: {
            title: i18n.t('Upcoming Members'),
            data: upcomingMembers,
          },
        })
      }
      if (upcomingBirthdays.length > 0) {
        response.data.push({
          id: 2,
          type: DataTypes.UpcomingBirthdays,
          attributes: {
            title: i18n.t('Upcoming Birthdays'),
            data: upcomingBirthdays,
          },
        })
      }
      return response
    },
  },
})
