<template>
  <ElDialog
    v-model="showDialog"
    top="5vh"
    width="70%"
    :append-to-body="true"
    :draggable="true"
    class="document-dialog"
    @closed="onClose"
  >
    <template #header>
      <div class="flex w-full justify-between pr-10">
        <FileInfo :data="currentDocument" :loading="currentDocumentLoading" />
        <div class="flex items-center space-x-2">
          <BaseTooltip :content="$t('Enter Full Screen')">
            <button
              class="btn btn-square btn-primary h-9 w-9 btn-outline btn-sm bg-primary/10 border-primary/10"
              type="button"
              @click="enter"
            >
              <ArrowsPointingOutIcon class="w-4 h-4" />
            </button>
          </BaseTooltip>
          <BaseTooltip v-if="isValidFileType" :content="$t('Download')">
            <button
              class="btn btn-square btn-primary h-9 w-9 btn-outline btn-sm bg-primary/10 border-primary/10"
              type="button"
              @click="documentsStore.downloadCurrentFile"
            >
              <FolderArrowDownIcon class="w-4 h-4" />
            </button>
          </BaseTooltip>
          <BaseTooltip
            v-if="canBeSigned"
            :content="getSignTooltip"
          >
            <button
              class="btn btn-square btn-primary h-9 w-9 btn-outline btn-sm bg-primary/10 border-primary/10"
              type="button"
              @click="openSignDialog"
            >
              <LoadingIcon v-if="signRequestLoading" class="text-white" />
              <svg
                v-else class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"
              >
                <path d="m12 19 7-7 3 3-7 7-3-3z" />
                <path d="m18 13-1.5-7.5L2 2l3.5 14.5L13 18l5-5z" />
                <path d="m2 2 7.586 7.586" />
                <circle cx="11" cy="11" r="2" />
              </svg>
            </button>
          </BaseTooltip>
        </div>
      </div>
    </template>
    <PrepareForSigningModal
      v-if="showSigningDialog"
      v-model="showSigningDialog"
      :file-id="fileId"
      @close="closeSignDialog"
    />
    <FilePreviewDetails ref="preview" />
  </ElDialog>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from "vue"
import { storeToRefs } from "pinia"
import { useRoute, useRouter } from "vue-router"
import { ArrowsPointingOutIcon, FolderArrowDownIcon } from '@heroicons/vue/24/outline'
import { useFullscreen } from "@vueuse/core"
import FileInfo from "@/modules/documents/components/FileInfo.vue"
import FilePreviewDetails from "@/modules/documents/components/FilePreviewDetails.vue"
import { useDocumentsStore } from "@/modules/documents/store/documentsStore"
import BaseTooltip from "@/components/common/BaseTooltip.vue"
import PrepareForSigningModal from "@/modules/documents/components/PrepareForSigningModal.vue"
import { DocumentStatus } from "@/modules/documents/types/documentStatus"
import i18n from "@/i18n"
import LoadingIcon from "@/components/common/buttons/LoadingIcon.vue"
import { isAcceptedFile } from "@/modules/documents/utils/documentUtils";

const props = defineProps({
  fileId: {
    type: String,
  },
})

const preview = ref<HTMLElement | null>(null)

const { enter, exit, toggle } = useFullscreen(preview)
const showSigningDialog = ref<boolean>(false)
const showDialog = ref<boolean>(!!props.fileId)
const documentsStore = useDocumentsStore()
const { currentDocument, currentDocumentLoading } = storeToRefs(documentsStore)

const isNewDocument = computed(() => {
  return [DocumentStatus.New].includes(currentDocument.value?.attributes?.status)
})
const canBeSigned = computed(() => {
  // TODO will be added later on when sign integration is ready
  return false
})

const isSigned = computed(() => {
  return [DocumentStatus.Signed, DocumentStatus.SignedAndDownloaded, DocumentStatus.Downloaded].includes(currentDocument.value?.attributes?.status)
})
const getSignTooltip = computed(() => {
  if (isSigned.value) {
    return i18n.t('Document signed')
  }
  if (isNewDocument.value) {
    return i18n.t('Prepare for signing')
  }
  return i18n.t('This document was already sent for signing')
})
const router = useRouter()
const route = useRoute()

async function onClose() {
  const path = route.path

  await router.push({
    path,
  })
}

function closeSignDialog() {
  showSigningDialog.value = false
}

const documentStore = useDocumentsStore()
async function openSignDialog() {
  if (isSigned.value) {
    await getSignRequestLink()
    return
  }
  showSigningDialog.value = true
}

const signRequestLoading = ref(false)
async function getSignRequestLink() {
  try {
    signRequestLoading.value = true
    const data = await documentStore.prepareDocumentForSigning({ signers: [] }, props.fileId as string)
    window.location.href = data.data.prepare_url
  } finally {
    signRequestLoading.value = false
  }
}

const isValidFileType = computed(() => {
  return isAcceptedFile({ name: currentDocument.value?.attributes?.file_name } as File)
})

watch(() => props.fileId, async (id: any) => {
  showDialog.value = !!id
  await documentsStore.getDocument(id)
})

onMounted(async () => {
  await documentsStore.getDocument(props.fileId as string)
})
</script>

<style>
.document-dialog {
  @apply bg-background rounded;
}

.document-dialog .el-dialog__body {
  @apply py-2;
  height: calc(100vh - 10vh - 62px);
}
</style>
