import { computed } from "vue"
import { storeToRefs } from "pinia"
import { useAuthStore } from "@/modules/auth/store/authStore"
import { can } from "@/plugins/permissionPlugin"

export function useAuth() {
  const authStore = useAuthStore()
  const {
    profile,
    isHR,
    isAdmin,
    isManager,
    isEmployee,
    userEmployeeId,
    isOwnEmployee,
    hasEmployeeId,
    isLoggedIn,
    tenantData,
    roles,
  } = storeToRefs(authStore)

  const userEmail = computed(() => profile.value?.email)

  return {
    can,
    user: profile,
    userEmail,
    tenant: tenantData,
    roles,
    isLoggedIn,
    isHR,
    isAdmin,
    isManager,
    isEmployee,
    isOwnEmployee,
    hasEmployeeId,
    userEmployeeId,
  }
}
