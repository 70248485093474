<template>
  <BaseSelect
    v-bind="allAttrs"
    :has-groups="true"
    :options="policyGroups"
    :data-loading="holidayPoliciesLoading"
    value-key="id"
    label-key="attributes.name"
    filterable
    :model-value="value"
    @update:model-value="handleChange"
  />
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia"
import { computed } from "vue"
import { groupBy, orderBy } from "lodash-es"
import { useSettingsStore } from "@/modules/settings/store/settingsStore"
import { useFormKitInput } from "@/components/formkit/useFormkitInput"
import { HolidayTypeLabels, HolidayTypes } from "@/modules/settings/types/settingTypes"
const props = defineProps({
  context: Object,
})
import HolidayPolicy = App.Domains.HolidayPolicies.Models.HolidayPolicy
import Data = API.Data
import i18n from "@/i18n"
const settingsStore = useSettingsStore()
const { holidayPolicies, holidayPoliciesLoading } = storeToRefs(settingsStore)
const { value, allAttrs, handleChange } = useFormKitInput(props)

const policyGroups = computed(() => {
  const contractType = props.context?.attrs?.contract_type
  const policies = holidayPolicies.value.filter((policy: Data<HolidayPolicy>) => {
    if (!contractType) {
      return true
    }
    return policy.attributes.applicable_contract_types.includes(contractType)
  })
  const groups = groupBy(policies, 'attributes.type')
  let groupedData = []

  for (const key in groups) {
    groupedData.push({
      label: i18n.t(HolidayTypeLabels[key] as string),
      order: key === HolidayTypes.TimeOff ? 0 : 1,
      options: groups[key],
    })
  }
  groupedData = orderBy(groupedData, 'order')
  return groupedData
})
</script>
