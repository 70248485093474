<template>
  <div>
    <router-link
      v-for="employeeId in data"
      :key="employeeId"
      :to="`/employees/${employeeId}/details`"
      class="no-underline text-primary bg-primary/10 px-3 py-2 min-w-[80px] mr-1"
    >
      {{ getEmployeeName(employeeId) }}
    </router-link>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue"
import { storeToRefs } from "pinia"
import { useEmployeeStore } from "@/modules/employees/store/employeeStore"

const props = defineProps({
  params: {
    type: Object,
    default: () => ({}),
  },
})

const data = computed(() => {
  const employees = props.params.value || []
  return employees || []
})

const employeeStore = useEmployeeStore()
const { allEmployees } = storeToRefs(employeeStore)

function getEmployee(id: string) {
  return allEmployees.value.find(e => e.id === id)?.attributes || {}
}

function getEmployeeName(id: string) {
  const employee = getEmployee(id)
  const { first_name, last_name } = employee
  const lastNameAbbr = last_name?.substring(0, 1) || ''
  return `${first_name} ${lastNameAbbr}.`
}
</script>
