<template>
  <BaseSelect
    ref="select"
    v-model="value"
    v-focus
    filterable
    :append-to-body="true"
    :value-key="params.valueKey"
    :options="params.options"
    class="w-full"
    @change="onChange"
  />
</template>

<script>
import { useCellEditor } from "@/components/table/cells/editors/useCellEditor"
import BaseSelect from "@/components/form/BaseSelect.vue"

export default {
  components: {
    BaseSelect,
  },
  setup(props) {
    return useCellEditor(props.params)
  },
}
</script>
