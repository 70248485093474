<template>
  <div class="flex justify-between px-2.5">
    <div class="hidden md:block text-sm font-semibold">
      {{ $t('Actions') }}
    </div>
    <el-dropdown
      :hide-on-click="false"
      trigger="click"
      placement="bottom-end"
    >
      <div tabindex="0" class="ml-1">
        <ViewColumnsIcon class="w-5 h-5"/>
      </div>
      <template #dropdown>
        <el-dropdown-menu>
          <li
            v-for="column in getToggleColumns()"
            :key="column.getColId()"
            class="px-2 py-1"
          >
            <BaseCheckbox
              :checked="column.visible"
              class="checkbox-sm"
              @change="setColumnVisible(column, $event)"
            >
              {{ column.getColDef()?.headerName }}
            </BaseCheckbox>
          </li>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
  </div>
</template>

<script setup lang="ts">
import { ViewColumnsIcon } from '@heroicons/vue/24/outline'
import { ColDef, Column } from '@ag-grid-community/core'
import { useStorage } from '@vueuse/core'

const props = defineProps({
  params: {
    type: Object,
    default: () => ({}),
  },
})

const storageColumns = useStorage(props.params.context.storageKey, {})

function getToggleColumns(): Column[] {
  const allColumns: Column[] = props.params.columnApi.getColumns()
  return allColumns.filter((col) => {
    const colDef = col.getColDef()
    return !colDef.lockVisible && !colDef.initialHide
  })
}

function setColumnVisible(col: Column, event: any) {
  const value = event.target?.checked
  props.params.columnApi.setColumnVisible(col, value)
  const colDefs: ColDef[] = props.params.api.getColumnDefs()
  storageColumns.value = colDefs
}
</script>
<style lang="scss">
.actions-header {
  .ag-cell-wrapper {
    @apply w-full;
  }
}
</style>
