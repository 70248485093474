<template>
  <div class="h-full flex flex-col account-page">
    <BaseFormCard
      :submit-text="t('Save')"
      :disabled="loading"
      class="flex-1 !max-h-[none]"
      @submit="updateSettings"
    >
      <FormCardSection>
        <div class="col-span-6 mb-4">
          <IconBox size="sm" class="mb-3">
            <Cog6ToothIcon class="w-4 h-4" />
          </IconBox>
          <h4 class="mb-3">
            {{ t('Invoice settings') }}
          </h4>
          <h5 class="mb-3">
            {{ t('Information about your company, which will show up on all future invoices') }}
          </h5>

          <FormKit
            v-model="model[SettingKeys.InvoiceCompanyAddress]"
            type="textarea"
            :name="t('Address')"
            :label="t('Address')"
            :help="t('This address will be selected as the default from address for all invoices.')"
            :rows="6"
            validation="required"
            validation-visibility="dirty"
            outer-class="col-span-6 md:col-span-4 mt-2 mb-6"
          />

          <FileUpload
            v-model="model[SettingKeys.InvoiceLogo]"
            accept="image/*"
            :label="model[SettingKeys.InvoiceLogo] ? $t('Change company logo') : $t('Upload company logo')"
            type="invoice_logo"
          />
          <div class="formkit-help text-xs text-gray-500 leading-4 mt-4">
            {{ $t('Your logo will be displayed on the top left corner of invoices. For best pdf results, please make sure your logo image is at least 500 pixels wide. Logos can be JPG, SVG, or PNG images under 3MB in size.') }}
          </div>
        </div>
        <h5 class="mb-3 col-span-6">
          {{ t('Invoice default values') }}
        </h5>
        <FormKit
          v-model="model[SettingKeys.InvoiceDueDays]"
          :label="t('Due date')"
          :options="dueDateOptions"
          type="customSelect"
          outer-class="col-span-6 md:col-span-2"
        />
        <FormKit
          v-model="model[SettingKeys.InvoiceTax]"
          :label="t('Default Tax')"
          :placeholder="t('Tax %')"
          type="number"
          validation="min:0|max:99"
          outer-class="col-span-6 md:col-span-2"
        />
        <FormKit
          v-model="model[SettingKeys.InvoiceSubject]"
          :label="t('Subject')"
          type="text"
          outer-class="col-span-6"
          :help="t('You can define a subject which will be used as the default subject for all future invoices. You can still change it before sending invoices')"
        />
        <FormKit
          v-model="model[SettingKeys.InvoiceNotes]"
          :label="t('Notes')"
          type="textarea"
          :help="t('Invoice notes show below the invoice items. You can define a default note which will be used for all future invoices.')"
          :rows="4"
          outer-class="col-span-6"
        />
      </FormCardSection>
      <FormCardSection>
        <div class="col-span-6 mb-4">
          <IconBox size="sm" class="mb-3">
            <PaperAirplaneIcon class="w-4 h-4" />
          </IconBox>
          <h4 class="mb-3">
            {{ t('Send messages as') }}
          </h4>
          <h5 class="mb-3">
            {{ t('Set up an email address to send invoices from. This address will be the default reply to email address for all invoice related emails.') }}
          </h5>
        </div>
        <ReplyToEmails class="col-span-6"/>
      </FormCardSection>
    </BaseFormCard>
  </div>
</template>

<script setup lang="ts">
import { Cog6ToothIcon, PaperAirplaneIcon } from "@heroicons/vue/24/outline"
import { useI18n } from "vue-i18n"
import { computed } from "vue"
import { useAuth } from "@/modules/auth/composables/useAuth"
import { SettingKeys } from "@/modules/auth/types/enums"
import FileUpload from "@/components/form/FileUpload.vue"
import { useSettings } from "@/modules/settings/composables/useSettings"
import { getDueDateOptions } from "@/modules/invoices/utils/invoiceUtils"
import ReplyToEmails from "@/modules/invoices/components/ReplyToEmails.vue";

const { user, tenant } = useAuth()
const { t } = useI18n()

const { model, loading, updateSettings } = useSettings([
  SettingKeys.InvoiceLogo,
  SettingKeys.InvoiceCompanyAddress,
  SettingKeys.InvoiceTax,
  SettingKeys.InvoiceSubject,
  SettingKeys.InvoiceNotes,
  SettingKeys.InvoiceDueDays,
])

const dueDateOptions = computed(() => {
  return getDueDateOptions()
})
</script>

<route lang="yaml">
name: Invoice Settings
</route>
