import i18n from "@/i18n";
import timezones from "@/components/formkit/timezones.json";

const { t } = i18n

export enum EmployeeStatus {
  Draft = 'draft',
  Invited = 'invited',
  Active = 'active',
  Archived = 'archived',
}


export enum ContractTypes {
  EmploymentContract = 'employment_contract',
  B2BPaidHolidays = 'b2b_paid_holidays',
  B2BUnPaidHolidays = 'b2b_unpaid_holidays',
}

export enum SalaryTypes {
  Monthly = 'monthly',
  Weekly = 'weekly',
  Hourly = 'hourly',
}

export const contractTypeOptions = [
  {
    value: ContractTypes.EmploymentContract,
    label: i18n.t('Employment Contract')
  },
  {
    value: ContractTypes.B2BPaidHolidays,
    label: i18n.t('B2B - Paid Holidays')
  },
  {
    value: ContractTypes.B2BUnPaidHolidays,
    label: i18n.t('B2B - Unpaid Holiday')
  },
]

export const salaryTypeOptions = [
  {
    value: SalaryTypes.Hourly,
    label: t('Hourly'),
  },
  {
    value: SalaryTypes.Monthly,
    label: t('Monthly'),
  },
]


export function getContractTypeLabel(value: string) {
  const option = contractTypeOptions.find(option => option.value === value)
  return option ? option.label : value
}

export function getSalaryTypeLabel(value: string) {
  const option = salaryTypeOptions.find(option => option.value === value)
  return option ? option.label : value
}

export function getTimezoneLabel(value: string) {
  return timezones.find(t => t.value === value)?.text || value
}

export function isB2BContract(contractType: ContractTypes) {
  return [ContractTypes.B2BPaidHolidays, ContractTypes.B2BUnPaidHolidays].includes(contractType)
}

type Address = {
  street: string;
  city: string;
  state: string;
  country: string;
}

export function formatAddress(address: Address) {
  const { street, city, state, country } = address || {}
  let addressParts = [street, state, city, country]
  addressParts = addressParts.filter(part => !!part)

  return addressParts.join(', ')
}

export function getEmployeeFullName(employee: any) {
  if (!employee) {
    return ''
  }
  const hasAttributes = employee?.attributes && Object.keys(employee?.attributes).length > 0
  if (!hasAttributes) {
    return `${employee?.first_name} ${employee?.last_name}`
  }
  return `${employee?.attributes?.first_name} ${employee?.attributes?.last_name}`
}
