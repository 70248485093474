<template>
  <div class="audio-recorder">
    <div class="flex items-center space-x-2 bg-gray-50 rounded-full px-4 py-2">
      <BaseTooltip
        v-if="hasFile"
        :content="isPlaying ? $t('Pause') : $t('Play')"
      >
        <button
          type="button"
          class="widget-button"
          @click="playOrPauseAudio"
        >
          <PauseIcon v-if="isPlaying" class="w-4 h-4" />
          <PlayIcon v-else class="w-4 h-4" />
        </button>
      </BaseTooltip>
      <div ref="containerRef" class="flex-1" />
      <p class="text-gray-600">
        <template v-if="!hasFile">
          {{ currentTime }}
        </template>
        <template v-else>
          {{ currentTimeFormatted }} / {{ totalTimeFormatted }}
        </template>
      </p>
    </div>
    <div
      v-if="canRecord"
      class="flex flex-col items-center justify-center mt-2">
      <div
        v-if="showAudioRecordButton"
        class="flex items-center space-x-1"
      >
        <BaseTooltip
          :content="$t('Start Recording')"
        >
          <button
            type="button"
            class="widget-button"
            @click="startAudioRecordHandler"
          >
            <MicrophoneIcon class="w-4 h-4"/>
          </button>
        </BaseTooltip>
      </div>
      <div v-else class="flex space-x-2">
        <BaseTooltip v-if="!converting" :content="isPauseResume ? $t('Pause') : $t('Resume')">
          <button
            type="button"
            class="widget-button"
            @click="pauseRecording"
          >
            <PauseIcon v-if="isPauseResume" class="w-4 h-4"/>
            <PlayIcon v-else class="w-4 h-4"/>
          </button>
        </BaseTooltip>
        <div class="flex items-center space-x-2">
          <BaseTooltip :content="$t('Stop recording')">
            <button
              type="button"
              class="widget-button !bg-red-500"
              @click="stopHandler"
            >
              <StopIcon v-if="!converting" class="w-4 h-4"/>
              <div v-else class="loading loading-spinner text-white w-4 h-4"/>
            </button>
          </BaseTooltip>
          <span v-if="converting" class="text-sm text-gray-600">{{ $t('Converting recorded audio to text...') }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { MicrophoneIcon, PauseIcon, PlayIcon } from '@heroicons/vue/24/outline'
import { StopIcon } from '@heroicons/vue/24/solid'
import axios from "axios"
import { useWaveSurferRecorder } from '../composables/useWaveSurferRecorder'
import { primaryColor } from "@/modules/reports/enum/reportEnums"
import BaseTooltip from "@/components/common/BaseTooltip.vue"
import { error } from "@/components/common/NotificationPlugin"
import { useWaveSurferState } from "@/components/audio/composables/useWaveSurferState"
import { can } from "@/plugins/permissionPlugin";

const showAudioRecordButton = ref<boolean>(true)
const containerRef = ref<HTMLDivElement | null>(null)

const props = defineProps({
  convertSpeechToText: {
    type: Boolean,
    default: false,
  },
  fileUrl: {
    type: String,
  },
  canRecord: {
    type: Boolean,
    default: true,
  },
  loadOnPlay: {
    type: Boolean,
    default: false,
  },
})

const options = computed(() => ({
  height: 48,
  waveColor: "#d1d5db",
  progressColor: primaryColor,
  barGap: 5,
  barWidth: 5,
  barRadius: 8,
  interact: true,
  url: props.loadOnPlay ? undefined : props.fileUrl,
}))

const { waveSurfer, recordingTime, isRecording, pauseRecording, startRecording, stopRecording, currentTime, isPauseResume } = useWaveSurferRecorder({
  containerRef,
  options: options.value,
})

const hasFile = computed(() => {
  return (recordingTime.value > 0 || props.fileUrl) && !isRecording.value
})

const { isPlaying, currentTimeFormatted, totalTimeFormatted } = useWaveSurferState(waveSurfer)

function startAudioRecordHandler() {
  startRecording()
  showAudioRecordButton.value = false
}

async function playOrPauseAudio() {
  const hasData = waveSurfer.value?.getDecodedData()
  if (props.loadOnPlay && props.fileUrl && !hasData) {
    await waveSurfer.value?.load(props.fileUrl)
  }
  waveSurfer.value?.playPause()
}

const emit = defineEmits(['audioRecorded', 'audioConverted'])

async function stopHandler() {
  const blob = await stopRecording()
  const file = blobToFile(blob, 'feedback-recording.webm')
  waveSurfer.value?.loadBlob(blob)
  emit('audioRecorded', file)
  if (props.convertSpeechToText) {
    await convertSpeechToText(file)
  }
  showAudioRecordButton.value = true
}

function blobToFile(blob: Blob, fileName: string): File {
  return new File([blob], fileName, { type: blob.type })
}

const converting = ref(false)
const { t } = useI18n()

async function convertSpeechToText(file: File) {
  try {
    if (!can('useAi')) {
      error(t('Unfortunately, speech to text conversion is not available for this account.'))
      return
    }
    converting.value = true
    const formData = new FormData()
    formData.append('audio', file)
    const data: any = await axios.post(`/speech-to-text`, formData)
    emit('audioConverted', data.text)
  } catch (err) {
    console.error(err)
    error(t('Failed to convert speech to text'))
  } finally {
    converting.value = false
  }
}
</script>

<style lang="scss">
.audio-recorder .widget-button {
  @apply border-none rounded-full bg-primary flex items-center justify-center text-white p-2 w-10 h-10 hover:bg-primary/80;
}
</style>
