<template>
  <BaseButton
    v-bind="$attrs"
    variant="primary"
    outline
    class="print:hidden"
    :left-icon="ShareIcon"
  >
    <span>{{ t('Share') }}</span>
  </BaseButton>
</template>

<script setup lang="ts">
import { ShareIcon } from '@heroicons/vue/24/outline'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

const props = defineProps({
  sourceText: {
    type: String,
  },
})

const emit = defineEmits(['copied'])

const { t } = useI18n()
const route = useRoute()
</script>
