<template>
  <div ref="wrapper" class="w-full flex flex-col col-span-6 mt-4 task-list">
    <BaseDataTable
      :data="data"
      :columns="columns"
      :pagination="false"
      :hide-search="true"
      :get-row-class="getRowClass"
      :row-height="50"
      class="-mt-4"
      dom-layout="autoHeight"
      @grid-ready="grid = $event"
    >
      <template #delete="{ row }">
        <BaseButton
          v-if="edit && !isTaskArchived(row)"
          outline
          square
          size="xs"
          @click="archiveTask(row)"
        >
          <XMarkIcon class="w-4 h-4" />
        </BaseButton>
        <BaseButton
          v-if="edit && isTaskArchived(row)"
          outline
          size="xs"
          class="bg-white"
          @click="restoreTask(row)"
        >
          {{ $t('Restore') }}
        </BaseButton>
      </template>
    </BaseDataTable>
    <div v-if="edit" class="w-full max-w-[400px] mt-4">
      <FormKit
        id="taskForm"
        :actions="false"
        type="form"
        @submit="addNewTask"
      >
        <div class="flex space-x-2 items-start">
          <FormKit
            ref="taskName"
            v-model="model.name"
            :name="t('Task name')"
            :placeholder="$t('Add a task...')"
            outer-class="w-full task-name"
            validation="length:2"
          />
          <button
            type="button"
            class="btn btn-primary btn-outline btn-sm"
            @click="addNewTask"
          >
            {{ t('Add task') }}
          </button>
        </div>
      </FormKit>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, nextTick, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { XMarkIcon } from '@heroicons/vue/24/outline'
import { reset } from '@formkit/core'
import axios from 'axios'
import { Column, GridApi, RowClassParams, ValueSetterParams } from "@ag-grid-community/core"
import { error } from '@/components/common/NotificationPlugin'
import BaseDataTable from "@/components/table/BaseDataTable.vue"
import { $confirm } from "@/components/common/modal/modalPlugin";

const props = defineProps({
  tasks: {
    type: Array,
    default: () => [],
  },
  projectId: {
    type: String,
  },
  edit: {
    type: Boolean,
    default: true,
  },
})

const wrapper = ref()
const data = ref(props.tasks)
const model = ref({
  name: '',
})

const grid = ref<GridApi>()

const hasUnsavedTask = computed(() => {
  return model.value.name?.length > 2
})

watch(() => props.tasks, () => {
  data.value = props.tasks
})

const { t } = useI18n()

const columns = computed<Column[]>(() => {
  return [
    {
      headerName: '',
      field: 'delete',
      maxWidth: 120,
      resizable: false,
    },
    {
      headerName: '',
      field: 'attributes.name',
      resizable: false,
      editable: true,
      valueSetter: (params: ValueSetterParams) => {
        const name = params.newValue
        params.data.attributes.name = name
        patchTask(params.data.id, {
          name,
        })
        return true
      },
    },
  ]
})

function getRowClass(params: RowClassParams) {
  if (isTaskArchived(params.data)) {
    return '!bg-gray-50 opacity-80'
  }
  return ''
}

function isTaskArchived(row: any) {
  return row?.attributes?.archived
}

function patchTask(id: string, taskData: any) {
  return axios.patch(`/restify/tasks/${id}`, taskData)
}

async function addNewTask() {
  const temporaryId = crypto.randomUUID()
  try {
    const name = model.value.name
    if (!name) {
      return
    }
    if (isDuplicate(name)) {
      error(t('Task with this name already exists'))
      await focusOnTaskName()
      return
    }
    reset('taskForm')
    const row = {
      id: temporaryId,
      is_local: true,
      attributes: {
        name,
        description: '',
        project_id: props.projectId,
      },
    }
    grid.value!.applyTransaction({
      add: [row],
    })
    if (!props.projectId) {
      return
    }
    const task = await axios.post('/restify/tasks', {
      name,
      project_id: props.projectId,
    })
    grid.value!.applyTransaction({
      remove: [row],
    })
    grid.value!.applyTransaction({
      add: [task.data],
    })
    await focusOnTaskName()
  } catch (err) {
    const task = data.value.find((task: any) => task.id === temporaryId)
    grid.value!.applyTransaction({
      remove: [task],
    })
  }
}

function isDuplicate(taskName: string) {
  return data.value.some((task: any) => task.attributes.name === taskName)
}

async function focusOnTaskName() {
  setTimeout(() => {
    wrapper.value?.querySelector('.task-name input')?.focus()
  }, 50)
}

const taskSelectKey = ref(0)

async function archiveTask(task: any) {
  try {
    const confirmed = await $confirm({
      title: t('Archive task'),
      description: t(`This action will archive the task on the project. The task will be hidden from the project but will still be visible in reports. Are you sure?`),
      buttonText: t('Archive'),
    })
    if (!confirmed) {
      return
    }
    await nextTick()
    taskSelectKey.value++
    if (task.is_local) {
      return
    }
    await axios.post(`/restify/tasks/${task.id}/actions?action=archive`, {})
    task.attributes.archived = true
    updateGridRow(task)
  } catch (err) {
    updateGridRow(task)
  }
}

async function restoreTask(task: any) {
  try {
    const confirmed = await $confirm({
      title: t('Restore task'),
      description: t(`This action will restore the task on the project. Are you sure?`),
      buttonText: t('Restore'),
    })
    if (!confirmed) {
      return
    }
    await axios.post(`/restify/tasks/actions?action=unarchive`, {
      task_id: task.id,
    })
    task.attributes.archived = false
    updateGridRow(task)
  } catch (err) {
    console.error(err)
  }
}

function updateGridRow(task: any) {
  grid.value!.applyTransaction({
    update: [task],
  })
  grid.value!.redrawRows()
}

defineExpose({
  hasUnsavedTask,
})
</script>
