<template>
  <div>
    <h1 class="text-center">
      {{ $t('Reset Password') }}
    </h1>
    <div class="flex justify-center space-x-2 mt-2">
      <p>{{ $t(`Changed your mind?`) }}</p>
      <RouterLink to="/login">{{ $t('Sign In') }}</RouterLink>
    </div>

    <div class="mt-10">
      <FormKit
        #default="{ state: { loading } }"
        type="form"
        :actions="false"
        class="mt-10"
        @submit="onSubmit"
      >
        <FormkitPasswordInput
          v-model="model.password"
          :name="$t('New Password')"
          :label="$t('New Password')"
          :placeholder="$t('New Password')"
          validation="required|length:8"
          validation-visibility="dirty"
        />

        <FormkitPasswordInput
          v-model="model.password_confirmation"
          :name="$t('Confirm Password')"
          :label="$t('Confirm Password')"
          :placeholder="$t('Confirm Password')"
          :validation="`required|confirm:${$t('New Password')}`"
          validation-visibility="dirty"
          :validation-messages="{ confirm: $t('Passwords do not match') }"
        />

        <div class="w-full mt-12">
          <FormKit
            type="submit"
            :disabled="loading"
            :classes="{ input: 'w-full' }"
          >
            <span>{{ $t('Reset Password') }}</span>
          </FormKit>
        </div>
      </FormKit>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { reactive } from 'vue'
import { error, success } from '@/components/common/NotificationPlugin'
import FormkitPasswordInput from '@/components/formkit/FormkitPasswordInput.vue'
import { useAuthStore } from "@/modules/auth/store/authStore";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import ResetPasswordRequestParams = Auth.ResetPasswordRequestParams;
import { extractEmailFromString } from "@/modules/common/utils/stringUtils";

const router = useRouter()
const route = useRoute()
const { t } = useI18n()



const model: ResetPasswordRequestParams = reactive({
  email: extractEmailFromString(window.location.href) || route.query.email,
  token: route.query.token as string,
  password: '',
  password_confirmation: '',
})
const authStore = useAuthStore()

async function onSubmit() {
  try {
    await authStore.resetPassword(model)
    success(t('Password was reset successfully. We will automatically sign you in.'))
    const valid = await authStore.login({
      email: model.email,
      password: model.password,
    })
    if (!valid) {
      return
    }
    await router.push('/dashboard')
  } catch (err: any) {
    console.error(err)
    if (err.handled) {
      return
    }
    error(t('An error occurred while trying to login'))
  }
}
</script>

<route lang="yaml">
name: Reset Password
meta:
  layout: authLayout
</route>
