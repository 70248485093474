<template>
  <div>
    <BaseDataTable
      url="/restify/evaluations"
      :url-params="urlParams"
      :columns="columns"
      :add-text="$t('Add evaluation')"
      :row-height="60"
      :extra-actions="rowActions"
      :suppress-cell-focus="true"
      :delete-extra-confirmation="true"
      entity="evaluations"
      :actions="actions"
      class="h-full"
      @add="onAdd"
    >
      <template #attributes.salary_after="{ row, params }">
        <div class="flex items-center space-x-2 salary-after">
          <FormattedPrice
            :params="{
              currency: row.attributes.currency,
              ...params,
            }"
          />
          <ArrowTrendingUpIcon
            v-if="isSalaryRaise(row)"
            class="text-green-500 w-4 h-4"
          />
          <ArrowTrendingDownIcon
            v-else-if="isSalaryDrop(row)"
            class="text-red-500 w-4 h-4"
          />
          <ArrowLongRightIcon
            v-else
            stroke-width="2"
            class="text-yellow-500 w-4 h-4"
          />
        </div>
      </template>
    </BaseDataTable>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue"
import { ColDef, ICellRendererParams } from "@ag-grid-community/core"
import { ArrowLongRightIcon, ArrowTrendingDownIcon, ArrowTrendingUpIcon } from "@heroicons/vue/24/outline"
import { useI18n } from "vue-i18n"
import { useRouter } from "vue-router"
import FormattedPrice from "@/components/table/cells/FormattedPrice.vue"
import { ColumnTypes } from "@/components/table/cells/tableCellComponents"
import { can } from "@/plugins/permissionPlugin"
import { RowAction } from "@/components/table/tableTypes"
import { FilterTypes } from "@/components/table/filters/filterTypes"

const props = defineProps({
  employeeId: {
    type: String,
  },
})
const urlParams = ref({
  related: 'employee,type',
  active_employee: true,
  employee_id: props.employeeId,
})
const { t } = useI18n()

const columns = computed<ColDef[]>(() => {
  return [
    {
      headerName: t('Type'),
      field: 'attributes.type_id',
      type: ColumnTypes.EvaluationTypeLink,
      minWidth: 120,
      maxWidth: 120,
      pinned: 'left',
      cellRendererParams: (params: ICellRendererParams) => {
        return {
          link: {
            path: `/evaluations/${params.data.id}/view`,
            query: {
              employee_id: props.employeeId,
            },
          },
        }
      },
      filter: FilterTypes.EvaluationType,
    },
    {
      headerName: t('Employee'),
      field: 'attributes.name',
      type: ColumnTypes.EmployeeLink,
      minWidth: 150,
      maxWidth: 250,
      pinned: 'left',
      initialHide: !can('manageEvaluations'),
      filter: props.employeeId ? null : FilterTypes.Employee,
    },
    {
      headerName: t('Assignees'),
      field: 'attributes.assignees',
      type: ColumnTypes.EmployeeTags,
      minWidth: 200,
      maxWidth: 320,
      filter: FilterTypes.Assignees,
    },
    {
      headerName: t('Evaluated At'),
      field: 'attributes.evaluated_at',
      type: ColumnTypes.Date,
      minWidth: 140,
      filter: FilterTypes.EvaluatedAt,
    },
    {
      headerName: t('Next Evaluation'),
      field: 'attributes.next_evaluation_at',
      type: ColumnTypes.Date,
      minWidth: 200,
      filter: FilterTypes.NextEvaluation,
    },
    {
      headerName: t('Position Before'),
      field: 'attributes.position_before_id',
      type: ColumnTypes.PositionLink,
      minWidth: 160,
    },
    {
      headerName: t('Position After'),
      field: 'attributes.position_after_id',
      type: ColumnTypes.PositionLink,
      minWidth: 160,
    },
    {
      headerName: t('Salary Before'),
      field: 'attributes.salary_before',
      type: ColumnTypes.Price,
      cellRendererParams: (params: ICellRendererParams) => {
        return {
          currency: params.data.attributes.currency,
        }
      },
      minWidth: 160,
    },
    {
      headerName: t('Salary After'),
      field: 'attributes.salary_after',
      minWidth: 160,
    },
  ]
})

const actions = computed(() => {
  const baseActions = []
  if (can('manageEvaluations')) {
    baseActions.push('add')
    baseActions.push('edit')
    baseActions.push('delete')
  }
  return baseActions.join(',')
})

const rowActions = computed<RowAction[]>(() => {
  return []
})

const router = useRouter()

async function onAdd() {
  await router.push({
    path: '/evaluations/create',
    query: {
      employee_id: props.employeeId,
    },
  })
}

function isSalaryRaise(row: any) {
  return row.attributes.salary_after > row.attributes.salary_before
}

function isSalaryDrop(row: any) {
  return row.attributes.salary_after < row.attributes.salary_before
}
</script>

<style>
.salary-after svg {
  stroke-width: 2px;
}
</style>
