<template>
  <FilterInput
    v-bind="$attrs"
    :data="evaluationTypes"
    value-key="id"
    label-key="attributes.name"
    filterable
  />
</template>
<script lang="ts" setup>
import FilterInput from "@/components/table/filters/base/FilterInput.vue";
import { useI18n } from "vue-i18n";
import { useSettingsStore } from "@/modules/settings/store/settingsStore";
import { storeToRefs } from "pinia";

const { t } = useI18n()
const settingsStore = useSettingsStore()
const { evaluationTypes } = storeToRefs(settingsStore)
</script>
