<template>
  <div class="flex flex-col">
    <div class="flex items-end w-full justify-between">
      <PageTitle :loading="currentHolidayLoading">
        {{ holidayName }}
      </PageTitle>
    </div>
    <div
      v-if="currentHolidayError || currentHolidayLoading"
      class="flex-1 flex justify-center items-center h-[50vh]"
    >
      <LoadingTable v-if="currentHolidayLoading" />
      <div v-else class="flex flex-col items-center">
        <IconBox class="mb-4" color="error">
          <ExclamationTriangleIcon class="w-8 h-8" />
        </IconBox>
        <h2>{{ $t('An error occurred while trying to access the holiday information') }}</h2>
      </div>
    </div>
    <RouterView v-else class="flex-1" />
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, watch } from "vue"
import { storeToRefs } from "pinia"
import { ExclamationTriangleIcon } from "@heroicons/vue/24/outline"
import { useHead } from "@vueuse/head"
import { useRoute } from "vue-router"
import { useI18n } from "vue-i18n"
import { useHolidayStore } from "@/modules/holidays/store/holidayStore"

const { t } = useI18n()

const holidayStore = useHolidayStore()
const { currentHolidayLoading, currentHolidayError } = storeToRefs(holidayStore)
const route = useRoute()
const id = computed(() => route.params.id as string)

const { holidayName } = storeToRefs(holidayStore)

useHead({
  title: holidayName,
})
watch(() => id.value, async (value: string) => {
  await holidayStore.getHoliday(value)
})
onMounted(async () => {
  await holidayStore.getHoliday(id.value)
})
</script>

<route lang="yaml">
name: Holiday
redirect:
  name: Holiday Details
</route>
