<template>
  <div
    v-loading="true"
    :element-loading-svg="spinner"
    element-loading-svg-view-box="0 0 34.967 28"
    element-loading-background="rgb(249 250 251 / var(--tw-bg-opacity))"
    class="table-loading-animation"
  />
</template>
<script setup lang="ts">
const spinner = `<g data-name="Logo - Symbol">
        <path data-name="Path 4297" d="m563.622 6.632 6.578.17 6.235 2.39a.062.062 0 0 0 .076-.088L571.663.037a.069.069 0 0 0-.105-.021l-7.964 6.53a.048.048 0 0 0 .028.086" transform="translate(-541.551 .001)" style="fill:#149ea3"/>
        <path data-name="Path 4298" d="M508.753 107.793c-.727-1.466-1.367-3.035-2-4.611-.83-2.317-1.2-3.156-1.948-3.454a.915.915 0 0 0-.34-.074h-4.434a.052.052 0 0 0-.017.1c1.2.37 3.779 7.872 4.3 8.707.33.719.737.943 1.073.993h4.753c-.221-.017-.59-.047-1.391-1.659" transform="translate(-486.439 -86.359)" style="fill:#0c7a7a"/>
        <g data-name="Logo - Symbol">
            <path data-name="Path 4299" d="M407.261 144.531c-.727-1.466-1.367-3.035-2-4.611-.83-2.317-1.2-3.156-1.948-3.454a.916.916 0 0 0-.34-.074h-4.434a.052.052 0 0 0-.017.1c1.2.37 3.779 7.872 4.3 8.707.33.719.737.943 1.073.993h4.753c-.221-.017-.59-.047-1.391-1.659" transform="translate(-398.488 -118.195)" style="fill:#0c7a7a"/>
            <path data-name="Path 4300" d="m452.477 99.378-4.708-.032a1.01 1.01 0 0 0-.372.06c-1.733.6-5.947 14.686-7.437 14.686h4.682a.926.926 0 0 0 .623-.211c1.141-1.022 4.836-11.436 5.758-13.079.883-1.573 1.234-1.4 1.455-1.42" transform="translate(-434.428 -86.091)" style="fill:#149ea3"/>
            <path data-name="Path 4301" d="M549.841 33.593a9.807 9.807 0 0 1-1.833 1.554l.208-.02c-1.8 6.449-6.075 17.081-7.694 17.082h4.682a1.408 1.408 0 0 0 1.1-.392c1.245-1.2 3.759-6.483 5.9-15.677l.307.185a10.144 10.144 0 0 1-.539-1.9 8.7 8.7 0 0 1-2.133-.833" transform="translate(-521.574 -29.111)" style="fill:#149ea3"/>
        </g>
    </g>

`
</script>

<style lang="scss">

.table-loading-animation .el-loading-spinner .circular {
  @apply text-primary/60;
  @apply animate-pulse
}
</style>
