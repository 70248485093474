<template>
  <div class="h-full">
    <FeedbackTimeline
      :title="$t('Feedback Activity')"
      :feedbackable-id="currentEmployee.id"
      :feedbackable-type="FeedbackType.Employee"
    />
  </div>
</template>

<script lang="ts" setup>
import { storeToRefs } from "pinia"
import { useEmployeeStore } from "@/modules/employees/store/employeeStore"
import FeedbackTimeline from "@/modules/feedback/components/FeedbackTimeline.vue"
import { FeedbackType } from "@/modules/feedback/store/feedbackStore";

const { currentEmployee } = storeToRefs(useEmployeeStore())
</script>

<route lang="yaml">
name: Employee Feedback
</route>
