<template>
  <BaseFormCard
    :submit-text="t('Update')"
    :reset-on-cancel="true"
    :disabled="loading"
    @submit="onSubmit"
  >
    <FormCardSection :title="t('Profile information')">
      <div id="avatar-section" class="col-span-6 md:col-span-2 flex h-full justify-center items-center">
        <AvatarUpload v-model="model.avatar" :name="getEmployeeFullName(currentEmployee)" />
      </div>
      <div class="grid grid-cols-6 gap-x-4 col-span-6 md:col-span-4">
        <FormKit
          v-model="model.first_name"
          type="text"
          :label="t('First Name')"
          :placeholder="t('John')"
          validation="required"
          outer-class="col-span-6 md:col-span-3"
        />

        <FormKit
          v-model="model.last_name"
          type="text"
          :label="t('Last Name')"
          :placeholder="t('Doe')"
          validation="required"
          outer-class="col-span-6 md:col-span-3"
        />

        <FormKit
          v-model="model.phone"
          :label="t('Phone')"
          :placeholder="t('0741 234 567')"
          validation="phone"
          outer-class="col-span-6 md:col-span-3"
        />
        <div class="col-span-6 md:col-span-2" />
      </div>

      <FormKit
        v-model="model.about"
        type="textarea"
        :label="t('About')"
        :placeholder="t('I’m Spencer Sharp. I live in Cluj-Napoca, where I develop the future')"
        rows="4"
        validation="length:0,255"
        outer-class="col-span-6 md:col-span-4"
      />
      <FormKit
        v-model="model.quote"
        type="textarea"
        :label="t('Favorite quote')"
        :placeholder="t('A person who never made a mistake, never tried anything new. \n\nAlbert Einstein')"
        rows="4"
        validation="length:0,255"
        outer-class="col-span-6 md:col-span-2"
      />
    </FormCardSection>
  </BaseFormCard>
</template>

<script setup lang="ts">
import { onMounted, ref, watch } from 'vue'
import { useI18n } from "vue-i18n"
import { useRouter } from "vue-router"
import { storeToRefs } from "pinia"
import { useEmployeeStore } from "@/modules/employees/store/employeeStore"
import FormCardSection from "@/components/form/FormCardSection.vue"
import AvatarUpload from "@/components/form/AvatarUpload.vue"
import { jsonToFormData } from "@/modules/common/utils/formUtils"
import { error, success } from "@/components/common/NotificationPlugin"
import { getEmployeeFullName } from "@/modules/clients/utils/employeeUtils";
import { useAuth } from "@/modules/auth/composables/useAuth";

const { t } = useI18n()
const employeeStore = useEmployeeStore()
const auth = useAuth()
const { currentEmployee } = storeToRefs(employeeStore)

const model = ref({
  first_name: '',
  last_name: '',
  avatar: '',
  phone: '',
  about: '',
  quote: '',
})

initEmployee(currentEmployee.value)
function initEmployee(employee: any) {
  let employeeData = employee
  if (employee.id !== auth.userEmployeeId.value) {
    employeeData = {}
  }
  model.value = {
    first_name: employeeData.first_name,
    last_name: employeeData.last_name,
    avatar: employeeData.avatar,
    phone: employeeData.phone,
    about: employeeData.about,
    quote: employeeData.quote,
  }
}

watch(() => currentEmployee.value, initEmployee, { immediate: true })

const loading = ref(false)

async function onSubmit() {
  try {
    loading.value = true
    const formData = jsonToFormData(model.value)
    await employeeStore.updateOwnEmployee(formData)
    success(t('Profile updated'))
  } catch (err: any) {
    if (err.handled) {
      return
    }
    error(t('Could not update profile'))
  } finally {
    loading.value = false
  }
}

onMounted(async () => {
  await employeeStore.getOwnEmployee()
})
</script>

<style lang="scss">
#avatar-section {
  .formkit-no-files {
    @apply hidden;
  }
}
</style>

<route lang="yaml">
name: Profile
</route>
