<template>
  <router-view/>
</template>

<script lang="ts" setup>
import { onMounted } from 'vue'
import { useClientStore } from '@/modules/clients/store/clientStore'
import { useProjectStore } from '@/modules/projects/store/projectStore'
import { useReportsStore } from "@/modules/reports/store/reportsStore"

const clientStore = useClientStore()
const projectStore = useProjectStore()

const reportStore = useReportsStore()
reportStore.resetIdFilters()
onMounted(async () => {
  await Promise.all([
    projectStore.getAllProjects(),
    clientStore.getAllClients(),
  ])
})
</script>

<route lang="yaml">
name: Projects report
</route>
