<template>
  <div>
    <SettingsCrudPage
      :title="$t('Tags')"
      :modal-component="DocumentTagModal"
      :add-text="$t('Add document tag')"
      :edit-text="$t('Edit document tag')"
      :extra-columns="extraColumns"
      entity="tags"
      entity-path="tags"
    >
      <template #after-title>
        <DocumentTabs />
      </template>
    </SettingsCrudPage>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from "vue-i18n"
import DocumentTagModal from "@/modules/settings/components/DocumentTagModal.vue"
import SettingsCrudPage from "@/modules/settings/components/SettingsCrudPage.vue"
import DocumentTabs from "@/modules/settings/components/documents/DocumentTabs.vue"

const { t } = useI18n()
const extraColumns = computed(() => {
  return [
    {
      headerName: t('Color'),
      field: 'attributes.color',
    },
  ]
})
</script>

<route lang="yaml">
name: Tags
meta:
  permission: manageDocumentTypes
</route>
