<template>
  <TabLinks v-if="holidayTabs.length > 1" v-model="activeTab" :tabs="holidayTabs" />
</template>
<script setup lang="ts">
import { useFilterTabs } from "@/modules/common/composables/useFilterTabs";
import { computed } from "vue";
import i18n from "@/i18n";
import { CalendarDaysIcon } from "@heroicons/vue/24/outline";

const { activeTab, tabs } = useFilterTabs('holidays')

const holidayTabs = computed(() => {
  return [
    ...tabs.value,
    {
      label: i18n.t('Planner'),
      path: `/holidays/planner`,
      icon: CalendarDaysIcon,
    },
  ]
})
</script>
