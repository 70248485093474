<template>
  <div class="h-full flex flex-col account-page">
    <PageTitle class="mb-2" :show-bread-crumbs="true">
      {{ t('Billing') }}
    </PageTitle>
    <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <div>
        <nav class="isolate flex divide-x divide-gray-200 rounded-xl" aria-label="Tabs">
          <button
            v-for="(tab, tabIdx) in tabs" :key="tab.name" role="button" class="group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10"
            :class="[tab.current ? 'text-gray-900' : 'text-gray-500 hover:text-gray-700', tabIdx === 0 ? 'rounded-l-lg' : '', tabIdx === tabs.length - 1 ? 'rounded-r-lg' : '']" :aria-current="tab.current ? 'page' : undefined"
            @click="makeTabActive(tab)"
          >
            <span>{{ tab.name }}</span>
            <span aria-hidden="true" class="absolute inset-x-0 bottom-0 h-0.5" :class="[tab.current ? 'bg-primary' : 'bg-transparent']" />
          </button>
        </nav>
      </div>
      <div class="font-bold mt-8 text-center">
        {{ t('Current plan with seats', { planName: getPlanName, seatCount: availableSeats }) }}
      </div>
      <div
        class="-mx-4 mt-8 grid max-w-2xl grid-cols-1 gap-y-10 sm:mx-auto lg:-mx-8 lg:max-w-none lg:grid-cols-2 lg:gap-x-4 xl:mx-0 xl:gap-x-8"
      >
        <Plan
          :name="t('Free')"
          :price="t('Free')"
          :description="t('Good for anyone who is just getting started.')"
          :featured="false"
          :is-active="isPlanActive('Free')"
          :features="planFeatures.Free"
          @plan-selected="billingStore.onPlanSelected('Free')"
        />

        <Plan
          v-if="!isYearlyTabActive"
          :name="t('Pro')"
          :price="getPrice(monthlyPlan)"
          :description="t('Perfect for companies looking to streamline their processes.')"
          :featured="true"
          :features="planFeatures.Pro"
          :is-active="isPlanActive(monthlyPlan.name)"
          @plan-selected="billingStore.onPlanSelected(monthlyPlan.name)"
          @update-seats="billingStore.showAddSeatsModal"
        />

        <Plan
          v-if="isYearlyTabActive"
          :name="t('Pro')"
          :price="getPrice(yearlyPlan)"
          :description="t('Perfect for companies looking to streamline their processes.')"
          :featured="true"
          :features="planFeatures.Pro"
          :is-active="isPlanActive(yearlyPlan.name)"
          @plan-selected="billingStore.onPlanSelected(yearlyPlan.name)"
          @update-seats="billingStore.showAddSeatsModal"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref } from "vue"
import { useI18n } from "vue-i18n"
import { useRoute } from "vue-router"
import { useBillingStore } from "@/modules/settings/store/billingStore"
import Plan from "@/modules/settings/components/Plan.vue"
import { formatPrice } from "@/plugins/formatPrice";

const { t } = useI18n()
const billingStore = useBillingStore()
const dialog = ref(true)
const planFeatures = computed(() => {
  return {
    Free: [
      t('Up to 3 employees'),
      t('Leave management'),
      t('Document management'),
      t('Evaluations'),
    ],
    Pro: [
      t('Unlimited employees'),
      t('Time logging'),
      t('Reports & invoicing'),
      t('Templates'),
      t('Slack and Google Calendar integrations'),
    ],
  }
})

const route = useRoute()
const tabs = ref([
  {
    name: t('Monthly'),
    value: 'monthly',
    current: true,
  },
  {
    name: t('Yearly (20% off)'),
    value: 'yearly',
    current: false,
  },
])

const isYearlyTabActive = computed(() => {
  return tabs.value[1].current
})

const monthlyPlan = computed<Plan>(() => {
  return billingStore.plans.find((plan: Plan) => plan.name.includes('Monthly')) || {}
})

const yearlyPlan = computed<Plan>(() => {
  return billingStore.plans.find((plan: Plan) => plan.name.includes('Yearly')) || {}
})

const { locale } = useI18n()
function getPrice(plan: Plan) {
  let priceLocale = locale.value
  if (plan.currency === 'ron') {
    priceLocale = 'ro-ro'
  }
  const price = formatPrice(plan.price, { currency: plan.currency, locale: priceLocale })
  return `${price} / ${t('user')}`
}

const getPlanName = computed(() => {
  return billingStore.activePlan?.name
})

const availableSeats = computed(() => {
  return billingStore.seatData.total_subscription_seats
})

function makeTabActive(tab: any) {
  tabs.value.forEach((tab) => {
    tab.current = false
  })
  tab.current = true
}
function isPlanActive(name: string) {
  const activePlan = billingStore.activePlan
  return activePlan && activePlan?.name === name || false
}

onMounted(async () => {
  await billingStore.getPlans()
})
</script>

<route lang="yaml">
name: Billing
meta:
  permission: manageBilling
</route>
