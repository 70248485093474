<template>
  <ClientForm
    :client="currentClient"
    :loading="currentClientLoading"
  />
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia";
import ClientForm from "@/modules/clients/components/ClientForm.vue";
import { useClientStore } from "@/modules/clients/store/clientStore";

const clientStore = useClientStore()
const { currentClient, currentClientLoading } = storeToRefs(clientStore);
</script>
<route lang="yaml">
name: Client Edit
meta:
  permission: manageClients
</route>
