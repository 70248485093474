<template>
  <Panel v-if="items && items.length" class="overflow-y-auto max-w-[18rem] max-h-[18rem]">
    <Button
      v-for="(item, index) in items"
      :key="item.name"
      :active="index === selectedIndex"
      variant="ghost"
      class="!justify-start w-full"
      button-size="small"
      :data-emoji-name="item.name"
      @click="selectItem(index)"
    >
      <img v-if="item.fallbackImage" :src="item.fallbackImage" class="w-5 h-5" alt="emoji">
      <template v-else>
        {{ item.emoji }}
      </template>
      <span class="truncate text-ellipsis">:{{ item.name }}:</span>
    </Button>
  </Panel>
</template>

<script setup>
import { onMounted, onUnmounted, ref, watch } from 'vue'
import Button from "@/components/formkit/html/components/Button.vue"
import Panel from "@/components/formkit/html/components/panel/Panel.vue"
// Assuming EmojiItem type is already defined/imported if necessary

const props = defineProps({
  items: Array,
  command: Function,
})

const selectedIndex = ref(0)

watch(() => props.items, () => {
  selectedIndex.value = 0
}, { deep: true })

function selectItem(index) {
  const item = props.items[index]
  if (item) {
    props.command({ name: item.name })
  }
}

function scrollIntoView(index) {
  const item = props.items[index]
  if (item) {
    const node = document.querySelector(`[data-emoji-name="${item.name}"]`)
    if (node) {
      node.scrollIntoView({ block: 'nearest' })
    }
  }
}

function handleKeydown(params) {
  const event = params.event
  if (event.key === 'ArrowUp') {
    const newIndex = (selectedIndex.value + props.items.length - 1) % props.items.length
    selectedIndex.value = newIndex
    scrollIntoView(newIndex)
    return true
  }

  if (event.key === 'ArrowDown') {
    const newIndex = (selectedIndex.value + 1) % props.items.length
    selectedIndex.value = newIndex
    scrollIntoView(newIndex)
    return true
  }

  if (event.key === 'Enter') {
    selectItem(selectedIndex.value)
    return true
  }

  return false
}

defineExpose({
  onKeyDown: handleKeydown,
})
</script>
