<template>
  <div>
    <FormKit
      ref="form"
      :actions="false"
      #default="{ state: { loading } }"
      type="form"
      @submit="onSubmit"
    >
      <ElDialog
        :model-value="modelValue"
        :append-to-body="true"
        :draggable="true"
        :title="$t('Prepare for signing')"
        @update:modelValue="$emit('update:modelValue', $event)"
      >
        <FormKit
          v-model="model.signers"
          id="signers"
          name="signers"
          type="repeater"
          :label="$t('Signers')"
          :add-label="$t('Add signer')"
          :help="$t(`You can add up to 5 signers. Each signer will receive an email with a link to sign the document.\nYour email will be added automatically, however it's optional and you can remove it if you want.`)"
          :min="1"
          :max="5"
          #default="{ index }"
        >
          <FormkitEmailInput
            :label="`${index + 1}. ${$t(`Signer`)}`"
            :placeholder="$t('Enter your email')"
            name="email"
            validation="required|email"
            validation-visibility="dirty"
          />
        </FormKit>

        <template #footer>
          <div class="w-full flex justify-center space-x-2">
            <FormKit
              type="button"
              :classes="{ input: 'min-w-[128px] btn-outline' }"
              @click="$emit('close')"
            >
              <span>{{ $t('Cancel') }}</span>
            </FormKit>
            <FormKit
              type="submit"
              :classes="{ input: 'min-w-[128px]' }"
              :loading="prepareLoading"
              @click="onSubmitClick"
            >
              <span>{{ $t('Prepare for signing') }}</span>
            </FormKit>
          </div>
        </template>
      </ElDialog>
    </FormKit>
  </div>
</template>
<script lang="ts" setup>
import FormkitEmailInput from "@/components/formkit/FormkitEmailInput.vue";
import { DocumentSigningData, useDocumentsStore } from "@/modules/documents/store/documentsStore";
import { computed, ref } from "vue";
import { useAuth } from "@/modules/auth/composables/useAuth";
import { error } from "@/components/common/NotificationPlugin";
import i18n from "@/i18n";
import { storeToRefs } from "pinia";
import { useEmployeeStore } from "@/modules/employees/store/employeeStore";

const props = defineProps({
  modelValue: {
    type: Boolean,
  },
  fileId: {
    type: String,
  }
})

const form = ref<any>(null)
const { user } = useAuth()
const emit = defineEmits(['update:modelValue', 'close'])

const documentsStore = useDocumentsStore()
const employeeStore = useEmployeeStore()
const { currentDocument } = storeToRefs(documentsStore)
const employee = employeeStore.allEmployees.find(e => e.id === currentDocument.value?.attributes?.employee_id)
const model = ref<DocumentSigningData>({
  signers: [
    {
      email: employee?.attributes?.email || ''
    }
  ]
})

function onSubmitClick() {
  form.value?.node?.submit?.()
}

const documentStore = useDocumentsStore()
const prepareLoading = ref(false)

async function onSubmit() {
  try {
    prepareLoading.value = true
    const data = await documentStore.prepareDocumentForSigning(model.value, props.fileId as string)
    const url = data.data.prepare_url
    window.open(url)
  } catch (err: any) {
    if (err.handled) {
      return
    }
    error(i18n.t('Could not prepare document for signing.'))
  } finally {
    prepareLoading.value = false
  }
}
</script>
