<template>
  <ElTooltip
    :show-after="400"
    placement="top"
    v-bind="$attrs"
  >
    <template v-if="$slots.content" #content>
      <slot name="content" />
    </template>
    <slot />
  </ElTooltip>
</template>

<script setup lang="ts">
</script>
