<template>
  <div class="flex flex-col">
    <ProjectsTable class="flex-1" />
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import ProjectsTable from '@/modules/projects/components/ProjectsTable.vue'

const props = defineProps({})
const { t } = useI18n()
const route = useRoute()
</script>

<route lang="yaml">
name: Projects
</route>
