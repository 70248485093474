<template>
  <BaseButton
    v-bind="$attrs"
    variant="primary"
    outline
    class="print:hidden"
    :left-icon="PrinterIcon"
    @click="onPrint"
  >
    <span>{{ t('Print') }}</span>
  </BaseButton>
</template>

<script setup lang="ts">
import { PrinterIcon } from '@heroicons/vue/24/outline'
import { useI18n } from "vue-i18n";

const { t } = useI18n()

function onPrint() {
  window.print()
}
</script>
