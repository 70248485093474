<template>
  <BaseFormDialog
    :loading="loading"
    :is-edit="isEdit"
    v-bind="$attrs"
    @submit="onSubmit"
  >
    <FormKit
      v-model="model.email"
      name="email"
      type="text"
      :label="t('Email')"
      :placeholder="t('Email')"
      validation="required|email"
      outer-class="col-span-6"
    />
  </BaseFormDialog>
</template>

<script setup lang="ts">
import { ref } from "vue"
import { useI18n } from "vue-i18n"
import axios from "axios"
import { useFormInit } from "@/modules/common/composables/useFormInit"
import { success } from "@/components/common/NotificationPlugin"

const props = defineProps({
  isEdit: {
    type: Boolean,
    default: false,
  },
  data: {
    type: Object,
    default: () => ({}),
  },
})

const { t } = useI18n()
const model = ref({
  email: '',
})

useFormInit(props, model)

const loading = ref(false)
const emit = defineEmits(['save'])

async function onSubmit() {
  try {
    loading.value = true
    let result
    if (props.isEdit) {
      result = await axios.put(`/restify/invoice-reply-mails/${props.data.id}`, model.value)
    } else {
      result = await axios.post(`/restify/invoice-reply-mails`, model.value)
    }
    if (props.isEdit) {
      success(t('Reply to email updated successfully'))
    } else {
      success(t('Reply to email added successfully'))
    }
    emit('save', result.data)
  } finally {
    loading.value = false
  }
}
</script>

