<template>
  <SettingsCrudPage
    ref="table"
    :title="$t('Evaluation Types')"
    :modal-component="EvaluationTypeModal"
    :add-text="$t('Add evaluation type')"
    :edit-text="$t('Edit evaluation type')"
    :columns="columns"
    entity="evaluation-types"
    entity-path="evaluations"
  />
</template>
<script setup lang="ts">
import EvaluationTypeModal from "@/modules/settings/components/EvaluationTypeModal.vue";
import SettingsCrudPage from "@/modules/settings/components/SettingsCrudPage.vue";
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { ColDef, ValueSetterParams } from "@ag-grid-community/core";

const table = ref()
const { t } = useI18n()

const columns = ref<ColDef[]>([
  {
    headerName: t('Name'),
    field: 'attributes.name',
    editable: true,
    sortable: true,
    valueSetter: (params: ValueSetterParams) => {
      if (!params.newValue) {
        return false
      }
      params.data.attributes.name = params.newValue
      table.value?.updateRow(params.data)
      return true
    },
  },
  {
    headerName: t('Description'),
    field: 'attributes.description',
    editable: true,
    cellEditor: 'agLargeTextCellEditor',
    cellEditorPopup: true,
    flex: 1,
    valueSetter: (params: ValueSetterParams) => {
      if (!params.newValue) {
        return false
      }
      params.data.attributes.description = params.newValue
      table.value?.updateRow(params.data)
      return true
    }
  },
])
</script>
<route lang="yaml">
name: Evaluation Types
meta:
permission: manageEvaluationTypes
</route>
