import { ICellEditorParams, NewValueParams } from "@ag-grid-community/core"
import { onMounted, ref, watch } from "vue"

function delay(ms: number) {
  return new Promise((resolve: any) => setTimeout(resolve, ms))
}

export function useCellEditor(params: ICellEditorParams | undefined) {
  const value = ref()
  const cancelBeforeStart = ref(true)
  const highlightAllOnFocus = ref(true)

  function getValue() {
    return value.value
  }

  function isCancelBeforeStart() {
    return false
  }

  function isCancelAfterEnd() {
    return false
  }

  function goToNextCell() {
    const isSameColumn = params?.colDef.field === params?.api?.getFocusedCell()?.column?.colId
    if (!isSameColumn) {
      return
    }
    params?.api.tabToNextCell()
  }

  async function onChange(newValue: any) {
    if (value.value === newValue) {
      goToNextCell()
      return
    }
    value.value = newValue
    await delay(80)
    goToNextCell()
  }

  function initCellValueChangedListener() {
    const colDef = params?.colDef || {}
    colDef.onCellValueChanged = (cellChangeParams: NewValueParams) => {
      // const currentIndex = params?.node?.rowIndex
      // const paramsIndex = cellChangeParams?.node?.rowIndex
      // if (cellChangeParams.newValue !== value.value && currentIndex === paramsIndex) {
      //   value.value = cellChangeParams.newValue
      // }
    }
  }

  onMounted(() => {
    initCellValueChangedListener()
  })

  watch(() => params?.value, (newValue) => {
    value.value = newValue
  }, { immediate: true })

  return {
    value,
    getValue,
    cancelBeforeStart,
    highlightAllOnFocus,
    isCancelBeforeStart,
    isCancelAfterEnd,
    onChange,
    goToNextCell,
  }
}
