<template>
  <BaseContentCard>
    <div class="flex flex-col lg:flex-row items-center justify-between mb-5">
      <div class="flex items-center space-x-4">
        <IconBox size="sm">
          <DocumentTextIcon class="w-4 h-4" />
        </IconBox>
        <h3>{{ title }}</h3>
      </div>
      <div class="flex flex-1 flex-col space-y-2 sm:space-y-0 sm:flex-row items-center justify-between mt-2 lg:mt-0">
        <div class="flex space-x-1 mx-4">
          <FormKit
            v-model="searchQuery"
            type="text"
            :placeholder="$t('Search...')"
            prefix-icon="magnifying-glass"
            :classes="{
              input: '!py-2 !leading-[20px] max-w-[200px] md:max-w-full',
            }"
            @update:modelValue="debouncedRemoteSearch(searchQuery)"
          />
          <TableRefreshButton @click="getData(true)" />
        </div>
        <div class="flex space-x-2">
          <BaseButton
            v-if="$can('manageFeedback') && showCreate"
            size="sm"
            variant="primary"
            data-test="add_feedback_button"
            block
            @click="onAdd"
          >
            <PlusIcon class="w-4 h-4 mr-1" />
            {{ $t('Add feedback') }}
          </BaseButton>
        </div>
      </div>
    </div>

    <el-timeline
      ref="scrollableArea"
      class="overflow-y-scroll h-[max(calc(100vh-370px),300px)]"
    >
      <template v-if="loading && feedback?.length === 0">
        <el-timeline-item
          v-for="i in 3"
          :id="i"
          v-bind="timelineItemProps"
        >
          <h5 class="bg-gray-100 animate-pulse h-5 mb-2 w-[120px]" />
          <div class="bg-gray-100 animate-pulse h-[16px] mb-2 w-[100px]" />
          <div class="bg-gray-100 animate-pulse rounded-sm h-20 mt-5" />
        </el-timeline-item>
      </template>

      <template v-if="!loading && feedback?.length === 0">
        <div class="flex h-full items-center justify-center">
          <h3>{{ $t('No activity found') }}</h3>
        </div>
      </template>

      <el-timeline-item
        v-for="(activity, index) in feedback"
        :id="activity.id"
        v-bind="timelineItemProps"
      >
        <FeedbackTimelineContent :data="activity" @delete="onDelete(index)" />
      </el-timeline-item>
      <LoadingTable v-if="loading && feedback?.length > 0" class="mt-4" />
    </el-timeline>
  </BaseContentCard>
</template>

<script lang="ts" setup>
import { DocumentTextIcon, PlusIcon } from "@heroicons/vue/24/outline"
import { debounce } from "lodash-es"
import { computed, onMounted, ref } from "vue"
import { useRoute, useRouter } from "vue-router"
import { useInfiniteScroll } from "@vueuse/core"
import axios from "axios"
import LoadingTable from "@/components/table/LoadingTable.vue"
import Data = API.Data
import FeedbackTimelineContent from "@/modules/feedback/components/FeedbackTimelineContent.vue"
import TableRefreshButton from "@/components/table/TableRefreshButton.vue"

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  feedbackableId: {
    type: String,
  },
  feedbackableType: {
    type: String,
    default: 'employee',
  },
  showCreate: {
    type: Boolean,
    default: true,
  },
})

const scrollableArea = ref()
const page = ref(1)
const lastPage = ref()
const loading = ref(false)
const searchQuery = ref('')
const searchQueryParam = ref('')
const feedback = ref<Data<any>[]>([])

const debouncedRemoteSearch = debounce(onRemoteSearch, 200)

const emit = defineEmits(['loadMore'])
useInfiniteScroll(
  scrollableArea,
  () => {
    if (lastPage.value && page.value >= lastPage.value) {
      return
    }
    page.value++
    getData()
  },
  { distance: 20 },
)

const timelineItemProps = computed(() => {
  return {
    hideTimestamp: true,
    hollow: true,
    size: 'large',
    color: 'var(--primary-color)',
    placement: 'top',
  }
})

async function onRemoteSearch(query: string) {
  searchQueryParam.value = query
  await getData(true)
}

async function getData(refresh = false) {
  try {
    if (refresh) {
      page.value = 1
    }
    loading.value = true
    const res: any = await axios.get(`/restify/feedback`, {
      params: {
        active_employee: true,
        temporary_urls: true,
        feedbackable_id: props.feedbackableId,
        feedbackable_type: props.feedbackableType,
        page: page.value,
        sort: '-given_at',
        perPage: 5,
        search: searchQueryParam.value,
      },
    })
    page.value = res.meta.current_page
    lastPage.value = res.meta.last_page
    if (refresh) {
      feedback.value = res.data
    } else {
      feedback.value = feedback.value.concat(res.data)
    }
  } finally {
    loading.value = false
  }
}

const router = useRouter()

function onAdd() {
  router.push({
    path: `/feedback/create`,
    query: {
      feedbackable_id: props.feedbackableId,
      feedbackable_type: props.feedbackableType,
    },
  })
}

function onDelete(index: number) {
  feedback.value.splice(index, 1)
}

onMounted(async () => {
  await getData()
})
</script>
