<template>
  <BaseSelect
    v-bind="allAttrs"
    filterable
    :options="timezoneOptions"
    :model-value="context._value"
    @update:model-value="handleChange"
  />
</template>

<script setup lang="ts">
import { computed } from 'vue'
import timezones from './timezones.json'
import { useFormKitInput } from "@/components/formkit/useFormkitInput";

const props = defineProps({
  context: Object,
})

const timezoneOptions = computed(() => {
  return timezones.map(t => {
    return {
      value: t.value,
      label: t.text,
    }
  })
})

const { allAttrs, handleChange } = useFormKitInput(props)
</script>
