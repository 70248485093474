<template>
  <FilterInput
    v-bind="$attrs"
    :data="statuses"
    :item-component="Status"
    value-key="value"
    label-key="label"
  />
</template>

<script lang="ts" setup>
import { ref } from "vue"
import { useI18n } from "vue-i18n"
import FilterInput from "@/components/table/filters/base/FilterInput.vue"
import { EmployeeStatus } from "@/modules/employees/utils/employeeUtils"
import Status from "@/components/table/cells/Status.vue"

const { t } = useI18n()

const statuses = ref([
  {
    value: EmployeeStatus.Active,
    label: t('Active'),
  },
  {
    value: EmployeeStatus.Draft,
    label: t('Uninvited'),
  },
  {
    value: EmployeeStatus.Invited,
    label: t('Invited'),
  },
  {
    value: EmployeeStatus.Archived,
    label: t('Archived'),
  },
])
</script>
