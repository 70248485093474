<template>
  <BaseFormDialog
    class="template-form"
    :loading="loading"
    :is-edit="isEdit"
    v-bind="$attrs"
    @submit="onSubmit"
  >
    <FormKit
      v-model="model.name"
      :label="t('Template name')"
      :placeholder="t('Software agreement contract')"
      validation="required"
      type="text"
      outer-class="col-span-6"
    />

    <FormKit
      v-model="model.entity"
      :label="t('Used For')"
      :placeholder="t('Used For')"
      :options="templateStore.templateEntities"
      validation="required"
      type="customSelect"
      outer-class="col-span-6"
      @update:model-value="templateStore.selectedTemplateEntity = $event"
    />

    <div class="col-span-6 md:col-span-3 mt-4">
      <FormKit
        v-model="model.autogenerate"
        type="checkbox"
        :label="t('Auto Generate')"
        :help="$t('Auto generate document based on the selected entity. For employees, the documents will be generated upon employee creation. For holidays, the documents will be generated upon holiday approval.')"
      />
    </div>

    <div class="col-span-6">
      <FormKit
        v-model="model.contract_types"
        multiple
        type="contractTypeSelect"
        :label="t('Contract Types')"
        :placeholder="t('Contract Types')"
        :help="$t('Select the contract types that this template will be available for. If no contract types are selected, the template will be available for all contract types.')"
      />
    </div>

    <FormKit
      v-model="model.content"
      type="html"
      :show-variables="true"
      :label="t('Content')"
      :placeholder="t('Template content')"
      validation="required"
      outer-class="col-span-6"
    />
  </BaseFormDialog>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref } from "vue"
import { useI18n } from "vue-i18n"
import { useRoute, useRouter } from "vue-router"
import { success } from "@/components/common/NotificationPlugin"
import { TemplateEntities, useTemplateStore } from "@/modules/templates/store/templateStore"
import { useFormInit } from "@/modules/common/composables/useFormInit"

const props = defineProps({
  isEdit: {
    type: Boolean,
    default: false,
  },
  data: {
    type: Object,
    default: () => ({}),
  },
})

const emit = defineEmits(['save'])

const { t } = useI18n()

const templateStore = useTemplateStore()

const model = ref({
  id: null,
  entity: '',
  name: '',
  content: '',
  contract_types: [],
  autogenerate: false,
})

function getEntity(entities: string[]) {
  const data = entities || []
  return data[0] || ''
}
useFormInit(props, model, (value) => {
  const entity = getEntity(value.entities)
  model.value.entity = entity
  templateStore.selectedTemplateEntity = entity
})

const loading = ref(false)

const showContractTypes = computed(() => {
  return [TemplateEntities.Employee, TemplateEntities.Holiday].includes(model.value.entity)
})

async function onSubmit() {
  try {
    const data: any = {
      ...model.value,
      entities: [model.value.entity],
    }
    delete data.key
    if (props.isEdit) {
      await templateStore.updateTemplate(data)
    } else {
      await templateStore.createTemplate(data)
    }
    if (props.isEdit) {
      success(t('Template updated successfully'))
    } else {
      success(t('Template created successfully'))
    }
    emit('save')
  } catch (err: any) {
    if (err.handled) {
      return
    }
  } finally {
    loading.value = false
  }
}

const route = useRoute()
const router = useRouter()

async function tryGetSharedTemplate() {
  const { share_token } = route.query
  if (!share_token) {
    return
  }
  const template = await templateStore.getSharedTemplate(share_token as string)
  if (!template) {
    return
  }
  model.value.name = template.name
  model.value.entity = getEntity(template.entities)
  model.value.content = template.content
  model.value.contract_types = template.contract_types || []
  const query = { ...route.query }
  delete query.share_token
  delete query.add
  await router.replace({ path: route.path, query })
}
onMounted(async () => {
  await templateStore.getTemplatePlaceholders()
  await tryGetSharedTemplate()
})
</script>

<style>
.template-form #text-editor .ProseMirror {
  @apply h-[300px] lg:h-[420px];
}
</style>
