import { GridApi } from "@ag-grid-community/core";

export function getTableData<T>(grid: GridApi | undefined) {
  if (!grid) {
    return []
  }
  const data: T[] = []
  grid.forEachNode((node) => {
    data.push(node.data)
  })
  return data
}
