<template>
  <div class="h-full w-full flex items-center justify-center table-no-data">
    <ElEmpty :description="$t('No data found')" />
  </div>
</template>

<script setup lang="ts">
</script>

<style lang="scss">
.table-no-data .el-empty {
  --el-empty-image-width: 150px;
}
</style>
