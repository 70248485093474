import i18n from "@/i18n";

export const DocumentStatus = {
  Converting: 'converting',
  New: 'new',
  Sent: 'sent',
  Viewed: 'viewed',
  Signed: 'signed',
  Downloaded: 'downloaded',
  SignedAndDownloaded: 'signed_and_download',
  Canceled: 'cancelled',
  Declined: 'declined',
  ErrorConverting: 'error_converting',
  ErrorSending: 'error_sending',
  Expired: 'expired',
}

export const DocumentStatusLabels = {
  [DocumentStatus.Converting]: i18n.t('Converting'),
  [DocumentStatus.New]: i18n.t('New'),
  [DocumentStatus.Sent]: i18n.t('Sent'),
  [DocumentStatus.Viewed]: i18n.t('Viewed'),
  [DocumentStatus.Signed]: i18n.t('Signed'),
  [DocumentStatus.Downloaded]: i18n.t('Downloaded'),
  [DocumentStatus.SignedAndDownloaded]: i18n.t('Signed and downloaded'),
  [DocumentStatus.Canceled]: i18n.t('Canceled'),
  [DocumentStatus.Declined]: i18n.t('Declined'),
  [DocumentStatus.ErrorConverting]: i18n.t('Error converting'),
  [DocumentStatus.ErrorSending]: i18n.t('Error sending'),
  [DocumentStatus.Expired]: i18n.t('Expired'),
}
