<template>
  <BaseButton
    v-bind="$attrs"
    type="button"
    variant="primary"
    class="print:hidden"
    :disabled="loading"
  >
    <FolderArrowDownIcon class="w-4 h-4 mr-2"/>
    <span>
      <slot>
        {{ t('Download') }}
      </slot>
    </span>
  </BaseButton>
</template>

<script setup lang="ts">
import { FolderArrowDownIcon } from '@heroicons/vue/24/outline'
import { useI18n } from 'vue-i18n'

const props = defineProps({
  loading: Boolean,
})
const { t } = useI18n()
</script>
