<template>
  <HolidayDetails />
</template>

<script>
import { defineComponent } from "vue"
import HolidayDetails from "@/modules/holidays/components/HolidayDetails.vue"

export default defineComponent({
  components: { HolidayDetails },
})
</script>

<route lang="yaml">
name: Holiday Details
</route>
