<template>
  <RouterLink
    :to="holidayLink"
    class="flex items-start space-x-1 no-underline"
  >
    <template v-if="policy">
      <component
        :is="getPolicyIcon(policy)"
        class="w-4 min-w-[16px] h-4 mt-1"
      />
      <div class="text-sm text-base-content">
        <div>{{ getPolicyTypeLabel(policy) }}</div>
        <div class="text-gray-400">
          {{ policy.attributes.name }}
        </div>
      </div>
    </template>
  </RouterLink>
</template>

<script setup lang="ts">
import { PropType, computed } from "vue"
import { useRoute } from "vue-router"
import { HolidayTypeIcons, HolidayTypeLabels } from "@/modules/settings/types/settingTypes"
import { useSettingsStore } from "@/modules/settings/store/settingsStore"
import HolidayPolicy = App.Domains.HolidayPolicies.Models.HolidayPolicy
import Data = API.Data
import Holiday = App.Domains.Holidays.Models.Holiday
const props = defineProps({
  holiday: {
    type: Object as PropType<Data<Holiday>>,
    required: true,
  },
  dialogLink: {
    type: Boolean,
    default: false,
  },
})
const settingsStore = useSettingsStore()
const route = useRoute()

const holidayLink = computed(() => {
  const holidayId = props.holiday.id
  if (props.dialogLink) {
    return {
      path: route.path,
      query: {
        id: holidayId,
      },
    }
  }
  return `/holidays/${holidayId}/view`
})
const policy = computed(() => {
  return settingsStore.getHolidayPolicyById(props.holiday?.attributes?.holiday_policy_id)
})

function getPolicyIcon(policy: Data<HolidayPolicy>) {
  const type = policy.attributes.type as string
  return HolidayTypeIcons[type]
}

function getPolicyTypeLabel(policy: Data<HolidayPolicy>) {
  const type = policy.attributes.type as string
  return HolidayTypeLabels[type]
}
</script>
