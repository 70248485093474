<template>
  <div>
    <div class="flex justify-end mt-4">
      <div v-if="showActions" class="flex flex-col space-y-2 md:space-y-0 md:flex-row md:space-x-2">
        <div class="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-2">
          <ShareButton
            class="btn-xs"
            :source-text="shareLink"
            :disabled="invoiceStore.shareLoading"
            @click="invoiceStore.shareInvoice(route.params.id as string)"
          />
          <InvoiceDownloadButton class="btn-xs md:mr-1.5" />
          <MarkAsPaidButton
            v-if="!isPaid"
            class="btn-xs"
            :disabled="invoiceStore.markAsPaidLoading"
            @click="invoiceStore.markAsPaid(route.params.id as string)"
          />
        </div>
        <div class="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-2">
          <SendInvoiceButton
            v-if="!isPaid"
            class="w-full" :invoice-id="invoice?.id"
          />
          <EditButton
            v-if="can('manageInvoices') && showActions && !isPaid"
            class="btn-xs"
            @click="goToEdit"
          />
        </div>
      </div>
    </div>
    <div
      v-if="showActions"
      class="flex flex-col md:flex-row justify-between items-end"
    >
      <PageTitle>
        <div class="flex items-center space-x-2">
          <div>{{ t('Invoice {id}', { id: invoiceId }) }}</div>
          <InvoiceStatusComponent :status="invoiceStatus" class="mt-1" />
        </div>
      </PageTitle>
      <div v-if="!invoiceStore.currentInvoiceLoading" class="flex items-center justify-end mt-4 mr-1 text-lg">
        <div v-if="!isPaid" class="flex space-x-2">
          <span v-if="isDue" class="text-red-700 font-semibold">
            {{ dueDaysText }}
          </span>
          <span>{{ t('Balance') }}:</span>
          <span class="font-semibold">
            {{ formatPriceWithCurrency(openAmount) }}
          </span>
        </div>
        <div v-else class="flex space-x-2">
          <span class="font-semibold">
            {{ formatPriceWithCurrency(invoice?.attributes?.total) }}
          </span>
          <span>
            {{ t('paid on') }} {{ formatDate(invoice?.attributes?.paid_at) }}
          </span>
        </div>
      </div>
    </div>
    <BaseContentCard v-if="invoiceStore.currentInvoiceLoading" class="mt-2">
      <div class="min-h-[400px] flex items-center justify-center">
        <LoadingTable />
      </div>
    </BaseContentCard>
    <BaseContentCard v-else class="mt-2">
      <div class="flex flex-wrap space-y-6 md:space-y-0 justify-between">
        <div v-if="invoiceLogo">
          <img :src="invoiceLogo" class="max-h-[80px] max-w-[80px] sm:max-h-[180px] sm:max-w-[180px]" alt="invoice logo">
        </div>
        <div
          v-if="invoiceStatus !== InvoiceStatus.Draft"
          class="flex flex-1 justify-center ml-[50px] items-end print:hidden"
        >
          <div class="transform -rotate-[30deg]">
            <InvoiceStatusComponent
              :status="invoiceStatus"
              class="!text-3xl rounded-sm py-3 px-6"
            />
          </div>
        </div>
        <div class="flex items-start space-x-2">
          <span class="text-sm text-gray-500">{{ t('From') }}</span>
          <div class="border-l border-gray-300 pl-2 flex flex-col w-[250px]">
            <div class="min-h-[100px] text-sm break-words text-gray-700">
              <div v-if="fromAddress" class="whitespace-break-spaces" v-html="fromAddress" />
              <div v-else-if="!fromAddress" class="mt-4">
                <span>
                  {{ t(`You didn't setup your company address for invoices`) }}
                  <button class="btn btn-link btn-sm px-0" @click="goToInvoiceConfiguration">{{
                    t('Configure now')
                  }}</button>
                </span>
              </div>
              <div v-if="showActions">
                <BaseButton variant="link" size="sm" class="px-0" @click="goToCompanyInvoiceDetails">
                  {{ t('Edit info') }}
                </BaseButton>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-col space-y-4 sm:space-y-0 sm:flex-row justify-between mt-6">
        <div class="space-x-4 flex">
          <div class="flex flex-col space-y-2 text-gray-500 text-sm min-w-[72px] md:min-w-0">
            <span>{{ t('Invoice ID') }}</span>
            <span>{{ t('Issue date') }}</span>
            <span>{{ t('Due date') }}</span>
            <span>{{ t('Subject') }}</span>
          </div>
          <div class="flex flex-col space-y-2 text-gray-700 text-sm border-l border-gray-300 pl-4">
            <span class="font-semibold">{{ invoice?.attributes?.invoice_number }}</span>
            <span>{{ formatDate(invoice?.attributes?.issue_date) }}</span>
            <span>{{ formatDate(invoice?.attributes?.due_date) }}</span>
            <span>{{ invoice?.attributes?.title }}</span>
          </div>
        </div>
        <div class="flex items-start space-x-2">
          <span class="text-sm text-gray-500 min-w-[80px] md:min-w-0">{{ t('Invoice For') }}</span>
          <div class="border-l border-gray-300 pl-2 flex flex-col w-[250px]">
            <span class="font-semibold">{{ client?.company_name }}</span>
            <span class="text-sm break-words text-gray-700">
              <template v-if="client?.company_vat">
                {{ client?.company_vat }} <br>
              </template>
              <template v-if="client?.description">
                {{ client?.description }} <br>
              </template>
              {{ clientAddress }}
            </span>
            <div v-if="showActions">
              <BaseButton variant="link" size="sm" class="px-0" @click="goToClientEdit">
                {{ t('Edit info') }}
              </BaseButton>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-8">
        <InvoiceLineItemDetails
          :invoice="invoice"
          :line-items="invoiceLineItems"
        />
      </div>
      <div v-if="invoice?.attributes?.notes" class="mt-6 print:mt-4">
        <span class="font-semibold text-sm">{{ t('Notes') }}</span>
        <div class="mt-2 pt-2 w-full border-t border-gray-200">
          <div class="prose prose-sm" v-html="invoice?.attributes?.notes" />
        </div>
      </div>
      <FileAttachments
        :data="invoice?.relationships?.files"
        :entity-id="invoice?.id"
        :authorized-to-attach-files="showActions"
        entity="invoice"
      />
    </BaseContentCard>
    <InvoiceHistory/>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import { useInvoiceStore } from '@/modules/invoices/store/invoiceStore'
import { useClientStore } from '@/modules/clients/store/clientStore'
import { formatAddress } from '@/modules/common/utils/addressUtils'
import { formatDate } from '@/modules/common/utils/dateUtils'
import InvoiceLineItemDetails from '@/modules/invoices/components/InvoiceLineItemDetails.vue'
import { LineItem } from '@/modules/invoices/types/invoiceTypes'
import EditButton from '@/components/common/buttons/EditButton.vue'
import { can } from '@/plugins/permissionPlugin'
import BaseContentCard from '@/components/common/BaseContentCard.vue'
import LoadingTable from '@/components/table/LoadingTable.vue'
import { SettingKeys } from '@/modules/auth/types/enums'
import { useSettingsStore } from '@/modules/settings/store/settingsStore'
import { success } from '@/components/common/NotificationPlugin'
import Data = API.Data
import InvoiceStatusComponent from '@/modules/invoices/components/InvoiceStatus.vue'
import { formatPrice } from '@/plugins/formatPrice'
import MarkAsPaidButton from '@/components/common/buttons/MarkAsPaidButton.vue'
import { InvoiceStatus } from "@/modules/invoices/types/invoicEnums"
import { getDueDaysText, isInvoiceDue } from "@/modules/invoices/utils/invoiceUtils"
import InvoiceDownloadButton from "@/components/common/buttons/InvoiceDownloadButton.vue"
import InvoiceHistory from "@/modules/invoices/components/InvoiceHistory.vue"
import FileAttachments from "@/modules/invoices/components/FileAttachments.vue";

const props = defineProps({
  showActions: {
    type: Boolean,
    default: true,
  },
})
const { t } = useI18n()

const invoiceStore = useInvoiceStore()
const invoice = computed(() => {
  return invoiceStore.currentInvoice
})
const invoiceId = computed(() => {
  return invoice.value?.attributes?.invoice_number
})

const invoiceLineItems = computed(() => {
  const lineItems = invoice.value?.relationships?.lineItems || []
  return lineItems.map((item: Data<LineItem>) => {
    return {
      id: item.id,
      ...item.attributes,
    }
  })
})
const clientStore = useClientStore()

const client = computed(() => {
  return invoice.value?.relationships?.client?.attributes
})

const clientAddress = computed(() => {
  return formatAddress(client.value?.company_address)
})

const settingsStore = useSettingsStore()
const invoiceLogo = computed(() => {
  const invoice_logo = invoice.value?.relationships?.invoice_logo
  if (invoice_logo) {
    return invoice_logo
  }
  return settingsStore.getSetting(SettingKeys.InvoiceLogo) || ''
})

const fromAddress = computed(() => {
  const invoice_company_address = invoice.value?.relationships?.invoice_company_address
  if (invoice_company_address) {
    return invoice_company_address
  }
  return settingsStore.getSetting(SettingKeys.InvoiceCompanyAddress)
})

const currency = computed(() => {
  return invoice.value?.attributes.currency
})
function formatPriceWithCurrency(price: number) {
  return formatPrice(price, {
    currency: currency.value,
  })
}

const openAmount = computed(() => {
  const { total, paid_at } = invoice.value?.attributes || {}
  if (paid_at) {
    return 0
  }
  return total
})

const isPaid = computed(() => {
  const { status, paid_at } = invoice.value?.attributes || {}
  return status === InvoiceStatus.Paid || paid_at !== null
})

const isDue = computed(() => {
  return isInvoiceDue(invoice.value)
})
const invoiceStatus = computed(() => {
  if (isDue.value) {
    return InvoiceStatus.Due
  }
  return invoice?.value?.attributes.status
})

const dueDaysText = computed(() => {
  return getDueDaysText(invoice.value?.attributes.due_date)
})

const router = useRouter()
const route = useRoute()

function goToEdit() {
  router.push(`/invoices/${route.params.id}/edit`)
}

function goToInvoiceConfiguration() {
  router.push('/invoices/configure')
}

function goToClientEdit() {
  router.push(`/clients/${client.value?.id}/edit`)
}

function goToCompanyInvoiceDetails() {
  router.push(`/invoices/configure`)
}

const { locale } = useI18n()
const shareLink = computed(() => {
  const currentLink = window.location.href
  const shareLink = currentLink.replace('/invoices', '/share/invoices')
  const token = localStorage.getItem('token')
  return `${shareLink}?token=${token}&locale=${locale.value}`
})
</script>
