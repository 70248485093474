import { FormKitNode } from "@formkit/core"

export function scrollToErrors(node: FormKitNode) {
  if (node.props.type === 'form') {
    function scrollTo(node: any) {
      const el = document.getElementById(node.props.id)
      if (el) {
        el.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' })
      }
    }

    function scrollToErrors() {
      node.walk((child) => {
        // Check if this child has errors
        if (child.ledger.value('blocking') || child.ledger.value('errors')) {
          // We found an input with validation errors
          scrollTo(child)
          // Stop searching
          return false
        }
      }, true)
    }

    const onSubmitInvalid = node.props?.onSubmitInvalid
    node.props.onSubmitInvalid = () => {
      if (typeof onSubmitInvalid === 'function') {
        onSubmitInvalid(node)
      }
      scrollToErrors()
    }
    node.on('unsettled:errors', scrollToErrors)
  }
  return false
}
