<template>
  <div class="flex flex-col">
    <div
      v-if="currentEvaluationError || currentEvaluationLoading"
      class="flex-1 flex justify-center items-center h-[50vh]"
    >
      <LoadingTable v-if="currentEvaluationLoading" />
      <div v-else class="flex flex-col items-center">
        <IconBox class="mb-4" color="error">
          <ExclamationTriangleIcon class="w-8 h-8" />
        </IconBox>
        <h2>{{ $t('An error occurred while trying to access the evaluation information') }}</h2>
      </div>
    </div>
    <RouterView v-else class="flex-1" />
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, watch } from "vue"
import { storeToRefs } from "pinia"
import { ExclamationTriangleIcon } from "@heroicons/vue/24/outline"
import { useHead } from "@vueuse/head"
import { useRoute } from "vue-router"
import { useI18n } from "vue-i18n"
import { useEvaluationStore } from "@/modules/evaluations/store/evaluationStore"
const { t } = useI18n()

const evaluationStore = useEvaluationStore()
const { currentEvaluationLoading, currentEvaluationError } = storeToRefs(evaluationStore)
const route = useRoute()
const id = computed(() => route.params.id as string)

const { evaluationName } = storeToRefs(evaluationStore)

useHead({
  title: evaluationName,
})
watch(() => id.value, async (value: string) => {
  await evaluationStore.getEvaluation(value)
})
onMounted(async () => {
  await evaluationStore.getEvaluation(id.value)
})
</script>

<route lang="yaml">
name: Evaluation
redirect:
  name: Evaluation Details
</route>
