<template>
  <div
    id="text-editor"
    ref="menuContainerRef"
    :class="{
      readonly: isReadOnly,
      autoHeight,
    }"
  >
    <div v-if="editor && !isReadOnly" class="toolbar">
      <div class="align-dropdown">
        <button class="dropbtn" type="button">
          {{ $t('Heading ▼') }}
        </button>
        <div class="dropdown-content flex flex-col space-y-1">
          <button
            v-for="index in 6"
            :key="index"
            :class="{ active: editor.isActive('heading', { level: index }) }"
            :style="{ fontSize: `${20 - index}px` }"
            type="button"
            class="dropdown-item"
            @click="onHeadingClick(index)"
          >
            H{{ index }}
          </button>
        </div>
      </div>

      <button
        v-for="({ slug, option, active, icon }, index) in textActions"
        :key="index"
        type="button"
        :class="{ active: editor.isActive(active) }"
        @click="onActionClick(slug, option)"
      >
        <i :class="icon" />
      </button>

      <div v-if="showVariables" class="align-dropdown">
        <button class="dropbtn" type="button">
          {{ $t('Variable ▼') }}
        </button>
        <div class="dropdown-content max-h-[400px] overflow-auto min-w-[250px]">
          <div>
            <FormKit
              v-model="placeholderQuery"
              type="text"
              :placeholder="$t('Search...')"
              prefix-icon="magnifying-glass"
              :classes="{
                input: '!py-2 !leading-[20px] max-w-[200px] rounded-none md:max-w-full border-t-0 border-l-0 border-r-0',
              }"
            />
          </div>
          <div
            v-if="filteredPlaceholders.length === 0"
            class="text-gray-400 px-4 py-2"
          >
            <span v-if="filteredPlaceholders.length === 0 && !templateStore.selectedTemplateEntity">
              {{ $t('Select a template type to see variables') }}
            </span>
            <span v-else-if="filteredPlaceholders.length === 0 && templateStore.selectedTemplateEntity">
              {{ $t('No variables for this template type') }}
            </span>
          </div>
          <button
            v-for="placeholder in filteredPlaceholders"
            :key="placeholder.value"
            :class="{ active: editor.isActive('mention') }"
            type="button"
            class="dropdown-item"
            @click="onVariableClick(placeholder)"
          >
            {{ placeholder.label }}
          </button>
        </div>
      </div>
    </div>

    <EditorContent v-if="editor" :editor="editor" />
    <template v-if="editor && !isReadOnly">
      <ColumnsMenu
        :editor="editor"
        :append-to="menuContainerRef"
      />
      <ContentItemMenu :editor="editor" />
      <TableRowMenu :editor="editor" :append-to="menuContainerRef" />
      <TableColumnMenu :editor="editor" :append-to="menuContainerRef" />
      <LinkMenu :editor="editor" :append-to="menuContainerRef" />
    </template>

    <div v-if="editor && !isReadOnly" class="footer">
      <span class="characters-count min-w-[100px]" :class="maxLimit ? limitWarning : ''">
        {{ charactersCount }} {{ maxLimit ? `/ ${maxLimit} ${$t('characters')}` : $t('characters') }}
      </span>
      <span>|</span>
      <span class="words-count min-w-[100px]">
        {{ wordsCount }} {{ $t('words') }}
      </span>
    </div>
  </div>
</template>

<script lang="ts" setup>
import 'remixicon/fonts/remixicon.css'
import './assets/index.scss'
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue'
import { Editor, EditorContent } from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'
import TextAlign from '@tiptap/extension-text-align'
import Underline from '@tiptap/extension-underline'
import Subscript from '@tiptap/extension-subscript'
import Superscript from '@tiptap/extension-superscript'
import CharacterCount from '@tiptap/extension-character-count'
import { FocusClasses as Focus } from '@tiptap/extension-focus'
import { TaskItem } from '@tiptap/extension-task-item'
import { TaskList } from '@tiptap/extension-task-list'
import { ChainedCommands } from "@tiptap/core"
import { Typography } from "@tiptap/extension-typography"
import { Dropcursor } from "@tiptap/extension-dropcursor"
import { Emoji } from "@tiptap-pro/extension-emoji"
import { Color } from "@tiptap/extension-color"
import { FontFamily } from "@tiptap/extension-font-family"
import { CodeBlockLowlight } from '@tiptap/extension-code-block-lowlight'
import { common, createLowlight } from 'lowlight'
import { Heading } from "@tiptap/extension-heading"
import { useTemplateStore } from "@/modules/templates/store/templateStore"
import VariablesExtension from "@/components/formkit/html/variableSuggestion"
import ColumnsMenu from "@/components/formkit/html/MultiColumn/menus/ColumnsMenu.vue"
import { Column, Columns } from "@/components/formkit/html/MultiColumn"
import Selection from "@/components/formkit/html/Selection/Selection"
import Document from "@/components/formkit/html/Document/Document"
import ContentItemMenu from "@/components/formkit/html/ContentItemMenu/ContentItemMenu.vue"
import { SlashCommand } from "@/components/formkit/html/SlashCommand"
import { Placeholder } from "@/components/formkit/html/Placeholder/Placeholder"
import TableColumnMenu from "@/components/formkit/html/Table/menus/TableColumn/TableColumnMenu.vue"
import TableRowMenu from "@/components/formkit/html/Table/menus/TableRow/TableRowMenu.vue"
import Table from "@/components/formkit/html/Table/Table"
import { TableCell, TableHeader, TableRow } from "@/components/formkit/html/Table"
import LinkMenu from "@/components/formkit/html/LinkMenu/LinkMenu.vue"
import Link from "@/components/formkit/html/Link/Link"
import { BlockquoteFigure } from "@/components/formkit/html/BlockquoteFigure"
import { emojiSuggestion } from "@/components/formkit/html/EmojiSuggestion"
import { TrailingNode } from "@/components/formkit/html/TrailingNode"
import { FontSize } from "@/components/formkit/html/FontSize"
import { HorizontalRule } from "@/components/formkit/html/HorizontalRule"
const lowlight = createLowlight(common)

const props = defineProps({
  maxLimit: {
    type: Number,
    default: null,
  },
  readOnly: {
    type: Boolean,
    default: false,
  },
  modelValue: {
    type: String,
    required: true,
    default: '',
  },
  showVariables: {
    type: Boolean,
    default: false,
  },
  editorClasses: {
    type: String,
    default: `prose prose-sm max-w-4xl`,
  },
  autoHeight: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['update:modelValue'])
const isReadOnly = computed(() => {
  return props.readOnly
})

const editor = ref<Editor>()
const menuContainerRef = ref()
const textActions = ref([
  { slug: 'bold', icon: 'ri-bold', active: 'bold' },
  { slug: 'italic', icon: 'ri-italic', active: 'italic' },
  { slug: 'underline', icon: 'ri-underline', active: 'underline' },
  { slug: 'strike', icon: 'ri-strikethrough', active: 'strike' },
  { slug: 'align', option: 'left', icon: 'ri-align-left', active: { textAlign: 'left' } },
  { slug: 'align', option: 'center', icon: 'ri-align-center', active: { textAlign: 'center' } },
  { slug: 'align', option: 'right', icon: 'ri-align-right', active: { textAlign: 'right' } },
  { slug: 'align', option: 'justify', icon: 'ri-align-justify', active: { textAlign: 'justify' } },
  { slug: 'bulletList', icon: 'ri-list-unordered', active: 'bulletList' },
  { slug: 'orderedList', icon: 'ri-list-ordered', active: 'orderedList' },
  { slug: 'columns', icon: 'ri-layout-column-line', active: 'columns' },
  { slug: 'subscript', icon: 'ri-subscript-2', active: 'subscript' },
  { slug: 'superscript', icon: 'ri-superscript-2', active: 'superscript' },
  { slug: 'undo', icon: 'ri-arrow-go-back-line', active: 'undo' },
  { slug: 'redo', icon: 'ri-arrow-go-forward-line', active: 'redo' },
  { slug: 'clear', icon: 'ri-format-clear', active: 'clear' },
])
const charactersCount = computed(() => {
  return editor.value?.storage.characterCount.characters()
})

const wordsCount = computed(() => {
  return editor.value?.storage.characterCount.words()
})

const limitWarning = computed(() => {
  const isCloseToMax = charactersCount.value >= props.maxLimit - 20
  const isMax = charactersCount.value === props.maxLimit

  if (isCloseToMax && !isMax) {
    return 'text-orange-500'
  }
  if (isMax) {
    return 'text-red-500'
  }

  return ''
})

watch(() => props.modelValue, (val: any) => {
  if (editor.value?.getHTML() === val) {
    return
  }
  editor.value?.commands.setContent(props.modelValue, false)
})

function onActionClick(slug: string, option: any = null) {
  const vm = editor.value?.chain().focus() as ChainedCommands
  const actionTriggers: Record<string, () => void> = {
    bold: () => vm.toggleBold().run(),
    italic: () => vm.toggleItalic().run(),
    underline: () => vm.toggleUnderline().run(),
    strike: () => vm.toggleStrike().run(),
    bulletList: () => vm.toggleBulletList().run(),
    orderedList: () => vm.toggleOrderedList().run(),
    align: () => vm.setTextAlign(option).run(),
    subscript: () => vm.toggleSubscript().run(),
    columns: () => {
      const selection = editor.value?.state.selection.head || 0
      return vm.setColumns()
        .focus(selection - 1)
        .run()
    },
    superscript: () => vm.toggleSuperscript().run(),
    undo: () => vm.undo().run(),
    redo: () => vm.redo().run(),
    clear: () => {
      vm.clearNodes().run()
      vm.unsetAllMarks().run()
    },
  }

  actionTriggers[slug]?.()
}

function onHeadingClick(index: any) {
  const vm = editor.value?.chain().focus()
  vm?.toggleHeading({ level: index }).run()
}

const templateStore = useTemplateStore()

const placeholderQuery = ref('')

const placeholders = computed(() => {
  return templateStore.getPlaceholdersForEntity(templateStore.selectedTemplateEntity)
})

const filteredPlaceholders = computed(() => {
  if (!placeholderQuery.value) {
    return placeholders.value
  }
  return placeholders.value.filter((placeholder: any) => {
    return placeholder.label?.toLowerCase().includes(placeholderQuery.value.toLowerCase())
  })
})

function onVariableClick(placeholder: any) {
  const vm = editor.value?.chain().focus()
  vm?.insertContent(`<span data-id="${placeholder.value}" data-label="${placeholder.label}">::${placeholder.value}::</span>`).run()
}

onMounted(() => {
  editor.value = new Editor({
    content: props.modelValue,
    editable: !isReadOnly.value,
    editorProps: {
      attributes: {
        class: props.editorClasses,
      },
    },
    extensions: [
      Document,
      Column,
      Selection,
      TaskList,
      TaskItem.configure({
        nested: true,
      }),
      Heading.configure({
        levels: [1, 2, 3, 4, 5, 6],
      }),
      HorizontalRule,
      StarterKit.configure({
        hardBreak: false,
        document: false,
        dropcursor: false,
        heading: false,
        horizontalRule: false,
        blockquote: false,
        codeBlock: false,
      }),
      Underline,
      Subscript,
      Superscript,
      Columns,
      CharacterCount.configure({
        limit: props.maxLimit,
      }),
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
      FontSize,
      FontFamily,
      Color,
      TrailingNode,
      Emoji.configure({
        enableEmoticons: true,
        suggestion: emojiSuggestion,
      }),
      CodeBlockLowlight.configure({
        lowlight,
        defaultLanguage: null,
      }),
      Link,
      Table,
      TableCell,
      TableHeader,
      Focus,
      TableRow,
      Typography,
      Placeholder,
      SlashCommand,
      VariablesExtension,
      BlockquoteFigure,
      Dropcursor.configure({
        width: 2,
        class: 'ProseMirror-dropcursor border-black',
      }),
    ],
    onUpdate: () => {
      const html = editor.value?.getHTML()
      emit('update:modelValue', html)
    },
  })
})

onBeforeUnmount(() => {
  editor.value?.destroy()
})

defineExpose({
  editor,
})
</script>

<style lang="scss">
#text-editor {
  @apply border border-input rounded-md;
  &.readonly {
    @apply border-none
  }

  .toolbar {
    @apply flex items-center flex-wrap border-b border-input;

    > button {
      @apply flex items-center justify-center h-8 w-8 text-xl bg-white text-gray-900 rounded-sm mx-1 my-0.5 cursor-pointer;

      &.active {
        @apply text-white bg-gray-500;
      }
    }
  }

  .align-dropdown {
    @apply relative inline-block my-0.5 mx-2;

    > button {
      @apply h-8 text-gray-500 bg-white border-none rounded-sm cursor-pointer;
    }

    > .dropdown-content {
      @apply hidden absolute left-0 right-0 border border-input rounded-sm bg-white z-10;

      .dropdown-item {
        @apply block text-left w-full no-underline text-gray-500 hover:bg-gray-200 px-2 py-1.5 cursor-pointer;

        &:hover, &.active {
          @apply text-white bg-gray-500;
        }
      }
    }

    &:hover .dropdown-content {
      @apply block;
    }
  }

  .footer {
    @apply flex justify-between text-gray-500 text-sm px-3 py-2;
  }

  .ProseMirror {
    @apply px-8 py-3 min-w-full w-full overflow-y-auto outline-none;
    min-height: 100px;
    max-height: 600px;
    height: auto;
  }
  &.autoHeight .ProseMirror {
    max-height: none;
  }

  &.readonly .ProseMirror {
    @apply h-auto px-2;
  }
}
</style>
